<!--  -->
<template>
    <div class='com-global-pet-animation' @click="$emit('click')">
        <div class="user-avatar" v-if="gif">
            <img :src="src" alt="" :style="petStyle">
        </div>
        <div class="user-avatar" v-else>
            <img :src="staticUrl" class="static-img">
        </div>
        <div class="pet-avatar"></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            url: {
                type: [String, Array],
                default(){
                    return []
                }
            },
            eatUrl: {
                type: [String, Array],
                default(){
                    return []
                }
            },
            pageName: String,
            scaleNum: {
                type: Number,
                default: 1
            },
            gif: {
                type: Boolean,
                default: true
            },
            userInfoProp: {
                type: Object,
                default(){ return {} }
            },
        },
        data() {
            return {
                index: null,
                animationTime: 8000,
                pageState: true,
                state: false,
                userInfo: {},
                eatUrlsControlActive: false,
                routeData: this.$route
            };
        },
        methods: {
            async start(){
                this.state = false
                if(!this.pageState) return
                let urls = this.urlsCul
                if(urls.length <= 1){
                    this.index = 0
                    return 
                }
                let index = 0
                if(this.index !== null) index = ++this.index % urls.length
                if(!urls[index]) return
                this.state = true
                this.index = index
                await this._common.settimeout(this.animationTime)
                this.start()
            },
            userInfoChange(e){
                let userInfo = this.$store.state.app
                this.userInfo = {}
                for(let i in userInfo){
                    this.$set(this.userInfo, i, userInfo[i])
                }
            },
            async eatUrlsControl(){
                if(this.eatUrlsControlActive) return
                this.eatUrlsControlActive = true
                await this._common.settimeout(this.animationTime)
                this._userHand.removePetEatUrl()
                this.eatUrlsControlActive = false
            },
            urlParse(list){
                // let list = data
                // if(!this._common.isObject(data)) data = {path: data}
                // list = data.path
                // if(!this._common.isString(list)){
                //     try{
                //         list = list.split(',')
                //     }catch(e){list = data.path}
                //     if(!this._common.isArray(list)) list = []
                // }
                // for(let i in list){
                //     if(this._common.isString(list[i])){
                //         list[i] = {
                //             path: list[i],
                //             scale: 1
                //         }
                //     }
                //     if(list[i].scale === undefined) list[i].scale = 1
                // }
                // return list
                if(this._common.isString(list)){
                    let tem = list
                    try{
                        list = list.split(',')
                    }catch(e){ list = tem }
                    if(!this._common.isArray(list)) list = []
                }
                for(let i in list){
                    if(this._common.isString(list[i])){
                        list[i] = {
                            path: list[i],
                            scale: 1
                        }
                    }
                    if(list[i].scale === undefined) list[i].scale = 1
                }
                return list
            }
        },
        created() {
            this.pageState = true
            this.userInfoChange('created')
            this._userHand.on('petAnimation', this.userInfoChange)
        },
        components: {
        },
        computed: {
            userInfoCul(){
                let {userInfoProp, userInfo} = this
                if(userInfoProp && userInfoProp.usercode) return userInfoProp
                return userInfo
            },
            staticUrl(){
                let {userInfoCul: userInfo} = this
                let url = ''
                let data = this._userHand.petUrls(userInfo.petUrl)
                data = data || {}
                url = data.static
                return url
            },
            urlsCul(){
                let {eatUrls, urls} = this
                let data = []
                if(eatUrls && eatUrls.length) data = eatUrls
                else data = urls
                return data

            },
            src(){
                let {urlsCul, index} = this
                if(!urlsCul[index]) return null
                let path = urlsCul[index].path || urlsCul[index]
                let t = (new Date()).getTime()
                // return `${path}?t=${t}`
                return path
            },
            scale(){
                let {urlsCul, index} = this
                let data = urlsCul[index]
                if(!data) return {}
                let {scale} = data
                if(scale === undefined || isNaN(Number(scale))) return 1
                return scale
            },
            petStyleData(){
                let {urlsCul, index} = this
                let data = urlsCul[index]
                if(!data) return {}
                let {scale, deltaX, deltaY} = data
                if(scale === undefined || isNaN(Number(scale))) scale = 1
                if(deltaX) deltaX = deltaX * 100
                if(deltaY) deltaY = deltaY * 100
                scale = scale * this.scaleNum
                return {scale, deltaX, deltaY}
            },
            petStyle(){
                let {scale, deltaX = 0, deltaY = 0} = this.petStyleData
                let pos = (1 - scale) * 100
                return {
                    'transform': `scale(${scale}) translate(${deltaX}%,${deltaY}%)`
                }
            },
            eatUrls(){
                return this.eatAnimations
            },
            urls(){
                return this.animations
            },
            animationWatch(){
                let {urls, eatUrls} = this
                return {urls, eatUrls}
            },
            eatAnimations(){
                let {eatAnimationList: list, pageNameCul: name} = this
                if(!list[name]) return []
                let data = list[name]
                data = this.urlParse(data)
                return data
            },
            animations(){
                let {animationList: list, pageNameCul: name} = this
                if(!list[name]) return []
                let data = list[name]
                data = this.urlParse(data)
                return data
            },
            eatAnimationList(){
                let {userInfoCul: userInfo} = this
                if(!userInfo.petEatUrl) return {}
                let data = this._userHand.petUrls(userInfo.petEatUrl) || {}
                let {animationList} = data
                if(!animationList) return {}
                return animationList
            },
            animationList(){
                let {userInfoCul: userInfo} = this
                let data = this._userHand.petUrls(userInfo.petUrl) || {}
                let {animationList} = data
                if(!animationList) return {}
                return animationList
            },
            pageNameCul(){
                let {name} = this.routeData
                return this.pageName || name
            },
        },
        watch: {
            urls: {
                handler(val){
                    let f = true
                    if(!val || !val.length){
                        f = false
                    }
                    this.$emit('petState', f)
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(val){
                    this.routeData = val
                },
                deep: true,
                immediate: true
            },
            eatUrls: {
                handler(val, oldVal){
                    if(!val || !val.length) return
                    this.eatUrlsControl()
                },
                deep: true,
                immediate: true
            },
            animationWatch: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal) || this.state) return
                    this.index = 0
                    this.start()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.pageState = false
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-user-avatar{
        .user-avatar{
            width: 100%;
            height: 100%;
        }
    }
</style>