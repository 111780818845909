<!--  -->
<template>
    <div class='timer-de'>
        <span class="time-box text-mini-a bold">
            {{valCul}}
        </span>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            time: Number,
            nanos: Number
        },
        data() {
            return {
                value: 0,
                countTimeSpeed: 100,
                interval: null,
                callback: null
            };
        },
        methods: {
            paramsInit(opt){
                this.callback = null
                this._common.paramsInit.call(this, opt)
            },
            start(opt){
                opt = opt || {}
                this.paramsInit(opt)
                let val = this.value
                this.action()
            },
            
            action(){
                this.stop()
                let t = this.countTimeSpeed
                this.interval = setInterval(() => {
                    let val = this.value - t
                    if(val <= 0){
                        val = 0
                        this.timesupHand()
                    }
                    this.value = val
                }, t)
            },
            stop(){
                if(this.interval === null) return
                clearInterval(this.interval)
            },
            timesupHand(){
                this.stop()
                if(this.callback) this.callback({type: 'TIME UP'})
                this.$emit('timeUp')
            },
            reset(){
                let t = this.time || 0
                let nao = this.nanos || 0
                nao = nao / 1000000
                this.value = t * 1000 + nao
            },
            setValue(val){
                this.value = val
            },
        },
        created() {

        },
        components: {
        },
        computed: {
            valCul(){
                let val = parseInt(this.value / 1000)
                let min = parseInt(val / 60)
                let hou = parseInt(min / 60)
                min = min % 60
                let sec = val % 60
                let data = {
                    min, hou, sec
                }
                data = this._common.serverTimeFormat(data)
                return `${data.hou}:${data.min}:${data.sec}`
            },
            timeWatch(){
                let {time, nanos} = this
                return {time, nanos}
            }
        },
        watch: {
            time: {
                handler(){
                    // this.reset()
                },
                deep: true,
                immediate: true
            },
            value: {
                handler(val){
                    this.$emit('timeUpdate', val)
                },
                deep: true,
                immediate: true
            },
            timeWatch: {
                handler(){
                    this.reset()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.stop()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .timer-de{
        text-align: center;
        color: #656060;
    }
</style>