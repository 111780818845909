const index = () => import(/* webpackChunkName: game */ "../views/personal/index.vue")
const file = () => import(/* webpackChunkName: game */ "../views/personal/page/file.vue")
const summary = () => import(/* webpackChunkName: game */ "../views/personal/page/summary.vue")




export default {
    path: "/personal",
    name: "personal",
    component: index,
    backRouteName: 'Home',
	redirect: 'personal/summary/LTE=',
	children:[
		{
			path:'file',
			name:'personal-file',
			component:file,
			backRouteName:'Home',
		},
		{
			path:'summary/:routeData',
			name:'personal-summary',
			component:summary,
			backRouteName:'Home',
		},
		
	]
}
