<!-- -->
<template>
    <div class='global-com-share-poster flex-center' v-if="show">
        <div class="mark"></div>
        <div class="poster-board">
            <div class="off-btn" @click="close()" v-if="!createImageLoading"></div>
            <div class="logo flex-center">
                <span class="text-normal bold flex-center text-logo">高途</span>
            </div>
            <div class="cover"></div>
            <div class="brief">
                <span class="text-mini bold flex-center txt">我在【高途围棋】</span>
                <span class="text-mini bold flex-center txt">水平测试中达到了{{certInfo.certificateLevel}}级!</span>
                <span class="text-mini bold flex-center txt">你能闯到几级呢？</span>
                <span class="text-mini bold flex-center txt">快来和我PK吧!</span>
            </div>
            <div class="role">
                <div class="share-photo-box">
                    <user-photo ref="userPhoto"></user-photo>
                </div>
                <div class="name flex-center">
                    <span class="text-mini bold flex-center txt text-nickname">{{userInfo.nickname}}</span>
                </div>
            </div>
            <div class="footer">
                <div class="footer-brief">
                    <span class="text-mini-a bold txt">扫描二维码即刻解锁</span>
                    <span class="text-mini-a bold txt">【免费课程】与【对弈平台】！</span>
                </div>
                <div class="arrow"></div>
                <div class="qr-code">
                    <!-- <img class="qr" src=""> -->
                </div>
            </div>
        </div>
        <div class="share-board">
            <div class="share-shadow scale-button" v-for="(v,i) in funList" :key="i" @click="shareFun(v)">
                <div class="share" :class="v.icon"></div>
                <div class="share-txt">
                    <span class="text-mini-bb text-purple-color bold flex-center txt">{{v.title}}</span>
                </div>
            </div>
        </div>
        <div class="preview-image flex-center" v-if="previewUrl" @click="previewUrl = null">
            <img :src="previewUrl" alt="">
        </div>
    </div>
</template>

<script>
import apiClass from '@/API/page/certificate'
import shareClass from '@/API/page/share'
import html2canvas from "html2canvas"
html2canvas.debug = null


    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                shareApi: new shareClass(),
                appData: new this.appDataClass({key: 'app'}),
                show: false,
                level: 22,
                funList: [
                    {icon: 'c14_1', title: '分享到朋友圈', type: 2, name: '微信', fun :'moment'},
                    {icon: 'c14_2', title: '分享到微信', type: 1, name: '微信', fun :'wechat'},
                    // {icon: 'c14_3', title: '分享到微博', name: '微博', fun :'microblog'},
                    {icon: 'c14_0', title: '下载分享图片', type: 0, fun :'download'},
                ],
                certInfo: {},
                userInfo: {},
                share_errToast: null,
                download_sucToast: null,
                download_errToast: null,
                previewUrl: null,
                imgHasLoaded: false,
                createImageLoading: false,
                parseEles: [],
                CDNLinks: [
                    {url: `${this.PARAMS.assetsUrl}/img/v2_0/certificate/c24.png`, query: '.global-com-share-poster .poster-board'},
                    {url: `${this.PARAMS.assetsUrl}/img/v2_0/certificate/c25.png`, query: '.global-com-share-poster .poster-board .logo'},
                    {url: `${this.PARAMS.assetsUrl}/img/v2_0/certificate/c26.png`, query: '.global-com-share-poster .poster-board .cover'},
                    {url: `${this.PARAMS.assetsUrl}/img/v2_0/certificate/c28.png`, query: '.global-com-share-poster .poster-board .role'},
                    {url: `${this.PARAMS.assetsUrl}/img/v2_0/certificate/c29.png`, query: '.global-com-share-poster .poster-board .footer .arrow'},
                    {url: `${this.PARAMS.assetsUrl}/img/v2_0/certificate/c30.png`, query: '.global-com-share-poster .poster-board .footer .qr-code'},
                ]
            };
        },
        methods: {
            paramsInit(){
                this.share_errToast = null,
                this.download_sucToast = null,
                this.download_errToast = null,
                this.previewUrl = null
                this.createImageLoading = false
                this.parseEles = []
            },
            async pageInit(){
                this.getUserInfo()
            },
            share_Image(val){
                if(!this._common.clientAppCheck('gaotu')){
                    this._common.confirmPro.open({
                        message: this.TEXT.share['share client error'],
                        confirmType: 'c3',
                        buttons: [
                            {title: '确定', type: 'confirm'}
                        ]
                    })
                    return 
                }
                this.shareAppAction(val)
            },
            async shareAppAction(val){
                let url = await this.getShareImg(val)
                if(!url) return
                let client = this._common.getClient()
                let fun = `share_${client}`
                if(!this[fun]) return
                this[fun]({...val, url})
            },
            share_Android(val){
                let {type, url} = val
                let params = JSON.stringify({
                    url,
                    type,
                })
                try{
                    window.gaotuJSBridge.shareImageToNative(params)
                }catch(e){ 
                    if(type == 0) return this.download_err()
                    this.share_err(val)
                }
            },
            share_IOS(val){
                let {type, url} = val
                let params = JSON.stringify({
                    url,
                    type,
                })
                try{
                    window.webkit.messageHandlers.shareImageToNative.postMessage(params);
                }catch(e){ 
                    try{
                        params = JSON.parse(params)
                        let res = this._quitApp.shareImageToNativeIos(params)
                        if(res.isSuccess) console.log('share success')
                        else {
                            if(type == 0) this.download_err()
                            this.share_err(val)
                        }
                    }catch(err){ 
                        if(type == 0) return this.download_err()
                        this.share_err(val)
                    }
                }
            },
            async share_err(val){
                clearTimeout(this.share_errToast)
                let {name} = val
                if(!name) return
                this._common.confirmPro.open({
                    message: `分享失败，未找到${name}客户端`,
                    confirmType: 'c3',
                })
                if(this.share_errToast) return
                this.share_errToast = setTimeout(() => {
                    this._common.confirmPro.close()
                },5000)
            },
            async download_suc(){
                clearTimeout(this.download_sucToast)
                this._common.confirmPro.open({
                    message: '下载成功！已保存至手机相册',
                    confirmType: 'c3',
                })
                if(this.download_sucToast) return
                this.download_sucToast = setTimeout(() => {
                    this._common.confirmPro.close()
                },3000)
            },
            download_err(){
                clearTimeout(this.download_errToast)
                this._common.confirmPro.open({
                    message: '下载失败，请再次尝试',
                    confirmType: 'c3',
                })
                if(this.download_errToast) return
                this.download_errToast = setTimeout(() => {
                    this._common.confirmPro.close()
                },3000)
            },
            getUserInfo(){
                this.userInfo = this.$store.state.app
            },
            async getCertificate(val){
                let {show,usercode:user_code} = this
                if(!show) return
                let opt ={
                    certificate_id: val,
                    user_code,
                }
                let res = await this.api.getCertificate(opt)
                if(res){
                    let {certificateLevel} = res.townCertificate || {}
                    this.certInfo = {
                        certificateLevel,
                    }
                }
            },
            open(val){
                this.pageInit()
                this.show = true
                this.imgLoadInit()
                this.paramsInit()
                this.getCertificate(val)
            },
            close(){
                this.show = false
            },
            shareFun(val){
                let {fun} = val
                if(!fun) return
                this.share_Image(val)
            },
            async toImage(ele) {
                if(!ele) return
                let canvas = await html2canvas(ele, {
                    backgroundColor: null,
                    useCORS: true,
                })
                let dataURL = canvas.toDataURL("image/png")
                return dataURL
            },
            async getEleBolb(ele) {
                if(!ele) return
                let canvas = await html2canvas(ele, {
                    backgroundColor: null,
                    useCORS: true,
                })
                canvas.className = 'tem-ele'
                // canvas.width = 1000
                // canvas.height = 1000
                document.body.appendChild(canvas);
                await this._common.nextTick.call(this)
                let data = canvas.toDataURL("image/jpeg", 1);
                document.body.removeChild(canvas);
                return this.base64ToFile(data, 'share_image.jpg')
            },
            base64ToFile(dataurl, filename){
                let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type:mime});
            },
            async getShareImg(val){
                // return `${this.PARAMS.assetsUrlV2_0}/certificate/c26.png`


                let {type} = val || {}
                if(type == 0) return this.getDownloadUrl(val)
                let ele = document.querySelector('.global-com-share-poster .poster-board')
                if(!ele) return false
                this.createImageLoading = true
                this.styleParse()
                await this._common.nextTick.call(this)
                let fileData = await this.getEleBolb(ele)
                this.createImageLoading = false
                this.styleRepair()
                return this.uploadFile(fileData, type)
            },
            styleParse(){
                let eles = document.querySelectorAll('.global-com-share-poster .poster-board span[class^="text-"]')
                
                if(!eles || !eles.length) return
                for(let i in eles){
                    let ele = eles[i]
                    let className = ele.className
                    let styleData = getStyleData(className)
                    if(ele.style){
                        if(styleData) ele = setStyle(styleData, ele)
                        else ele.style.transform = `translateY(-25%)`
                    }
                }
                this.parseEles = eles
                function setStyle(items, ele){
                    if(!ele || !ele.style) return ele
                    items = items || {}
                    for(let i in items){
                        ele.style[i] = items[i]
                    }
                    return ele
                }
                function getStyleData(name){
                    name = name || ''
                    let list = {
                        'text-logo': {
                            transform: `translateY(-45%)`
                        },
                        // 'text-nickname': {
                        //     transform: `translateY(-25%)`
                        // }
                    }
                    let data = null
                    for(let i in list){
                        if(name.indexOf(i) > -1){
                            data = list[i]
                            break
                        }
                    }
                    return data
                }
            },
            styleRepair(){
                let {parseEles: eles} = this
                if(!eles || !eles.length) return
                for(let i in eles){
                    let ele = eles[i]
                    if(ele.style) ele.style.transform = `translateY(0)`
                }
                this.parseEles = []
            },
            async getDownloadUrl(val){
                let {type} = val || {}
                let ele = document.querySelector('.global-com-share-poster .poster-board')
                if(!ele) return false
                this.createImageLoading = true
                this.styleParse()
                await this._common.nextTick.call(this)
                let fileData = await this.getEleBolb(ele)
                this.createImageLoading = false
                this.styleRepair()
                return this.uploadFile(fileData, type)
            },
            getImage(url) {
                return new Promise(success => {
                    var xhr = new XMLHttpRequest();
                    xhr.open('get', url, true);
                    // 设置请求头（这一步得设置不然oss图片还是跨域）
                    xhr.setRequestHeader("Cache-Control", "no-cache");
                    xhr.responseType = 'blob';
                    xhr.onload = function () {
                        if (this.status == 200) {
                            let url = URL.createObjectURL(this.response);
                            success(url)
                        }else success(false)
                    };
                    xhr.send();
                })
                
            },
            async imgLoadInit(){
                if(this.imgHasLoaded) return
                await this._common.nextTick.call(this)
                let photoUrl = null
                if(this.$refs['userPhoto']) photoUrl = this.$refs['userPhoto'].photoCul
                if(photoUrl) this.CDNLinks.push({
                    url: photoUrl,
                    query: '.global-com-share-poster .com-user-photo .user-photo-img'
                })
                // this.PARAMS.loading.open()
                await this.downCDNImg()
                // this.PARAMS.loading.close()
                this.imgHasLoaded = true
                this.eleImgInit()
                return true
            },
            eleImgInit(){
                let {CDNLinks: list} = this
                for(let i in list){
                    let {query, imgLocalUrl} = list[i]
                    let ele = document.querySelector(query)
                    if(!ele || !imgLocalUrl) continue
                    let {tagName} = ele
                    if(ele != 'IMG'){
                        ele.style.background = `url('${imgLocalUrl}') no-repeat`
                        ele.style.backgroundSize = `100% 100%`
                    }else{
                        ele.src = imgLocalUrl
                    }
                    
                }
            },
            async downCDNImg(index){
                let {CDNLinks: list} = this
                index = index || 0
                if(!list[index]) return
                let {url} = list[index]
                let res = await this.getImage(url)
                if(res) this.CDNLinks[index].imgLocalUrl = res
                index++
                return this.downCDNImg(index)
            },
            async uploadFile(file, type){
                let file_type = type == 0 ? 2 : 1
                // file.name = file.name || "share_image"
                let res = await this.shareApi.uploadImage({
                    file_type,
                    file: [{key: 'certificateFiles', data: file}]
                })
                if(!res) return false
                if(this._common.isArray(res)) res = res[0]
                return res
            },
        },
        created() {
            
        },
        components: {
        },
        computed: {
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            username(){
                let {getUserName} = this
                return getUserName
            },
        },
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
.global-com-share-poster {
    @text-purple-color: #605D7B;
    z-index: 401;
    // background: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;

    .text-purple-color {
        color: @text-purple-color;
    }

    .mark {
        .background-img-max(url('@{assetsUrlV2_0}/certificate/c19.png'));
        width: 100vw;
        height: 100vh;
    }

    .poster-board {
        .background-img-max(url('@{assetsUrlV2_0}/certificate/c24.png'));
        position: absolute;
        left: 0;
        right: 0;
        bottom: 77px;
        margin: auto;
        width: 236px;
        height: 292px;

        .off-btn {
            .background-img-max(url('@{assetsUrlV2_0}/task_center/d5.png'));
            position: absolute;
            top: -5px;
            right: -5px;
            width: 17px;
            height: 17px;
            cursor: pointer;
            z-index: 1;
        }

        .logo {
            .background-img-max(url('@{assetsUrlV2_0}/certificate/c25.png'));
            position: absolute;
            top: 6px;
            left: 6px;
            width: 44px;
            height: 23px;
        }

        .cover {
            .background-img-max(url('@{assetsUrlV2_0}/certificate/c26.png'));
            position: absolute;
            top: 18px;
            left: 0;
            right: 0;
            width: 192px;
            height: 106px;
            margin: auto;
        }

        .brief {
            position: absolute;
            top: 130px;
            left: 0;
            right: 0;
            width: 124px;
            height: 67px;
            margin: auto;
            color: #605D7B;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .txt {
                white-space: nowrap;
            }
        }

        .role {
            .background-img-max(url('@{assetsUrlV2_0}/certificate/c28.png'));
            position: absolute;
            width: 84px;
            height: 21px;
            top: 204px;
            left: 0;
            right: 0;
            margin: auto;

            .share-photo-box {
                width: 21px;
                height: 21px;
                position: absolute;
                left: -10px;
                top: 0;
                bottom: 0;
                margin: auto;
                border-radius: 100%;
            }

            .img {
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c27.png'));
                width: 21px;
                height: 21px;
                position: absolute;
                left: -10px;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .name {
                width: 84px;
                height: 21px;
                position: relative;
                left: 5px;
                transform: scale(0.7);

                .txt {
                    white-space: nowrap;
                    color: #596380;
                    display: inline-block;
                    // overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    height: auto;
                    line-height: normal;
                }
            }
        }

        .footer {
            .footer-brief {
                position: absolute;
                bottom: 15px;
                left: 13px;
                width: 139px;
                height: 27px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                .txt {
                    white-space: nowrap;
                    transform: scale(0.9);
                    color: #697D8F;
                }

                .txt:first-child {
                    text-indent: 9px;
                }
            }

            .arrow {
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c29.png'));
                width: 20px;
                height: 11px;
                position: absolute;
                right: 60px;
                bottom: 32px;
            }

            .qr-code {
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c30.png'));
                width: 47px;
                height: 47px;
                position: absolute;
                right: 8px;
                bottom: 6px;

                .qr {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .share-board {
        .background-img-max(url('@{assetsUrlV2_0}/certificate/c12.png'));
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 597px;
        height: 71px;
        display: flex;
        justify-content: space-evenly;

        .share-shadow {
            .background-img-max(url('@{assetsUrlV2_0}/certificate/c13.png'));
            width: 44px;
            height: 44px;
            position: relative;
            top: 0;
            bottom: 0;
            margin: auto 0;

            .share {
                cursor: pointer;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 38px;
                height: 38px;
            }

            .c14_0 {
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c14_0.png'));
            }

            .c14_1 {
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c14_1.png'));
            }

            .c14_2 {
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c14_2.png'));
            }

            .c14_3 {
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c14_3.png'));
            }

            .share-txt {
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
                margin: 0 auto;

                .txt {
                    white-space: nowrap;
                    transform: scale(0.9);
                }
            }
        }
    }

    .preview-image {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.6);

        img {
            width: 236px;
            height: 292px;
        }
    }
}
</style>