<!--  -->
<template>
    <div class='com-global-user-avatar' @click="$emit('click')">
        <div class="user-avatar" v-if="hasPet && gif">
            <img :src="url" alt="">
        </div>
        <div class="user-avatar" v-else>
            <img :src="staticUrl" alt="">
        </div>
        <div class="pet-avatar"></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            userUrl: String,
            petUrl: String,
            pageName: String,
            userInfoProp: {
                type: Object,
                default(){ return {} }
            },
            gif: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                index: null,
                animationTime: 8000,
                userInfo: {},
                state: false,
                pageDestroy: false,
                routeData: this.$route,
            };
        },
        methods: {
            async start(){
                if(this.pageDestroy) return
                let {urls} = this
                if(urls.length <= 1){
                    this.index = 0
                    return 
                }
                let index = 0
                if(this.index !== null) index = ++this.index % urls.length
                if(!urls[index]) return
                this.state = true
                this.index = index
                await this._common.settimeout(this.animationTime)
                this.start()
            },
            userInfoChange(e){
                let userInfo = this.$store.state.app
                this.userInfo = {}
                for(let i in userInfo){
                    this.$set(this.userInfo, i, userInfo[i])
                }
            }
        },
        created() {
            this.userInfoChange('created')
            this._userHand.on('avatar', this.userInfoChange)
        },
        components: {
        },
        computed: {
            userInfoCul(){
                let {userInfoProp, userInfo} = this
                if(userInfoProp && (userInfoProp.usercode || userInfoProp.userCode || userInfoProp.user_code)) return userInfoProp
                return userInfo
            },
            staticUrl(){
                let {userInfoCul: userInfo} = this
                let url = ''
                let data = this._userHand.avatarDressUrl(userInfo.avatarDressUrl)
                data = data || {}
                url = data.static
                return url

            },
            hasPet(){
                let {petUrl, petStatus} = this.userInfoCul
                return petUrl && petStatus > 0
            },
            url(){
                let {urls, index} = this
                urls = urls || []
                if(!urls[index]) return null
                let path = urls[index]
                let t = (new Date()).getTime()
                // return `${path}?t=${t}`
                return path
            },
            urls(){
                return this.animations
            },
            animations(){
                let {animationList: list, pageNameCul: name, userInfoCul: {petId}, petStatus: status} = this
                if(!list[name]) return []
                let data = list[name][petId]
                if(!data || !data[status]) return []
                let rd = data[status]
                try{
                    rd = rd.split(',')
                }catch(e){ rd = data[status] }
                if(!this._common.isArray(rd)) rd = []
                return rd
            },
            animationList(){
                let {userInfoCul: userInfo} = this
                let data = this._userHand.avatarDressUrl(userInfo.avatarDressUrl) || {}
                let {animationList} = data
                if(!animationList) return []
                return animationList
            },
            pageNameCul(){
                let {name} = this.routeData
                return this.pageName || name
            },
            petStatus(){
                let {userInfoCul: userInfo} = this
                return this._userHand.getPetStatus(userInfo)
            }
        },
        watch: {
            $route: {
                handler(val){
                    this.routeData = val
                },
                deep: true,
                immediate: true
            },
            urls: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal) || this.state) return
                    this.start()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.pageDestroy = true
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-user-avatar{
        .user-avatar{
            width: 100%;
            height: 100%;
        }
    }
</style>