
import enums from '../js/enums'

let data = {
    coorLimit: {
        '19': {
            min: 'a',
            max: 's'
        },
        '13': {
            min: 'a',
            max: 'm'
        },
        '9': {
            min: 'a',
            max: 'i'
        },
    },
    sgfInfo: {
        gn: { label: '棋谱名', value: null },
        pw: { label: '白方姓名', value: null },
        pb: { label: '黑方姓名', value: null },
        wr: { label: '白方段位', value: null },
        br: { label: '黑方段位', value: null },
        wt: { label: '白方代表队', value: null },
        bt: { label: '黑方代表队', value: null },
        ha: { label: '让子', value: null },
        re: { label: '结果', value: null },
        ev: { label: '赛事', value: null },
        dt: { label: '日期', value: null },
        pc: { label: '地点', value: null },
        tm: { label: '比赛用时', value: null },
        ro: { label: '回合', value: null },
        lt: { label: '读秒', value: null },
        lc: { label: '读秒次数', value: null },
        rd: { label: '日期', value: null },
        km: { value: 7.5, label: '3又3/4子' },
        sz: { label: '棋谱路数', value: 19 },
        up: { label: '类型', value: null },
        st: { label: '起始手数', value: 0 }
    },
    areaMinValue: 0.1,
    assetsUrl: 'https://assets.19x19.com/gaotu',
    assetsUrlV2_0: 'https://assets.19x19.com/gaotu/img/v2_0',
    assetsUrl_root: 'https://assets.19x19.com',
    default_access_token: 'Z29sYXh5X3dlYjp4aW5nemhlbjA3MzA=',
    default_token_type: 'Basic',
    user_token_type: 'bearer',
    default_area_code: '0086',
    // default_area_code: '0077',
    user_items: ['access_token', 'refresh_token', 'token_type', 'usercode', 'username', 'photo', 'nickname', 'level', 'goalDifference', 'highestLevel'],
    user_level_items: ['level', 'goalDifference', 'highestLevel'],
    user_account_items: ['avatarDressUrl', 'avatarHeadUrl', 'avatarName', 'dressName', 'balance', 'avatarId', 'dressId', 'petUrl', 'petStatus', 'petName', 'petId', 'decorateId', 'decorateUrl', 'needActivateFlag', 'showActivateFlag', 'activateRemaining'],
    client_id: 'golaxy_web',
    scope: 'any',
    client_secret: 'xingzhen0730',
    AIList: [],
    minGameMoveNum: {
        '9': 5,
        '13': 5,
        '19': 5,
    },
    capture_start_board: {
        '9': [40, 41, 32, 31],
        '13': [84, 85, 72, 71],
    },
    AIName: '星阵',
    AIName_gaotu: 'AI机器人',
    resultList: {
        'N+R': {title: '无胜负', win: ''},
        'W+R': {title: '白中盘胜', win: 'white'},
        'B+R': {title: '黑中盘胜', win: 'black'},
        'W+T': {title: '白超时胜', win: 'white'},
        'B+T': {title: '黑超时胜', win: 'black'},
        'B+O': {title: '白断线负', win: 'black'},
        'W+O': {title: '黑断线负', win: 'white'},
    },
    resultSelect: [
        { value: 'N+R', label: ' ', name: '结果：无', liveTxt: '无胜负', hide: true },
        { value: 'B+R', label: '黑中盘胜', name: '结果：黑中盘胜' },
        { value: 'W+R', label: '白中盘胜', name: '结果：白中盘胜' },
        { value: 'B+T', label: '黑超时胜', name: '结果：黑超时胜' },
        { value: 'W+T', label: '白超时胜', name: '结果：白超时胜' },
        { value: 'B+', label: '黑胜', name: '结果：黑胜', num: true },
        { value: 'W+', label: '白胜', name: '结果：白胜', num: true },
        { value: 'R+R', label: '和棋', name: '结果：和棋', hide: true },
        { value: 'B+O', label: '白断线负', name: '结果：白断线负' },
        { value: 'W+O', label: '黑断线负', name: '结果：黑断线负' },
    ],
    gameType: {
        'capture': enums.EXERCISE_CAPTURE_MATCH,
        'play': enums.EXERCISE_CONTAIN_MATCH
    },
    designWidth: 667,
    designHeight: 375,
    gameJudgeMoveNum: {
        9: 30,
        13: 60,
        19: 100
    },
    sgfNodeKey: '_children',
    // deviceMinWidth: 640,
    // deviceMinHeight: 3
    subjectAIplayDelayTime: 650,
    subjectPageSize: 16,
    puzzleMaxNo: {
        'G1': 108
    },
    appMinWidth: 630,
    appMinHeight: 354,
    invideTokenCode: '6001',
    invideTokenStatus: '401',
    specHandList: {
        '401': 'invideTokenHand',
        '6001': 'invideTokenHand',
        '6002': 'invideTokenHand',
        '6003': 'invideTokenHand',
        '91000': 'balanceNotEnough',
        '71028': 'petStatusIsMax',
        '71033': 'stockNotEnough',
        // '71034': 'stockPurchased',
        '71035': 'activityClose',
        '71036': 'activityExpiration',
    },
    clientList: {
        'gaotuapp': 'gaotu',
        'gaotuapp-android': 'Android',
        'gaotuapp-ios': 'IOS'
    },
    AIplayDelayTime: 650,
    genmoveDelayStart: 1,
    genmoveDelayEnd: 4,
    petName: {
        'clever': '可莱沃',
        'keke': '可可',
        'min': 1,
        'max': 6
    },
    gameReviewId: 5,
    normalGame: 0,
    reportingGame: 1,
    reportGame: 2,
    gameReviewWatchInterval: 1500,
    terribleMoveMaxNum: 3,
    greatMoveMaxNum: 3,
    terribleMoveLevel: 4,
    greatMoveLevel: 0,
    petVoiceTime: 8000,
    genmoveResendTime: 2000, //genmove 重发间隔时间
    RRText: '和棋',
    game_level_keys: { //对弈小屋列表等级选择类型
        'capture': true,
        'play': true,
        'town_gaotu_capture': true,
        'town_gaotu_play': true,
    },
    resign_split_num: 20, // 拒绝认输后X手之前不能再次提出认输
    puzzle_last_num: 13,
    puzzle_normal_num: 16,
    last_puzzle_cheer_basic: 10000,
    guest_path_list: [
        {
            url: '/exercise-preview'
        }
    ],
    schoolList: [
        {name: '围棋小镇', id: '1', key: 'go', code: '0086', prefix: ''},
        {name: '思维小镇', id: '10001', key: 'mind', code: '0086', prefix: 'mind.html'},
        {name: '学霸小镇', id: '20001', key: 'middle', code: '0086', prefix: 'middle.html'},
    ],
    disabled_course_id: {
        '9': true
    },
    taste_enter_countdown: 2000
}
data.AIPhoto = `${data.assetsUrl}/img/avatar/photo/AI.png`

export default data