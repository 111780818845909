import Vue from 'vue'
import user from '@/components/user.vue'
import confirm from '@/components/confirm.vue'
import confirmPro from '@/components/confirmPro.vue'
import userPhoto from '@/components/userPhoto.vue'
import nickname from '@/components/nickname.vue'
import balance from '@/components/balance.vue'
import mainTime from '@/components/mainTime.vue'
import countdown from '@/components/countdown.vue'
import stone from '@/components/stone.vue'
import blurOverlay from '@/components/blurOverlay.vue'
import captureBox from '@/components/captureBox.vue'
import stoneLabelSwitch from '@/components/stoneLabelSwitch.vue'
import diaButton from '@/components/diaButton.vue'
import personalPieChart from '@/components/personalPieChart.vue'
import stoneJudge from '@/components/stoneJudge.vue'
import result from '@/components/result.vue'
import confirmPlay from '@/components/confirmPlay.vue'
import wave from '@/components/wave.vue'
import columnLine from '@/components/subjectChildrenComponents/columnLine.vue'
import rowLineLeft from '@/components/subjectChildrenComponents/rowLineLeft.vue'
import rowLineRight from '@/components/subjectChildrenComponents/rowLineRight.vue'
import fileItem from '@/components/subjectChildrenComponents/fileItem.vue'
import debugBox from '@/components/debugBox.vue'
import componentBcg from '@/components/componentBcg.vue'
import slButton from '@/components/button/slButton.vue'
import confirmTitle from '@/components/confirmTitle.vue'
import confirmPanel from '@/components/confirmPanel.vue'
import AIAvatar from '@/components/AI.vue'
import avatar from '@/components/avatar.vue'
import avatarStatic from '@/components/avatarStatic.vue'
import award from '@/components/award.vue'
import petStatic from '@/components/petStatic.vue'
import petAnimation from '@/components/petAnimation.vue'
import avatarPet from '@/components/avatarPet.vue'
import AIPhoto from '@/components/AIPhoto.vue'
import certWindow from '@/components/certificate/certWindow.vue'
import latex from '@/components/latex.vue'



Vue.component('user', user)
Vue.component('confirm', confirm)
Vue.component('confirmPro', confirmPro)
Vue.component('userPhoto', userPhoto)
Vue.component('nickname', nickname)
Vue.component('balance', balance)
Vue.component('mainTime', mainTime)
Vue.component('countdown', countdown)
Vue.component('stone', stone)
Vue.component('blurOverlay', blurOverlay)
Vue.component('captureBox', captureBox)
Vue.component('stoneLabelSwitch', stoneLabelSwitch)
Vue.component('diaButton', diaButton)
Vue.component('personalPieChart', personalPieChart)
Vue.component('stoneJudge', stoneJudge)
Vue.component('result', result)
Vue.component('confirmPlay', confirmPlay)
Vue.component('wave', wave)
Vue.component('columnLine', columnLine)
Vue.component('rowLineLeft', rowLineLeft)
Vue.component('rowLineRight', rowLineRight)
Vue.component('fileItem', fileItem)
Vue.component('debugBox', debugBox)
Vue.component('componentBcg', componentBcg)
Vue.component('slButton', slButton)
Vue.component('confirmTitle', confirmTitle)
Vue.component('confirmPanel', confirmPanel)
Vue.component('AIAvatar', AIAvatar)
Vue.component('avatar', avatar)
Vue.component('avatarStatic', avatarStatic)
Vue.component('award', award)
Vue.component('petStatic', petStatic)
Vue.component('petAnimation', petAnimation)
Vue.component('avatarPet', avatarPet)
Vue.component('AIPhoto', AIPhoto)
Vue.component('certWindow', certWindow)
Vue.component('latex', latex)
