import data from './base'
import enums from '../js/enums'
let key = 'go'
let list = data.schoolList
let schoolId = null
for(let i in list){
    if(key == list[i].key){
        schoolId = list[i].id
        break
    }
}

let params = {
    ...data,
    ENUMS: enums,
    school_id: schoolId,
    assetsUrl: 'https://assets.19x19.com/gaotu',
    assetsUrlV2_0: 'https://assets.19x19.com/gaotu/img/v2_0',
    assetsUrl_root: 'https://assets.19x19.com',
    appDataKey: 'app',
    user_level: [
        {name: '一品上', value: 2900, goalDifference: 5},
        {name: '一品中', value: 2800, goalDifference: 5},
        {name: '一品下', value: 2600, goalDifference: 5},
        {name: '二品上', value: 2500, goalDifference: 5},
        {name: '二品中', value: 2400, goalDifference: 5},
        {name: '二品下', value: 2300, goalDifference: 5},
        {name: '三品上', value: 2200, goalDifference: 5},
        {name: '三品中', value: 2100, goalDifference: 5},
        {name: '三品下', value: 2000, goalDifference: 5},
        {name: '四品上', value: 1900, goalDifference: 4},
        {name: '四品中', value: 1800, goalDifference: 4},
        {name: '四品下', value: 1700, goalDifference: 4},
        {name: '五品上', value: 1600, goalDifference: 4},
        {name: '五品中', value: 1500, goalDifference: 4},
        {name: '五品下', value: 1400, goalDifference: 4},
        {name: '六品上', value: 1300, goalDifference: 4},
        {name: '六品中', value: 1200, goalDifference: 4},
        {name: '六品下', value: 1100, goalDifference: 4},
        {name: '七品上', value: 1000, goalDifference: 3},
        {name: '七品中', value: 900, goalDifference: 3},
        {name: '七品下', value: 800, goalDifference: 3},
        {name: '八品上', value: 700, goalDifference: 3},
        {name: '八品中', value: 620, goalDifference: 3},
        {name: '八品下', value: 540, goalDifference: 3},
        {name: '九品上', value: 460, goalDifference: 3},
        {name: '九品中', value: 380, goalDifference: 3},
        {name: '九品下', value: 300, goalDifference: 3},
    ],
    peer_game_info: {
        capture: {
            game_type: enums.EXERCISE_CAPTURE_PEER_COMPETITIVE_MATCH,
            gamename: '吃子对弈',
            game_type_text: 'capture'
        },
        capture_taste: {
            game_type: enums.EXERCISE_CAPTURE_TASTE_COMPETITIVE_MATCH,
            gamename: '吃子对弈',
            game_type_text: 'capture'
        },
        play: {
            game_type: enums.EXERCISE_CONTAIN_PEER_COMPETITIVE_MATCH,
            gamename: '围空对弈',
            game_type_text: 'play'
        },
        play_taste: {
            game_type: enums.EXERCISE_CONTAIN_TASTE_COMPETITIVE_MATCH,
            gamename: '围空对弈',
            game_type_text: 'play'
        },
    },
    peer_game_info_type: {},
    game_default_rule: 'chinese',
    game_default_board_size: 19,
    default_komi: 7.5,
    default_handicap: 0,
    game_capture_text: 'capture',
    web_clientId: 'golaxy_web',
    phone_clientId: 'golaxy_phone',
    game_min_move_num: 0, //有效对局最小手数
    invalid_game_key: 'N+N',
    playResignPosition: 10,
    last_puzzle_cheer_basic: 10000,
    guest_path_list: [
        {
            url: '/exercise-preview'
        }
    ],
    invite_timeout: 30,
    actionCountdown: 10,
    actionCountdownWait: 15,
    applyJudgeCountdown: 30,
    readSecondNum: 10, //最小读秒时间
    min_goal_difference: 1, //最小净胜盘数
    game_room_max_user_num: 2,
    play_again_countdown_time: 10000,

    board_probs: {
        '19': {
            judge: 150,
            resign: 30,
            judgeCheck: 180,
            judgeCheckSplit: 10,
        },
        '13': {
            judge: 50,
            resign: 10,
            judgeCheck: 50,
            judgeCheckSplit: 10,
        },
        '9': {
            judge: 30,
            resign: 4,
            judgeCheck: 30,
            judgeCheckSplit: 5,
        }
    },
    gameTypeSocial: {
        'capture': enums.EXERCISE_CAPTURE_PEER_COMPETITIVE_MATCH,
        'play': enums.EXERCISE_CONTAIN_PEER_COMPETITIVE_MATCH
    },
    ai_game_info_type: {},
    play_again_max_num: 1,
    taste_max_wait_time: 30000,
    watch_game_detail: 'WSGAME_WATCH',
    play_game_detail: 'WSGAME_PLAY',
    AI_life_death: 'AI_LIFE_DEATH',
    AI_analysis: 'AI_ANALYSIS',
    AI_game: 'AI_GAME',
    taste_max_wait: 30, //匹配最长等待时间（秒）
    taste_query_time: 1 * 60, //每X秒询问一次是否继续匹配
    webSocket_http_time: 15000,
    rank_game_boardSize: 19,
    game_config_default_rule: 'chinese',
    game_config_default_stone: 0,
    rank_game_komi: 7.5,
    rank_game_handicap: 0,
    taste_success_delay: 3000, //匹配成功后延迟X秒后进入房间
    capture_game_result_wait_time: 15000,
    game_ready_interval_max_time: 30000,
    social_game_none_result: 'N+R',
    rank_max_level: 4,
    game_info_check_time: 5000,
    currency: '棋石',
    game_review_price: 30

}
params.peer_game_info_type[enums.EXERCISE_CAPTURE_PEER_COMPETITIVE_MATCH] = {
    type: params.peer_game_info.capture.game_type_text
}
params.peer_game_info_type[enums.EXERCISE_CONTAIN_PEER_COMPETITIVE_MATCH] = {
    type: params.peer_game_info.play.game_type_text
}
// 匹配
params.peer_game_info_type[enums.EXERCISE_CAPTURE_TASTE_COMPETITIVE_MATCH] = {
    type: params.peer_game_info.capture_taste.game_type_text
}
params.peer_game_info_type[enums.EXERCISE_CONTAIN_TASTE_COMPETITIVE_MATCH] = {
    type: params.peer_game_info.play_taste.game_type_text
}

params.ai_game_info_type[enums.EXERCISE_CAPTURE_MATCH] = {
    type: params.peer_game_info.capture.game_type_text
}
params.ai_game_info_type[enums.EXERCISE_CONTAIN_MATCH] = {
    type: params.peer_game_info.play.game_type_text
}


export default params