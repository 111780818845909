import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class avatar extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'avatar'
        let name = 'avatar'
        opt = {localKey, name}
        super(opt)
    }

    async dressList(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                ...opt
            }
        }
        let res = await this.requestAction('dressList', options)
        if(res){
            let dressNow = this._appData.getItem('dressName', 'app'), dressId = null
            for(var i in res){
                let {dressName: name} = res[i]
                if(dressNow == name) dressId = res[i].dressId
            }
            if(dressId !== null) this._userHand.userAccountSet({dressId})
        }
        return res
    }

    async purchaseDress(opt){
        opt = opt || {}
        let {user_code, dressId: dress_id} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        if(!dress_id) return
        let options = {
            method: "post",
            ...opt,
            data:{
                // schoolId,
                dress_id
            }
        }
        return this.requestAction('purchaseDress', options)
    }

    async purchaseAvatar(opt){
        opt = opt || {}
        let {user_code, avatarId: avatar_id} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        if(!avatar_id) return
        let options = {
            method: "post",
            ...opt,
            data:{
                avatar_id
            }
        }
        return this.requestAction('purchaseAvatar', options)
    }

    async purchaseDecorate(opt){
        opt = opt || {}
        let {user_code, decorateId: decorate_id} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        if(!decorate_id) return
        let options = {
            method: "post",
            ...opt,
            data:{
                decorate_id
            }
        }
        return this.requestAction('purchaseDecorate', options)
    }

    async avatarList(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                // schoolId
            }
        }
        let res = await this.requestAction('avatarList', options)
        if(res){
            let avatarNow = this._appData.getItem('avatarName', 'app'), avatarId = null
            let rd = []
            for(let i in res){
                if(res[i].deleteFlag) continue
                let {avatarUrl, avatarName: name, id} = res[i]
                try{
                    avatarUrl = JSON.parse(avatarUrl)
                }catch(e){ avatarUrl = {} }
                res[i].avatarUrl = avatarUrl
                if(name == avatarNow) avatarId = id
                rd.push(res[i])
            }
            res = rd
            if(avatarId !== null) this._userHand.userAccountSet({avatarId})
        }
        return res
    }

    async avatarDress(opt){
        opt = opt || {}
        let {dressId: dress_id, avatarId: avatar_id} = opt
        // schoolId = schoolId || this.PARAMS.school_id
        if(!dress_id || !avatar_id) return
        opt = {
            ...opt,
            // schoolId,
            noError: true,
            fullData: true
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                dress_id,
                avatar_id
            }
        }
        let res = await this.requestAction('avatarDress', options)
        let data = null
        if(res && res.code == 0){
            data = res.data
            data.avatarDressUrl = this._common.parseAvatarDressUrl(data.avatarDressUrl)
        }
        return {code: res.code, data}
    }

    async purchasePet(opt){
        opt = opt || {}
        let {user_code, petId: pet_id, petName: pet_name} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        // schoolId = schoolId || this.PARAMS.school_id
        opt = {
            ...opt,
            user_code
        }
        if(!pet_id || !pet_name) return
        let options = {
            method: "post",
            ...opt,
            data:{
                // schoolId,
                pet_id,
                pet_name
            }
        }
        return this.requestAction('purchasePet', options)
    }

    async decorateList(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        let options = {
            method: "get",
            ...opt,
            data:{
            }
        }
        let res = await this.requestAction('decorateList', options)
        if(res){
            // let avatarNow = this._appData.getItem('avatarName', 'app'), avatarId = null
            // let rd = []
            // for(let i in res){
            //     if(res[i].deleteFlag) continue
            //     let {avatarUrl, avatarName: name, id} = res[i]
            //     try{
            //         avatarUrl = JSON.parse(avatarUrl)
            //     }catch(e){ avatarUrl = {} }
            //     res[i].avatarUrl = avatarUrl
            //     if(name == avatarNow) avatarId = id
            //     rd.push(res[i])
            // }
            // res = rd
            // if(avatarId !== null) this._userHand.userAccountSet({avatarId})
        }
        return res
    }

    async checkDressRedeemCode(opt){
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('checkDressRedeemCode', options)
        return res
    }

    async redeemDress(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        let options = {
            method: "post",
            ...opt,
            data:{
                ...opt
            }
        }
        return this.requestAction('redeemDress', options)
    }

    async dressOverdue(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                ...opt
            }
        }
        return this.requestAction('dressOverdue', options)
    }
}

export default singleProxy(avatar)
