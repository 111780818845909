<!--  -->
<template>
    <div class='com-user-pet'>
        <div class="pet-body-content" v-if="hasPet">
            <div class="pet-item a" v-if="isNormal && petAnimationState">
                <div class="a-i a">
                    <span class="text-mini-b v2_0-color bold">{{TEXT.components['t19']}}</span>
                </div>
                <div class="a-i b">
                    <div class="press" :style="petStatusStyle"></div>
                    <div class="border-st"></div>
                    <div class="pre-icon vertical-align-center-position flex-center"
                    :style="petStatusStyle_pre"
                    >
                        <span class="text-mini-b">{{userInfoCul.petStatus}}</span>
                    </div>
                </div>
            </div>
            <div class="pet-item b">
                <!-- <div class="b-pet"></div> -->
                <petAnimation :pageName.sync="pageName" @petState="petStateChange" :scaleNum.sync="scaleNum" :gif.sync="gif" :userInfoProp="userInfoProp"></petAnimation>
                <!-- <div class="b-shadow align-center-position"></div> -->
            </div>
            <div class="pet-item c flex-center" v-if="isNormal && petAnimationState">
                <span class="text-mini-b v2_0-color bold">{{userInfoCul.petName}}</span>
            </div>
        </div>
        <div class="pet-body-get scale-button" v-else-if="petTake" @click="takePet">
            <!-- <normalAnimation ref="" :urls="anumationUrls"></normalAnimation> -->
            <div class="pet-get-text-box position-center flex-center">
                <span class="text-mini-c bold" v-html="petGetText"></span>
            </div>
        </div>
        <confirmPro ref="confirm">
            <div class="pet-select-box" slot="message">
                <div class="sel-title">
                    <span class="text-normal-aa bold">{{TEXT.pet['select pet title']}}</span>
                </div>
                <div class="sel-content">
                    <div class="pet-item" v-for="v in petListCul" :key="v.id">
                        <petStatic :src="v.static" :name="v.petName"></petStatic>
                        <div class="pet-name" v-if="v.name">
                            <confirmPlay type="primary" @click="purchasePet(v)">
                                <span class="text-normal-aa flex-center">{{v.name}}</span>
                            </confirmPlay>
                        </div>
                    </div>
                </div>
            </div>
        </confirmPro>
        <confirmPro ref="petNameConfirm" class="pet-name-notice" :class="{'balance': purchaseData.price > 0 && petName}">
            <div class="pet-confirm-notice">
                <div class="notice-title">
                    <span class="text-normal-aa bold">{{TEXT.pet['purchase notice']}}</span>
                </div>
                <div class="notice-price-title" v-if="purchaseData.price > 0 && petName">
                    <span class="text-mini-bb bold">{{_common.urlParse(TEXT.pet['purchase price notice'], {price: purchaseData.price})}}</span>
                </div>
                <div class="notice-sub-title">
                    <span class="text-mini-bb">（{{TEXT.pet['pet name notice']}}）</span>
                </div>
                <div class="notice-content flex-center">
                    <div class="input-body flex">
                        <div class="input-icon"></div>
                        <input v-model="petNameDefine" class="name-input-area text-mini" />
                        <div class="error-msg" >
                            <span class="text-mini-c text-error-color">{{petNameErrorMsg}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </confirmPro>
    </div>
</template>

<script>
    // import normalAnimation from '@/components/animation.vue'
    import apiClass from '@/API/page/user'
    import apiAvatarClass from '@/API/page/avatar'

    export default {
        name: '',
        props: {
            type: {
                type: String,
                default: 'normal'
            },
            pageName: String,
            scaleNum: Number,
            gif: Boolean,
            petTake: {
                type: Boolean,
                default: true
            },
            userInfoProp: {
                type: Object,
                default(){ return {} }
            },
        },
        data() {
            return {
                api: new apiClass(),
                apiAvatar: new apiAvatarClass(),
                anumationUrls: [],
                petList: [],
                petNameDefine: null,
                purchaseData: {},
                petNameErrorMsg: null,
                userInfo: {},
                petAnimationState: false,
                
            };
        },
        methods: {
            animationInit(){
                let num = 37
                for(let i = 0; i < num; i++){
                    this.anumationUrls.push(`${this.PARAMS.assetsUrlV2_0}/animation/com/pet/${i}.png`)
                }
            },
            async pageInit(){
                this.animationInit()
                await this.listLoad()
            },
            async reClaimConfirm(){
                let message = this.TEXT.pet['claim pet again confirm']
                let list = this.petListCul
                let {price} = list[0] || {}
                message = this._common.urlParse(message, {price})
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    className: 'pet-re-claim',
                    buttons: [
                        {title: '确认', label: 0, type: 'confirm'},
                        {title: '取消', label: 1, type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0

            },
            async takePet(){
                if(this.hasPet) return
                if(this.petId){
                    let state = await this.reClaimConfirm()
                    if(!state) return
                }
                this.$refs['confirm'].open({
                    confirmType: "c9"
                })
            },
            async listLoad(){
                let res = await this.api.petList()
                if(res) this.petList = res
                
            },
            async purchasePet(val){
                this.petNameErrorMsg = null
                let res = await this.purchaseNotice(val)
                if(!res) return
                let errorMsg = this.petNameError()
                if(errorMsg){
                    this.petNameErrorMsg = errorMsg
                    return
                }
                this.$refs['petNameConfirm'].close()
                this.purchaseAction(val)

            },
            async purchaseAction(val){
                let {id: petId} = val || {}, petName = this.petNameDefine
                let res = await this.apiAvatar.purchasePet({petId, petName})
                if(res){
                    this._common.toastOpen({
                        message: this.TEXT.pet['purchase success']
                    })
                    this.$refs['confirm'].close()
                    this.apiAvatar.userAccount()
                }
            },
            async purchaseNotice(val){
                let message = ''
                this.purchaseData = val
                this.petNameErrorMsg = null
                this.petNameDefine = null
                this
                if(this.petName) this.petNameDefine = this.petName
                return this.purchaseNoticeAction(val)
            },
            async purchaseNoticeAction(val){
                let res = await this.$refs['petNameConfirm'].open({
                    confirmType: 'c3plus',
                    showClose: true,
                    buttons: [
                        {title: '确认', type: 'confirm', label: 0, keepOpen: true}
                    ]
                })
                if(!res) return
                res = res || {}
                let errorMsg = this.petNameError()
                if(errorMsg){
                    this.petNameErrorMsg = errorMsg
                    return this.purchaseNoticeAction(val)
                }
                return res.label == 0
            },
            userInfoChange(e){
                let userInfo = this.$store.state.app
                this.userInfo = {}
                for(let i in userInfo){
                    this.$set(this.userInfo, i, userInfo[i])
                }
            },
            voicePlay(index){
                let {voicePath: paths} = this
                if(!paths || !paths.length) return
                index = index || 0
                let path = paths[index]
                if(!path) return
                this._voiceHand.playByPath(path)
            },
            voicePause(){
                let {voicePath: paths} = this
                paths = paths || []
                for(let i in paths){
                    let path = paths[i]
                    this._voiceHand.pauseByPath(path)
                }
            },
            petStateChange(val){
                this.petAnimationState = val
            },
            petNameError(){
                let {petNameDefine} = this
                let {min, max} = this.PARAMS.petName
                let txt = null
                if(!petNameDefine || petNameDefine.length < min || petNameDefine.length > max) txt = this._common.urlParse(this.TEXT.pet['name disabled'], {min, max})
                return txt
            },
        },
        created() {
            this.userInfoChange('created')
            this._userHand.on('pet', this.userInfoChange)
            this.pageInit()
        },
        components: {
            // normalAnimation
        },
        computed: {
            userInfoCul(){
                let {userInfoProp, userInfo} = this
                if(userInfoProp && userInfoProp.usercode) return userInfoProp
                return userInfo
            },
            isNormal(){
                return this.type == 'normal'
            },
            isConcise(){
                return this.type == 'concise'
            },
            voicePath(){
                let {eatVoice, normalVoice} = this
                let path = null
                if(eatVoice && eatVoice.length) path = eatVoice
                else path = normalVoice
                return path
            },
            eatVoice(){
                let path = null
                let {userInfoCul: userInfo} = this
                if(!userInfo.petEatUrl) return null
                let data = this._userHand.petUrls(userInfo.petEatUrl)
                data = data || {}
                if(this._common.isObject(data)) path = data.voice
                if(!path) return []
                let path_copy
                if(!this._common.isArray(path)){
                    try{
                        path = path.split(',')
                    }catch(e) { path = path_copy }
                }
                return path
            },
            normalVoice(){
                let path = null
                let {userInfoCul: userInfo} = this
                let data = this._userHand.petUrls(userInfo.petUrl)
                data = data || {}
                if(this._common.isObject(data)) path = data.voice
                if(!path) return []
                let path_copy
                if(!this._common.isArray(path)){
                    try{
                        path = path.split(',')
                    }catch(e) { path = path_copy }
                }
                return path
            },
            petStatusStyle(){
                let {petStatus} = this.userInfoCul
                return {
                    width: `${petStatus}%`
                }
            },
            petStatusStyle_pre(){
                let {petStatus} = this.userInfoCul
                return {
                    left: `${petStatus}%`
                }
            },
            
            petListCul(){
                let {petList: list} = this
                let {petName} = this.PARAMS
                for(let i in list){
                    let {petName: name} = list[i]
                    if(!petName[name]) continue
                    name = petName[name]
                    list[i] = {
                        ...list[i],
                        name
                    }
                }
                return list
            },
            hasPet(){
                let {petUrl, petStatus} = this.userInfoCul
                return petUrl && petStatus > 0
            },
            petId(){
                let {petId} = this.userInfoCul
                return petId
            },
            petName(){
                let {petName} = this.userInfo
                return petName
            },
            petGetText(){
                let {petUrl, petStatus, petName} = this.userInfoCul
                if(!petUrl) return this.TEXT.pet['take pet new']
                if(petStatus <= 0) return this._common.urlParse(this.TEXT.pet['take pet re'], {petName}) 
                return petUrl && petStatus > 0
            },
            voiceWatch(){
                let {gif, voicePath} = this
                return {gif, voicePath}
            }
        },
        watch: {
            voiceWatch: {
                handler(val, oldVal){
                    let {gif} = val
                    if(this._common.isSameObject(val, oldVal) || !gif) return
                    this.voicePlay()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.voicePause()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-user-pet{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .pet-body-content{
            width: 80px;
        }
        .com-global-pet-animation{
                img{
                    width: 80px;
                    height: auto;
                }
            }
        .pet-item.a{
            width: 71px;
            text-align: center;
            // margin-bottom: 19px;
            .a{
                margin-bottom: 4px;
            }
            .b{
                width: 71px;
                height: 9px;
                border-radius: 9px;
                position: relative;
                @press-size: 60%;
                .border-st{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 2;
                    .background-img-max(url('@{assetsUrlV2_0}/user/c26.png'));
                }
                .pre-icon, .press{
                    transition: all .5s;
                }
                .pre-icon{
                    position: absolute;
                    width: 16px;
                    height: 15px;
                    left: @press-size;
                    transform: translate(-50%, -50%);
                    z-index: 3;
                    .background-img-max(url('@{assetsUrlV2_0}/user/c28.png'));  
                }
                .press{
                    position: absolute;
                    width: @press-size;
                    height: 7px;
                    border-radius: 7px;
                    top: 50%;
                    margin-left: 1px;
                    transform: translateY(-50%);
                    .background-img-max(url('@{assetsUrlV2_0}/user/c27.png'));
                    left: 0px;
                    z-index: 1;
                    
                }
            }
        }
        .pet-item.b{
            position: relative;
            z-index: 3;
            .b-pet{
                width: 80px;
                height: 79px;
                .background-img-max(url('@{assetsUrlV2_0}/user/c29.png'));
            }
            .b-shadow{
                z-index: -1;
                position: absolute;
                top: 67px;
                width: 73px;
                height: 21px;
                .background-img-max(url('@{assetsUrlV2_0}/user/shadow_pet.png'));
            }
        }
        .pet-item.c{
            // margin-top: 10px;
            width: 76px;
            height: 20px;
            .background-img-max(url('@{assetsUrlV2_0}/user/c31.png'));
        }

        .pet-body-get{
            width: 80px;
            height: 80px;
            position: relative;
            .background-img-max(url('@{assetsUrlV2_0}/animation/com/pet/pet.gif'));
            .com-global-animation-image{
                width: 100%;
                height: 100%;
            }
            .pet-get-text-box{
                color: #4B4547;
                width: 90%;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 5px;
                border: 1.1px solid #EFFCFF;
                span[class^='text']{
                    height: auto;
                    float: left;
                    line-height: 10px;
                }
            }
        }
        .pet-select-box{
            width: 100%;
            .sel-title{
                margin-bottom: 17px;
                color: #6F5E65;
            }
            .sel-content{
                padding-left: 48px;
                display: flex;
                .pet-item+ .pet-item{
                    margin-left: 73px;
                }
            }
            .pet-name{
                margin-top: 7px;
                .com-confirm-button{
                    width: 68px;
                    height: 24px;
                    color: #BE7D18;
                }
            }
        }
        .pet-name-notice{
            .van-dialog.c3plus .gotu-confirm-pro{
                padding-top: 40px;
            }
            .notice-title{
                color: #6F5E65;
                height: 13px;
            }
            .notice-price-title{
                color: #6F5E65;
                margin-top: 5px;;
            }
            .notice-sub-title{
                margin: 5px 0px;
                color: #95948F;
            }
            span[class^='text-']{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .notice-content{
                .input-body{
                    position: relative;
                }
                .error-msg{
                    position: absolute;
                    top: 20px;
                    left: 0px;
                    width: 100%;
                    white-space: nowrap;
                }
                .input-icon{
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 4px;
                    margin: auto 0px;
                    width: 9px;
                    height: 12px;
                    .background-img-max(url('@{assetsUrlV2_0}/avatar/c32.png'));
                }
            }
            .name-input-area{
                padding: 3px 3px 3px 17px;
                color: #6F5E65;
                width: 131px;
                height: 17px;
                background: #D6D5CF;
                border: 0px;
                border-radius: 4px;
                text-align: left;

            }
        }
        .pet-name-notice.balance{
            .van-dialog.c3plus .gotu-confirm-pro{
                padding-top: 30px;
            }
        }
        
    }
</style>