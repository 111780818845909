<!--  -->
<template>
    <div class='com-stone-label-switch cursor-pointer' @click="valChange()" :class="{'open': valueData, 'close': !valueData}">
        <div class="sw-bar">
            <div class="sw-button">
                <div class="button-shadow"></div>
                <div class="button-bcg"></div>
                <div class="button-text bold flex-center nowrap" :class="{'open': valueData, 'close': !valueData}">{{buttonText}}</div>
            </div>
            <div class="sw-text left flex-center">
                <span class="text bold text-normal-ab">{{TEXT.components.t12}}</span>
            </div>
            <div class="sw-text right stone-label-text-color flex-center">
                <span class="text bold text-normal-aa">{{TEXT.components.t13}}</span>
            </div>

            <!-- <div class="bar-new-left">
                <div class="text-box"></div>
            </div>
            <div class="bar-new-right"></div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            value: Boolean
        },
        data() {
            return {
                valueData: false
            };
        },
        methods: {
            valChange(val){
                if(val === undefined) val = !this.valueData
                this.valueData = val
                this.$emit('change', val)
            }
        },
        created() {

        },
        components: {
        },
        computed: {
            buttonText(){
                return this.valueData ? '手数' : '123'
            }
        },
        watch: {
            value: {
                handler(val){
                    this.valueData = val
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
        @height: 28px;
        @width: 79px;
        @btn-size: 40px;
        @btn-block-size: 32px;
    .com-stone-label-switch{
       
        width: @width;
        height: @height;
        .sw-bar{
            position: relative;
            height: 100%;
            width: 100%;
            border-radius: @height;
            display: flex;
            padding: 0px 5px;
            align-items: center;
            justify-content: space-between;
            transition: all .3s;
            // .sw-button::before{
            //     content: '';
            //     top: -4px;
            //     left: -4px;
            //     width: 40px;
            //     height: 40px;
            //     position: absolute;
            //     .background-img-max(url('@{assetsUrlV2_0}/game/g33.png'));
            // }
            .sw-button{
                top: 0px;
                bottom: 0px;
                margin: auto 0px;
                position: absolute;
                width: @btn-block-size;
                height: @btn-block-size;
                // background: #fff;
                // border-radius: 100%;
                // box-shadow: 0px 0px 6px #DCDCDC;
                z-index: 0;
                transition: left .3s;
                z-index: 2;
                .button-shadow{
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    .background-img-max(url('@{assetsUrlV2_0}/game/g33-shadow.png'));
                }
                .button-bcg, .button-text{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
                .button-text{
                    color: #FF7155;
                }
                .button-text.open{
                    font-size: 13px;
                }
                .button-text.close{
                    font-size: 15px;
                }
            }
            
            .sw-text{
                opacity: 0;
                // margin-top: -4px;
                top: 0px;
                bottom: 0px;
                margin: auto 0px;
                width: @btn-size;
                height: @btn-size;
                line-height: @btn-size;
                z-index: 1;
                transition: all .3s;
                position: absolute;
            }
            .sw-text.left{
                left: 0px;
                // background: url('@{assetsUrl}/img/game/g13_open.png') no-repeat;
                // background-size: 100% 100%;
            }
            .sw-text.right{
                width: auto;
                right: 8px;
                span[class^='text']{
                    
                }
            }
            .text{
                letter-spacing: -1px;
            }
        
            .bar-new-left{
                .text-box{
                    width: 40px;
                    height: 40px;
                }
            }
        
        }
    }
    .com-stone-label-switch.open{
        .sw-button{
            left: calc(@width - @btn-block-size);
            // background: url('@{assetsUrl}/img/game/g14_open.png');
            // background-size: 100% 100%;
            .button-bcg{
                .background-img-max(url('@{assetsUrlV2_0}/game/g33.png'));
                // .background-img-max(url('@{assetsUrl}/game/g14_open.png'));
            }
        }
        .sw-bar{
            .background-img-max(url('@{assetsUrlV2_0}/game/g32.png'));
            // background: #9BD2FF;
        }
        .sw-text.left{
            color: @stone-label-num-color;
            opacity: 1;
        }
        // .sw-text.left{
        //     background: url('@{assetsUrl}/img/game/g13_open.png') no-repeat;
        //     background-size: 100% 100%;
        // }
    }
    .com-stone-label-switch.close{
        .sw-bar{
            .background-img-max(url('@{assetsUrlV2_0}/game/g31.png'));
            // background: #EEEEEE;
        }
        .sw-button{
            left: 0px;
            // background: url('@{assetsUrl}/img/game/g14_close.png');
            // background-size: 100% 100%;
            .button-bcg{
                .background-img-max(url('@{assetsUrlV2_0}/game/g33.png'));
                // .background-img-max(url('@{assetsUrl}/img/game/g14_close.png'));
            }
        }
        .sw-text.left{
            color: @stone-label-text-color;
        }
        .sw-text.right{
            color: #ABABAE;
            opacity: 1;
            // background: url('@{assetsUrl}/img/game/g13_close.png') no-repeat;
            // background-size: 100% 100%;
        }
    }
</style>