const index = () => import(/* webpackChunkName: certificate */ "../views/certificate/index.vue")
const summary = () => import(/* webpackChunkName: certificate */ "../views/certificate/page/summary.vue")

export default {
    path: "/certificate",
    name: "certificate",
    redirect: '/certificate/summary',
    component: index,
    children:[
        {
            path: 'summary', 
            name: 'certificate-summary', 
            component: summary, 
            backRouteName: 'user-summary',
        },
    ]
}