
export default {
    'dataCount': {
        url: '/town/game/statistics/history/user/{user_code}',
        serve: 'social'
    },
    'playCount': {
        url: '/town/puzzle/statistics/history/user/{user_code}',
        serve: 'social'
    },
    'gameList': {
        url: '/town/game/list/{user_code}',
        serve: 'social'
    },
    'getGameReportCount': {
        url: '/town/game/user/report/count/{user_code}',
        serve: 'social'
    },
    'changeUserGameReport': {
        url: '/town/school/user/game/report/{user_code}',
        serve: 'social'
    }
}