<!-- -->
<template>
    <div class='global-com-share-poster flex-center mind' v-if="show">
        <div class="mark"></div>
        <div class="poster-board">
            <div class="off-btn" @click="close()" v-if="!createImageLoading"></div>
            <div class="logo flex-center">
                <span class="text-normal bold flex-center text-logo">高途</span>
            </div>
            <div class="cover"></div>
            <div class="brief">
                <span class="text-mini bold flex-center txt">我在【高途数理思维】</span>
                <span class="text-mini bold flex-center txt">水平测试中达到了{{certInfo.certificateLevel}}级!</span>
                <span class="text-mini bold flex-center txt">你能闯到几级呢？</span>
                <span class="text-mini bold flex-center txt">快来和我PK吧!</span>
            </div>
            <div class="role">
                <div class="share-photo-box">
                    <user-photo ref="userPhoto"></user-photo>
                </div>
                <div class="name flex-center">
                    <span class="text-mini bold flex-center txt text-nickname">{{userInfo.nickname}}</span>
                </div>
            </div>
            <div class="footer">
                <div class="footer-brief">
                    <span class="text-mini-a bold txt">扫描二维码即刻解锁</span>
                    <span class="text-mini-a bold txt">【免费课程】与【高途数理思维】！</span>
                </div>
                <div class="arrow"></div>
                <div class="qr-code">
                    <!-- <img class="qr" src=""> -->
                </div>
            </div>
        </div>
        <div class="share-board">
            <div class="share-shadow scale-button" v-for="(v,i) in funList" :key="i" @click="shareFun(v)">
                <div class="share" :class="v.icon"></div>
                <div class="share-txt">
                    <span class="text-mini-bb text-purple-color bold flex-center txt">{{v.title}}</span>
                </div>
            </div>
        </div>
        <div class="preview-image flex-center" v-if="previewUrl" @click="previewUrl = null">
            <img :src="previewUrl" alt="">
        </div>
    </div>
</template>

<script>
    import com from '@/components/sharePoster.vue'

    export default {
        name: '',
        extends: com,
        props: {},
        data() {
            return {}
        },
        methods: {},
        created() {},
        components: {},
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
.global-com-share-poster.mind {
    .poster-board {
        .footer {
            .footer-brief {
                left: 0px;
            }
        }
    }
}
</style>
