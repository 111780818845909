export default {
    'game-list-ai': {
        t1: '挑战AI',
        t2: '新手入门',
        t3: '能力进阶',
        t4: '吃子',
        t5: '对弈',
        t6: '高手切磋',
        t7: '王者争霸',
        t8: '巅峰对决',
        t9: '比赛规则',
        t10: '9路吃3子：吃3子获胜，40手和棋 13路吃5子：吃5子获胜，60手和棋 9路对弈：黑棋贴6目半 13路对弈：黑棋贴7目半 19路对弈：黑棋贴7目半',
        t11: '9路吃3子',
        t12: '13路吃5子',
        t13: '9路对弈',
        t14: '13路对弈',
        t15: '19路对弈',
        t16: '吃3子获胜，40手和棋',
        t17: '吃5子获胜，60手和棋',
        t18: '黑棋贴6目半',
        t19: '黑棋贴7目半',
        t20: '黑棋贴7目半',
        t21: '难度选择',
        t22: '确认',
        'list title': '对弈小屋'
    },
    'game-action': {
        t1: '路',
        't1-1': '吃',
        t2: '子',
        t3: '吃子数',
        rule_9: '吃3子获胜，40手和棋',
        rule_13: '吃5子获胜，60手和棋',
        t4: '手数',
        t5: '认输',
        t6: '确认落子',
        t7: '执黑',
        t8: '执白',
        t9: '猜先',
        t10: '清空',
        color_notice_1: '我方执黑棋',
        'color_notice_-1': '我方执白棋',
        't11': '确定认输吗？',
        t12: '比赛规则',
        t13: '≤5手 退出对局不计入胜负',
        t14: '退出对局将自动认输<br />≤5手 退出对局不计入胜负',
        t15: '确定退出吗？',
        t16: '确认',
        t17: '再想想',
        t18: '确认停一手吗？',
        judge_9: '40手以上才能数子哦~',
        judge_13: '60手以上才能数子哦~',
        judge_19: '100手以上才能数子哦~',
        judgeDis: '{num}手以上才能数子哦~',
        t19: '对弈',
        komi_9: '黑贴3.75子',
        komi_13: '黑贴3.75子',
        komi_19: '黑贴3.75子',
        t20: '比赛还没结束哟！',
        t21: '确认退出对局吗？',
        t22: 'AI非法落子',
        t23: '提子数',
        'AI resign confirm': '小神判-途途觉得这盘棋机器人输很多了，是否同意机器人中盘认输呢？',
        'game judge auto check': '小神判-途途觉得这盘棋下完了，是否同意开始数子判定胜负呢？'
    }
}