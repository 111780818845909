
const index = () => import(/* webpackChunkName: game */ "../views/game/index.vue")
const list = () => import(/* webpackChunkName: game */ "../views/game/page/list.vue")
const action = () => import(/* webpackChunkName: game */ "../views/game/page/action.vue")
const AIGameSummary = () => import(/* webpackChunkName: game */ "../views/game/page/AIGame/summary.vue")
const playerGameSummary = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/summary.vue")
const playerGameMenu = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/menu.vue")
const playerGameMenuTaste = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/menuTaste.vue")
const playerGameMenuInvite = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/menuInvite.vue")
const playerGameRoom = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/room/index.vue")
const playerGameRoomAction = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/room/action.vue")
const playerGameRoomConfig = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/inviteJoinConfirm.vue")
const playerGameHistory = () => import(/* webpackChunkName: game */ "../views/game/page/playerGame/history.vue")

export default {
    path: "/game",
    name: "game",
    component: index,
    children: [
        {
            path: 'list', name: 'game-list', component: list, backRouteName: 'Home',
            redirect: '/game/list/AI',
            children: [
                {path: 'AI', component: AIGameSummary, name: 'game-list-ai', backRouteName: 'Home'},
                {path: 'player', component: playerGameSummary, name: 'game-list-player', backRouteName: 'Home'},
            ]
        },
        {
            path: 'menu', name: 'player-menu', component: playerGameMenu, children: [
                { path: 'taste', name: 'player-menu-taste', component: playerGameMenuTaste, backRouteName: 'game-list-player' },
                { path: 'invite', name: 'player-menu-invite', component: playerGameMenuInvite, backRouteName: 'game-list-player' },
            ]
        },
        {
            path: 'room/:gameroomId', name: 'player-game-room', component: playerGameRoom,  backFun: 'gameBack', children: [
                {path: 'action', name: 'player-game-room-action', component: playerGameRoomAction},
                {path: 'confirm', name: 'player-game-room-config', component: playerGameRoomConfig},
            ]
        },
        {path: 'history', name: 'player-game-room-history', component: playerGameHistory, backRouteName: 'game-list-player'}, 
        {path: 'action/:routeData', name: 'game-action', component: action, backFun: 'gameBack'},
    ]
}