import data from './mind'

let key = 'middle'
let list = data.schoolList
let schoolId = null
for(let i in list){
    if(key == list[i].key){
        schoolId = list[i].id
        break
    }
}

export default {
    ...data,
    base_url: 'middle.html',
    school_id: schoolId,
    assetsUrlMind: 'https://assets.19x19.com/gaotu/mind',
    assetsUrlMiddle: 'https://assets.19x19.com/gaotu/middle',
    exercise_type_list: {
        'course': {
            title: '作业',
            fun: 'courseHandler',
            titleHandler: 'courseTitle'
        },
        'puzzle': {
            title: '闯关',
            fun: 'puzzleHandler'
        },
        'subject': {
            title: '专项',
            fun: 'subjectHandler'
        },
        'note': {
            title: '错题本',
            fun: 'noteHandler'
        },
        'palace': {
            title: '练习',
            fun: 'palaceHandler'
        },
        'island': {
            title: '海岛',
            fun: 'islandHandler'
        }
    }
}