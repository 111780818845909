<!--  -->
<template>
    <div class='com-capture'>
        <div class="cap-item a flex-center light-dark-color">
            <span class="text-small">{{TEXT.components.t11}}</span>
        </div>
        <div class="cap-item b">
            <div class="b-item a text-center text-black-color">
                <span class="text-normal-a bold">{{remove_LB}}</span>
            </div>
            <div class="b-item b text-center text-white-color">
                <span class="text-normal-a bold">{{remove_RW}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            blackRemove: {
                type: Number,
                default: 0
            },
            whiteRemove: {
                type: Number,
                default: 0
            },
            
            leftRemove: Number,
            rightRemove: Number,
        },
        data() {
            return {
                
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            remove_LB(){
                if(this.leftRemove !== undefined) return this.leftRemove
                return this.blackRemove
            },
            remove_RW(){
                if(this.rightRemove !== undefined) return this.rightRemove
                return this.whiteRemove
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-capture{
        @b-size: 24px;
        width: 100%;
        height: 100%;
        background: url('@{assetsUrl}/img/game/g_14.png');
        background-size: 100% 100%;
        padding: 2px;
        
        .cap-item.a{
            height: calc(100% - @b-size);
        }
        .cap-item.b{
            overflow: hidden;
            height: @b-size;
            line-height: @b-size;
            .b-item{
                float: left;
                width: 50%;
            }
        } 
    }
</style>