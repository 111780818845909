import params from './params'
import { Toast, ImagePreview } from 'vant';
import text from '../text/text'
import store from '@/store/index'
import {singleProxy} from './singleClass';
import errorCode from './errorCode'
import localData from './localData'

class commonFun {
    constructor(opt){
        this.init(params)
    }
    init(items){
        items = items || {}
        for(let i in items){
            this[i] = items[i]
        }
        this.eventList = {}
        this.onresizeList = {}
        this.TEXT = text
        this.appClickList = {}
        this.$store = store
        this._errorCode = errorCode
        this.localData = localData
    }
    getItem(key, localKey){
        localKey = localKey || this.localKey
        let data = this.localData.getItem(localKey)
        try{
            data = JSON.parse(data)
        }catch(e){
            data = {}
        }
        data = data || {}
        return data[key]
    }
    nextTick(){
        return new Promise(res => {
            this.$nextTick(() => {
                res()
            })
        })
    }
    funhandAct(list){
        list = this._common.deepCopy(list)
        let rd = []
        let normal = []
        let extend = []
        for(let i in list){
            if(list[i].selectedCul && this[list[i].selectedCul] !== undefined) list[i].selected = this[list[i].selectedCul]
            if(list[i].disabledCul && this[list[i].disabledCul] !== undefined) list[i].isDisabled = this[list[i].disabledCul]
            if(list[i].popoverCul && this[list[i].popoverCul] !== undefined) list[i].popover = this[list[i].popoverCul]
            if(list[i].difineTemplate && this[list[i].difineTemplate] !== undefined) list[i].template = this[list[i].difineTemplate]
            if(list[i].nameReset && this[list[i].nameReset] !== undefined) list[i].name = this[list[i].nameReset]
            if(list[i].sizeCul && this[list[i].sizeCul] !== undefined) list[i].size = this[list[i].sizeCul]
            if(list[i].btnClassCul && this[list[i].btnClassCul] !== undefined) list[i].btnClass = this[list[i].btnClassCul]
            if(list[i].boxClassCul && this[list[i].boxClassCul] !== undefined) list[i].boxClass = this[list[i].boxClassCul]
            if(list[i].boxClassCul &&  this[list[i].boxClassCul] !== undefined) list[i].boxClass = this[list[i].boxClassCul]
            if(list[i].loadingCul && this[list[i].loadingCul] !== undefined) list[i].loading = this[list[i].loadingCul]
            else list[i].size = 1
            if(list[i].sortCul && this[list[i].sortCul] !== undefined) list[i].sort = this[list[i].sortCul]
            else list[i].sort = i
            if(list[i].iconCul && this[list[i].iconCul] !== undefined) list[i].icon = this[list[i].iconCul]
            if(list[i].nameCul &&  this[list[i].nameCul] !== undefined) list[i].name = this[list[i].nameCul]
            if(list[i].propNumCul &&  this[list[i].propNumCul] !== undefined) list[i].propNum = this[list[i].propNumCul]
            if(list[i].funIconDefine &&  this[list[i].funIconDefine] !== undefined) list[i].funIconCul = this[list[i].funIconDefine]
            if(list[i].clickToOpenCul &&  this[list[i].clickToOpenCul] !== undefined) list[i].clickToOpen = this[list[i].clickToOpenCul]
            if(list[i].popoverSlotCul &&  this[list[i].popoverSlotCul] !== undefined) list[i].popoverSlot = this[list[i].popoverSlotCul]
            if(list[i].extendTextCul &&  this[list[i].extendTextCul] !== undefined) list[i].extendText = this[list[i].extendTextCul]
            
            
            if(list[i].display && this[list[i].display] !== undefined){
                if(!this[list[i].display]) continue
            }

            if(list[i].extendList && list[i].extendList.length){
                let extendList = []
                for(let j in list[i].extendList){
                    if(list[i].extendList[j].selectedCul && this[list[i].extendList[j].selectedCul] !== undefined) list[i].extendList[j].selected = this[list[i].extendList[j].selectedCul]
                    if(list[i].extendList[j].disabledCul && this[list[i].extendList[j].disabledCul] !== undefined) list[i].extendList[j].isDisabled = this[list[i].extendList[j].disabledCul]
                    if(list[i].extendList[j].popoverCul && this[list[i].extendList[j].popoverCul] !== undefined) list[i].extendList[j].popover = this[list[i].extendList[j].popoverCul]
                    if(list[i].extendList[j].difineTemplate && this[list[i].extendList[j].difineTemplate] !== undefined) list[i].extendList[j].template = this[list[i].extendList[j].difineTemplate]
                    if(list[i].extendList[j].nameReset && this[list[i].extendList[j].nameReset] !== undefined) list[i].extendList[j].name = this[list[i].extendList[j].nameReset]
                    if(list[i].extendList[j].sizeCul && this[list[i].extendList[j].sizeCul] !== undefined) list[i].extendList[j].size = this[list[i].extendList[j].sizeCul]
                    if(list[i].extendList[j].btnClassCul && this[list[i].extendList[j].btnClassCul] !== undefined) list[i].extendList[j].btnClass = this[list[i].extendList[j].btnClassCul]
                    if(list[i].extendList[j].boxClassCul && this[list[i].extendList[j].boxClassCul] !== undefined) list[i].extendList[j].boxClass = this[list[i].extendList[j].boxClassCul]
                    if(list[i].extendList[j].boxClassCul &&  this[list[i].extendList[j].boxClassCul] !== undefined) list[i].extendList[j].boxClass = this[list[i].extendList[j].boxClassCul]
                    if(list[i].extendList[j].loadingCul && this[list[i].extendList[j].loadingCul] !== undefined) list[i].extendList[j].loading = this[list[i].extendList[j].loadingCul]
                    else list[i].extendList[j].size = 1
                    if(list[i].extendList[j].iconCul && this[list[i].extendList[j].iconCul] !== undefined) list[i].extendList[j].icon = this[list[i].extendList[j].iconCul]
                    if(list[i].extendList[j].nameCul &&  this[list[i].extendList[j].nameCul] !== undefined) list[i].extendList[j].name = this[list[i].extendList[j].nameCul]
                    if(list[i].extendList[j].propNumCul &&  this[list[i].extendList[j].propNumCul] !== undefined) list[i].extendList[j].propNum = this[list[i].extendList[j].propNumCul]
                    if(list[i].extendList[j].funIconDefine &&  this[list[i].extendList[j].funIconDefine] !== undefined) list[i].extendList[j].funIconCul = this[list[i].extendList[j].funIconDefine]
                    if(list[i].extendList[j].popoverSlotCul &&  this[list[i].extendList[j].popoverSlotCul] !== undefined) list[i].extendList[j].popoverSlot = this[list[i].extendList[j].popoverSlotCul]

                    if(list[i].extendList[j].display && this[list[i].extendList[j].display] !== undefined){
                        if(!this[list[i].extendList[j].display]) continue
                    }
                    extendList.push(list[i].extendList[j])
                }
                list[i].extendList = extendList
            }


            rd.push(list[i])
            if(list[i].clickToOpen) extend.push(1)
            else normal.push(1)
        }
        if(extend.length && (normal.length + 1) >  extend.length){
            let l = normal.length - extend.length + 1
            for(let i = 1; i <= l; i++){
                rd.push({name: '', fun: '', clickToOpen: true})
            }
        }
        rd = this._common.sortData(rd, (v1, v2) => {
            return Number(v1.sort) < Number(v2.sort)
        })
        return rd
    }

    funListHandler(list){
        list = list || []
        list = this._common.deepCopy(list)
        let rd = []
        for(let i in list){
            let {disabled, text, display} = list[i]
            if(this[text] !== undefined) list[i].name = this[text]
            if(this[disabled] !== undefined) list[i].isDisabled = this[disabled]
            if(this[display] !== undefined && !this[display]) continue
            rd.push(list[i])
        }
        return rd
    }
    funHandler(val){
        let {fun, params} = val || {}
        if(!this[fun]) return
        this[fun](params)
    }


    deepCopy(obj) {
        if (this.isString(obj) || this.isBool(obj) || this.isNumber(obj) || !obj) return obj
        let data
        try{
            data = JSON.parse(JSON.stringify(obj))
        }catch(e){
            data = obj
        }
        return data
    }
    copy(obj) {
        return this.deepCopy(obj)
    }
    isArray(obj) {
        return Object.prototype.toString.call(obj) == '[object Array]'
    }
    isObject(obj) {
        return Object.prototype.toString.call(obj) == '[object Object]'
    }
    isString(obj) {
        return Object.prototype.toString.call(obj) == '[object String]'
    }
    isNumber(obj) {
        if(isNaN(Number(obj))) return false
        obj = Number(obj)
        return Object.prototype.toString.call(obj) == '[object Number]'
    }
    isBool(obj) {
        return Object.prototype.toString.call(obj) == '[object Boolean]'
    }
    isFunction(obj){
        let type = Object.prototype.toString.call(obj)
        return type == '[object Function]' || type == '[object AsyncFunction]'
    }
    isElement(obj){
        // [object HTMLSpanElement]
        let type = Object.prototype.toString.call(obj)
        return type.indexOf('HTML') > -1 && type.indexOf('Element') > -1
    }
    isVideo(val){
        let rx = /\.(mp4|wmv|ramb|avi|flv)(\?.*)?$/
        return rx.test(val)
    }
    isImg(val){
        let rx = /\.(png|jpe?g|gif|svg)(\?.*)?$/
        return rx.test(val)
    }
    isImageElement(ele){
        ele = ele || {}
        let {tagName} = ele
        return tagName == 'IMG'
    }
    isUrl(data){
        // let rx = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/
        // return rx.test(data)
        let rx = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/
        return rx.test()
    }
    isMobile() {
        if(!navigator) return false
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    }
    isIos() {
        if(!navigator) return false
        return navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    }
    isAndroid() {
        if(!navigator) return false
        return navigator.userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
    }
    takeFun(opt){
        opt = opt || {}
        let {fun, data} = opt
        if(this[fun]) return this[fun](data)
    }
    settimeout(time){
        time = time || 0
        return new Promise(res => {
            setTimeout(() => {
                res()
            }, time);
        })
    }
    sortData(data, handleFun) {
        let qsort = fn => ([x, ...xn]) => x == null ? []
            : [
                ...qsort(fn)(xn.filter(a => fn(a, x))),
                x,
                ...qsort(fn)(xn.filter(a => !fn(a, x)))
            ]
        return qsort((a, b) => {
            return handleFun(a, b)
        })(data)
    }
    
    getCoorLimit(size) {
        return params.coorLimit[size] || null
    }
    getSgfInfo(){
        return this.deepCopy(params.sgfInfo)
    }
    urlParse(url, opt){
        opt = opt || {}
        url = url || ''
        url = url.replace(new RegExp("{","gm"), "${")
        url = url.split('$')
        let rUrl = []
        for(var i in url){
            let tem = url[i] || ''
            if(tem.indexOf('{') > -1 && tem.indexOf('}') > -1){
                let funKey = tem.substring(tem.indexOf('{') + 1, tem.indexOf('}'))
                let params = ''
                if(opt[funKey] !== undefined) params = opt[funKey]
                else if(this[`${funKey}Get`]) params = this[`${funKey}Get`]()
                if(params !== undefined) tem = tem.replace(`{${funKey}}`, params)
                else tem = tem.replace(`{${funKey}}`, '')
            }
            rUrl.push(tem)
        }
        return rUrl.join('')
    }

    serverTimeFormat(obj) {
        let data = this.deepCopy(obj)
        for (var i in data) {
            if (data[i] < 10) data[i] = '0' + data[i]
        }
        return data
    }

    emitEvent(opt){
        opt = opt || {}
        let {fun, data} = opt
        if(this[fun]) this[fun](data)
    }

    encodeData(data){
        if(this.isObject(data) || this.isArray(data)) data = JSON.stringify(data)
        else if(this.isBool(data)) data = data ? 1 : 0
        return btoa(encodeURIComponent(data))
    }
    decodeData(data){
        data = decodeURIComponent(atob(decodeURIComponent(data)))
        let tem
        try{
            tem = JSON.parse(data)
        }catch(e){tem = data}
        return tem
    }

    routeDataCul(data){
        if(!data) return {}
        data = this.decodeData(data)
        if(!this.isObject(data)) return {}
        return data
    }
    randomColor(){
        let n = Math.round(Math.random())
        n = n == 0 ? -1 : 1;
        this.color = n
        return n
    }

    async refreshPage(opt){
        opt = opt || {}
        let {name, items, type} = opt
        
        let params = this.setParams(items)
        await this.refreshPageAction({name, params, type})
        return new Promise(res => {
            this.$nextTick(() => {
                res()
            })
        })
        
    }
    setParams(items){
        items = items || {}
        let data = this.routeDataCul
        data = data || {}
        for(let i in items){
            // if(items[i] === undefined) delete data[i]
            // else data[i] = items[i]
            data[i] = items[i]
        }
        data = JSON.stringify(data)
        return this._common.encodeData(data)
    }
    async refreshPageAction(opt){
        opt = opt || {}
        let {name, params, type, query} = opt
        params = {routeData: params}
        let options = {
            name,
            params,
            type,
            query
        }
        await this._routeHand.goto(options)
    }
    update(options){
        for(var i in options){
            this[i] = options[i]
        }
    }

    toastOpen(opt){
        return new Promise(success => {
            opt = opt || {}
            opt.onClose = () => {
                success()
            }
            Toast(opt)
        })
    }
    toastClose(){
        Toast.clear()
    }
    imagePreviewOn(options){
        this.imagePreviewOff()
        this.previewImgObj = ImagePreview(options)
    }
    imagePreviewOff(){
        if(!this.previewImgObj) return
        this.previewImgObj.close()
    }
    paramsInit(opt){
        opt = opt || {}
        for(let i in opt){
            this[i] = opt[i]
        }
    }

    getCaptureInitBoard(boardSize){
        let {capture_start_board} = this
        capture_start_board = capture_start_board || {}
        let data = capture_start_board[boardSize] || []
        return data
    }

    getResultKey(result){
        result = result || {}
        let {win, winNum, timeout} = result
        let k1 = '', k2 = ''
        if(win == 'black') k1 = 'B'
        else if(win == 'white') k1 = 'W'
        else k1 = 'R'
        if(winNum > 0){
            k2 = winNum
        }else if(timeout){
            k2 = 'T'
        }else k2 = 'R'
        return `${k1}+${k2}`
    }
    getGameType(type){
        // let list = this.gameType
        // return list[type] || ''

        let listAI = this.gameType
        let listSocial = this.gameTypeSocial
        return listAI[type] || listSocial[type] || ''
        
    }
    gameTypeCheck(type){
        let listAI = this.ai_game_info_type
        let listSocial = this.peer_game_info_type
        let data = listAI[type] || listSocial[type] || {}
        return data.type
    }
    isAIGame(type){
        let list = this.ai_game_info_type
        let data = list[type]
        return data ? true : false
    }

    getTimeNow(date) {
        date = date || new Date();
        if (isNaN(date.getFullYear())) date = new Date();
        let seperator1 = "-";
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        let hour = date.getHours()
        let minute = date.getMinutes()
        let second = date.getSeconds()
        if (hour < 10) hour = '0' + hour
        if (minute < 10) minute = '0' + minute
        if (second < 10) second = '0' + second
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        let currentdate = year + seperator1 + month + seperator1 + strDate;
        currentdate = `${currentdate} ${hour}:${minute}:${second}`
        return currentdate;
    }

    getCurrentSize(width, height, basic){
        let {designWidth, designHeight} = this
        let px = designWidth / designHeight
        basic = basic || 'width'
        let data = {}
        if(basic == 'width') {
            data.width = width
            data.height = width / px
        }else{
            data.height = height
            data.width = height * px
        }
        return data
    }

    parseResult(result){
        if(!result) return result

    }

    parseResultSelect(result) {
        result = this.deepCopy(result)
        let num = null
        result = this.parseResultString(result)
        if (result.indexOf('W+') > -1 || result.indexOf('B+') > -1) {
            num = Number(result.substring(2, result.length))
            if (!isNaN(num)) {
                result = result.substring(0, 2)
            }
        }
        let resultSelect = this.deepCopy(this.resultSelect)
        let rtxt = resultSelect[0]
        let data = resultSelect
        if (result == '' || result == undefined || !result) return rtxt
        for (var i in data) {
            if (data[i].value == result || data[i].label == result) {
                rtxt = data[i]
                break
            }
        }
        if (num != null && !isNaN(num)) rtxt.num = num
        return rtxt
    }

    parseResultString(result) {
        if (!result) result = 'N+R'
        result = result || ''
        let reTem = result
        try {
            result = eval(result)
        } catch (e) { result = null}
        result = result || ''
        result = result.replace(/\\/, '')
        let rex = /^(黑胜|白胜)/
        if (rex.test(result)) {
            let re = result.indexOf('黑胜') > -1 ? 'B+' : 'W+'
            result = result.replace('黑胜', '')
            result = result.replace('白胜', '')
            result = result.replace('子', '')
            try {
                result = eval(result)
            } catch (e) {result = null }
            if (!isNaN(Number(result))) return re + result
        }
        let list = this.resultSelect
        let label = null
        for (var i in list) {
            if (list[i].num) continue
            if (list[i].value == result || list[i].label == result) {
                label = list[i].value
                break
            }
        }

        if (label !== null) return label
        return reTem
    }
    resultWinLabel(label) {
        label = label || ''
        if (label.indexOf('黑') > -1 || label.indexOf('B') > -1 || label.indexOf('b') > -1) return 'b'
        if (label.indexOf('白') > -1 || label.indexOf('W') > -1 || label.indexOf('w') > -1) return 'w'
        return null
    }

    paramsSet(obj, values){
        values = values || {}
        for(var i in values){
            this.$set(obj, i, values[i])
        }
    }

    AIResign(coord){
        return coord == -3
    }
    AIPass(coord){
        return coord == -1
    }
    
    eventSet(key, callback){
        let list = this.eventList
        list[key] = callback
        this.eventList = list
    }
    eventRemove(key){
        let list = this.eventList
        delete list[key]
        this.eventList = list
    }
    emitEventBus(key, data){
        let list = this.eventList
        for(let i in list){
            if(i != key || !this.isFunction(list[i])) continue
            list[i](data)
        }
    }
    windowSizeOn(key, fun){
        this.onresizeList[key] = fun
    }
    windowSizeRemove(key){
        try{
            delete this.onresizeList[key]
        }catch(e){ this.onresizeList[key] = null }
    }
    windowSizeEmit(data){
        let {eventList, onresizeList} = this
        if(eventList.onresize && this.isFunction(eventList.onresize)) eventList.onresize(data)
        for(let i in onresizeList){
            if(!this.isFunction(onresizeList[i])) continue
            onresizeList[i](data)
        }
    }
    getVertexPosition(el) {
		let currentTarget = el
		let top = 0
		let left = 0
		while (currentTarget !== null) {
			top += currentTarget.offsetTop
			left += currentTarget.offsetLeft
			currentTarget = currentTarget.offsetParent
		}
		return { top, left }
    }
    
    clientAppCheck(client){
        let {userAgent = ''} = navigator || {}
        let {clientList: list} = this
        let f = false
        for(let i in list){
            if(userAgent.indexOf(i) > -1 && list[i] == client){
                f = true
                break
            }
        }
        return f
    }
    getClient(){
        // let list = {
        //     'gaotuapp-android': 'Android',
        //     'gaotuapp-ios': 'IOS'
        // }
        let {userAgent = ''} = navigator || {}
        let {clientList: list} = this
        list = this.deepCopy(list)
        delete list['gaotuapp']
        let f = false
        for(let i in list){
            if(userAgent.indexOf(i) > -1){
                f = list[i]
                break
            }
        }
        return f

    }
    getClientFull(){
        let client = this.getClient()
        if(!client) return
        let callback = this.getItem('quit_callback', 'app')
        if(callback) return 'PC'
        return client
    }

    getClientWithoutGaotu(){
        if(this.isMobile()){
            if(this.isIos()) return "IOS"
            if(this.isAndroid()) return 'Android'
        } 
        return 'PC'
    }

    randomNumber(start, end) {
        return Math.floor(Math.random() * (end - start) + start)
    }

    isSameObject(obj, otObj){
        if(!this.isObject(obj) && !this.isArray(obj)) return obj == otObj
        let txt1, txt2
        try{
            txt1 = JSON.stringify(obj)
            txt2 = JSON.stringify(otObj)
        }catch(e){
            txt1 = obj
            txt2 = otObj
        }
        return txt1 == txt2
    }

    async balanceNotEnough(res){
        let message = this.TEXT.common['balance not enough']
        if(this.school_id == '10001' || this.school_id == '20001') message = this.TEXT.common['mind balance not enough']
        let buttons = [
            {title: '确定', type: 'confirm'}
        ]
        if(this.taskCenter){
            buttons = [
                {title: '返回', type: 'cancel'},
                {title: '去赚棋石', type: 'confirm', label: 0},
            ]
            if(this.school_id == '10001' || this.school_id == '20001') buttons = [
                {title: '返回', type: 'cancel'},
                {title: '去赚思维币', type: 'confirm', label: 0},
            ]
        }
        let data = await this.confirmPro.open({
            message,
            confirmType: 'c3',
            buttons
        })
        data = data || {}
        if(data.label != 0) return
        this.taskCenter()
    }
    async stockNotEnough(res) {
        let message = this.TEXT.purchased['stock not enough']
        let buttons = [
            {title: '知道了', label: 1, type: 'cancel'},
        ]
        let data = await this.confirmPro.open({
            message,
            confirmType: 'c2plus',
            buttons
        })
        return res
        // await this.toastOpen({ message })
        // return
    }
    async stockPurchased(res){
        let message = this.TEXT.purchased['stock purchased']
        let buttons = [
            { title: '确定', label: 1, type: 'cancel' },
        ]
        let data = await this.confirmPro.open({
            message,
            confirmType: 'c6plus',
            buttons
        })
        return res
    }
    async activityExpiration(res) {
        let message = this.TEXT.purchased['stock activity expiration']
        let buttons = [
            { title: '确定', label: 1, type: 'cancel' },
        ]
        let data = await this.confirmPro.open({
            message,
            confirmType: 'c6plus',
            buttons
        })
        return res
        // await this.toastOpen({ message })
        // return
    }
    async activityClose(res) {
        let message = this.TEXT.purchased['stock activity close']
        let buttons = [
            { title: '确定', label: 1, type: 'cancel' },
        ]
        let data = await this.confirmPro.open({
            message,
            confirmType: 'c6plus',
            buttons
        })
        return res
        // await this.toastOpen({ type: 'html', message })
        // return 
    }
    async petStatusIsMax(res){
        let message = this.TEXT.common['pet status is max']
        let {petName: name} = this.$store.state.app
        message = this.urlParse(message, {name})
        let buttons = [
            {title: '确定', type: 'confirm'}
        ]
        await this.confirmPro.open({
            message,
            confirmType: 'c3',
            buttons
        })
        return res
    }
    addAppClick(type, callback){
        this.appClickList[type] = callback
    }
    removeAppClick(type){
        if(!this.appClickList[type]) return
        delete this.appClickList[type]
    }
    appClick(e){
        let list = this.appClickList
        for(let i in list){
            if(this.isFunction(list[i])) list[i](e)
        }
    }
    appClickCheck(e, atrName, checkName) {
        let ele = e.target
        let f = false
        while (ele) {
            let atr = ele.getAttribute(checkName)
            if (atr && atr == atrName) {
                f = ele
                break
            }
            ele = ele.parentElement
        }
        return f
    }
    parseAvatarDressUrl(data){
        data = data || ''
        try{
            data = JSON.parse(data)
        }catch(e){ data = {} }
        if(!this.isObject(data)) return data
        for(let i in data){
            if(!this.isString(data[i])) continue
            if(data[i].indexOf(',') > -1){
                try{
                    data[i] = data[i].split(',')
                }catch(e){ data[i] = [] }
            }
        }
        return data
    }

    selected(opt){
        let {list = [], key, value} = opt || {}
        let data = null, index = null
        for(let i in list){
            if(list[i][key] == value){
                data = list[i]
                index = i
                break
            }
        }
        return {data, index}
    }

    writeToClipBoard(data){
        let state = this.writeToClipBoardByNav(data)
        if(!state) state = this.writeToClipBoardByEle(data)
        return state
    }
    writeToClipBoardByNav(data){
        if(!navigator.clipboard) return false
        navigator.clipboard.writeText(data)
        return true
    }
    writeToClipBoardByEle(data){
        let ele = document.createElement('input')
        ele.style.display = 'none'
        ele.value = data
        document.body.appendChild(ele)
        ele.select()
        document.execCommand('copy')
        document.body.removeChild(ele)
        return true
    }
    createElementForCopy(data){
        let ele = document.createElement('input')
        ele.style.display = 'none'
        ele.value = data
        document.body.appendChild(ele)
        return ele
    }

    IOSCopy(text){
        //要复制文字的节点
        // var copyDOM = obj.parentNode.firstChild.nextElementSibling;
        let copyDOM = this.createElementForCopy(text)
        var range = document.createRange();
        // 选中需要复制的节点
        range.selectNode(copyDOM);
        // 执行选中元素
        window.getSelection().addRange(range);
        // 执行 copy 操作
        var state = document.execCommand('copy');
        // 移除选中的元素
        window.getSelection().removeAllRanges();
        // obj.innerHTML = '已复制'
        document.body.removeChild(copyDOM)
        return state

        //obj.setAttribute('data-state','yes');
    }
    AndroidCopy(text){
        let copyDOM = this.createElementForCopy(text)
        // var $copyPP = obj.parentNode.firstChild.nextElementSibling,
        //     $copyText = obj.parentNode.lastChild.nextElementSibling;
        $copyText.innerHTML = $copyPP.innerHTML.replace(/<[^>]+>/g, "");

        copyDOM.select(); // 选择对象

        document.execCommand("Copy"); // 执行浏览器复制命令

        // obj.innerHTML = '已复制';
        return true
    }
    PCCopy(text){
        return this.writeToClipBoard(text)
    }

    copyTextForMobile(text){
        let client = this.getClientFull()
        if(!client || !this[`${client}Copy`]) client = this.getClientWithoutGaotu()
        return this[`${client}Copy`](text)
    }

    isRR(result){
        return result == 'R+R'
    }
    isNR(result){
        return result == 'N+R'
    }
    isDraw(result){
        return result == 'R+R'
    }
    getDrawResultKey(){
        return 'R+R'
    }
    getLevel(level) {
        let l = this.user_level, val = null
        for (var i in l) {
            if (l[i].value == level) {
                val = this.deepCopy(l[i])
                break
            }
        }
        if (!val) val = { value: 0, name: '' }
        return val
    }
    guestRouteCheck(){
        if(!this.$route) return false
        let {path} = this.$route
        let list = params.guest_path_list
        let f = false
        for(let i in list){
            let {url} = list[i]
            if(path.indexOf(url) > -1){
                f = true
                break
            }
        }
        return f
    }

    queryDataSet(){
        let {query} = this.$route
        for(let i in query){
            this[i] = query[i]
        }
    }

    mindExerciseHandler(list){
        for(let i in list){
            let {exerciseContent} = list[i]
            let tem = exerciseContent
            try{
                exerciseContent = JSON.parse(exerciseContent)
            }catch(e){ exerciseContent = tem }
            if(this.isArray(exerciseContent)) exerciseContent = exerciseContent[0] || {}
            list[i].exerciseContent = exerciseContent
        }
        return list
    }

    getParentNode(node, items){
        items = items || {}
        node = node || {}
        let par_node = node.parentNode
        if(!par_node || !par_node.getAttribute) return null
        let f = false
        for(let i in items){
            let data = par_node.getAttribute(i)
            if(data != items[i]) continue
            f = true
            break
        }
        if(!f) return this.getParentNode(par_node, items)
        return par_node
    }
    userAgent(){
        if (!navigator) return ''
        return navigator.userAgent || ''
    }

    //终局数子手数
    getGameJudgePos(boardSize){
        let list = this.board_probs
        if(!list[boardSize]) return false
        return list[boardSize].judge
    }

    sgfLengthCheckSync(moves){
        let res = this.lengthCheckSync(moves)
        if(!res) return false
        if(res.code != 0){
            // this.errorHandler(res, null, {num: res.max, noTimeCheck: true})
            let message = this._errorCode.game[code]
            this.comfirmPro.open({
                message,
                confirmType: 'c3',
                buttons: [
                    {title: '确定', type: 'confirm'}
                ]
            })
            return false
        }
        return true
    }

    lengthCheckSync(moves){
        if(!this.isArray(moves)) return false
        let {max} = this.$store.state.game.sgf
        if(moves.length <= max) return {code: 0}
        return {code: 'length is too long', max: max}
    }
    isTaste(type){
        return type == this.ENUMS.EXERCISE_CAPTURE_TASTE_COMPETITIVE_MATCH || type == this.ENUMS.EXERCISE_CONTAIN_TASTE_COMPETITIVE_MATCH
    }
    transformToObject(opt){
        let {list, key} = opt || {}
        list = this.deepCopy(list)
        let rd = {}
        for(let i in list){
            if(!list[i] || !list[i][key]) continue
            rd[list[i][key]] = list[i]
        }
        return rd
    }
    nicknameCheck(nickname){
        if(nickname && nickname != '' && nickname != ' ') return
        this.confirmPro.open({
            message: '经围棋小镇系统检测，宝贝还没有起名字，尽快回到高途APP给自己起一个善良的名字哦',
            className: 'balance-check',
            confirmType: 'c10 balance-check',
            buttons: [
                {title: '确认', type: 'confirm'}
            ]
        })

    }

    numToCapital(num, type = ''){
        if (!num) return 0
        const strNum = Number((num + '').replace(/[,，]*/g, '')) + '' // 记录字符
        num = parseInt(Number(strNum)) // 转为整数，
        let capitalAr = '零一二三四五六七八九十'
        let unitAr = ['十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千']
        if (type) {
            capitalAr = '零壹贰叁肆伍陆柒捌玖拾'
            unitAr = ['拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟'] // 单位
        }
        const resultAr = [] // 记录结果，后边json.in就可
        let index = strNum.length - 1 //记录位数
        let idx = 0 // 记录单位
        let percent = 10
        const turnNum = (num, percent, index) => {
            const unit = num / percent
            const capital = capitalAr[Number(strNum[index])]
            if (unit < 1) {
                resultAr.push(capital)
                // 出现11【一十一】这种情况
                if (Number(strNum[index]) === 1 && (strNum.length === 2 || strNum.length === 6 || strNum.length === 10)) {
                    resultAr.pop()
                }
                return false //结束递归
            } else {
                if (capital === '零') {
                    // 万和亿单位不删除
                    if (!['万', '亿'].includes(resultAr[resultAr.length - 1])) {
                        resultAr.pop()
                    }
                    // 前面有零在删掉一个零
                    if (resultAr[resultAr.length - 1] === '零') {
                        resultAr.pop()
                    }
                }
                resultAr.push(capital)
                // 过滤存在【零万】【零亿】这种情况
                if (['万', '亿'].includes(resultAr[resultAr.length - 2]) && capital === '零') {
                    resultAr.pop()
                }
                // 过滤【1亿万】这种情况
                if (resultAr[0] === '万' && resultAr[1] === '亿') {
                    resultAr.shift()
                }
                // 末尾【零】删掉
                if (resultAr[0] === '零') {
                    resultAr.pop()
                }
                resultAr.push(unitAr[idx++])
                turnNum(num, percent * 10, --index)
            }
        }
        turnNum(num, percent, index)
        return resultAr.reverse().join('')
    }
    formatTimeText(time){
        let s = parseInt(time / 1000)
        let m = parseInt(s / 60)
        let h = parseInt(m / 60)
        s = s % 60
        m = m % 60
        let data = [h, m, s]
        if(h <= 0) data.shift()
        data = this.serverTimeFormat(data)
        return data.join(':')
    }
    getDecimalPlaces(num) {
        // 将变量转换为字符串形式
        var numStr = num.toString();
        
        // 使用正则表达式匹配小数位数
        var match = numStr.match(/\.(\d+)/);
      
        // 如果存在小数位数，则返回小数位数的长度
        if (match) {
            return match[1].length    
        }
        
        // 如果不存在小数位数，则返回 0
        return 0;
    }
    parseMindExerciseAnswer(list){
        let data = this.deepCopy(list)
        data = data || []
        let nums = {
            '一': 1, '二': 2, '两': 2, '三': 3, '四': 4, '五': 5, '六': 6, '七': 7, '八': 8, '九': 9, '零': 0,
        }
        let i = 0
        while(i < data.length){
            let val =  data[i]
            if(nums[val]) val = nums[val]
            else val = val.toLowerCase()
            data[i] = val
            i++
        }
        return data
    }
    arrayToObject(arr, key){
        let rd = {}
        for(let i in arr){
            let tem = arr[i][key]
            if(!key || !tem){
                tem = arr[i]
                if(!this.isString(tem)){
                    try{
                        tem = JSON.stringify(tem)
                    }catch(e){ tem = null }
                }
            }
            if(!tem) continue
            rd[tem] = arr[i]
        }
        return rd
    }
}

export default singleProxy(commonFun)