<!--  -->
<template>
    <div class='com-blur-overlay' ref="overlayBox" :style="exStyle">
        <!-- <div class="overlay-toast-box">
            <slot></slot>
        </div> -->
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            zIndex: [Number, String]
        },
        data() {
            return {
                getContainer: null,
                active: false
            };
        },
        methods: {
            paramsInit(opt){
                this.getContainer = null
                this._common.paramsInit.call(this, opt)
            },
            async open(opt){
                this.paramsInit(opt)
                this.active = true
                await this._common.nextTick.call(this)
                let ele = this.$refs['overlayBox']
                let {getContainer} = this
                let eleP = document.querySelector(getContainer)
                if(!eleP) return
                eleP.appendChild(ele)
            },
            close(){
                this.active = false

            }
        },
        created() {

        },
        components: {
        },
        computed: {
            exStyle(){
                let {zIndex = 9} = this
                return {
                    'z-index': zIndex
                }
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    // .com-blur-overlay{
    //     background: url('@{assetsUrl}/img/game/g_15.png');
    //     background-size: 100% 100%;
    //     position: fixed;
    //     width: 100%;
    //     height: 190px;
    //     top: 0px;
    //     bottom: 0px;
    //     left: 0px;
    //     margin: auto;
    //     .over-body{
    //         width: 100%;
    //         height: 100%;
    //         // background: radial-gradient(#FF6F3A 0%, rgba(255, 115, 64, 0.11) 65%, rgba(255, 102, 47, 0) 100%);
    //     }
    //     // background: linear-gradient(90deg, #FF6F3A 0%, rgba(255, 115, 64, 0.11) 65%, rgba(255, 102, 47, 0) 100%);
    //     // border: 3px solid;
    //     // border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 3 3;
    //     // filter: blur(5px);
    //     .c-blur-border.top{
    //         top: 0px;
    //     }
    //     .c-blur-border.bottom{
    //         bottom: 0px;
    //     }
    //     .c-blur-border{
    //         position: absolute;
    //         left: 0px;
    //         width: 100%;
    //         height: 5px;
    //     }
    // }
    .com-blur-overlay{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9;
    }
</style>