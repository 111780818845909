<!--  -->
<template>
    <div class='com-component-background' v-if="url" :style="styleCul">

    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            url: String
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            styleCul(){
                let data = {}
                if(this.url){
                    data = {
                        background: `url(${this.PARAMS.assetsUrlV2_0}/${this.url}) no-repeat`,
                        'background-size': '100% 100%'
                    }
                }
                return data
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-component-background{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }
</style>