<!--  -->
<template>
    <div class='stone-box-judge flex-center' :class="colorClass">
        <span class="text-large" v-if="colorClass == 'none'">?</span>
        <div class="st-judge-body max-size" v-else></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            color: String
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            colorClass(){
                let {color} = this
                if(color == 'black') return 'black' 
                if(color == 'white') return 'white' 
                return 'none'
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .stone-box-judge{
        width: 44px;
        height: 44px;
        border-radius: 100%;
        overflow: hidden;
        margin: 0px auto;
    }
    .stone-box-judge.none{
        background: none;
        border: 1px solid #fff;
    }
    .stone-box-judge.black{
        background: linear-gradient(180deg, #949494 0%, #4D4D4D 38%, #353535 61%, #353535 100%);
        box-shadow: 0px 1px 10px 2px rgba(255, 255, 255, 0.5);
        .st-judge-body{
            background: url('@{assetsUrl}/img/game/g_20.png');
            background-size: 100% 100%;
        }
    }
    .stone-box-judge.white{
        background: linear-gradient(180deg, #FFFFFF 0%, #EEF3F7 35%, #C8DEEF 65%, #DEECF6 100%);
        box-shadow: 0px 1px 10px 2px rgba(255, 255, 255, 0.5);
        .st-judge-body{
            background: url('@{assetsUrl}/img/game/g_21.png');
            background-size: 100% 100%;
        }
    }

    
</style>