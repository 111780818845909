<template>
    <span>
        <span class="global-com-latex" v-html="rawHtml"></span>
        <!-- <span class="global-com-latex" ref="latex"></span> -->
    </span>
</template>
<script>
    import katex from "katex";
    import "katex/dist/katex.css";

    export default {
        name: '',
        props: {
            value: String
        },
        data() {
            return {
                delimiters: [
                    {left: '$$', right: '$$', display: true},
                    {left: '$', right: '$', display: false},
                    {left: '\\(', right: '\\)', display: false},
                    {left: '\\[', right: '\\]', display: true}
                ],
            };
        },
        methods: {
            async dataInit(){
                await this._common.nextTick.call(this)
                katex.render(this.value, this.$refs.latex, {
                    throwOnError: false,
                });
            }
        },
        created() {},
        components: {},
        computed: {
            rawHtml(){
                let {delimiters} = this
                let html = katex.renderToString(this.value, {
                    throwOnError: false,
                    delimiters
                });
                return html
            }
        },
        watch: {
            value: {
                handler(val, oldVal){
                    if(!val || val == oldVal) return
                    // this.dataInit()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    
</style>