<template>
    <div class="global-com-user-level">
        <span class="text-mini">{{levelText}}</span>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            level: [Number, String]
        },
        data() {
            return {
                levelList: this.PARAMS.user_level,
                userInfo: {}
            };
        },
        methods: {
            userInfoChange(){
                this.userInfo = this.$store.state.app
            },
        },
        created() {
            this.userInfoChange()
            this._userHand.on('user', this.userInfoChange)
        },
        components: {},
        computed: {
            levelCul(){
                let {level, userInfo} = this
                if(level !== undefined) return level
                let {level: infoLevel} = userInfo
                return infoLevel || 0
            },
            levelData(){
                let {levelCul: value, levelList: list} = this
                let key = 'value'
                if(!this._common.isNumber(value)) return {}
                let {data} = this._common.selected({value, list, key})
                return data || {}
            },
            levelText(){
                let {levelData: info} = this
                return info.name || ''
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .global-com-user-level{
        overflow: hidden;
        > span{
            float: left;
        }
    }
</style>