import appData from './appData'
import params from './params'
import {singleProxy} from './singleClass'

class userHand {
    constructor(){
        this.PARAMS = params
        this._appData = new appData({key: 'app'})
        this.loginStatus = false
        this.userSetting = {
            'backgroundMusic': 1,
            'playVoice': 1,
            'sound': 1
        }
        this.emitList = {}
    }
    userLogoutSet(){
        let userInfo = {}
        let items = {
            ...this.PARAMS.user_items,
            ...this.PARAMS.user_account_items,
            ...this.PARAMS.user_level_items
        }
        for(let i in items){
            userInfo[items[i]] = true
        }
        this._appData.removeItem(userInfo, 'app')
        this.storeDataSet()
    }
    userLoginSet(res){
        res = res || {}
        let userInfo = {}
        let items = this.PARAMS.user_items
        for(let i in items){
            let key = items[i]
            if(res[key] === undefined) continue
            userInfo[key] = res[key]
        }
        let setting =  this._appData.getItem('setting', 'app')
        let quit_callback =  this._appData.getItem('quit_callback', 'app')
        let sid =  this._appData.getItem('sid', 'app')
        let pclient =  this._appData.getItem('pclient', 'app')
        this._appData.removeAll('app')
        let saveData = {
            ...userInfo, setting
        }
        if(quit_callback) saveData.quit_callback = quit_callback
        if(sid) saveData.sid = sid
        if(pclient) saveData.pclient = pclient
        this._appData.setItem(saveData, 'app')
        this.storeDataSet()

    }
    userLevelSet(res){
        res = res || {}
        let userLevel = {}
        let items = this.PARAMS.user_level_items
        for(let i in items){
            let key = items[i]
            if(res[key] === undefined) continue
            userLevel[key] = res[key]
        }
        this._appData.setItem(userLevel, 'app')
        this.storeDataSet()
        this.emit()
    }
    userAccountSet(res){
        res = res || {}
        let userLevel = {}
        let items = this.PARAMS.user_account_items
        for(let i in items){
            let key = items[i]
            if(res[key] === undefined) continue
            userLevel[key] = res[key]
        }
        this._appData.setItem(userLevel, 'app')
        this.storeDataSet()
        this.emit()
    }
    getUserToken(){
        let token = null
        let access_token = this._appData.getItem('access_token', 'app')
        let access_token_type = this._appData.getItem('token_type', 'app')
        if(access_token && access_token_type) token = `${access_token_type} ${access_token}`
        return token
    }
    getDefaultToken(){
        return `${this.PARAMS.default_token_type} ${this.PARAMS.default_access_token}`
    }
    getToken(){
        let token = this.getDefaultToken()
        let userToken = this.getUserToken(this)
        if(userToken) token = userToken
        return token
    }
    storeDataSet(){
        let data = this._appData.getAll()
        this._appData.removeAllStore()
        let setData = {}
        for(let i in data){
            let tem = data[i]
            try{
                tem = JSON.parse(tem)
            }catch(e){tem = data[i]}
            setData[i] = tem
        }
        if(setData.photo) {
            setData.photo = `user_photo/${setData.photo}`
        }
        this._appData.setStoreItemByCom(setData)
        if(this._appData.getStoreItemByCom('username')) this.loginStatus = true
        else this.loginStatus = false
    }
    usercodeGet(){
        return this._appData.getStoreItemByCom('usercode')
    }
    getUserSetting(){
        let data = this._appData.getItem('setting') || {}
        let config = this.userSetting
        for(let i in config){
            if(data[i] === undefined) data[i] = config[i]
        }
        return data
    }
    setUserConfig(data){
        for(let i in data){
            if(data[i]) data[i] = 1
            else data[i] = 0
        }
        let save = {
            setting: data
        }
        this._appData.setItem(save)
    }
    avatarDressUrl(data){
        data = data || this._appData.getItem('avatarDressUrl', 'app') || ''
        try{
            data = JSON.parse(data)
        }catch(e){ let rd }
        return data
    }
    headUrl(){
        return this._appData.getItem('headUrl', 'app')
    }
    petUrls(data){
        data = data || this._appData.getItem('petUrl', 'app')
        try{
            data = JSON.parse(data)
        }catch(e){ let rd }
        return data
    }
    emit(){
        let {emitList: list} = this
        for(let i in list){
            if(!list[i]) continue
            list[i]()
        }
    }
    on(type, fun){
        this.emitList[type] = fun
    }
    remove(type){
        delete this.emitList[type]
    }
    setPetEatUrl(res){
        this._appData.setItem({'petEatUrl': res}, 'app')
        this.storeDataSet()
        this.emit()
    }
    removePetEatUrl(){
        this._appData.removeItem({'petEatUrl': true}, 'app')
        this.storeDataSet()
        // this.emit()

    }
    getPetStatus(info){
        let {petUrl, petEatUrl} = info || {}
        let urls = petEatUrl || petUrl
        let tem = urls
        try{
            urls = JSON.parse(urls)
        }catch(e) { urls = tem }
        urls = urls || {}
        return urls.status || null
    }

    // animationListParse(list, pageName){

    // }
}

export default singleProxy(userHand)