// import "babel-polyfill";
import './global/js/proxyDefine'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./global/css/reset.less";
import "./global/css/media.less";
import "./global/css/_le.less";
import './global/js/vueProSet'
import './plugins/vant/vant'
import './plugins/echarts/echarts'
import './global/vue/eventBus'
import './global/vue/components'
import './global/vue/filter'
import './components/global/export'
import './mixin/index'
// import fastclick from 'fastclick'
import routeHand from './global/js/routeHand'
Vue.config.productionTip = false;
// fastclick.attach(document.body)

// new Vue({
// 	router,
// 	store,
// 	render: (h) => h(App),
// 	methods: {
// 		textSet(to) {
// 			let {name} = to
// 			let {TEXT} = this
// 			TEXT = TEXT || {}
// 			let list = TEXT[name] || {}
// 			Vue.prototype.pageText = list
// 		},
// 		routerInit(){
			
// 		},
// 		routeBeforeEach(to, from){
// 			this.textSet(to)
// 			return true
// 		},
// 		async afterEach(){
// 		}
// 	},
// 	created() {
// 		Vue.prototype._routeHand = new routeHand({
// 			router, 
// 			route: this.$route, 
// 			routeList: this._routeList, 
// 			beforeEach: this.routeBeforeEach, 
// 			afterEach: this.afterEach,
// 			nextTick: this.$nextTick
// 		})
// 		if(this._request){
// 			this._request._routeHand = Vue.prototype._routeHand
// 		}
// 	},
// 	update(){
// 	},
// 	mounted(){

// 	},
// 	watch: {
// 		$route: {
// 			handler(val, oldVal){
// 				if(!this.pageText || !Object.keys(this.pageText).length) this.textSet(val)
// 				if(this._routeHand){
// 					this._routeHand.setRoute(this.$route)
// 					let to = val, from = oldVal
// 					this._routeHand.eachEmit({to, from, type: 'after'})
// 				}
// 			},
// 			deep: true,
// 			immediate: true
// 		}
// 	}
// }).$mount("#app");


export default class main {
    constructor(opt){
        this.init(opt)
    }
    init(opt){
        let {router: customRouter, store: customStore, render} = opt
        customRouter = customRouter || router
        customStore = customStore || store
        if(!render) render = (h) => h(App)
        this.customRouter = customRouter
        this.customStore = customStore
        this.options = {
            router: customRouter,
            store: customStore,
            render,
            methods: {
                textSet(to) {
                    let {name} = to
                    let {TEXT} = this
                    TEXT = TEXT || {}
                    let list = TEXT[name] || {}
                    Vue.prototype.pageText = list
                },
                routerInit(){
                    
                },
                routeBeforeEach(to, from){
                    this.textSet(to)
                    return true
                },
                async afterEach(){
                }
            },
            created() {
                Vue.prototype._routeHand = new routeHand({
                    router: customRouter, 
                    route: this.$route, 
                    routeList: this._routeList, 
                    beforeEach: this.routeBeforeEach, 
                    afterEach: this.afterEach,
                    nextTick: this.$nextTick
                })
                if(this._request){
                    this._request._routeHand = Vue.prototype._routeHand
                }
                if(this.paramsReset) this.paramsReset(this.PARAMS)
            },
            update(){
            },
            mounted(){

            },
            watch: {
                $route: {
                    handler(val, oldVal){
                        if(!this.pageText || !Object.keys(this.pageText).length) this.textSet(val)
                        if(this._routeHand){
                            this._routeHand.setRoute(this.$route)
                            let to = val, from = oldVal
                            this._routeHand.eachEmit({to, from, type: 'after'})
                        }
                    },
                    deep: true,
                    immediate: true
                }
            }
        }
    }
}