let p = {
    ".mjx-chtml": {
        display: "inline-block",
        "line-height": 0,
        "text-indent": 0,
        "text-align": "left",
        "text-transform": "none",
        "font-style": "normal",
        "font-weight": "normal",
        "font-size": "100%",
        "font-size-adjust": "none",
        "letter-spacing": "normal",
        "word-wrap": "normal",
        "word-spacing": "normal",
        "white-space": "nowrap",
        "float": "none",
        direction: "ltr",
        "max-width": "none",
        "max-height": "none",
        "min-width": 0,
        "min-height": 0,
        border: 0,
        margin: 0,
        padding: "1px 0"
    },
    ".MJXc-display": {
        display: "block",
        "text-align": "center",
        margin: "1em 0",
        padding: 0
    },
    ".mjx-chtml[tabindex]:focus, body :focus .mjx-chtml[tabindex]": {
        display: "inline-table"
    },
    ".mjx-full-width": {
        "text-align": "center",
        display: "table-cell!important",
        width: "10000em"
    },
    ".mjx-math": {
        display: "inline-block",
        "border-collapse": "separate",
        "border-spacing": 0
    },
    ".mjx-math *": {
        display: "inline-block",
        "-webkit-box-sizing": "content-box!important",
        "-moz-box-sizing": "content-box!important",
        "box-sizing": "content-box!important",
        "text-align": "left"
    },
    ".mjx-numerator": {
        display: "block",
        "text-align": "center"
    },
    ".mjx-denominator": {
        display: "block",
        "text-align": "center"
    },
    ".MJXc-stacked": {
        height: 0,
        position: "relative"
    },
    ".MJXc-stacked > *": {
        position: "absolute"
    },
    ".MJXc-bevelled > *": {
        display: "inline-block"
    },
    ".mjx-stack": {
        display: "inline-block"
    },
    ".mjx-op": {
        display: "block"
    },
    ".mjx-under": {
        display: "table-cell"
    },
    ".mjx-over": {
        display: "block"
    },
    ".mjx-over > *": {
        "padding-left": "0px!important",
        "padding-right": "0px!important"
    },
    ".mjx-under > *": {
        "padding-left": "0px!important",
        "padding-right": "0px!important"
    },
    ".mjx-stack > .mjx-sup": {
        display: "block"
    },
    ".mjx-stack > .mjx-sub": {
        display: "block"
    },
    ".mjx-prestack > .mjx-presup": {
        display: "block"
    },
    ".mjx-prestack > .mjx-presub": {
        display: "block"
    },
    ".mjx-delim-h > .mjx-char": {
        display: "inline-block"
    },
    ".mjx-surd": {
        "vertical-align": "top"
    },
    ".mjx-surd + .mjx-box": {
        display: "inline-flex"
    },
    ".mjx-mphantom *": {
        visibility: "hidden"
    },
    ".mjx-merror": {
        "background-color": "#FFFF88",
        color: "#CC0000",
        border: "1px solid #CC0000",
        padding: "2px 3px",
        "font-style": "normal",
        "font-size": "90%"
    },
    ".mjx-annotation-xml": {
        "line-height": "normal"
    },
    ".mjx-menclose > svg": {
        fill: "none",
        stroke: "currentColor",
        overflow: "visible"
    },
    ".mjx-mtr": {
        display: "table-row"
    },
    ".mjx-mlabeledtr": {
        display: "table-row"
    },
    ".mjx-mtd": {
        display: "table-cell",
        "text-align": "center"
    },
    ".mjx-label": {
        display: "table-row"
    },
    ".mjx-box": {
        display: "inline-block"
    },
    ".mjx-block": {
        display: "block"
    },
    ".mjx-span": {
        display: "inline"
    },
    ".mjx-char": {
        display: "block",
        "white-space": "pre"
    },
    ".mjx-itable": {
        display: "inline-table",
        width: "auto"
    },
    ".mjx-row": {
        display: "table-row"
    },
    ".mjx-cell": {
        display: "table-cell"
    },
    ".mjx-table": {
        display: "table",
        width: "100%"
    },
    ".mjx-line": {
        display: "block",
        height: 0
    },
    // ".mjx-strut": {
    //     width: 0,
    //     "padding-top": k + "em"
    // },
    ".mjx-vsize": {
        width: 0
    },
    ".MJXc-space1": {
        "margin-left": ".167em"
    },
    ".MJXc-space2": {
        "margin-left": ".222em"
    },
    ".MJXc-space3": {
        "margin-left": ".278em"
    },
    ".mjx-chartest": {
        display: "block",
        visibility: "hidden",
        position: "absolute",
        top: 0,
        "line-height": "normal",
        "font-size": "500%"
    },
    ".mjx-chartest .mjx-char": {
        display: "inline"
    },
    ".mjx-chartest .mjx-box": {
        "padding-top": "1000px"
    },
    ".MJXc-processing": {
        visibility: "hidden",
        position: "fixed",
        width: 0,
        height: 0,
        overflow: "hidden"
    },
    ".MJXc-processed": {
        display: "none"
    },
    ".mjx-test": {
        "font-style": "normal",
        "font-weight": "normal",
        "font-size": "100%",
        "font-size-adjust": "none",
        "text-indent": 0,
        "text-transform": "none",
        "letter-spacing": "normal",
        "word-spacing": "normal",
        overflow: "hidden",
        height: "1px"
    },
    ".mjx-test.mjx-test-display": {
        display: "table!important"
    },
    ".mjx-test.mjx-test-inline": {
        display: "inline!important",
        "margin-right": "-1px"
    },
    ".mjx-test.mjx-test-default": {
        display: "block!important",
        clear: "both"
    },
    ".mjx-ex-box": {
        display: "inline-block!important",
        position: "absolute",
        overflow: "hidden",
        "min-height": 0,
        "max-height": "none",
        padding: 0,
        border: 0,
        margin: 0,
        width: "1px",
        height: "60ex"
    },
    ".mjx-test-inline .mjx-left-box": {
        display: "inline-block",
        width: 0,
        "float": "left"
    },
    ".mjx-test-inline .mjx-right-box": {
        display: "inline-block",
        width: 0,
        "float": "right"
    },
    ".mjx-test-display .mjx-right-box": {
        display: "table-cell!important",
        width: "10000em!important",
        "min-width": 0,
        "max-width": "none",
        padding: 0,
        border: 0,
        margin: 0
    },
    "#MathJax_CHTML_Tooltip": {
        "background-color": "InfoBackground",
        color: "InfoText",
        border: "1px solid black",
        "box-shadow": "2px 2px 5px #AAAAAA",
        "-webkit-box-shadow": "2px 2px 5px #AAAAAA",
        "-moz-box-shadow": "2px 2px 5px #AAAAAA",
        "-khtml-box-shadow": "2px 2px 5px #AAAAAA",
        padding: "3px 4px",
        "z-index": 401,
        position: "absolute",
        left: 0,
        top: 0,
        width: "auto",
        height: "auto",
        display: "none"
    }
};

export const handler = () => {
        // 创建一个style元素
    const bodyStyle = document.createElement('style');
    
    // WebKit内核或者火狐浏览器
    bodyStyle.appendChild(document.createTextNode('')); // 需要设置一个空的文本节点
    
    // 获取样式表
    const sheet = bodyStyle.sheet;
    const cssData = []
    for(const i in p){
        let text = `${i}{`
        for(const j in p[i]){
            text += `${j}:${p[i][j]};`
        }
        text += '}'
        cssData.push(text)
        // sheet.insertRule(text, sheet.cssRules.length);
    }
    bodyStyle.setAttribute('styleType', 'MathJaxStyle')
    bodyStyle.innerHTML = cssData.join('')
    document.head.appendChild(bodyStyle);
    // 添加新的规则
    // var cssRule = selector + " { " + rules + " }";
    // sheet.insertRule(cssRule, sheet.cssRules.length);
    
    // 将style元素添加到head中
    // document.getElementsByTagName('head')[0].appendChild(style);
}