

export default {
    'userAccount': {
        url: '/town/school/user/account/{user_code}',
        serve: 'social',
    },
    'petFeedGet': {
        url: '/town/school/user/account/pet/food',
        serve: 'social',
    },
    'petList': {
        url: '/town/school/user/account/pets',
        serve: 'social',
    },
    'feedPet': {
        url: '/town/school/user/account/pet/feed/{user_code}',
        serve: 'social',
    }
    
}