<!--  -->
<template>
    <div class='com-global-award'>
        <div class="award-icon"></div>
        <div class="award-text">
            <span class="text-normal-d bold">+{{number}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            number: [Number, String]
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-award{
        width: 85px;
        height: 29px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 13px;
        .background-img-max(url('@{assetsUrlV2_0}/exercise/c16.png'));
        .award-icon{
            width: 37px;
            height: 37px;
            .background-img-max(url('@{assetsUrlV2_0}/global/balance-icon.png'));
        }

    }
</style>