<!--  -->
<template>
    <div class='invite-confirm-box'>
        <div class="con-item" v-for="v in confirmListArray" :key="v.key">
             <!-- v-if="!isWaitInvite(v) || v.type != 'receive'" -->
             <!--  -->
            <inviteConfirm :ref="v.key"  :id="v.key" @timeout="inviteTimeout" @remainTimeChange="remainTimeChange"></inviteConfirm>
            <!--  -->
        </div>
    </div>
</template>

<script>
    import inviteConfirm from './inviteConfirm.vue'
    import apiClass from '@/API/page/socialGame'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                confirmList: {},
                usercode: null,
                noticeList: {},
                waitOpen: [],
                confirmListCopy: null,
                noticeListCopy: null,
            };
        },
        methods: {
            openFun(opt){
                opt = opt || {}
                let {type} = opt
                type = type || 'receive'
                return `${type}_hand`
            },
            setWaitData(opt){
                this.waitOpen.push(opt)
            },
            async open(opt){
                if(this.confirmListArray.length >= this.PARAMS.invite_max_window) return this.setWaitData(opt)
                this.paramsInit(opt)
                let key = this.getKey(opt)
                // if(!this.receiveCheck(key)) return
                this.check(key)
                let fun = this.openFun(opt)
                if(!this[fun]){
                    return
                }
                this[fun](opt)
            },
            async close(opt){
                let key = this.getKey(opt)
                this.check(key)

            },
            async send_hand(opt){
                let message = this.pageTitleGet(opt)
                let duration = 3000, className = 'invite-toast-box'
                this.$toast({
                    message,
                    duration,
                    className
                });
            },
            receiveCheck(key){
                let data = this.confirmListCul[key]
                if(!data) return true
                if(this.isInvite(data)) return false
                return true
            },
            getIndex(){
                return Object.keys(this.confirmListCul).length
            },
            async receive_hand(opt){
                let key = this.getKey(opt)
                if(opt.index === undefined) opt.index = this.getIndex()
                this.$set(this.confirmList, key, opt)
                await this._funhand.nextTick.call(this)
                let ele = this.$refs[key]
                if(this._common.isArray(ele)) ele = ele[0]
                let data = this.confirmListCul[key]
                data.noticeClass = 'receive'
                if(this.isWaitInvite(opt)){
                    let title = this._errorCode.game['wait invite title smple']
                    let {nickname} = opt.userInfo || {}
                    title = this._common.urlParse(title, {nickname})
                    data.title = title
                    data.noticeClass = ''
                    // this.send_hand({...opt, ...data})
                }
                if(!ele){
                    return
                }
                if(!this.isWaitInvite(opt) && !opt.reopen){
                    this._common.playAction('invite')
                }
                let notice = await ele.open(data)
                this.noticeList[key] = notice
                if(this.isWaitInvite(opt)){
                    let timeout = 3000
                    await this._common.settimeout(timeout)
                    notice.close()
                }
            },
            async cancel_hand(opt){
                opt = opt || {}
                opt.titleKey = 'invite cancel'
                opt.timeout = 3000
                this.cancel_reject_hand(opt)
            },
            async reject_hand(opt){
                opt = opt || {}
                opt.titleKey = 'invite reject'
                opt.timeout = 8000
                await this.cancel_reject_hand(opt)
                let key = this.getKey(opt)
                this.deleteList(key)
            },
            async cancel_reject_hand(opt){
                opt = opt || {}
                // await this._funhand.nextTick.call(this)
                let key = this.getKey(opt)
                let data = this.confirmList[key]
                let optcan = {...data, ...opt}
                this.$set(this.confirmList, key, optcan)
                data = this.confirmList[key]
                await this._funhand.nextTick.call(this)
                if(!data) return
                // this.reject_hand_toast({...data, ...opt})
                let ele = this.$refs[key]
                if(this._common.isArray(ele)) ele = ele[0]
                if(!ele) return
                let {userInfo} = data
                userInfo = userInfo || {}
                let {nickname} = userInfo
                let {titleKey, timeout} = opt
                let title = this._errorCode.game[titleKey]
                title = this._common.urlParse(title, {nickname})
                let notice = await ele.open({title, userInfo, key})
                this.noticeList[key] = notice
                await this._common.settimeout(timeout)
                if(!notice) return
                notice.close()
            },
            reject_hand_toast(data){
                let {userInfo = {}} = data
                let {nickname} = userInfo
                let {titleKey, timeout} = data
                let message = this._errorCode.game[titleKey]
                message = this._common.urlParse(message, {nickname})
                let duration = 5000, className = 'invite-toast-box'
                this.$toast({
                    message,
                    duration,
                    className
                });
            },
            paramsInit(opt){
                opt = opt || {}
                let usercode = opt.usercode || this.$store.state.app.usercode
                this.usercode = usercode
            },
            getKey(opt){
                opt = opt || {}
                let {inviterUserCode, inviteeUserCode} = opt
                return `${inviterUserCode}-${inviteeUserCode}`
            },
            check(key){
                if(this.noticeList[key]) this.noticeList[key].close()
                this.deleteList(key)
            },
            closeByData(data){
                let key = this.getKey(data)
                this.check(key)
            },
            deleteList(key){
                if(this.noticeList[key]) this.$delete(this.noticeList, key)
                if(this.confirmList[key]) this.$delete(this.confirmList, key)

            },
            isWaitInvite(opt){
                let {inviterUserCode} = opt
                let {usercode} = this
                return inviterUserCode == usercode
            },
            isInvite(opt){
                let {inviterUserCode} = opt
                let {usercode} = this
                return inviterUserCode != usercode
            },
            pageTitleGet(opt){
                let {userInfo} = opt
                if(!userInfo) return null
                let {nickname} = userInfo
                let title = ''
                if(this.isWaitInvite(opt)) title = this._errorCode.game['wait invite title for window']
                if(this.isInvite(opt)) title = this._errorCode.game['invite title for window']
                if(title) title = this._common.urlParse(title, {nickname})
                return title
            },
            async funHand(opt){
                opt = opt || {}
                let {key, val} = opt
                val = val || {}
                let fun = val.value
                if(!this[fun] || !this.confirmListCul[key]) return
                let data = this.confirmListCul[key]
                let res = await this[fun](data)
                if(res) this.check(key)
            },
            async accept(val){
                val = val || {}
                let {inviteeUserCode, inviterUserCode, inviterClientId: inviter_client_id, inviteeClientId: invitee_client_id} = val
                let res = await this.api.acceptInvitation({inviteeUserCode, inviterUserCode, inviter_client_id, invitee_client_id})
                if(res){
                    let params = {
                        gameroomId: res
                    }
                    // this.$emit('takeFun', {
                    //     fun: 'gotoGameroomConfig',
                    //     data: params
                    // })
                    this.$emit('takeFun', {
                        fun: 'acceptInviteWait',
                        data: val
                    })
                }
                return res
            },
            async refuse(val){
                val = val || {}
                let {inviteeUserCode, inviterUserCode, inviterClientId: inviter_client_id, inviteeClientId: invitee_client_id, reject_duration} = val
                let res = await this.api.refuseInvitation({inviteeUserCode, inviterUserCode, inviter_client_id, invitee_client_id, reject_duration})
                return res
            },
            async cancel(val){
                val = val || {}
                let {inviteeUserCode, inviterUserCode} = val
                let res = await this.api.inviteCancelToGame({inviteeUserCode, inviterUserCode})
                return res
            },
            inviteTimeout(id){
                if(!id) return
                this.check(id)
            },
            noticeClose(id){
                if(!id) return
                this.deleteList(id)
            },
            async reopen(){
                // if(this.)
                let {confirmListArray: list, waitOpen} = this
                if(!list.length && !waitOpen.length) return
                let data = list.concat(waitOpen)
                this.confirmList = {}
                this.waitOpen = []
                await this._funhand.nextTick.call(this)
                this.usercode = this.$store.state.app.usercode
                for(let i in data){
                    let {inviterUserCode} = data[i]
                    if(inviterUserCode == this.usercode) continue
                    let key = this.getKey(data[i])
                    let remainTime
                    let ele = this.$refs[key]
                    if(this._common.isArray(ele)) ele = ele[0]
                    if(ele) remainTime = ele.remainTime
                    if(remainTime) data[i].remainTime = remainTime
                    data[i].reopen = true
                    this.open(data[i])
                }
            },
            remainTimeChange(remainTime, id){
                let {confirmList: list} = this
                if(!list[id]) return
                list[id].remainTime = remainTime
            },
            rejectAllDay(val){
                let reject_duration = this.PARAMS.reject_duration_default
                return this.refuse({...val, reject_duration})
            }
        },
        created() {
            // this._routeCallback.setCallback('inviteConfirmBox', this.routerBeforeEach)
            // this._routeCallback.setCallback('inviteConfirmBox', this.routerAfterEach, 'after')
        },
        components: {
            inviteConfirm
        },
        computed: {
            confirmListArray(){
                let {confirmListCul: list} = this
                list = this._common.deepCopy(list)
                list = Object.values(list)
                list = this._common.sortData(list, (v1, v2) => {
                    return v1.index < v2.index
                })
                return list
            },
            confirmListCul(){
                let list = this.confirmList
                let rd = {}
                for(let i in list){
                    let key = i
                    // let title = this.pageTitleGet(list[i])
                    let title = null
                    let buttons = []
                    if(this.isWaitInvite(list[i])) buttons = this.waitInviteButtons
                    if(this.isInvite(list[i])) buttons = this.inviteButtons
                    let callback = this.funHand
                    let closeCallback = this.noticeClose
                    rd[key] = {key, title, ...list[i], buttons, callback, closeCallback}
                }
                return rd
            },
            inviteButtons(){
                return [
                    {name: '拒绝', value: 'refuse', class: 'ref'},
                    {name: '接受', value: 'accept', class: 'acc'},
                ]
            },
            waitInviteButtons(){
                return [
                    // {name: '取消', value: 'cancel', class: 'cancel'},
                ]
            },
            listLength(){
                return this.confirmListArray.length
            }
        },
        watch: {
            listLength: {
                handler(val){
                    if(val >= this.PARAMS.invite_max_window || !this.waitOpen.length) return
                    let data = this.waitOpen.shift()
                    this.open(data)
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            // this._routeCallback.removeCallback('inviteConfirmBox')
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >

</style>