import {common} from './exportEnter'
import {singleProxy} from './singleClass'

class routeHand{
    constructor(opt){
        opt = opt || {}
        this.$route = opt.route
        this.router = opt.router
        this.beforeEachCall = opt.beforeEach
        this.afterEachCall = opt.afterEach
        this.nextTickFun = opt.nextTick
        this.beforeEachList = {}
        this.afterEachList = {}
        this.history = {}
        this.routeList = opt.routeList || {}
        this._common = common
        this.routeEachInit()
    }
    setRoute(r){
        this.$route = r
    }
    routeEachInit(){
        if(!this.router) return
        this.router.afterEach((to, from) => {
            this.afterEach(to, from)
        })
        this.router.beforeEach((to, from, next) => {
            this.beforeEach(to, from, next)
        })
    }
    async afterEach(to, from){
        from = from || {}
        let {name} = from
        this.history[name] = from
        if(this.afterEachCall) this.afterEachCall(to, from)
    }
    async beforeEach(to, from, next){
        let res = true
        
        res = await this.eachEmit({to, from, type: 'before'})
        if(!res) return
        if(this.beforeEachCall) res = await this.beforeEachCall(to, from)
        if(!res) return
        next()
    }
    async eachEmit(opt){
        let {type, to, from} = opt || {}
        let {beforeEachList: before, afterEachList: after} = this
        let data = {before, after}
        type = type || 'before'
        if(!data[type]) return
        let funList = data[type]
        let keyList = Object.keys(funList)
        let index = 0
        return this.eachEmitAction({to, from, keyList, index, funList})
    }
    async eachEmitAction(opt){
        let {to, from, keyList, index, funList} = opt || {}
        let key = keyList[index]
        let fun = funList[key]
        if(!fun) return true
        let res = await fun(to, from)
        if(!res) return res
        index++
        return this.eachEmitAction({to, from, keyList, index, funList})
    }
    eachOn(type, key, fun){
        type = type || 'before'
        let index = `${type}EachList`
        if(!this[index]) return
        this[index][key] = fun
    }
    eachRemove(type, key){
        // if(!this.beforeEachList[key]) return
        // type = type || 'before'
        // delete this.beforeEachList[key]


        type = type || 'before'
        let index = `${type}EachList`
        if(!this[index]) return
        delete this[index][key]
    }

    async beforeEachEmit(to, from){
        let {beforeEachList: list} = this
        let keyList = Object.keys(list)
        let index = 0
        return this.beforeEachEmitAction({to, from, list: keyList, index})
    }
    async beforeEachEmitAction(opt){
        let {to, from, list, index} = opt || {}
        let eventList = this.beforeEachList
        let key = list[index]
        let fun = eventList[key]
        if(!fun) return true
        let res = await fun(to, from)
        if(!res) return res
        index++
        return this.beforeEachEmitAction({to, from, list, index})
    }
    beforeEachOn(key, fun){
        this.beforeEachList[key] = fun
    }
    beforeEachRemove(key){
        if(!this.beforeEachList[key]) return
        delete this.beforeEachList[key]
    }
    async nextTick(){
        if(!this.nextTickFun) return
        return new Promise(res => {
            this.nextTickFun(() => {
                res()
            })
        })
    }
    async back(opt){
        // let {history} = this
        // let data = history.pop()
        // this.history = history
        // if(!data) return false


        this.eachOn('after', 'routeBack', () => {
            let {name, path, params, query} = this.$route
            let {hash} = opt || {}
            if(!hash) return
            this.eachRemove('after', 'routeBack')
            path = `${path}#${hash}`
            this.replace({name, path, params, query, hash})
        })
        this.router.back()

    }
    getBackPath(name, options){
        options = options || {}
        let {history, routeList} = this
        let data = routeList[name] || {}
        let {backRouteName} = data
        if(options.backByName) backRouteName = name
        if(!backRouteName) return false
        let path = null, params = {}
        if(history[backRouteName]){
            path = history[backRouteName].path
        }
        else if(routeList[backRouteName]){
            path = routeList[backRouteName].path
            params = routeList[backRouteName].params || {}
        }
        let {query = {}} = options
        query = this.queryPush(query)
        return {path, params, query} 
    }
    getBackPath_new(name, options){
        let {query: {backPath} = {}} = this.$route
        if(!backPath) return false
        return {path: backPath}
    }
    backHand(name, options){
        let {backByName} = options || {}
        if(!backByName) return this.back()
        let opt = this.getBackPath(name, options)
        if(!opt) return false
        this.replace(opt)
    }
    getBackFun(name){
        let {routeList} = this
        let data = routeList[name] || {}
        let {backFun} = data
        return backFun
    }
    async push(opt){
        // let path = this.backPathGet()
        // let {query} = opt
        // query = query || {}
        // if(!query.backPath) query.backPath = path
        // opt.query = query
        let res = await this.router.push(opt)
        return res
    }
    async replace(opt){
        // let {fullPath} = this.$route
        // let {path} = opt || {}
        // if(fullPath.indexOf(path) > -1){
        //     let {query = {}} = opt
        //     query = this.queryPush(query)
        //     opt.query = query
        // }
        let {hash, path} = opt || {}
        if(hash && path.indexOf('#') <= -1){
            path = `${path}#${hash}`
            opt.path = path
        }
        let res = await this.router.replace(opt)
        return res
    }
    pathParse(name, params){
        let val = this.routeList[name] || {}
        let {path} = val
        if(!path) return false
        path = this._common.urlParse(path, params)
        return path
    }
    async goto(opt){
        // opt = opt || {}
        // let {name, params, type, query, hash} = opt
        // type = type || 'push'
        // let path = this.pathParse(name, params)
        // if(!path || !this[type]){
        //     return false
        // }
        // if(!hash){
        //     if(path == this.$route.path){
        //         return
        //     }
        //     query = this.queryPush(query)
        //     return this[type]({name, params, query, hash})
        // }
        // else {
        //     path = `${path}#${hash}`
        //     if(path == this.$route.fullPath){
        //         return
        //     }
        //     query = this.queryPush(query)
        //     return this[type]({path, params, query})
        // }


        opt = opt || {}
        let {name, params, type, query, hash} = opt
        type = type || 'push'
        let path = this.pathParse(name, params)
        if(!path || !this[type]){
            return false
        }
        if(hash){
            hash = hash || ''
            hash = hash.replace(/#/g, '')
            path = `${path}#${hash}`
        }
        query = this.queryPush(query)
        return this[type]({path, params, query})
    }
    queryPush(query){
        query = query || {}
        let routeQuery = this.$route.query
        let notPushList = {
            'backName': true
        }
        for(let i in routeQuery){
            if(query[i] !== undefined || query[i] === undefined) continue
            if(notPushList[i]) continue
            query[i] = routeQuery[i]
        }
        let rd = this._common.deepCopy(query)
        for(let i in rd){
            if(rd[i] === undefined) delete query[i]
        }
        return query
    }
    clearQuery(key){
        let {path, query} = this.$route
        query = query || {}
        delete query[key]
        this.replace({path, query})
    }
    backPathGet(){
        let {fullPath} = this.$route
        return fullPath
    }
}

export default singleProxy(routeHand)
