<!--  -->
<template>
    <div class='com-head media-html'>
        <!-- <img :src="`${PARAMS.assetsUrl}/img/back.png`" class="h-icon cursor-pointer" @click="backHand" v-if="show && !isFilePage">
		<img :src="`${PARAMS.assetsUrl}/img/wht/iconHome.png`" class="h-icon cursor-pointer" @click="backHand" v-if="show && isFilePage"> -->
        <div class="flex-center scale-button" :class="backIconBcg" @click.self="backHand">
            <div class="back-btn-box scale-button cursor-pointer" v-if="show"
            :style="iconStyle"
            @click.stop="backHand"
            :class="{'normal': !isPersonal, 'personal': isPersonal}"
            ></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {
                pathName: this.$route.name,
                path: this.$route.path,
                query: this.$route.query,
                backForWindow: {
                    'taskCenter': 'taskCenterOpen'
                }
            };
        },
        methods: {
            back(){
                let route = this.$route || {}
                let opt = {}
                let {name, query} = route
                query = query || {}
                if(query.backName){
                    name = query.backName
                    opt.backByName = true
                }
                this._routeHand.backHand(name, opt)
            },
            backHand(){
                let fun = this.backFun
                console.log(fun)
                if(fun){
                    if(this[fun]) this[fun]()
                    else {
                        this._common.emitEventBus(fun)
                    }
                    return
                }
                // if(this.queryBackHand()) return 
                this.back()
            },
            queryBackHand(){
                let {query} = this
                query = query || {}
                let f = false
                for(let i in query){
                    let fun = this.backForWindow[i]
                    if(this[fun]){
                        f = this[fun](query[i])
                    }
                }
                return f
            },
            taskCenterOpen(routeName){
                let {name} = this.$route
                if(routeName != name) return
                this._common.taskCenter()
                this.query = {}
                return true
            }
        },
        created() {
            this._common.globalQuit = this.backHand
        },
        components: {
        },
        computed: {
            iconStyle(){
                let {backIcon} = this
                let sty = {
                    background: `url(${backIcon}) 0% 0% / 100% 100% no-repeat`,
                    // 'backgroundSize': '100% 100%'
                }
                return sty
            },
            backIconBcg(){
                let {path} = this
                let list = {
                    '/palace/island': 'mind-palace-island',
                    '/palace/knowledge': 'mind-palace-knowledge',
                }
                let icon = ''
                if(this.pathName == 'Home') return icon
                for(let i in list){
                    if(path.indexOf(i) > -1){
                        icon = list[i]
                        break
                    }
                }
                return icon
            },
            backIcon(){
                let {path} = this
                let list = {
                    '/user/summary': `${this.PARAMS.assetsUrlV2_0}/global/c4.png`,
                    '/subject/summary': `${this.PARAMS.assetsUrlV2_0}/global/c4_1.png`,
                    '/token': `${this.PARAMS.assetsUrlV2_0}/global/c4_2.png`,
                    '/palace/island': `${this.PARAMS.assetsUrl_root}/gaotu/mind/img/palace/return.png`,
                    '/palace/knowledge': '',
                }
                let icon = `${this.PARAMS.assetsUrlV2_0}/global/c3.png`
                let iconHome = `${this.PARAMS.assetsUrlV2_0}/global/c4_3.png`
                if(this.pathName == 'Home') return iconHome
                for(let i in list){
                    if(path.indexOf(i) > -1){
                        icon = list[i]
                        break
                    }
                }
                return icon
            },
			isPersonal(){
				// let name = this.pathName
                // return (name == 'personal-summary') ? true : false
                let {path} = this
                let key = 'personal'
                return path.indexOf('personal') > -1
			},
            show(){
                let name = this.pathName
                let backPath = this._routeHand.getBackPath(name)
                let backFun = this.backFun
                return (backPath || backFun) ? true : false
            },
            backFun(){
                let name = this.pathName
                let backFun = this._routeHand.getBackFun(name)
                return backFun || null
            }
        },
        watch: {
            $route: {
                handler(){
                    this.pathName = this.$route.name
                    this.path = this.$route.path
                    this.query = this.$route.query
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-head{
        overflow: hidden;
        .h-icon{
            height: 32px;
            width: 32px;
            float: left;
        }
        .mind-palace-island{
            .background-img-max(url('@{assetsUrlMind}/img/palace/return_bcg.png'));
            width: 62px;
            height: 42px;
        }
        .mind-palace-knowledge{
            .background-img-max(url('@{assetsUrlMind}/img/palace/knowledge/return_bcg.png'));
            width: 44px;
            height: 44px;
        }
        .back-btn-box{
            // height: 32px;
            // width: 32px;
            width: 21px;
            height: 23px;
        }
        // .back-btn-box.normal{
        //     // background: url('@{assetsUrl}/img/back.png') no-repeat;
        //     background: url('@{assetsUrlV2_0}/global/c3.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .back-btn-box.personal{
        //     background: url('@{assetsUrl}/img/wht/iconHome.png') no-repeat;
        //     background-size: 100% 100%;
            
        // }
    }
</style>