<template>
    <div class="com-global-button-loading position-center">
        <van-loading type="spinner" />
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            size: [Number, String]
        },
        data() {
            return {};
        },
        methods: {},
        created() {},
        components: {},
        computed: {
            loadingStyle(){
                let {size} = this
                if(this._common.isNumber(size)) size = `${size}px`
                return {
                    width: size,
                    height: size,
                }
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-global-button-loading{
        .van-loading{
            color: #333;
        }
        .van-loading__spinner--spinner{
            width: 15px;
            height: 15px;
        }
    }
</style>