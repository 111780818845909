<!--  -->
<template>
    <div class='com-global-balance'>
        <!-- <div class="balance-icon"></div> -->
        <div class="balance-text">
            <span class="text-normal flex-center">{{balanceCul}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            balance: Number
        },
        data() {
            return {
                userInfo: {}
            };
        },
        methods: {
            userInfoChange(){
                this.userInfo = this.$store.state.app
            }
        },
        created() {
            this.userInfoChange()
            this._userHand.on('userInfo', this.userInfoChange)
        },
        components: {
        },
        computed: {
            balanceCul(){
                let {balance} = this.userInfo
                if(this.balance === undefined) return balance
                return this.balance
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-balance{
        display: flex;
        align-items: center;
        .balance-icon{
            width: 30px;
            height: 30px;
            margin-right: 4px;
            .background-img-max(url('@{assetsUrlV2_0}/global/balance-icon.png'));
        }
    }
</style>