export default {
	//闯关目录
	'getSubjectDirectory': {
		url: '/town/puzzle/list/user/{user_code}',
		serve: 'social',
	},
	//作业列表
	'puzzleList': {
		url: '/town/puzzle/exercise/list/{puzzle_id}',
		serve: 'social',
		login: false,
	},
	'exerciseList': {
		url: '/town/puzzle/exercise/list/{puzzle_id}',
		serve: 'social',
		login: false,
	},
	//答题上传
	'uploadPuzzle': {
		url: '/town/puzzle/exercise/user/{user_code}',
		serve: 'social',
		login: false,
	},
	'uploadUserAnswer': {
		url: '/town/puzzle/exercise/user/{usercode}',
		serve: 'social',
		login: false,
	},
	//获取答题情况
	'puzzleSituation': {
		url: '/town/puzzle/statistics/user/{user_code}',
		serve: 'social',
		login: false,
	},
	//获取关卡信息
	'puzzleInfo': {
		url: '/town/puzzle/user/{user_code}',
		serve: 'social',
		login: true
	},
	//闯关结果
	'puzzleResult': {
		url: '/town/puzzle/user/answer/list/user/{usercode}',
		serve: 'social',
	},
	//获取下一关
	'nextPuzzle': {
		url: '/town/puzzle/next/user/{user_code}',
		serve: 'social'
	},
	//关卡基本信息(back)
	'puzzleBasicInfo': {
		url: '/town/puzzle/{puzzle_id}',
		serve: 'social'
	},
	'userPuzzleInfo': {
		url: '/town/puzzle/record/user/{usercode}',
		serve: 'social'
	},
	//关卡动画位置
	'puzzleAnimationList': {
		url: '/town/puzzle/cheer/user/list/{usercode}',
		serve: 'social'
	},
	//关卡动画点击
	'puzzleAnimationClick': {
		url: '/town/puzzle/cheer/user/{usercode}',
		serve: 'social'
	},
    // 关卡全部完成额外奖励
    'puzzleAccomplish': {
        url: '/town/puzzle/accomplish/user/{usercode}',
		serve: 'social'
    }
    // /town/puzzle/accomplish/user/{user_code}
}

