import Vue from 'vue'
import { Button, Cell, CellGroup, Collapse, CollapseItem, Field, Form, Loading, Popup, Dialog, Switch, Col, Row, CountDown, Toast, Icon, Slider, Swipe, SwipeItem, Tab, Tabs, Circle, ImagePreview, RadioGroup, Radio } from 'vant';
Vue.use(Button)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Field)
Vue.use(Form)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Row)
Vue.use(Switch)
Vue.use(CountDown)
Vue.use(Toast)
Vue.use(Icon)
Vue.use(Slider)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Circle)
Vue.use(ImagePreview)
Vue.use(RadioGroup)
Vue.use(Radio)
