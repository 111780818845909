const index = () => import(/* webpackChunkName: mistakes */ "../views/mistakes/index.vue")
// const wrong = () => import(/* webpackChunkName: mistakes-wrong */ "../views/mistakes/page/wrong.vue")
// const exercise = () => import(/* webpackChunkName: mistakes-exercise */ "../views/mistakes/page/exercise.vue")
const summary = () => import(/* webpackChunkName: mistakes-summary */ "../views/mistakes/page/summary.vue")
const exercise = () => import(/* webpackChunkName: mistakes-exercise */ "../views/mistakes/page/action.vue")
const result = () => import(/* webpackChunkName: mistakes-result */ "../views/mistakes/page/result.vue")


export default {
    path: "/mistakes",
    name: "mistakes",
    component: index,
    backRouteName: 'Home',
    redirect: '/mistakes/summary',
    children: [
        {
            path: 'summary',
            name: 'mistakes-summary',
            component: summary,
            backRouteName: 'Home',
        },
        // {
        //     path: 'wrong',
        //     name: 'mistakes-wrong',
        //     component: wrong,
        //     backRouteName: 'Home',
        // },
        // {
        //     path: 'exercise/:exerciseId',
        //     name: 'mistakes-exercise',
        //     component: exercise,
        //     backRouteName: 'mistakes',
        // },
        {
            path: 'exercise/:exerciseId',
            name: 'mistakes-exercise',
            component: exercise,
            backRouteName: 'mistakes-summary',
        },
        {
            path: 'result/:resultData',
            name: 'mistakes-result',
            component: result,
            backRouteName: 'mistakes-summary',
        },
    ]
}
