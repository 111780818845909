export default {
    handicap13: [
        [],
        [],
        [42, 126],
        [42, 126, 48],
        [42, 126, 48, 120],
        [42, 126, 48, 120, 84]
    ],
    handicap9: [
        [],
        [],
        [20, 24],
        [20, 24, 56],
        [20, 24, 56, 60]
    ],
    handicap19: [
        [],
        [],
        [72, 288,],
        [72, 288, 300],
        [72, 288, 60, 300],
        [72, 288, 60, 300, 180],
        [72, 288, 60, 300, 174, 186],
        [72, 288, 60, 300, 174, 186, 180],
        [72, 288, 60, 300, 174, 186, 66, 294],
        [72, 288, 60, 300, 174, 186, 66, 294, 180],
    ],
    sgf: {
        max: 722
    },
}