import Vue from "vue";
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import {PieChart, LineChart} from 'echarts/charts';
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent,
	DatasetComponent
} from 'echarts/components';

import {
	CanvasRenderer
} from 'echarts/renderers';

echarts.use(
	[
		TitleComponent,
		TooltipComponent,
		GridComponent,
		PieChart,
		LineChart,
		LegendComponent,
		DatasetComponent,
		CanvasRenderer
	]
);
Vue.prototype.$echarts = echarts
