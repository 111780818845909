export default {
    //课程权限
    'coursePower': {
        url: '/town/course/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //作业进度
    'courseProgress': {
        url: '/town/school/course/{course_id}',
        serve: 'social',
        login: false,
    },
    //章节进度
    'courseChapter': {
        url: '/town/course/progress/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //作业列表
    'courseList': {
        url: '/town/course/exercise/list/{chapter_id}',
        serve: 'social',
        login: false,
    },
    //答题上传
    'uploadCourse': {
        url: '/town/course/exercise/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //获取答题情况
    'courseSituation': {
        url: '/town/course/exercise/answer/list/user/{user_code}',
        serve: 'social',
        login: false,
    },


    'courseAuth': {
        url: '/town/course/user/{user_code}',
        serve: 'social',
        login: false,
    },
    'chapterList': {
        url: '/town/course/progress/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //作业列表
    'exerciseList': {
        url: '/town/course/exercise/list/{chapter_id}',
        serve: 'social',
        login: false,
    },
    'userExpandExerciseList': {
        url: '/town/course/user/exercise/list/{chapter_id}',
        serve: 'social',
        login: false,
    },
    //答题上传
    'exerciseUpload': {
        url: '/town/course/exercise/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //获取答题情况
    'chapterExStatus': {
        url: '/town/course/exercise/answer/list/user/{user_code}',
        serve: 'social',
        login: false,
    },

    //专项题库一级目录列表
    'subjectChapterList': {
        url: '/town/subject/chapter/list',
        serve: 'social',
        login: false,
    },
    // 专项题库二级目录列表
    'subjectSectionList': {
        // url: '/town/subject/chapter/section/list',
        url: '/town/subject/user/chapter/section/list',
        serve: 'social',
        login: false,
    },
    // 专项题库三级目录列表
    'subjectExerciseList': {
        url: '/town/subject/user/exercise/list',
        serve: 'social',
        login: false,
    },
    // 专项题库习题列表
    'subjectExercise': {
        url: '/town/subject/exercise/list',
        serve: 'social',
        login: false,
    },
    // 专项题库答题上传
    'subAnswerUpload': {
        url: '/town/subject/user/answer/{user_code}',
        serve: 'social',
        login: false,
    },
    
    // 上传sgf生成图片 --test
    'createImageBoardFile': {
        url: '/export/board/sgf/image/path',
        serve: 'engine',
        login: false,
    },
    // 更新列表棋谱图片 --test
    'updateImage': {
        url: '/town/subject/exercise/media/path/update',
        serve: 'social',
        login: false,
    },
    // 课程列表
    'courseListCo': {
        url: '/town/school/course/list',
        serve: 'social',
        login: false
    },
    'courseListCov': {
        url: '/town/user/course/list/{user_code}',
        serve: 'social',
        login: false
    },
    'sectionInfo': {
        url: '/town/subject/section/{section_id}',
        serve: 'social',
        login: false
    },
    'courseDuration': {
        url: '/town/course/exercise/answer/duration/user/{user_code}',
        serve: 'social',
        login: true
    },
    'subjectDuration': {
        url: '/town/subject/user/answer/duration/{user_code}',
        serve: 'social',
        login: true
    },
    'userExerciseList': {
        url: '/town/subject/user/course/exercise/list',
        serve: 'social',
        login: true
    },
    // 专项题库状态
    'subjectStatus': {
        url: '/town/subject/user/status/{user_code}',
        serve: 'social',
    }
}
