export default {
    'taskSign': {
        url: '/town/school/user/task/sign/{user_code}',
        serve: 'social'
    },
    'taskList': {
        url: '/town/school/user/task/list/{user_code}',
        serve: 'social'
    },
    'stoneRecord': {
        url: '/town/school/user/task/record/{user_code}',
        serve: 'social'
    },
    'middleTaskList': {
        url: '/town/school/user/task/middle/list/{user_code}',
        serve: 'social'
    },
    'taskListV2': {
        url: '/town/school/user/task/list/v2/{user_code}',
        serve: 'social'
    },
    'taskSignV2': {
        url: '/town/school/user/task/sign/v2/{user_code}',
        serve: 'social'
    },
    'signInList': {
        url: '/town/school/user/task/get/sign/{user_code}',
        serve: 'social'
    },
    'getPalaceParent': {
        url: '/town/palace/parent',
        serve: 'social'
    },
    'getSubjectParent': {
        url: '/town/subject/section/parent',
        serve: 'social'
    },
    'haveNewTask': {
        url: '/town/school/user/task/light',
        serve: 'social'
    },
    'getWeekList': {
        url: '/town/report/user',
        serve: 'social'
    }
}