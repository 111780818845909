<template>
    <div id="app" class="app" :style="{...appStyle, ...clearPadding}" @click="appClick">
        <appBcg></appBcg>
        <div id="nav" class="app-item head">
            <app-head ref="head" v-if="appLoaded"></app-head>
        </div>
        <div class="app-item content flex-center">
            <router-view
            v-if="appLoaded"
            ref="pageNow"
            :appStyle.sync="appStyle"
            :appSizeData.sync="appSizeData"
            :htmlFontSize.sync="htmlFontSize"
            @tokenLoaded="tokenLoaded"
             />
        </div>
        <loading ref="loading"></loading>
        <confirm ref="confirm"></confirm>
        <confirm-pro ref="confirmPro"></confirm-pro>
        <setting ref="setting"></setting>
        <taskCenter ref="taskCenter" :appLoaded.sync="appLoaded"></taskCenter>
        <stoneRecord ref="stoneRecord"></stoneRecord>
        <sharePoster ref="sharePoster"></sharePoster>
        <activateMember ref="activateMember"></activateMember>
        <screen-time ref="screenTime"></screen-time>
    </div>
</template>
<script>
    import com from '@/App.vue'
    import sharePoster from './components/sharePoster.vue'
    import taskCenter from './components/taskCenter.vue'
    import stoneRecord from './components/stoneRecord.vue'
    import setting from './components/setting.vue'
    import activateMember from './components/activate.vue'
    import screenTime from './views/game/components/screenTime.vue'
    import mindGameApi from '@/API/page/mind/mindGame.js'

    export default {
        name: '',
        extends: com,
        props: {
        },
        provide(){
            return {
                postScreenTime: this.postScreenTime,
                userPointStart: this.userPointStart,
            }
        },
        data() {
            return {
                mindGameApi: new mindGameApi(),
                userTimer: null,
                gameActionFlag: 1, // 初始值1 游戏中2 游戏结束时3
                screenTimeObj: {
                    '/game/': 'game',
                    '/palace/': 'palace',
                }
            };
        },
        methods: {
            userLevelInit(){},
            async postScreenTime(val) {
                val = val || {}
                let { usercode: user_code } = this
                let path = this.$route.path
                let gameActionFlag = val.gameActionFlag != null ? val.gameActionFlag : this.gameActionFlag
                let list = this.screenTimeObj
                let type = 'other' // 思维乐园:game、学霸殿堂:palace、其它:other
                for (const i in list) {
                    if(path.indexOf(i) > -1) {
                        type = list[i]
                        break
                    }
                }
                if(path.indexOf('/mall/') > -1) type ='goods'
                let res = await this.mindGameApi.getUserPoint({ user_code, type, noLoad: true }) || {}
                if(res.code == 0) return false
                if (res.code == '71037' && (gameActionFlag == 1 || gameActionFlag == 3)) this.$refs['screenTime'].open({ timeTxt: '40min' }) //周一～周五
                if (res.code == '71038' && (gameActionFlag == 1 || gameActionFlag == 3)) this.$refs['screenTime'].open({ timeTxt: '60min' }) //周六日
                return true
            },
            userPointStart(firstFlag){
                this.startTimer(firstFlag)
            },
            async startTimer(firstFlag){
                this.stopTimer()
                let path = this.$route.path
                let list = {
                    '/action': 2,
                    '/correction': 2,
                }
                this.userTimer = setInterval(async () => {
                    for (const i in list) {
                        if(path.indexOf(list[i]) > -1 && path.indexOf('/game/') > -1){
                            this.gameActionFlag = list[i]
                            break
                        }else{
                            this.gameActionFlag = 1
                        }
                    }
                    await this.postScreenTime()
                }, 29000); 
                if(firstFlag) await this.postScreenTime()
            },
            stopTimer() {
                if(this.userTimer) clearInterval(this.userTimer);
            },
        },
        created() {
        },
        components: {
            sharePoster,
            taskCenter,
            stoneRecord,
            setting,
            activateMember,
            screenTime,
        },
        computed: {},
        watch: {
            queryFunWatch: {
                handler(val, oldVal){
                    let {appLoaded} = val 
                    if(this._common.isSameObject(val, oldVal) || !appLoaded) return 
                    this.queryFunHandl()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {
            this.stopTimer();
        }, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    
</style>