import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'
import socketHand from '@/plugins/websocket/socketHand'


class socialGame extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'socialGame'
        opt.localKey = 'app'
        opt.name = localKey
        super(opt)
        this.socketHand = new socketHand()
    }


    async getPlayRoomUserList(opt) {
        opt = opt || {}
        let {page, size, level = -1} = opt
        page = page || 0
        size = size || 15
        let options = {
            ...opt,
            data: {page, size, level}
        }
        let res = await this.requestAction('getPlayRoomUserList', options, null, 'social')
        return res
    }
    async getFollowingUser(opt){
        opt = opt || {}
        let {page, size} = opt
        page = page || 0
        size = size || 15
        let options = {
            ...opt,
            data: {page, size}
        }
        let res = await this.requestAction('getFollowingUser', options, null, 'social')
        return res
    }
    async userListSearch(opt){
        opt = opt || {}
        let {page, size, nickname} = opt
        page = page || 0
        size = size || 15
        let options = {
            ...opt,
            data: {page, size, nickname}
        }
        let res = await this.requestAction('userListSearch', options, null, 'social')
        return res
    }

    async getRoomList(opt) {
        opt = opt || {}
        let {page, size} = opt
        page = page || 0
        size = size || 15
        let options = {
            ...opt,
            data: {
                page,
                size,
            }
        }
        let res = await this.requestAction('getRoomList', options, null, 'social')
        if(res){
            let rd = []
            for(let i in res){
                if(res[i]) rd.push(res[i])
            }
            res = rd
        }
        return res
    }

    async getUserStatus(usercode){
        usercode = usercode || this._appData.getStoreItemByCom('usercode')
        let options = {
            usercode,
            data: {
            }
        }
        return this.requestAction('getSataus', options, null, 'social')
    }

    async setUserInviteState(state){
        let urlKey = `${state}Invite`
        if(!this.apiList[urlKey]) return false
        let options = {
            data: {
            },
            method: 'post'
        }
        return this.requestAction(urlKey, options, null, 'social')
    }
    async inviteStatusCheck(){
        let res = await this.getFirstGameRoom()
        if(!res.id) return false
        let {gameroomStatus: status} = res
        let isEnd = this.gameroomIsEnd(status)
        if(isEnd) return false
        return res
    }
    gameroomIsEnd(status){
        let enums = this.ENUMS
        return (
            enums.ROOM_ENDED == status ||
            enums.ROOM_CLOSED == status ||
            enums.ROOM_DELETED == status
        )
    }
    async getFirstGameRoom(){
        // let opt = {noLoad: true, noError: true}
        let data = await this.getUserGameroom()
        data = data || []
        data = data[0] || {}
        return data
    }
    async inviteUserForGame(opt) {
        let state = await this.stompStatusCheck(this.socketHand)
        if(!state) return false
        let gameRes = await this.inviteStatusCheck()

        if(gameRes){
            let message = this._errorCode.game['has busy status on other device']
            this._common.confirmPro.open({
                message,
                buttons: [
                    {name: '确定', type: 'confirm'}
                ]
            })
            return false
        } 
        let {usercode, inviteUsercode, inviter_client_id, invitee_client_id} = opt || {}
        usercode = usercode || this._appData.getStoreItemByCom('usercode')
        inviter_client_id = this.PARAMS.web_clientId
        invitee_client_id = invitee_client_id || ''
        let options = {
            method: "post",
            data: {
                inviter_user_code: usercode,
                invitee_user_code: inviteUsercode,
                inviter_client_id,
                invitee_client_id,

            }
        }
        return this.requestAction('inviteUserGame', options, null, 'social')
    }

    inviteCancelToGame(opt){
        opt = opt || {}
        let invitee_user_code = opt.inviteeUserCode
        let inviter_user_code = opt.inviterUserCode
        let {inviter_client_id, invitee_client_id} = opt
        inviter_client_id = inviter_client_id || this.PARAMS.web_clientId
        invitee_client_id = invitee_client_id || ''
        let options = {
            method: "post",
            data: {
                invitee_user_code,
                inviter_user_code,
                invitee_client_id,
                inviter_client_id
            }
        }
        return this.requestAction('inviteCancelToGame', options, null, 'social')
    }

    refuseInvitation(opt) {
        opt = opt || {}
        let invitee_user_code = opt.inviteeUserCode
        let inviter_user_code = opt.inviterUserCode
        let {inviter_client_id, invitee_client_id, reject_duration = 0} = opt
        invitee_client_id = invitee_client_id || this.PARAMS.web_clientId
        inviter_client_id = inviter_client_id || ''
        let options = {
            method: "post",
            data: {
                inviter_user_code,
                invitee_user_code,
                inviter_client_id, 
                invitee_client_id,
                reject_duration
            }
        }
        return this.requestAction('refuseInvitation', options, null, 'social')
    }

    //游戏大厅登录
    gamezoneLogin(usercode){
        usercode = usercode || this._appData.getStoreItemByCom('usercode')
        let options = {
            method: "post",
            usercode,
            data: {
            }
        }
        return this.requestAction('gamezoneLogin', options, null, 'social')
    }

    //接受对局邀请
    acceptInvitation(opt) {
        opt = opt || {}
        let inviter_user_code = opt.inviterUserCode
        let invitee_user_code = opt.inviteeUserCode
        let {inviter_client_id, invitee_client_id} = opt
        invitee_client_id = invitee_client_id || this.PARAMS.web_clientId
        inviter_client_id = inviter_client_id || ''
        let options = {
            method: "post",
            data: {
                inviter_user_code,
                invitee_user_code,
                inviter_client_id,
                invitee_client_id
            }
        }
        return this.requestAction('acceptInvitation', options, null, 'social')
    }
    //invite ack send
    ackInvitation(opt){
        opt = opt || {}
        let inviter_user_code = opt.inviterUserCode
        let invitee_user_code = opt.inviteeUserCode
        let {inviter_client_id, invitee_client_id} = opt
        inviter_client_id = inviter_client_id || this.PARAMS.web_clientId
        invitee_client_id = invitee_client_id || ''
        let options = {
            method: "post",
            data: {
                inviter_user_code,
                invitee_user_code,
                inviter_client_id, 
                invitee_client_id
            }
        }
        return this.requestAction('ackInvitation', options, null, 'social')
    }

    //游戏房间登录
    gameroomLogin(opt){
        opt = opt || {}
        let {gameroomId, role, password} = opt
        let data = {
            user_role: role,
            password
        }
        if(password === undefined) delete data['password']
        let options = {
            method: "post",
            gameroomId,
            data
        }
        return this.requestAction('gameroomLogin', options, null, 'social')
    }

    //房间退出
    async gameroomLogout(gameroomId, opt){
        opt = opt || {}
        let {timeout} = opt
        if(timeout === undefined) timeout = 10000
        let options = {
            method: "post",
            gameroomId,
            timeout
        }
        
        return this.requestAction('gameroomLogout', options, null, 'social')
        
    }
    

    //房间信息
    async getGameroomInfo(opt){
        opt = opt || {}
        let gameroomId = opt.gameroomId
        let options = {
            method: "get",
            ...opt,
            gameroomId,
            data: {
            }
        }
        let res = await this.requestAction('gameroomInfo', options, null, 'social')
        return res
    }
    async gameroomInfoByCode(opt){
        opt = opt || {}
        let gameroomCode = opt.gameroomCode
        let options = {
            method: "get",
            ...opt,
            gameroomCode,
            data: {}
        }
        let res = await this.requestAction('gameroomInfoByCode', options, null, 'social')
        return res
    }

    //房间人员列表
    getRoomUserList(opt) {
        opt = opt || {}
        let {gameroomId} = opt
        let options = {
            method: "get",
            noLoad: true,
            ...opt,
            gameroom_id: gameroomId,
            data: opt
        }
        return this.requestAction('getRoomUserList', options, null, 'social')
    }

    //发送对局申请
    gameConfigCheck(params){
        params = params || {}
        let default_data = {
            gameroomId: true,
            gameType: true,
            boardSize: true,
            komi: true,

            blackUserCode: '',
            whiteUserCode: '',
            whiteLevel: 0,
            blackLevel: 0,

            gameName: '',
            handicap: this.PARAMS.rank_game_handicap,
            countdownTime: 0,
            countdownNum: 0,
            mainTime: 0,

            stone: this.PARAMS.game_config_default_stone,
            rule: this.PARAMS.game_config_default_rule,


            currentPlayer: '',
        }
        for(let i in default_data){
            if(params[i] === undefined) params[i] = default_data[i]
        }
        return params
    }
    sendGameConfig(params){
        params = this.gameConfigCheck(params)
        let {gameroomId} = params
        if(!gameroomId) return
        let options = {
            method: "post",
            gameroomId,
            headers:{
               'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params,
        }
        return this.requestAction('sendGameConfig', options, null, 'social')
    }

    updateGameConfig(params){
        params = this.gameConfigCheck(params)
        let {gameroomId} = params
        if(!gameroomId) return
        let options = {
            method: "post",
            gameroomId,
            headers:{
               'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params,
        }
        return this.requestAction('updateGameConfig', options, null, 'social')
    }

    //接受对局规则
    acceptConfig(params){
        params = this.gameConfigCheck(params)
        let {gameroomId} = params
        if(!gameroomId) return
        let options = {
            method: "post",
            gameroomId,
            headers:{
               'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params,
        }
        return this.requestAction('acceptGameConfig', options, null, 'social')
    }

    //拒绝对局规则（退出）
    async rejectConfig(params){
        let {gameroomId: gameroom_id} = params
        if(!gameroom_id) return
        let options = {
            method: "post",
            gameroom_id,
            ...params,
            headers:{
               'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params,
        }
        let res = await this.requestAction('rejectGameConfig', options, null, 'social')
        return res
    }

    //genmove
    async genmove(opt) {
        opt = opt || {}
        let {wsGameId} = opt
        if(!wsGameId) return
        let options = {
            ...opt,
            method: "post",
            gameId: wsGameId,
            fullData: true,
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: opt
        }
        let res = await this.requestAction('genmove', options, null, 'social')
        res = this.genmoveResCheck(res)
        return res
    }
    async gameTimeSync(opt){
        opt = opt || {}
        let {params = {}} = opt
        // delete opt['params']
        let {wsGameId} = params
        if(!wsGameId) return
        let options = {
            ...opt,
            method: "post",
            wsGameId,
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params
        }
        let res = await this.requestAction('gameTimeSync', options, null, 'social')
        return res
    }
    genmoveResCheck(res){
        let list = {
            '16013': true
        }
        if(res && res.code == 0) return res.data
        res = res || {}
        let {code} = res
        if(!list[code]) return false
        return res
    }
    gameStart(gameId){
        let options = {
            gameId,
            method: "post",
            data: {
            }
        }
        return this.requestAction('gameStart', options, null, 'social')
    }

    /*
        10 -- 悔棋
        20 -- 数子
        30 -- 和棋
    */ 
    sendConfirmAction(opt){
        opt = opt || {}
        let {action_type} = opt
        let options = {
            method: "post",
            ...opt,
            data: {
                action_type
            }
        }
        return this.requestAction('confirmAction', options, null, 'social')
    }
    sendResultAction(opt){
        opt = opt || {}
        let {type, action_type} = opt
        let api = type ? 'actionAccept' : 'actionReject'
        let options = {
            method: "post",
            ...opt,
            data: {
                action_type
            }
        }
        return this.requestAction(api, options, null, 'social')
    }


    //set user statis
    

    //对局用户
    gamePlayer(opt){
        opt = opt || {}
        let {gameroom_id} = opt
        if(!gameroom_id) return
        let options = {
            ...opt,
            data: {
            }
        }
        return this.requestAction('gamePlayer', options, null, 'social')

    }

    //结束对局
    endGame(opt){
        opt = opt || {}
        let {game_id, game_result, user_code} = opt
        user_code = user_code || this._appData.getStoreItemByCom('usercode')
        if(!game_id) return
        let options = {
            ...opt,
            method: 'post',
            data: {
                game_result, 
                user_code
            }
        }
        return this.requestAction('endGame', options, null, 'social')
    }

    //对局信息
    gameMeta(opt){
        opt = opt || {}
        let {game_id} = opt
        if(!game_id) return
        let options = {
            ...opt,
            method: 'get',
            data: {
            }
        }
        return this.requestAction('gameMeta', options, null, 'social')
    }
    //对局信息？
    gameInfo(opt){
        opt = opt || {}
        let {game_id} = opt
        if(!game_id) return
        let options = {
            ...opt,
            method: 'get',
            data: {
            }
        }
        return this.requestAction('gameInfo', options, null, 'social')
    }

    //对弈准备
    gameReady(opt){
        opt = opt || {}
        let {game_id} = opt
        if(!game_id) return
        let options = {
            ...opt,
            method: 'post',
            data: {
            }
        }
        return this.requestAction('gameReady', options, null, 'social')
    }
    // 房间准备 -- 
    async gameroomReady(opt){
        opt = opt || {}
        let {user_code, gameroomId} = opt
        user_code = user_code || this._appData.getStoreItemByCom('usercode')
        if(!gameroomId || !user_code) return
        let options = {
            ...opt,
            gameroomId,
            method: 'post',
            data: {
                user_code
            }
        }
        return this.requestAction('gameroomReady', options, null, 'social')
    }
    // 房间取消准备
    async gameroomUnready(opt){
        opt = opt || {}
        let {user_code, gameroomId} = opt
        user_code = user_code || this._appData.getStoreItemByCom('usercode')
        if(!gameroomId || !user_code) return
        let options = {
            ...opt,
            gameroomId,
            method: 'post',
            data: {
                user_code
            }
        }
        return this.requestAction('gameroomUnready', options, null, 'social')
    }
    //悔棋
    async backMove(opt){
        opt = opt || {}
        let {wsGameId} = opt
        if(!wsGameId) return
        let options = {
            ...opt,
            method: "post",
            gameId: wsGameId,
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: opt
        }
        let res = await this.requestAction('backMove', options, null, 'social')
        return res
    }

    //申请数子
    async applyJudge(opt){
        opt = opt || {}
        let {game_id, userCode} = opt
        userCode = userCode || this._appData.getStoreItemByCom('usercode')
        if(!game_id) return
        let options = {
            ...opt,
            method: "post",
            game_id,
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: {
                ...opt,
                wsGameId: game_id,
                userCode,
                
            }
        }
        let res = await this.requestAction('applyJudge', options, null, 'social')
        return res
    }

    //wsgame info
    async wsUserInfo(opt){
        opt = opt || {}
        let {usercode} = opt
        usercode = usercode || this._appData.getStoreItemByCom('usercode')
        if(!usercode) return
        opt.usercode = usercode
        let options = {
            ...opt,
            method: "get",
            data: {}
        }
        let res = await this.requestAction('wsUserInfo', options, null, 'social')
        if(res){
            this._appData.saveUserInfo(res)
        }
        return res
    }
    async gameOptions(opt){
        opt = opt || {}
        let {usercode, type} = opt
        usercode = usercode || this._appData.getStoreItemByCom('usercode')
        if(!usercode) return
        type = type || 'get'
        let method = type == 'get' ? 'get' : 'post'
        opt.usercode = usercode
        let options = {
            ...opt,
            method,
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('gameOptions', options, null, 'social')
        return res
    }

    async getUserGameroom(opt){
        opt = opt || {}
        let usercode = opt.usercode || this._appData.getStoreItemByCom('usercode')
        if(!usercode) return
        let {user_role = 0} = opt
        opt.usercode = usercode
        let options = {
            ...opt,
            method: "get",
            data: {
                user_role
            }
        }
        let res = await this.requestAction('getUserGameroom', options, null, 'social')
        return res
    }

    async userWatchRoom(opt){
        opt = opt || {}
        let usercode = opt.usercode || this._appData.getStoreItemByCom('usercode')
        if(!usercode) return
        opt.usercode = usercode
        let options = {
            ...opt,
            method: "get",
            data: {}
        }
        let res = await this.requestAction('userWatchRoom', options, null, 'social')
        return res
    }

    async gameSgf(opt){
        opt = opt || {}
        let username = opt.username || this._appData.getStoreItemByCom('username')
        let usercode = opt.usercode || this._appData.getStoreItemByCom('usercode')
        if(!usercode || !opt.gameroomId) return
        opt.usercode = usercode
        opt.game_round = opt.gameRound
        let options = {
            ...opt,
            method: "get",
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('gameSgf', options, null, 'engine')
        return res
    }

    gameTasteParamsCheck(opt){
        opt = opt || {}
        let defaultData = {
            tasteBoardSize: this.PARAMS.rank_game_boardSize,
            tasteRule: this.PARAMS.game_config_default_rule,
            tasteStone: this.PARAMS.game_config_default_stone,
            tasteKomi: this.PARAMS.rank_game_komi,
            tasteHandicap: this.PARAMS.rank_game_handicap,
        }
        return {...defaultData, ...opt}
    }
    async gameTaste(opt){
        let state = await this.stompStatusCheck(this.socketHand)
        if(!state) return false
        opt = opt || {}
        let userCode = opt.userCode || this._appData.getStoreItemByCom('usercode')
        if(!userCode) return
        opt.userCode = userCode
        opt = this.gameTasteParamsCheck(opt)
        let options = {
            ...opt,
            method: "post",
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('gameTaste', options, null, 'social')
        return res
    }
    async gameTasteHeartbeat(opt){
        opt = opt || {}
        let userCode = opt.userCode || this._appData.getStoreItemByCom('usercode')
        if(!userCode) return
        opt.userCode = userCode
        opt = this.gameTasteParamsCheck(opt)
        let options = {
            ...opt,
            method: "post",
            data: {
            }
        }
        let res = await this.requestAction('gameTasteHeartbeat', options, null, 'social')
        return res
    }
    async gameTasteCancel(opt){
        opt = opt || {}
        let userCode = opt.userCode || this._appData.getStoreItemByCom('usercode')
        if(!userCode) return
        opt.userCode = userCode
        let options = {
            ...opt,
            method: "post",
            data: {
            }
        }
        let res = await this.requestAction('gameTasteCancel', options, null, 'social')
        return res
    }
    async gameStateSet(opt){
        opt = opt || {}
        let wsGameId = opt.wsGameId
        if(!wsGameId) return
        let options = {
            ...opt,
            method: "post",
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: opt
        }
        let res = await this.requestAction('gameStateSet', options, null, 'social')
        return res
    }

    //用户信息
    async gamezoneUserInfo(opt){
        opt = opt || {}
        let userCode = opt.userCode || this._appData.getStoreItemByCom('usercode')
        if(!userCode) return
        opt.userCode = userCode
        let options = {
            ...opt,
            method: "get",
            data: {
            }
        }
        let res = await this.requestAction('gamezoneUserInfo', options, null, 'social')
        return res
    }

    playAgainPushClientId(params){
        let {blackUserCode, whiteUserCode} = params
        let userCode = this._appData.getStoreItemByCom('usercode')
        let clientKey = ''
        if(blackUserCode == userCode) clientKey = 'black'
        else if(whiteUserCode == userCode) clientKey = 'white'
        params[`${clientKey}ClientId`] = this.PARAMS.web_clientId
        return params
    }
    //续战
    async sendPlayAgain(params){
        params = this.gameConfigCheck(params)
        let {gameroomId} = params
        if(!gameroomId) return
        params = this.playAgainPushClientId(params)
        let options = {
            method: "post",
            gameroomId,
            headers:{
               'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params,
        }
        return this.requestAction('sendPlayAgain', options, null, 'social')
    }
    //续战拒绝
    async rejectPlayAgain(params){
        let {gameroomId: gameroom_id} = params
        if(!gameroom_id) return
        params = this.playAgainPushClientId(params)
        let options = {
            method: "post",
            gameroom_id,
            ...params,
            headers:{
               'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params,
        }
        let res = await this.requestAction('rejectPlayAgain', options, null, 'social')
        return res
    }
    //续战接受
    async acceptPlayAgain(params){
        params = this.gameConfigCheck(params)
        let {gameroomId} = params
        if(!gameroomId) return
        params = this.playAgainPushClientId(params)
        let options = {
            method: "post",
            gameroomId,
            headers:{
               'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: params,
        }
        return this.requestAction('acceptPlayAgain', options, null, 'social')
    }

    //复制棋谱
    async replicateGame(opt){
        let {id} = opt
        let username = opt.username || this._appData.getStoreItemByCom('username')
        if(!id || !username) return
        opt = {...opt, username, id}
        let options = {
            ...opt,
            method: "post",
            data: {
                ...opt
            }
        }
        return this.requestAction('replicateGame', options, null, 'engine')
    }

    // 关闭房间
    async closeGameroom(opt){
        opt = opt || {}
        let {gameroomId} = opt || {}
        if(!gameroomId) return
        let options = {
            ...opt,
            gameroomId,
            method: "post",
            data: {
            }
        }
        return this.requestAction('closeGameroom', options, null, 'social')
    }

    async applyArea(params){
        params = params || {}
        let options = {
            method: "get",
            ...params,
            data: params,
            fullData: true
        }
        let res = await this.requestAction('applyArea', options, null)
        return res
    }
}

export default singleProxy(socialGame)





