export default {
    'levelList': {
        url: '/ai/style/list',
        serve: 'engine',
    },
    'AIList': {
        url: '/ai/level/list',
        serve: 'engine'
    },
    'genmove': {
        url: '/dcnn/group/genmove',
        serve: 'engine'
    },
    'genmoveDcnn': {
        url: '/dcnn/genmove',
        serve: 'engine'
    },
    'saveGame': {
        url: '/games',
        serve: 'engine',
        method: 'post'
    },
    'patternList': {
        url: '/town/game/label/1',
        serve: 'social',
        method: 'get'
    },
    'judge': {
        url: '/dcnn/group/judge',
        serve: 'engine',
        method: 'get'
    },
    'gameGift': {
        url: '/town/game/user/task/{usercode}',
        serve: 'social',
        method: 'get'
    },
    'createRoom': {
        url: '/gameroom/reserve/config',
        serve: 'social',
        method: 'post'
    },
    'rankList': {
        url: '/gamezone/user/rank/list',
        serve: 'social',
        method: 'get'
    }
}