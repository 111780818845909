

export default {
    username: null,
    nickname: null,
    token_type: null,
    access_token: null,
    refresh_token: null,
    usercode: null,
    photo: null,
    avatarDressUrl: null,
    avatarHeadUrl: null,
    avatarName: null,
    dressName: null,
    balance: 0,
    appStyle: {},
    avatarId: null,
    dressId: null
}