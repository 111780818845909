import SockJS from "sockjs-client";
// import commonApi from '../common'

// export default class sockClient extends commonApi {
export default class sockClient {
    constructor(opt){
        opt = opt || {}
        opt.localKey = 'sockjs_data'
        // super(opt)
        this.init(opt)
    }
    init(opt){
        opt = opt || {}
        for(let i in opt){
            this[i] = opt[i]
        }
    }
    connect(){
        let {url} = this
        if(!url) return false
        this.client = new SockJS(url)
        if(!this.eventSet()) return false
        return this.client
    }
    eventSet(){
        let {client} = this
        if(!client) return false
        client.onopen = info => {console.log('socket open 1'); this.onopenCallback(info)}
        client.onclose = info => {this.oncloseCallback(info)}
        client.onmessage = info => {this.onmessageCallback(info)}
        client.open = info => {
        }
        this.client = client
        return client
    }
    onopenCallback(info){
        console.log('on open sock')
        if(this.onopen) this.onopen(info)
    }
    oncloseCallback(info){
        console.log('on close')
        if(this.onclose) this.onclose(info)
    }
    onmessageCallback(info){
        console.log('on message')
        if(this.onmessage) this.onmessage(info)
    }
}