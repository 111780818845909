const index = () => import(/* webpackChunkName: subject */ "../views/subject/index.vue")
const directory = () => import(/* webpackChunkName: subject */ "../views/subject/page/directory.vue")

const list = () => import(/* webpackChunkName: subject */ "../views/subject/page/list.vue")
const summary = () => import(/* webpackChunkName: subject */ "../views/subject/page/summary/index.vue")
const listG0 = () => import(/* webpackChunkName: subject */ "../views/subject/page/summary/g0.vue")

const action = () => import(/* webpackChunkName: subject */ "../views/subject/page/action.vue")
const result = () => import(/* webpackChunkName: subject */ "../views/subject/page/result.vue")



export default {
    path: "/subject",
    name: "subject",
    component: index,
    backRouteName: 'Home',
	// redirect: 'subject/list/LTE=',
	redirect: 'subject/summary/LTE=',
	children:[
		{
			path:'directory',
			name:'subject-directory',
			component:directory,
			backRouteName:'Home',
		},
		// {
		// 	path: 'list',
		// 	name: 'subject-list',
		// 	component: list,
		// 	backRouteName: 'Home',
		// },
		{
			path: 'list/:routeData',
			name: 'subject-list-full',
			component: list,
			backRouteName: 'Home',
		},
		{
			path: 'summary/:routeData',
			name: 'subject-summary-full',
			component: summary,
			backRouteName: 'Home',
			// children:[
			// 	{
			// 		path:'G0/:routeData',
			// 		name:'subject-list-G0',
			// 		component:listG0,
			// 		backRouteName:'Home',
			// 	}
			// ]

		},
		{
			path: 'action/:routeData',
			name: 'subject-action',
			component: action,
			backFun: 'subjectActionBack'
		},
		{
			path: 'result/:puzzleId',
			name: 'subject-result',
			component: result,
			backFun: 'subjectResultBack'
		},
		
	]
}
