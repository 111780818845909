export default {
    //错题进度
    'wrongProgress': {
        url: '/town/note/statistics/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //错题列表
    'wrongList': {
        url: '/town/note/list/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //错题上传
    'uploadWrongCourse': {
        url: '/town/note/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 目录页总数据
    'getAllStatistics': {
        url: '/town/note/all/statistics/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 获取相似题
    'getSimilarList': {
        url: '/town/note/similar/list/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // ai相似题答题
    'postSimilarAnswer': {
        url: '/town/note/similar/user/{user_code}',
        serve: 'social',
        login: false,
    },
}