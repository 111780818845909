<!--  -->
<template>
    <div class="com-confirm-button cursor-pointer no-selected" :class="textClass" @click="click">
        <div class="btn-shadow" v-if="!shadowDisabled"></div>
        <div class="btn-bcg"></div>
        <div class="btn-text position-center bold">
            <slot></slot>
            <!-- <div class="btn-text-bcg" v-if="!$scopedSlots['default']" :class="textClass"></div> -->
        </div>
        
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            disabled: Boolean,
            type: String,
            showShadow: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {

            };
        },
        methods: {
            click(){
                this.$emit('click')
            }
        },
        created() {

        },
        components: {
        },
        computed: {
            shadowDisabled(){
                return this.type == 'text' || !this.showShadow
            },
            textBtn(){
                return this.type == 'text'
            },
            textClass(){
                let {type = 'normal'} = this
                if(this.disabled) return 'disabled'
                return `${type} scale-button`
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    // .com-confirm-button{
    //     margin: 0px auto;
    //     width: 100px;
    //     height: 40px;
    //     text-align: center;
    //     background: linear-gradient(155deg, #FF985C 0%, #FF8254 21%, #FF6932 100%);
    //     // box-shadow: 0px 0px 2px 0px #FF3700;
    //     border-radius: 8px;
    //     .confirm-button-body{
    //         width: 84px;
    //         height: 25px;
    //         background: linear-gradient(357deg, #FF8254 0%, #FF5B1E 100%);
    //         border-radius: 6px;
    //         color: #fff;
    //         > span[class^='text']{
    //             line-height: normal;
    //         }
    //     }
    // }
    // .com-confirm-button.disabled{
    //     background: linear-gradient(155deg, #D9D9D9 0%, #F1F1F1 21%, #DBDBDB 100%);
    //     // box-shadow: 0px 0px 2px 0px #CDCDCD;
    //     .confirm-button-body{
    //         background: linear-gradient(357deg, #EFEFEF 0%, #D4D4D4 100%);
    //         color: #B2B2B2;
    //     }
        
    // }

    .com-confirm-button{
        margin: 0px auto;
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 42px;
        position: relative;
        z-index: 0;
        .btn-text{
            // margin-top: 1px;
            z-index: 2;
            white-space: nowrap;
            span[class^='text']{
                // font-family: PingFangSC-Semibold, PingFang SC;
                // font-weight: 600;
                height: 11px;
                display: flex;
                align-items: center;
                // line-height: 10px;
            }
        }
        .textClass.exercise{

        }
        .btn-text-border{
            // width: 100%;
            // height: 1.2px;
            // background: #666161;
            // position: absolute;
            // bottom: -3px;
        }
    }
    .com-confirm-button.normal{
        color: #9B1F3E;
        .btn-bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/btn-normal.png'));
        }
    }
    .com-confirm-button.success{
        color: #1D7626;
        .btn-bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/btn-success.png'));
        }
    }
    .com-confirm-button.primary{
        color: #A2642A;
        .btn-bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/c2.png'));
        }
    }
    .com-confirm-button.disabled{
        color: #686868;
        .btn-bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/btn-disabled.png'));
        }
    }
    .com-confirm-button.complete{
        color: #708300;
        .btn-bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/btn-complete.png'));
        }
    }
    .com-confirm-button.text{
        color: #656060;
        width: auto;
        height: auto;
        border-bottom: 1.2px solid #666161;
        .btn-text{
            position: static;
            transform: translate(0);
            display: flex;
        }
    }
    .com-confirm-button.trans{
        color: #fff;
        .btn-bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/btn-trans.png'));
        }
    }
    .com-confirm-button.mis-mine{
        color: #656060;
        .btn-bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/btn-mis-mine.png'));
        }
    }

    .btn-shadow{
        position: absolute;
        width: 110%;
        height: 122%;
        top: -11%;
        left: -5%;  
        z-index: 1;  
        .background-img-max(url('@{assetsUrlV2_0}/global/btn-shadow.png'));
    }
    .btn-bcg{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 1;  
    }
</style>