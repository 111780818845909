export default {
    dressList: {
        url: '/town/school/user/account/dresses/{user_code}',
        serve: 'social'
    },
    avatarList: {
        url: '/town/school/user/account/avatars/{user_code}',
        serve: 'social'
    },
    purchaseDress: {
        url: '/town/school/user/account/dress/{user_code}',
        serve: 'social'
    },
    purchaseAvatar: {
        url: '/town/school/user/account/avatar/{user_code}',
        serve: 'social'
    },
    purchaseDecorate: {
        url: '/town/school/user/account/decorate/{user_code}',
        serve: 'social'
    },
    avatarDress: {
        // url: '/town/school/user/account/avatardress/{schoolId}',
        url: '/town/school/user/account/avatar/dress',
        serve: 'social'
    },
    purchasePet: {
        url: '/town/school/user/account/pet/claim/{user_code}',
        serve: 'social'
    },
    decorateList: {
        url: '/town/school/user/account/decorate/{user_code}',
        serve: 'social'
    },
    // 检验服装兑换码
    checkDressRedeemCode: {
        url: '/town/school/user/account/dress/check/card',
        serve: 'social',
    },
    // 兑换服装
    redeemDress: {
        url: '/town/school/user/account/dress/card/{user_code}',
        serve: 'social'
    },
    // 服装兑换活动是否过期
    dressOverdue: {
        url: '/town/school/user/account/dresses/overdue',
        serve: 'social',
    }
}