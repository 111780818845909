<!-- -->
<template>
    <div class='page-stone-record-summary flex-center' v-if="show">
        <div class="bcg">
            <div class="mark" @click="close()"></div>
            <div class="d1">
                <div class="off-btn" @click="close()"></div>
                <div class="stone-record-title flex-center">
                    <span class="text-normal bold text-purple-color">{{"思维币记录"}}</span>
                </div>
                <div class="items-empty" v-if="recordLength==0">
                    <span class="stone-record-size bold text-brown-color">{{"当前记录还是空的,"}}</span>
                    <span class="stone-record-size bold text-brown-color">{{"快去获取思维币吧~"}}</span>
                </div>
                <div class="items mini-scrollbar" 
                @scroll="scrolLoad"
                ref="commentContent"
                v-else>
                    <div ref="commentBox">
                        <!-- <div class="head-box">
                            <span class="stone-record-size bold text-brown-color l">{{'时间'}}</span>
                            <span class="stone-record-size bold text-brown-color c">{{'事件'}}</span>
                            <span class="stone-record-size bold text-brown-color r">{{'思维币增加个数'}}</span>
                        </div> -->
                        <div class="item" v-for="(v,i) in recordList" :key="i">
                            <div class="con">
                                <span class="stone-record-size bold text-brown-color l">{{v.date}}</span>
                                <span class="stone-record-size bold text-brown-color c">{{v.name}}</span>
                                <span class="stone-record-size bold text-brown-color r">{{v.value}}</span>
                            </div>
                            <div class="d3"></div>
                        </div>
                    </div>
                    <loading v-show="isLoading" :loading.sync="loading" :color="`#CABCAE`"></loading>
                </div>
                <div class="earn-stone scale-button" @click="taskOpen">
                    <div class="d4 flex-center">
                        <confirmPlay>
                            <span class="text-normal-ab bold">{{"去赚思维币"}}</span>  
                        </confirmPlay>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import com from '@/components/stoneRecord.vue'

    export default {
        name: '',
        extends: com,
        props: {},
        data() {
            return {};
        },
        methods: {},
        created() {
        },
        components: {},
        computed: {},
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>

</style>