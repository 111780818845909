const index = () => import(/* webpackChunkName: user */ "../views/avatar/index.vue")
const summary = () => import(/* webpackChunkName: user */ "../views/avatar/page/summary.vue")

export default {
    path: "/avatar",
    name: "avatar",
    component: index,
    backRouteName: 'Home',
	redirect: 'avatar/summary/-1',
    children:[
        {
			path:'summary/:goodsType',
			name:'avatar-summary',
			component:summary,
			backRouteName:'Home',
		},
    ]
}
