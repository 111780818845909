<!--  -->
<template>
    <div class="debug-data-com">
        <div class="data-body" v-if="debugActive">
            <div class="data-item" v-for="(v, i) in data" :key="i">
                <span class="text-mini">{{i}}:</span>
                <span class="text-mini">{{v}}</span>
            </div>
        </div>
        <div class="de-close" :class="{'open': debugActive, 'close': !debugActive}" @click="debugActive = !debugActive">
            <van-icon name="eye" />
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            data: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data() {
            return {
                debugActive: true
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .debug-data-com{
        position: fixed;
            top: 10px;
            left: 60px;
            z-index: 999;
            padding-right: 20px;
            .data-body{
                display: flex;
                flex-wrap: wrap;
                background: #fff;
                color: #333;
            }
            .data-item+ .data-item{
                margin-left: 10px;
            }
            .de-close.open{
                color: #000;
            }
            .de-close.close{
                color: #f83838;
            }
            .de-close{
                position: absolute;
                right: 5px;
                top: 0px;
                z-index: 2;
            }
    }
</style>