<!--  -->
<template>
    <div class='com-global-sl-button' @click="$emit('click')" :class="{'disabled-img': disabled || loading, 'scale-button': !disabled}">
        <div class="button-shadow"></div>
        <div class="sl-button-body flex-center bold" :class="[type, `sl-button-${type}-color`]">
            <button-loading v-if="loading"></button-loading>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            disabled: Boolean,
            loading: Boolean,
            type: {
                type: String,
                default: 'default'
            }
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-sl-button{
        position: relative;
        width: 68px;
        height: 24px;
        .button-shadow{
            width: 110%;
            height: 125%;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            margin: auto;
            margin-left: -3px;
            z-index: -1;
            .background-img-max(url('@{assetsUrlV2_0}/global/c5.png'));
        }
        .sl-button-body{
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        .sl-button-body.default{
            .background-img-max(url('@{assetsUrlV2_0}/global/c2.png'));
        }
        .sl-button-body.confirm{
            .background-img-max(url('@{assetsUrlV2_0}/global/c2.png'));
        }
        .sl-button-body.cancel{
            .background-img-max(url('@{assetsUrlV2_0}/global/c7.png'));
        }
    }
</style>