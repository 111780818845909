const index = () => import(/* webpackChunkName: cabin */ "../views/cabin/index.vue")
const summary = () => import(/* webpackChunkName: cabin */ "../views/cabin/page/summary.vue")

export default {
    path: "/cabin",
    name: "cabin",
    redirect: '/cabin/summary/G0',
    component: index,
    children:[
        {
            path: 'summary/:headVal', 
            name: 'cabin-summary', 
            component: summary, 
			backFun: 'backHome',
        },
    ]
}