import commonApi from '@/API/_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

import stompClass from './stompSocket'
// import connectUrl from '@/API/url/connectUrl'


class socketHand extends commonApi {
    constructor(opt){
        let localKey = 'websocket'
        opt = opt || {}
        opt.localKey = localKey
        opt.name = localKey
        super(opt)
        this.paramsSet()
        let {url} = this
        let headers = this.getHeaders()
        // let headers = {}
        this.callbackList = {
            onclose: {},
            onopen: {}
        }
        this.stompOnopenStack = []
        this.stompClass = new stompClass({url, headers})
    }
    addEventListener(options){
        let {type, name, callback} = options
        if(!this.callbackList[type]) return false
        this.callbackList[type][name] = callback
        return true
    }
    removeEventListener(options){
        let {type, name} = options
        if(!this.callbackList[type]) return false
        delete this.callbackList[type][name]
    }
    paramsSet(){
        this.stompClient = null
        this.url = this.apiList.socketConnect
        this.socketPath = this.apiList.socketPath
        this.subscribeList = {}
        this.maxReConnect = 4
        this.errorKey = 'stomp'
        this.continueConnect = false
        this.heartbeatInterval = {
            gamezone: {
                interval: null,
                time: 15000
            },
            room: {
                interval: null,
                time: 15000
            }
        }
    }
    getConnectTimeout(num){
        let time = 5000
        if(num >= this.maxReConnect){
            time = 15000
            // num = 0
            let code = 'connect error reconnect'
            let key = this.errorKey
            // this.stompClass.noticeByCodeConfirm({code, key})
        }
        return {time, num}
        
    }
    getHeaders(){
        let opt = {
            Auth_token: this._appData.getItem('access_token', 'app'),
            Channel: this.socketPath
        }
        return opt
    }
    setConnectMsg(num){
        num = num || 0
        let msg = this._errorCode.stomp.connecting
        if(num >= this.maxReConnect){
            msg = this._errorCode.stomp['connect error reconnect']
        }
        this.connectMsg = msg
    }
    connectSuccessMsg(){
        return this._errorCode.stomp['connect success']
    }
    loadingCallbackHand(res){
        if(!res) return
        let {fun} = res
        let realFun = `${fun}Callback`
        if(this[realFun]) this[realFun]()
        this.continueConnect = false
    }
    async loadingOpen(num){
        if(!this.loadingComponent) return
        this.setConnectMsg(num)
        let msg = this.connectMsg
        this.loadingComponent.text = msg
        this.loadingComponent.loading = true
        let loadingRes = await this.loadingComponent.open()
        this.loadingCallbackHand(loadingRes)
    }
    async loadingClose(msg){
        if(!this.loadingComponent) return
        msg = msg === undefined ? this.connectSuccessMsg() : msg
        this.loadingComponent.handedText = msg
        this.loadingComponent.loading = false
        if(msg){
            await this._common.settimeout(1000)
        }
        this.loadingComponent.close()
    }
    async connect(opt){
        this.oncloseTimeoutClear()
        opt = opt || {}
        let {num} = opt
        num = num || 0
        delete opt['num']
        this.init(opt)
        this.continueConnect = true
        let {url} = this
        this.loadingOpen(num)
        let headers = this.getHeaders()
        this.stompClient = await this.stompClass.connect({headers, debug: null, onclose: err => {
            this.oncloseHand(err)
        }})
        if(this.stompClient){
            // this.stompClient.debug = null
            this.status = true
            this.loadingClose()
            this.onopenCallback()
        }else{
            return this.connectError(num)
        }
        if(!this.stompClient){
            console.log('stomp connect fail')
        }

        return this.stompClient
    }
    oncloseCallback(err){
        let {callbackList: {onclose}} = this
        for(let i in onclose){
            let callback = onclose[i]
            if(!this._common.isFunction(callback)) continue
            callback(err)
        }
        this.gamezoneHeatbeatStop()
    }
    onopenCallback(err){
        this.awaitStompStatusPop()
        let {callbackList: {onopen}} = this
        for(let i in onopen){
            let callback = onopen[i]
            if(!this._common.isFunction(callback)){
                continue
            }
            callback(err)
        }
        this.gamezoneHeatbeatStart()
    }
    stompClientGet(){
        if(this.stompClient && this.stompClient.connected) return true
        return false
    }
    awaitStompStatusPop(){
        if(!this.stompOnopenStack.length) return
        let callback = this.stompOnopenStack.shift()
        if(callback) callback()
        this.awaitStompStatusPop()
    }
    waitStompStatus(){
        return new Promise(success => {
            if(this.stompClient && this.stompClient.connected) return success(true)
            this.stompOnopenStack.push(success)
        })
    }
    async oncloseHand(err){
        console.log(err)
        console.log('socket on close')
        this.oncloseTimeoutClear()
        if(!this.status){
            console.log('status is false')
            return
        }
        if(this.stompClient && this.stompClient.connected){
            console.log('connected')
            return
        }
        this.stompClass.disconnect()
        this.stompClient = null
        this.oncloseCallback(err)
        this.oncloseTimeout = setTimeout(async () => {
            await this.connect()
            if(!this.stompClient) return
            let list = this.subscribeList
            list = list || {}
            for(let i in list){
                let {callback, opt} = list[i]
                let channel = i
                this.subscribe(channel, callback, opt)
            }
        }, 5000)
        
    }
    oncloseTimeoutClear(){
        if(!this.oncloseTimeout) return
        clearTimeout(this.oncloseTimeout)
    }
    async connectError(num){
        num = num || 0
        let reconnectPar = this.getConnectTimeout(num)
        num = reconnectPar.num
        await this._common.settimeout(reconnectPar.time)
        num++
        if(!this.continueConnect) return
        return this.connect({num})
    }
    getUrl(channel, opt){
        let url = this.apiList[channel] || null
        if(!url) return null
        url = this._common.urlParse(url, opt)
        return url
    }
    subscribe(channel, callback, opt){
        opt = opt || {}
        let {client} = opt
        client = client || this.stompClient
        if(!client){
            return false
        }
        let url = this.getUrl(channel, opt)
        if(!url){
            return false
        }
        this.unsubscribeGlobal(channel)
        let subClient = this.stompClient.subscribe(url, res => {
            callback(res)
        }, {id: url})
        this.subscribeList[channel] = {
            url,
            client: subClient,
            callback,
            opt
        }
        return subClient
    }
    // subscribe(channel, callback, opt){
    //     opt = opt || {}
    //     let {client} = opt
    //     client = client || this.stompClient
    //     if(!client){
    //         return false
    //     }
    //     let url = this.getUrl(channel, opt)
    //     if(!url){
    //         return false
    //     }
    //     this.unsubscribeGlobal(channel)
    //     this.subscribeAction(channel, callback, {id: url})
    // }
    async subscribeAction(channel, callback, opt){
        let url = this.getUrl(channel, opt)
        if(!url){
            return false
        }
        let subClient = this.stompClient.subscribe(url, res => {
            callback(subClient)
        }, opt)
        return subClient
        
    }
    unsubscribe(channel){
        let subData = null
        if(channel && !this._common.isString(channel)) subData = channel
        else subData = this.subscribeList[channel]
        if(!subData) return
        let {client, url} = subData
        if(!url) return
        client.unsubscribe(url)
        delete this.subscribeList[channel]

    }
    unsubscribeGlobal(channel){
        if(!this.stompClient || !this.stompClient.unsubscribe) return
        // this.stompClient.unsubscribe(channel)
        this.unsubscribe(channel)
    }
    async sendMessage(path, opt){
        opt = opt || {}
        let client = this.stompClient
        if(!client){
            return false
        }
        path = this.getUrl(path, opt)
        if(!path){
            return
        }
        let headers = opt.headers || this.getHeaders()
        let data = opt.data || ''
        if(!this._common.isString(data)) {
            try{
                data = JSON.stringify(data)
            }catch(e){ 
                console.log('err send') 
                data = {}
            }
        }
        return client.send(path, headers, data)
    }
    
    disconnect(){
        this.status = false
        this.continueConnect = false
        if(this.stompClient){
            console.log('dis connect')
            this.stompClient.disconnect()
        }
        this.gamezoneHeatbeatStop()
        this.stompClient = null
    }
    gamezoneSubscribe(callback, opt){
        let channel = 'gamezoneChannel'
        let usercode = this._appData.getItem('usercode', 'app')
        opt = opt || {}
        opt.usercode = usercode
        return this.subscribe(channel, callback, opt)
    }
    gamezoneUnsubscribe(){
        let channel = 'gamezoneChannel'
        this.unsubscribe(channel)
    }

    inviteSubscribe(callback, opt){
        let channel = 'gameUserChannel'
        let usercode = this._appData.getItem('usercode', 'app')
        opt = opt || {}
        opt.usercode = usercode
        this.subscribe(channel, callback, opt)
    }
    inviteUnsubscribe(){
        let channel = 'gameUserChannel'
        this.unsubscribe(channel)
    }
    gamezoneHeatbeatStart(){
        this.gamezoneHeatbeatStop()
        let {gamezone} = this.heartbeatInterval
        gamezone.interval = setInterval(() => {
            this.gamezoneHeatbeatAction()
        }, gamezone.time);
        this.heartbeatInterval.gamezone = gamezone
    }
    // gamezoneHeatbeatAction(){
    //     let {gamezone: {time: heartbeatInterval}} = this.heartbeatInterval
    //     let userCode = this._appData.getItem('usercode', 'app')
    //     let stateData = this.getUserStatus()
    //     let {state: userStatus, stateDetail: userStatusDetail} = stateData
    //     this.sendMessage('gamezoneHeartbeat', {data: {userCode, heartbeatInterval, deviceType: this.ENUMS.WEB_DEVICE_TYPE, userStatus, userStatusDetail}})
    // }

    gamezoneHeatbeatAction(){
        let stateData = this.getUserStatus()
        let params = this.getHeartbeatParams()
        let {state: userStatus, stateDetail: userStatusDetail} = stateData
        this.sendMessage('gamezoneHeartbeat', {
            data: {
                ...params,
                userStatus, userStatusDetail
            }
        })
    }
    getHeartbeatParams(){
        let userCode = this._appData.getStoreItem('usercode')
        let {gamezone: {time: heartbeatInterval}} = this.heartbeatInterval
        let deviceType = this.ENUMS.WEB_DEVICE_TYPE
        let user_agent = this._common.userAgent()
        let Api_version = this.ENUMS.SOCKET_API_VERSION
        return {userCode, heartbeatInterval, deviceType, user_agent, Api_version}
    }



    getUserStatus(){
        let state = this._appData.getItem('userStatus', 'app')
        let stateDetail = this._appData.getItem('userStatusDetail', 'app')
        state = state || this.ENUMS.GAME_USER_IDLE
        stateDetail = stateDetail || ''
        return {state, stateDetail}
    }
    gamezoneHeatbeatStop(){
        let {gamezone} = this.heartbeatInterval
        if(!gamezone.interval) return
        clearInterval(gamezone.interval)
        gamezone.interval = null
        this.heartbeatInterval.gamezone = gamezone
    }
    
    //gameroom订阅
    gameroomSubscribe(callback, opt){
        opt = opt || {}
        let channel = 'gameroomSub'
        return this.subscribe(channel, callback, opt)
    }
    gameroomUnsubscribe(){
        let channel = 'gameroomSub'
        this.unsubscribe(channel)
    }

    //game 订阅
    gameSubscribe(callback, opt){
        opt = opt || {}
        let channel = 'gameSub'
        return this.subscribe(channel, callback, opt)
    }
    gameUnsubscribe(){
        let channel = 'gameSub'
        this.unsubscribe(channel)
    }

    //聊天室订阅
    chatroomSubscribe(callback, opt){
        opt = opt || {}
        let channel = 'chatroomSub'
        return this.subscribe(channel, callback, opt)
    }
    chatroomUnsubscribe(){
        let channel = 'chatroomSub'
        this.unsubscribe(channel)
    }

    //订阅X频道
    defineSubscribe(callback, opt){
        let {options, channel} = opt || {}
        if(!channel) return false
        return this.subscribe(channel, callback, options)
    }
    defineUnsubscribe(options){
        let {channel} = options || {}
        if(!channel) return false
        this.unsubscribe(channel)
    }
}


export default singleProxy(socketHand)