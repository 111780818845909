<!--  -->
<template>
    <div class='com-global-pet' v-if="src">
        <div class="pet-body">
            <div class="pet-shadow pet-item"></div>
            <img :src="src">
        </div>
        
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            src: String,
            pro: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-pet{
        .pet-body{
            position: relative;
            height: 86px;
            img{
                height: 100%;
                width: auto;
            }
        }
        .pet-item{
            position: absolute;
        }
        .pet-shadow{
            bottom: -3px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 77%;
            height: 8%;
            z-index: -1;
            .background-img-max(url('@{assetsUrl}/img/pet/pet-shadow.png'));
        }
        
    }
</style>