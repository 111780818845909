<!--  -->
<template>
    <div class='com-game-result' :class="[winColor]" >
        <span class="txt" v-if="resultData == ''">{{resultData}}</span>
        <span v-if="isRR" class="txt">{{PARAMS.RRText}}</span>
        <span v-else-if="resultData.value != 'N+R'">
            <span class="txt">
                <span class="txt">{{resultData.liveTxt || resultData.label}}</span>
                <span class="txt" v-if="resultData.num && resultData.num > 0">
                    <span class="txt" v-if="chineseRule">{{(resultData.num / 2)| decimalsToFractional}}子</span>
                    <span class="txt" v-else>{{resultData.num}}目</span>
                </span>
            </span>
        </span>
        <span v-else class="txt">{{result}}</span>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            result: String,
            rule: {
                type: String,
                default: 'chinese'
            }
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            isRR(){
                return this.result == 'N+R' || this.result == 'R+R'
            },
            resultData(){
                let re = this._common.deepCopy(this.result)
                re = re || ''
                let data = this._common.parseResultSelect(re)
                let win = this._common.resultWinLabel(re)
                if(!win) data.hide = true
                else data.hide = false
                data.win = win
                return data
                // return {}
            },
            chineseRule(){
                return this.rule == 'chinese'
            },
            japaneseRule(){
                return this.rule == 'japanese'
            },
            winColor(){
                let re = this.resultData || {}
                return re.win == 'b' ? 'black' : re.win == 'w' ? 'white' : ''
            },
        },
        watch: {
            // resultData: {
            //     handler(val){
            //         let data = val || {}
            //         this.$emit('winColorChange', data.win)
            //     },
            //     deep: true,
            //     immediate: true
            // }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-game-result{
        text-align: center;
    }
</style>