import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class login extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'app'
        let name = 'app'
        opt = {localKey, name}
        super(opt)
    }

    async checkUser(opt){
        opt = opt || {}
        let token = this._appData.getItem('access_token')
        if(!token) return false
        let client_id = this.PARAMS.client_id
        let client_secret = this.PARAMS.client_secret
        let options = {
            ...opt,
            method: 'post',
            headers: {
                Authorization: this._userHand.getDefaultToken()
            },
            data: {
                token,
                client_id,
                client_secret
            },
        }
        let res = await this.requestAction('checkUser', options)
        return res
    }
    async checkUserTokenGaotu(opt){
        // refresh_token
        let {access_token: token} = opt || {}
        if(!token) return false
        let client_id = this.PARAMS.client_id
        let client_secret = this.PARAMS.client_secret
        let options = {
            ...opt,
            method: 'post',
            noLoad: true,
            noError: true,
            headers: {
                Authorization: this._userHand.getDefaultToken()
            },
            data: {
                token,
                client_id,
                client_secret
            },
        }
        let res = await this.requestAction('checkUser', options)
        return res
    }
    async userTokenRefresh(refresh_token){
        refresh_token = refresh_token || this._appData.getItem('refresh_token')
        if(!refresh_token) return false
        let grant_type = 'refresh_token'
        let client_id = this.PARAMS.client_id
        let client_secret = this.PARAMS.client_secret
        let options = {
            method: 'post',
            headers: {
                Authorization: this._userHand.getDefaultToken()
            },
            data: {
                refresh_token,
                grant_type,
                client_id,
                client_secret
            },
        }
        let res = await this.requestAction('refreshToken', options)
        if(res){
            this._userHand.userLoginSet(res)
            await this.userAccount()
        }
        return res
    }
    async userTokenRefreshGaotu(opt){
        let {refresh_token} = opt || {}
        if(!refresh_token) return false
        let grant_type = 'refresh_token'
        let client_id = this.PARAMS.client_id
        let client_secret = this.PARAMS.client_secret
        let options = {
            method: 'post',
            noLoad: true,
            noError: true,
            headers: {
                Authorization: this._userHand.getDefaultToken()
            },
            data: {
                refresh_token,
                grant_type,
                client_id,
                client_secret
            },
        }
        let res = await this.requestAction('refreshToken', options)
        if(res){
            this._userHand.userLoginSet(res)
            await this.userAccount()
        }
        return res
    }
    async userLevel(opt){
        opt = opt || {}
        let options = {
            ...opt,
            method: 'get',
            data: {
            },
        }
        let res = await this.requestAction('userLevel', options)
        if(res) this._userHand.userLevelSet(res)
        return res
    }


    //登录推送 / 接管对弈
    async multiSend(opt){
        opt = opt || {}
        let {usercode: userCode, accessToken, refreshToken, multiType} = opt
        userCode = userCode || this._appData.getStoreItem('usercode')
        if(!userCode) return
        opt.multiType = multiType || this.PARAMS.login_multi_type
        opt.userCode = userCode
        opt.deviceType = this.PARAMS.device_type
        accessToken = accessToken || this._global.AuthorizationGet()
        refreshToken = refreshToken || this._appData.getItem('refresh_token', 'app')
        opt.accessToken = accessToken
        opt.refreshToken = refreshToken
        let options = {
            ...opt,
            method: 'post',
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('multiSend', options, null, 'social')
        return res
    }

    async checkToken(){
        let res = await this._global.userCheckToken()
        if(res && res.code == 0) return true
        return false
    }

    async uploadImageTest(opt){
		opt = opt || {}
        let {file_type, user_code, file} = opt
        user_code = user_code || this._appData.getStoreItem('usercode')
        if(!file.postName) file.postName = 'certificateFiles'
		let options = {
			method: "post",
			...opt,
			user_code,
			authUpload: true,
			file,
			extendParams: {
				file_type,
				user_code,
			},
		}
		return this.requestAction('uploadImageTest', options, null, 'social')
	}

    async loadGaotuExercise(opt){
		opt = opt || {}
        let {id} = opt
        let subjectId = 1, sourceId = id, questionIdList = [id], schoolId = '10001'
		let options = {
            ...opt,
            method: 'post',
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 
            },
            data: {
                subjectId,
                sourceId,
                questionIdList,
                schoolId
            }
        }
        let res = await this.requestAction('loadGaotuExercise', options, null, 'social')
        return res
	}
    async reportVideoError(opt){
		opt = opt || {}
        const userCode = this._appData.getItem('usercode', 'app')
        let options = {
            noError: true,
            noLoad: true,
            method: 'get',
            data: {
                ...opt,
                userCode
            },
        }
		return this.requestAction('reportVideoError', options, null, 'social')
    }
}

export default singleProxy(login)