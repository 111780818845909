import axios from 'axios'
import errorCode from './errorCode'
import {errorHand, params, appData, common, singleProxy, userHand} from './exportEnter'
axios.defaults.baseURL = window.location.origin
class request{
    constructor(opt){
        this._errorHand = errorHand
        this.PARAMS = params
        this._appData = new appData({key: 'request'})
        this._common = common
        this._userHand = new userHand()
        this.ERRORCODE = errorCode
        this.specHandList = this.PARAMS.specHandList
        this.axiosInit()
    }
    axiosInit(){
        this.axiosEventSet()
    }
    invideTokenHand(res){
        this._userHand.userLogoutSet()
        if(this._routeHand) this._routeHand.goto({name: 'login', type: 'replace'})
        if(this._common.confirm){
            let message = this.ERRORCODE.main['invalid token']
            this._common.confirm.open({
                message,
                buttons: [
                    {title: '确定', type: 'cancel'}
                ]
            })
        }
        return res
    }
    axiosEventSet(){
        axios.interceptors.request.use(config => {
            return this.interceptors(config)
        })
        // axios.interceptors.response.use(res => {
        //     return this.axiosSuccess(res)
        // }, err => {
        //     this.axiosError(err)
        // })
        axios.interceptors.response.use(res => {
            return this.axiosSuccess(res)
        })
    }
    //请求前拦截
    interceptors(config){
        config = config || {}
        let {headers} = config
        headers = this.tokenSet(headers)
        config.headers = headers
        return config
    }
    
    axiosSuccess(res){
        // return res
        return this.responseSuccessCheck(res)
    }
    axiosError(err){
        return this.responseSuccessCheck(err.response)
        // return err.response
    }
    responseSuccessCheck(res){
        let {data: {code} = {}, status} = res || {}
        let fun = null
        if(code !== undefined){
            if(this.specHandList[code]) fun = this.specHandList[code]
        }
        if(status !== undefined){
            if(this.specHandList[status]) fun = this.specHandList[status]
        }
        if(!fun || !this[fun]){
            return res
        }
        return this[fun](res)
    
    }
    tokenSet(headers){
        headers = headers || {}
        let {common} = headers
        common = common || {}
        if(!common['Authorization']){
            let token =  this.getUserToken()
            common['Authorization'] = token
            headers.common = common
        }
        return headers
    }
    getUserToken(){
        let token = this._userHand.getDefaultToken()
        let userToken = this._userHand.getUserToken()
        if(userToken) token = userToken
        return token
    }
    send(api, options){
        options = options || {}
        if(options.uploadFile) return this.uploadFiles(api, options)
        options.data = options.data || {}
        options = this.headersHand(options)
        let method = options.method || 'get'
        if(method.toLowerCase() != 'get' && !options.requestBody){
            let contentType = options.headers['Content-Type']
            if(contentType == 'application/x-www-form-urlencoded; charset=UTF-8'){
                let params = new URLSearchParams();
                for(var i in options.data){
                    params.append(i, options.data[i])
                }
                options.data = params;
            }else{
                options.data = JSON.stringify(options.data)
            }
        }
        if(options.timeout === undefined) options.timeout = 10000
        if(options.static) return this.sendStatic(options)
        options.url = `api/${api}${options.url}`
        if(method.toLowerCase() == 'get'){
            options.params = options.data || {}
        }
        return this.sendRequest(options)
    }

    async uploadFiles(api, options) {
        options = options || {}
        // options.data = options.data || {}
        // options = this.headersHand(options)
        // let file = options.file;
        // let param = new FormData();
        // if (Object.prototype.toString.call(file) != '[object Array]') {
        //     let {postName = 'file'} = options
        //     file = [{
        //         key: postName,
        //         data: file
        //     }]
        // }
        // for (let i in file) {
        //     let {key = 'file'} = file[i]
        //     param.append(key, file[i].data, file[i].data.name);
        // }
        // if (options.extendParams) {
        //     for (let i in options.extendParams) {
        //     param.append(i, options.extendParams[i])
        //     }
        // }
        // options.data = param;
        // // options.headers = {'Content-Type':'multipart/form-data'}
        // options.method = options.method || 'post'
        // options.timeout = options.timeout || 0



        options.data = options.data || {}
        let file = options.file;
        let param = new FormData();
        if(file.length){
            if (Object.prototype.toString.call(file) != '[object Array]') {
                let {postName = 'file'} = file
                file = [{
                    key: postName,
                    data: file
                }]
            }
            for (let i in file) {
                let {key = 'file'} = file[i]
                param.append(key, file[i].data, file[i].data.name);
            }
        }
        if (options.extendParams) {
            for (let i in options.extendParams) {
                param.append(i, options.extendParams[i])
            }
        }
        options.data = options.data || {}
        for(let i in options.data){
            param.append(i, options.data[i])
        }
        options.data = param;
        // options.headers = {'Content-Type':'multipart/form-data'}
        options.method = options.method || 'post'
        options.timeout = options.timeout || 0




        options.url = `api/${api}${options.url}`
        return this.sendRequest(options)
    }

    headersHand(options){
        options = options || {}
        options.headers = options.headers || {}
        let contentType = 'application/x-www-form-urlencoded; charset=UTF-8'
        if(options.headers['Content-Type']) contentType = options.headers['Content-Type']
        options.headers['Content-Type'] = contentType
        return options
    }
    async sendRequest(options){
        options = options || {}
        let res = await axios(options)
        .catch(err => {
            // return {data: {code: -1, msg: 'unknown error'}}
            return this.responseSuccessCheck(err.response)
        })
        res = res || {}
        let {status} = res
        let resData = res.data
        try{
            resData = JSON.parse(resData)
        }catch(e){
            resData = res.data
        }
        resData = resData || {}
        let {msg, code, data} = resData
        if(code === undefined) code = status == 200 ? 0 : status
        if(data === undefined) data = resData
        msg = this._errorHand.errorMsgGet({code, msg})
        let responseData = {code, data, msg}
        return {code, data, msg}
    }

    async sendStatic(options) {
        let opt = {}
        for (var i in options) {
            if (i == 'url') continue
            opt[i] = options[i]
        }
        let res = await axios.get(options.url, opt)
        if (res.status !== 200) return false
        return res.data
    }
}

export default singleProxy(request)