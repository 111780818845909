import commonApi from '@/API/_EXT_API'
import apiClass from '@/API/page/socialGame'
import appClass from '@/API/page/app'
import enums from './enums'

export default class userStatus extends commonApi {
    constructor(opt){
        opt = opt || {}
        let localKey = 'app'
        opt.localKey = localKey
        opt.name = 'userStatus'
        super(opt)
        // this.apiInit('userStatus')
        this.gameApi = new apiClass()
        this._appApi = new appClass()
        this.ENUMS = enums
    }
    async userStatusQuery(){
        
        let {type, statusDetail} = await this.statusTypeGet()
        if(!type) return
        if(this.setUserStatus) await this.setUserStatus({type, source: `userStatusControl:${type} userStatusQuery`, statusDetail})
        return type
    }
    async statusTypeGet(){
        let userInfo = await this.wsUserInfoInit()
        userInfo = userInfo || {}
        let type = 'idle', statusDetail = ''
        if(this.multiedCheck(userInfo)) return {type, statusDetail}
        type = await this.hasBusyFunction()
        if(!type) type = 'idle'
        statusDetail = await this.getStatusDetail(type)
        return {type, statusDetail}
    }
    async getStatusDetail(type){
        if(type == 'game') return this.PARAMS.play_game_detail
        if(type == 'engine') return this.PARAMS.AI_analysis
        // {
            // if(!this._global.$route) return ''
            // let path = this._global.$route.path
            // let list = {}
            // list[this._global.pageList.lifeAndDeath.index] = this.PARAMS.AI_life_death
            // list[this._global.pageList.engine.normal] = this.PARAMS.AI_analysis
            // list[this._global.pageList.play.engine] = this.PARAMS.AI_game
            // let data = null
            // for(let i in list){
            //     if(path.indexOf(i) <= -1) continue
            //     data = list[i]
            //     break
            // }
            // if(data) return data
        // }
        if(type != 'idle') return ''
        let watchGame = await this.gameApi.userWatchRoom({noLoad: true, noError: true})
        watchGame = watchGame || []
        return watchGame.length ? this.PARAMS.watch_game_detail : ''
    }
    async inviteStatusCheck(opt){
        let res = await this.getFirstGameRoom(opt)
        if(!res.id) return false
        let {gameroomStatus: status} = res
        let isEnd = this.gameroomIsEnd(status)
        if(isEnd) return false
        return res
    }
    gameroomIsEnd(status){
        let enums = this.ENUMS
        return (
            enums.ROOM_ENDED == status ||
            enums.ROOM_CLOSED == status ||
            enums.ROOM_DELETED == status
        )
    }
    async getFirstGameRoom(opt){
        opt = opt || {}
        if(opt.noLoad === undefined) opt.noLoad = true
        if(opt.noError === undefined) opt.noError = true
        let data = await this.gameApi.getUserGameroom(opt)
        data = data || []
        data = data[0] || {}
        return data
    }
    async wsUserInfoInit(){
        let res = await this.gameApi.wsUserInfo({noLoad: true})
        if(res) this.wsUserInfo = res
        return res
    }
    async getGameOptions(){
        let res = await this.gameApi.gameOptions({type: 'get'})
        if(res && this.gameOptionsUpdate) this.gameOptionsUpdate(res)
        return res
    }
    async setGameOptions(data){
        let res = await this.gameApi.gameOptions({type: 'set', ...data})
        if(res){
            let {invite_able: inviteAble, inviter_follow_restrict: inviterFollowRestrict, inviter_level_restrict: inviterLevelRestrict} = data
            if(this.gameOptionsUpdate) this.gameOptionsUpdate({inviteAble, inviterFollowRestrict, inviterLevelRestrict})
        }
        return res
    }

    async userLoginSend(){
        let userInfo = await this.wsUserInfoInit()
        userInfo = userInfo || {}
        let {webConnectionStatus} = userInfo
        if(webConnectionStatus == this.ENUMS.CONNECTION_CLOSED) return true
        let multiType = this.PARAMS.login_multi_type, multiAction = this.PARAMS.request_multi_action
        let res = await this._appApi.multiSend({multiType, multiAction})
        if(!res) return false
        return this.loginUserStatusWatch()
    }
    async loginUserStatusWatch(num){
        await this.statusWatch({callback: res => {
            let {webConnectionStatus} = res
            if(webConnectionStatus != this.ENUMS.CONNECTION_CLOSED) return false
            return true
        }})
    }


    async multiGame(){
        let multiType = this.PARAMS.game_multi_type
        let multiAction = this.PARAMS.request_multi_action
        let res = await this._appApi.multiSend({multiType, multiAction})
        await this.statusWatch({callback: res => {
            let {appUserStatus} = res
            if(appUserStatus == this.ENUMS.GAME_USER_BUSY || appUserStatus == this.ENUMS.GAME_USER_PLAYING) return false
            return true
        }})
    }
    async statusWatch(opt){
        opt = opt || {}
        let {num = 1, callback} = opt
        let timeout = 2000
        let resendTime = 3
        if(num > resendTime){
            if(callback) callback(false)
            return
        }
        let res = await this.wsUserInfoInit()
        res = res || {}
        let status = false
        if(callback) status = callback(res)
        if(status) return true
        opt.num = ++num
        await this._common.settimeout(timeout)
        return this.statusWatch(opt)
    }

    async userGameTasteReset(){
        let res = await this.gameApi.gamezoneUserInfo()
        if(!res) return
        res = res || {}
        let {tasteStatus = 0} = res
        if(tasteStatus == 0) return
        this.gameApi.gameTasteCancel()
    }
    async gamezoneUserInfo(opt){
        let res = await this.gameApi.gamezoneUserInfo(opt)
        return res
    }

    multiedCheck(userInfo){
        let enu = this.ENUMS
        let {appUserStatus, appConnectionStatus} = userInfo || {}
        appConnectionStatus = Number(appConnectionStatus)
        if(appUserStatus != enu.GAME_USER_BUSY && appUserStatus != enu.GAME_USER_PLAYING) return false
        if(!appConnectionStatus) return false
        return true
    }

    async gameTastCheck(){
        let type = await this.hasBusyFunction()
        if(!type) return false
        let enu = this.ENUMS
        let userInfo = await this.wsUserInfoInit()
        let {appUserStatus, appConnectionStatus} = userInfo || {}
        appConnectionStatus = Number(appConnectionStatus)
        let ownType = 1
        if(this._appData.getStoreItem('engine_device') != 'web' && type == 'engine') ownType = 0
        if(appUserStatus == enu.GAME_USER_BUSY || appUserStatus == enu.GAME_USER_PLAYING) ownType = 0
        let message = this._errorCode.game[`has busy function ${type} ${ownType}`]
        if(!message) message = this._errorCode.game[`has busy function common ${ownType}`]
        this._common.confirmPro.open({
            message,
            confirmType: 'c3',
            buttons: [
                {name: '确定', type: 'confirm'}
            ]
        })
        return type
    }

    async hasBusyFunction(){
        if(!this._appData.getItem('username', 'app')) return false
        let game = await this.inviteStatusCheck()
        if(game) return 'game'
        let taste = await this.userTasteStateCheck()
        if(taste) return 'taste'
        return false
    }
    async userTasteStateCheck(){
        let res = await this.gameApi.gamezoneUserInfo()
        if(!res) return false
        let {tasteStatus, webUserStatus, webConnectionStatus, appUserStatus, appConnectionStatus} = res
        tasteStatus = Number(tasteStatus)
        return tasteStatus ? res : false
    }
    async userProbs(opt) {
        opt = opt || {}
        let {username = this._appData.getItem('username', 'app')} = opt
        opt = {...opt, username}
        if(!username) return
        let options = {
            ...opt,
            method: "get",
        }
        let res = await this.requestAction('userProbs', options, null)
        if(res){
            try {
                res = JSON.parse(res)
            } catch (e) { console.log('parse err') }
        }
        return res
    }
    isGamingStatus(status){
        return status == this.ENUMS.GAME_USER_BUSY || status == this.ENUMS.GAME_USER_PLAYING
    }
    isRefuseInvite(info, ownInfo){
        let enums = this.ENUMS
        let {level: ownLevel = this._appData.getItem('userLevel', 'app')} = ownInfo || {}
        let {inviteAble, inviterFollowRestrict, inviterLevelRestrict, followType, level} = info
        if(!inviteAble && !inviterFollowRestrict && !inviterLevelRestrict) return true
        if(inviterFollowRestrict && !inviterLevelRestrict && followType != enums.SOCIAL_MUTUAL_FOLLOW) return true
        if(inviterLevelRestrict && !inviterFollowRestrict && level != ownLevel) return true
        if(inviterFollowRestrict && inviterLevelRestrict && (level != ownLevel || followType != enums.SOCIAL_MUTUAL_FOLLOW)) return true
        return false
    }
    inviteAvailble(info, ownInfo){
        if(!this.inviteAvai_status(info)) return false
        if(this.isRefuseInvite(info, ownInfo)) return false
        return true
    }
    inviteAvai_status(info){
        let enums = this.ENUMS
        info = info || {}
        let {appConnectionStatus, webConnectionStatus, appUserStatus, webUserStatus} = info
        if(!appConnectionStatus && !webConnectionStatus) return false
        if(
            ((appUserStatus == enums.GAME_USER_BUSY || appUserStatus == enums.GAME_USER_PLAYING) && appConnectionStatus) ||
            ((webUserStatus == enums.GAME_USER_BUSY || webUserStatus == enums.GAME_USER_PLAYING) && webConnectionStatus)
        ) return false
        return true
    }
    photoLocal(usercode){
        let list = this._appData.getRootItem('userPhotoFileList')
        try{
            list = JSON.parse(list)
        }catch(e){ list = {} }
        return list[usercode] || null
    }
}