<!--  -->
<template>
    <div class='global-com-cert-window' v-if="active">
        <div class="window-body">
            <div class="win-content flex-center">
                <div class="cert-box">
                    <div class="cert-light"></div>
                    <van-swipe v-if="defaultIndex !== null" ref="certSwipe" class="cert-swipe-box" :autoplay="0" indicator-color="white" :loop="false" @change="certChange" :initial-swipe="defaultIndex">
                        <van-swipe-item v-for="v in certListCul" :key="v.index" :class="{'selected': v.index == index}" @click="certSelect(v)">
                            <div class="cert-swipe-item">
                                <div class="item-bcg" v-if="v.index != index"></div>
                                <!-- <img :src="v.img" alt=""> -->
                                <cert :info="v" :ref="`cert-${v.index}`"></cert>
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                    <div class="date-box align-center-position">
                        <div class="date-text">
                            <span class="text-large flex-center bold m">{{levelMessage}}</span>
                            <span class="text-large flex-center bold m">{{timeMessage}}</span>
                        </div>
                    </div>
                    <div class="fl-item a"></div>
                    <div class="fl-item b"></div>
                    <div class="close-box" @click="close"></div>
                </div>
                
                
            </div>
            <div class="win-foot">
                <div class="foot-item scale-button" v-for="(v, i) in funListCul" :key="i" @click="shareFun(v)">
                    <div class="item-icon">
                        <img :src="`${PARAMS.assetsUrlV2_0}/${v.icon}`" alt="">
                    </div>
                    <div class="item-text">
                        <span class="text-mini-bb flex-center bold">{{v.title}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="preview-image flex-center" v-if="previewUrl" @click="previewUrl = null">
            <img :src="previewUrl" alt="">
        </div> -->
    </div>
</template>

<script>
    import cert from './cert.vue'
    import shareClass from '@/API/page/share'
    import html2canvas from "html2canvas"
    html2canvas.debug = null

    export default {
        name: '',
        props: {},
        data() {
            return {
                appData: new this.appDataClass({key: 'app'}),
                shareApi: new shareClass(),
                active: false,
                funList: [
                    {icon: 'certificate/c14.png', title: '分享', fun :'share', display: 'isAppClient',},
                    {icon: 'certificate/c14_0.png', type: 0, title: '下载证书', fun :'download',},
                ],
                certList: [
                    // {img: `${this.PARAMS.assetsUrlV2_0}/certificate/c20.png`},
                    // {img: `${this.PARAMS.assetsUrlV2_0}/certificate/c20.png`},
                    // {img: `${this.PARAMS.assetsUrlV2_0}/certificate/c20.png`},
                    // {img: `${this.PARAMS.assetsUrlV2_0}/certificate/c20.png`},
                ],
                // previewUrl: null,
                parseEles: [],
                index: null,
                certId: null,
                download_sucToast: null,
                download_errToast: null,
                createImageLoading: false,
                userInfo: this.$store.state.app,
            };
        },
        methods: {
            paramsInit(opt){
                this.download_sucToast = null,
                this.download_errToast = null,
                this.certList = []
                this.parseEles = []
                opt = opt || {}
                this._common.paramsInit.call(this, opt)
                this.certList = opt || []
            },
            open(opt){
                this.paramsInit(opt)
                return new Promise(success => {
                    this.active = true
                    this.response = success
                })
            },
            certChange(val){
                this.index = val
            },
            certSelect(val){
                let {index} = val || {}
                this.certId = val.id
                if(!this.certListCul[index]) return
                this.$refs['certSwipe'].swipeTo(index)
            },
            close(){
                this.response({type: 'close'})
                this.active = false
                this.$emit("gameOut")
            },
            shareFun(val){
                let {certIdCul} = this
                let {fun} = val
                if(!fun) return
                if(fun == 'share') return this._common.sharePoster.open(certIdCul)
                if(fun == 'download') return this.share_Image({...val,certIdCul})       
            },
            share_Image(val){
                if(!this.isAppClient && !this.isPCClient){
                    this._common.confirmPro.open({
                        message: this.TEXT.share['share client error'],
                        confirmType: 'c3',
                        buttons: [
                            {title: '确定', type: 'confirm'}
                        ]
                    })
                    return 
                }
                this.shareAppAction(val)
            },
            async shareAppAction(val){
                if(this.isPCClient) return this.share_PC(val)
                let url = await this.getdownloadImg(val)
                if(!url) return
                let client = this._common.getClient()
                if(this.isPCClient) client = 'PC'
                let fun = `share_${client}`
                if(!this[fun]) return
                this[fun]({...val,url})
            },
            share_Android(val){
                let {type, url} = val
                let params = JSON.stringify({
                    url,
                    type,
                })
                try{
                    window.gaotuJSBridge.shareImageToNative(params)
                }catch(e){ 
                    if(type == 0) return this.download_err()
                }
            },
            share_IOS(val){
                let {type, url} = val
                let params = JSON.stringify({
                    url,
                    type,
                })
                try{
                    window.webkit.messageHandlers.shareImageToNative.postMessage(params);
                }catch(e){ 
                    try{
                        params = JSON.parse(params)
                        let res = this._quitApp.shareImageToNativeIos(params)
                        if(res.isSuccess) console.log('share success')
                        else if(type == 0) this.download_err()
                    }catch(err){ 
                        if(type == 0) return this.download_err()
                    }
                }
            },
            async share_PC(val){
                let {level, date} = this.selectData || {}
                let url = await this.getdownloadImg(val, {fun: 'getEleDataUrl', upload: false})
                let image = new Image()
                let name = `${this.userInfo.nickname}_${level}级_${date}.jpg`
                image.setAttribute('crossOrigin', 'anonymous')
                image.src = url
                image.onload = () => {
                    let canvas = document.createElement('canvas')
                    canvas.width = image.width
                    canvas.height = image.height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(image, 0, 0, image.width, image.height)
                    canvas.toBlob((blob) => {
                        let url = URL.createObjectURL(blob)
                        this.downloadAction(url,name)
                        // 用完释放URL对象
                        URL.revokeObjectURL(url)
                    })
                }
            },
            downloadAction(href, name){
                let eleLink = document.createElement('a')
                eleLink.download = name
                eleLink.href = href
                eleLink.click()
                eleLink.remove()
            },
            async download_suc(){
                clearTimeout(this.download_sucToast)
                this._common.confirmPro.open({
                    message: '下载成功！已保存至手机相册',
                    confirmType: 'c3',
                })
                if(this.download_sucToast) return
                this.download_sucToast = setTimeout(() => {
                    this._common.confirmPro.close()
                },3000)
            },
            async download_err(){
                clearTimeout(this.download_errToast)
                this._common.confirmPro.open({
                    message: '下载失败，请再次尝试',
                    confirmType: 'c3',
                })
                if(this.download_errToast) return
                this.download_errToast = setTimeout(() => {
                    this._common.confirmPro.close()
                },3000)
            },
            async getdownloadImg(val, opt){
                let {fun = 'getEleBolb', upload = true} = opt || {}
                let {certIdCul} = this
                let {type } = val || {}
                if(type != 0) return
                let ele = document.querySelector(`.global-com-cert-single_${certIdCul}`)
                if(!ele) return false
                this.createImageLoading = true
                this.styleParse()
                // let boxEle = document.querySelector('.global-com-cert-window .window-body .win-content .cert-box')
                // if(boxEle) boxEle.style.backgroundColor = '#fff'
                await this._common.nextTick.call(this)
                let fileData = await this[fun](ele)
                // if(boxEle) boxEle.style.backgroundColor = 'none'
                this.createImageLoading = false
                this.styleRepair()
                if(upload) return this.uploadFile(fileData, type)
                return fileData
            },
            styleRepair(){
                let {parseEles: eles} = this
                if(!eles || !eles.length) return
                for(let i in eles){
                    let ele = eles[i]
                    if(ele.style) ele.style.transform = `translateY(0)`
                }
                this.parseEles = []
            },
            async getEleBolb(ele) {
                if(!ele) return
                let canvas = await html2canvas(ele, {
                    backgroundColor: null,
                    useCORS: true,
                })
                canvas.className = 'tem-ele'
                document.body.appendChild(canvas);
                await this._common.nextTick.call(this)
                let data = canvas.toDataURL("image/jpeg", 1);
                document.body.removeChild(canvas);
                return this.base64ToFile(data, 'download_image.jpg')
            },
            async getEleDataUrl(ele) {
                if(!ele) return
                let canvas = await html2canvas(ele, {
                    backgroundColor: null,
                    useCORS: true,
                })
                canvas.className = 'tem-ele'
                
                document.body.appendChild(canvas);
                await this._common.nextTick.call(this)
                let data = canvas.toDataURL("image/jpeg", 1);
                document.body.removeChild(canvas);
                return data
            },
            base64ToFile(dataurl, filename){
                let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type:mime});
            },
            styleParse(){
                let {certIdCul} = this
                let eles = document.querySelectorAll(`.global-com-cert-single_${certIdCul} span[class^="text-"]`)
                if(!eles || !eles.length) return
                for(let i in eles){
                    let ele = eles[i]
                    let className = ele.className
                    let styleData = getStyleData(className)
                    if(ele.style){
                        if(styleData) ele = setStyle(styleData, ele)
                        else ele.style.transform = `translateY(-25%)`
                    }
                }
                this.parseEles = eles
                function setStyle(items, ele){
                    if(!ele || !ele.style) return ele
                    items = items || {}
                    for(let i in items){
                        ele.style[i] = items[i]
                    }
                    return ele
                }
                function getStyleData(name){
                    name = name || ''
                    let list = {
                        'text-recover': {
                            transform: `translateY(400%)`
                        },
                    }
                    let data = null
                    for(let i in list){
                        if(name.indexOf(i) > -1){
                            data = list[i]
                            break
                        }
                    }
                    return data
                }
            },
            async uploadFile(file, type){
                let file_type = type == 0 ? 2 : 1
                // file.name = file.name || "share_image"
                let res = await this.shareApi.uploadImage({
                    file_type,
                    file: [{key: 'certificateFiles', data: file}]
                })
                if(!res) return false
                if(this._common.isArray(res)) res = res[0]
                return res
            },
        },
        created() {

        },
        components: {
            cert,
        },
        computed: {
            funListCul(){
                let {funList: list} = this
                let data = this._callFun.funhandAct.call(this, list)
                return data
            },
            isAppClient(){
                return this._common.clientAppCheck('gaotu')
            },
            isPCClient(){
                let callback = this.appData.getItem('quit_callback')
                return callback
            },
            defaultIndex(){
                let {certListCul: list} = this
                if(!list.length) return null
                let data = null
                for(let i in list){
                    let {level} = list[i]
                    if(!data || data.level > level) data = list[i]
                }
                if(!data) return 0
                return data.index
            },
            levelMessage(){
                let {selectData: {level, date}} = this
                let message = this.TEXT.certWindow['certificate level get']
                message = this._common.urlParse(message, {level})
                return message
            },
            timeMessage(){
                let {selectData: {date}} = this
                let message = this.TEXT.certWindow['certificate date get']
                message = this._common.urlParse(message, {date})
                return message
            },
            selectData(){
                let {index} = this
                return this.certListCul[index] || {}
            },
            certListCul(){
                let {certList: list} = this
                list = this._common.deepCopy(list)
                let rd = []
                for(let i in list){
                    list[i].index = i
                    let {status, townCertificate, certificateTime} = list[i] || {}
                    let {id, certificateName: name, certificateLevel: level} = townCertificate
                    let date = ""
                    if(status){
                        let {day, month, year} = certificateTime.date
                        date = `${year}年${month}月${day}日`

                    }
                    let info = {
                        index: i,
                        date,
                        id,
                        name,
                        level,
                    }
                    rd.push(info)
                }
                return rd
            },
            certIndex(){
                let {index,certId} = this
                if(!certId) return index
                return certId
            },
            certIdCul(){
                let {certIndex} = this
                let {certListCul:list} = this
                let rd = null
                for (const i in list) {
                    if(certIndex == i){
                        rd = list[i].id
                        break
                    }
                }
                return rd
            },
        },
        watch: {
            defaultIndex: {
                handler(val){
                    if(this.index !== null) return
                    this.index = val
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .global-com-cert-window{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.6);
        z-index: 400;
        
        .window-body{
            width: 100%;
            height: 100%;
            .win-foot{
                z-index: 5;
                width: 597px;
                height: 71px;
                position: absolute;
                bottom: 0px;
                left: 0px;
                right: 0px;
                margin: 0px auto;
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c12.png'));
                padding: 15px 185px 0px;
                display: flex;
                // justify-content: space-between;
                justify-content: center;
                align-items: flex-start;
                .foot-item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    justify-content: center;
                    .item-icon{
                        width: 38px;
                        height: 38px;
                        img{
                            width: 100%;
                        }
                    }
                    .item-text{
                        margin-top: 4px;
                        color: #605D7B;
                    }
                }
            }
            .win-content{
                width: 100%;
                height: calc(100% - 71px);
                .date-box{
                    width: 667px;
                    height: 83px;
                    bottom: -47px;
                    position: absolute;
                    padding-top: 11px;
                    display: flex;
                    justify-content: center;
                    z-index: 4;
                    .background-img-max(url('@{assetsUrlV2_0}/certificate/c15_0.png'));
                    .date-text{
                        width: 329px;
                        text-shadow: 1px 1px 1px #C46A00,1px -1px 1px #C46A00,-1px 1px 1px #C46A00,-1px -1px 1px #C46A00;
                        .m{
                            height: 25px;
                            line-height: 25px;
                        }
                    }
                }
                .cert-swipe-box{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 3;
                }
                .van-swipe{
                    overflow: visible;
                }
                .cert-box{
                    width: 333px;
                    height: 252px;
                    position: relative;
                    .cert-swipe-item{
                        width: 333px;
                        height: 252px;
                        position: relative;
                        transform: scale(0.7);
                        transition: all .5s;
                        img{
                            width: 100%;
                        }
                        .item-bcg{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.6);
                            z-index: 2;
                        }
                    }
                    .van-swipe-item{
                        width: 333px !important;
                        height: 252px;
                    }
                    .van-swipe-item.selected{
                        .cert-swipe-item{
                            transform: scale(1);
                        }
                        .item-bcg{
                            z-index: -1;
                        }
                    }
                    .fl-item{
                        width: 140px;
                        position: absolute;
                        top: -21px;
                        z-index: 2;
                    }
                    .fl-item.a{
                        height: 228px;
                        left: -88px;
                        .background-img-max(url('@{assetsUrlV2_0}/certificate/c17_left.png'));
                    }
                    .fl-item.b{
                        height: 220px;
                        right: -87px;
                        .background-img-max(url('@{assetsUrlV2_0}/certificate/c17_right.png'));
                    }
                    .cert-light{
                        width: 562px;
                        height: 296px;
                        position: absolute;
                        .background-img-max(url('@{assetsUrlV2_0}/certificate/c16.png'));
                        top: -12px;
                        left: -111px;
                        z-index: 1;
                    }
                }
            }
        }
        .close-box{
            z-index: 2;
            .background-img-max(url('@{assetsUrlV2_0}/task_center/d5.png'));
            position: absolute;
            top: -25px;
            right: -120px;
            width: 17px;
            height: 17px;
            cursor: pointer;
        }
    }
</style>