<!--  -->
<template>
    <div class='com-confirm'>
        <van-dialog 
        :message="message" 
        ref="dialog" 
        v-model="active" 
        :show-cancel-button="showCancelButton" 
        :show-confirm-button="showConfirmButton" 
        :confirm-button-text="confirmButtonText"
        :cancel-button-text="cancelButtonText"
        :close-on-click-overlay="closeOnClickOverlay"
        @confirm="confirm" 
        @cancel="cancel">
            <slot></slot>
            <div class="gotu-confirm" v-if="!$slots.default">
                <div class="pattern-a" v-if="patternA">
                    <div class="a-item head confirm-title-color text-center" v-if="title">
                        <span class="text-normal-b bold" v-if="!$slots.title">{{title}}</span>
                        <slot name="title"></slot>
                    </div>
                    <div class="a-item content" v-if="message">
                        <slot name="message"></slot>
                        <div class="text-normal-a confirm-content-color text-center" v-if="!$slots.message">{{message}}</div>
                    </div>
                    <div class="a-item foot" v-if="$slots.foot">
                        <slot name="foot"></slot>
                    </div>
                    <div class="a-item foot" v-if="buttons && buttons.length && !$slots.foot" :class="{'n1': buttons.length <= 1}">
                        <div class="foot-button" v-for="v in buttons" :key="v.label">
                            <dia-button :type="v.type" @click="handl(v)">
                                <span class="text-normal-a">{{v.title}}</span>
                            </dia-button>
                        </div>
                    </div>
                </div>
                <div class="pattern-b" v-if="patternB">
                    <div class="a-item head confirm-title-color text-center" v-if="title || $slots.title">
                        <span class="text-normal-b bold" v-if="!$slots.title">{{title}}</span>
                        <slot name="title"></slot>
                    </div>
                    <div class="a-item content" v-if="message || $slots.message">
                        <slot name="message"></slot>
                        <div class="text-normal-a confirm-content-color text-center" v-if="!$slots.message">{{message}}</div>
                    </div>
                    <div class="a-item foot" v-if="$slots.foot">
                        <slot name="foot"></slot>
                    </div>
                    <div class="a-item foot" v-if="buttons && buttons.length && !$slots.foot">
                        <div class="foot-button" v-for="v in buttons" :key="v.label">
                            <dia-button :type="v.type" @click="handl(v)">
                                <span class="text-normal-a">{{v.title}}</span>
                            </dia-button>
                        </div>
                    </div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                active: false,
                response: null,
                pattern: 'a',
                message: null,
                title: null,
                buttons: [
                    {title: '取消', type: 'cancel', label: 1},
                    {title: '确定', type: 'confirm', label: 0},
                ],
                'showConfirmButton': false,
                'showCancelButton': false,
                'confirmButtonText': '确认',
                'cancelButtonText': '取消',
                closeOnClickOverlay: false
            };
        },
        methods: {
            paramsInit(opt){
                this.response = null
                this.pattern = 'a'
                this.message = null
                this.title = null
                this.buttons = [
                    {title: '取消', type: 'cancel', label: 1},
                    {title: '确定', type: 'confirm', label: 0},
                ]
                this.showConfirmButton = false
                this.showCancelButton = false
                this.confirmButtonText = '确认'
                this.cancelButtonText = '取消'
                this.paramsSet(opt)
            },
            paramsSet(opt){
                this._common.paramsInit.call(this, opt)
            },
            open(opt){
                this.paramsInit(opt)
                return new Promise(success => {
                    this.response = success
                    this.active = true
                })
            },
            close(){
                this.$refs['dialog'].close()
            },
            confirm(){
                if(this.response) this.response(true)
            },
            cancel(){
                if(this.response) this.response(false)
            },
            handl(val){
                if(this.response) this.response(val)
                this.active = false
            },
        },
        created() {

        },
        components: {
        },
        computed: {
            patternA(){
                return this.pattern == 'a'
            },
            patternB(){
                return this.pattern == 'b'
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-confirm{
        .van-dialog{
            top: 50%;
            bottom: auto;
            width: auto;
        }
        .gotu-confirm{
            
            .pattern-a{
                width: 300px;
                // height: 180px;
                padding: 24px 20px 20px;
                
            }
            .content{
                // margin: 10px 0px 28px;
                margin-top: 10px;
            }
            .foot{
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
            }
            .foot.n1{
                justify-content: center;
            }
            .a-item.head{
                > span[class^="text"]{
                    line-height: 20px;
                }
            }
            .a-item.content{
                text-align: center;
                > div[class^="text"], > span[class^='text']{
                    line-height: 24px;
                    height: 24px;
                }
            }
            .pattern-b{
                padding: 27px 15px 22px;
                width: 303px;
                height: 335px;
                .foot{
                    margin-top: 60px;
                    display: block;
                    .com-dia-button{
                        width: 146px;
                        height: 40px;
                        line-height: 40px;
                        margin: 0px auto;
                    }
                    .foot-button+ .foot-button{
                        margin-top: 12px;
                    }
                    .com-dia-button.confirm{
                        border-radius: 8px;
                    }
                }
            }
        }
    }
</style>