import commonApi from '../_EXT_API'

export default class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'taskcenter'
        let name = 'taskcenter'
        opt = {localKey, name}
        super(opt)
    }
    async taskSign(opt){
        opt = opt || {}
        let {user_code,task_id} = opt 
        // schoolId = schoolId || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
            task_id,
        }
        let options = {
            method: "post",
            ...opt,
            data:{
                // schoolId
            }
        }
        return await this.requestAction('taskSign', options)
	}
    async taskList(opt){
        opt = opt || {}
        let {user_code} = opt 
        // schoolId = schoolId || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                // schoolId
            }
        }
        return await this.requestAction('taskList', options)
	}
    async stoneRecord(opt){
        opt = opt || {}
        let {user_code,page, size} = opt
        // school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                // school_id,
                page,
                size,
                type:0,
            }
        }
        return await this.requestAction('stoneRecord', options)
    }
    async middleTaskList(opt){
        opt = opt || {}
        let {user_code} = opt 
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                ...opt
            }
        }
        return await this.requestAction('middleTaskList', options)
	}
    async taskListV2(opt){
        opt = opt || {}
        let {user_code,is_new,page,size} = opt 
        if(page == null || !size || is_new == null) return false
        // school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
        }
        let options = {
            method: "get",
            user_code,
            data:{
                // school_id,
                is_new,
                page,
                size
            }
        }
        return await this.requestAction('taskListV2', {...options, noLoad: false})
	}
    async taskSignV2(opt){
        let {user_code} = opt || {}
        user_code = user_code || this._appData.getItem('usercode', 'app')
        // school_id = school_id || this.PARAMS.school_id
        if(!user_code) return false
        let options = {
            method: "post",
            user_code,
            data: {
                // school_id,
            }
        }
        return await this.requestAction('taskSignV2', options)
    }
    async signInList(opt){
        let {user_code} = opt || {}
        const {page} = opt || {}
        // school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!user_code || page == null) return false
        let options = {
            method: "get",
            user_code,
            data: {
                // school_id,
                page
            }
        }
        return await this.requestAction('signInList', options)
    }
    async getPalaceParent(opt){
        let {user_code} = opt || {}
        opt.user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!opt.user_code ) return false
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt,
            }
        }
        return await this.requestAction('getPalaceParent', options)
    }
    async getSubjectParent(opt){
        let {user_code} = opt || {}
        opt.user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!opt.user_code ) return false
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt,
            }
        }
        return await this.requestAction('getSubjectParent', options)
    }
    async haveNewTask(opt){
        let {user_code} = opt || {}
        // school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!user_code) return false
        let options = {
            method: "get",
            data: {
                // school_id,
                user_code
            }
        }
        return await this.requestAction('haveNewTask', options)
    }
    async getWeekList(opt){
        opt = opt || {}
        let {user_code} = opt || {}
        user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!user_code) return false
        let options = {
            method: "get",
            data: {
                user_code
            }
        }
        return await this.requestAction('getWeekList', {...options,...opt})
    }
}
