<template>
	<div class="columnLine">
		<div class="Inside" v-show="!isActive"> </div>
		<div class="Inside-active"  v-show="isActive"> </div>
	</div>
</template>

<script>
export default {
	name: 'columnLine',
	props: {
		isActive:{
			
		}
	},
	data() {
		return {
			
		};
	},
	methods: {

	},
	created() {

	},
	components: {
	},
	computed: {},
	watch: {},
	mounted() {},
	beforeCreate() {}, //生命周期 - 创建之前
	beforeMount() {}, //生命周期 - 挂载之前
	beforeUpdate() {}, //生命周期 - 更新之前
	updated() {}, //生命周期 - 更新之后
	beforeDestroy() {}, //生命周期 - 销毁之前
	destroyed() {}, //生命周期 - 销毁完成
	activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style  lang='less' >
	.columnLine{
		width: 111px;
		height: 14px;
		position: relative;
		.Inside{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 111px;
			height: 14px;
			background: #9EC8FF
		}
		.Inside-active{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 111px;
			height: 14px;
			background: #FFE973;
		}
	}
	
</style>
