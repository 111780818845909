/*
 * @Description: 
 * @Author: lele
 * @Date: 2023-03-17 16:12:58
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-02 14:15:52
 */

export default {
    'checkUser': {
        url: '/oauth/check_token',
        serve: 'auth',
        login: false
    },
    'refreshToken': {
        url: '/oauth/token',
        serve: 'auth',
        login: false
    },
    'userLevel': {
        url: '/infos/level/{username}',
        serve: 'engine',
        login: true
    },
    'userAccount': {
        url: '/town/school/user/account/{user_code}',
        serve: 'social',
    },

    'moduleState': {
        url: '/info/module/{endpoint}',
        login: false
    },
    //登录推送
    'multiSend': {
        url: '/wsuser/multi/{userCode}',
        serve: 'social'
    },

    // 
    'loadGaotuExercise': {
        url: '/town/import/question/list',
        serve: 'social'
    },
    'reportVideoError': {
        url: '/log/gaotu_video',
        serve: 'social'
    }   
}