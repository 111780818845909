<!--  -->
<template>
    <div class='com-item-user'>
        <div class="user-login-box" 
            @mousedown="test($event);$event.currentTarget.classList.add('button-scale-down')"
            @touchstart="$event.currentTarget.classList.add('button-scale-down')"
            @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
            @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
            @touchend="$event.currentTarget.classList.remove('button-scale-down')"
            @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
            @click="_routeHand.goto({
                name: 'user',
                type: 'push',
            })" v-if="userInfo.username">
            <div class="user-photo">
                <user-photo></user-photo>
            </div>
            <div class="user-info-box">
                <div class="user-name">
                    <nickname></nickname>
                </div>
                <div class="user-level flex-align-center">
                    <user-level :level.sync="level"></user-level>
                </div>
            </div>
           
        </div>
        <div class="user-balance-box ">
            <div class="balance-body flex-align-center" @click="taskOpen">
                <div class="balance-icon"></div>
                <div class="balance-number flex-align-center">
                    <balance></balance>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            photo: String,
            nickname: String,
            level: [Number, String]
        },
        data() {
            return {
                userInfo: {}
            };
        },
        methods: {
            async taskOpen(){
                this._common.stoneRecord()
            },
            userInfoChange(){
                this.userInfo = this.$store.state.app
            },
            test(e){
            }
        },
        created() {
            this.userInfoChange()
            this._userHand.on('user', this.userInfoChange)
        },
        components: {
        },
        computed: {
            photoCul(){
                let {photo} = this
                let {app} = this.userInfo
                app = app || {}
                if(!photo) photo = app.photo
                if(!photo) photo = 'gaotu_tutu.png'
                return `${this.PARAMS.assetsUrl}/user_photo/${photo}`
            },
            nicknameCul(){
                let {app} = this.userInfo
                app = app || {}
                return this.nickname || app.nickname || app.username
            },
        },
        watch: {
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-item-user {
        display: flex;
        height: @photo-size-home;

        .user-login-box {
            height: 100%;
            display: flex;

            // align-items: center;
            .user-photo {
                width: @photo-size-home;
                height: 100%;
                padding: 3px;
                z-index: 1;
                .background-img-max(url('@{assetsUrlV2_0}/global/photo-border.png'));

                .user-photo-img {
                    // width: 20px;
                    // border-radius: 100%;
                    float: left;
                }
            }
            .user-name, .user-level{
                margin-left: -18px;
                padding-left: 23px;
                width: 129px;
                height: 22px;
                .background-img-max(url('@{assetsUrlV2_0}/global/name-bcg.png'));
            }
            .user-level{
                width: 60px;
                margin-top: -3px;
                height: 19px;
            }
        }

        .user-balance-box {
            height: 100%;
            position: relative;
            margin-left: 17px;

            .balance-icon {
                position: absolute;
                width: 30px;
                height: 30px;
                left: -6px;
                top: -3px;
                .background-img-max(url('@{assetsUrlV2_0}/global/balance-icon.png'));
            }

            .balance-number {
                width: 86px;
                height: 22px;
                padding-left: 27px;
                .background-img-max(url('@{assetsUrlV2_0}/global/balance-bcg.png'));
            }
        }

        .user-logout-box {}
    }
</style>