<!--  -->
<template>
    <div class='com-dia-button flex-center cursor-pointer' :class="[type, shape, {'is-disabled no-selected': disabled, 'scale-button': !disabled}]" @click="click">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            type: {
                type: String,
                default: 'cancel'
            },
            shape: {
                type: String,
                default: 'normal'
            },
            disabled: Boolean
        },
        data() {
            return {

            };
        },
        methods: {
            async click(e){
                await this._common.settimeout(50)
                this.$emit('click', e)
            },
        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-dia-button{
        text-align: center;
        margin: 0px auto;
        line-height: 100%;
        flex-wrap: wrap;
    }
    .com-dia-button.normal{
        width: 122px;
        height: 48px;
        border-radius: 48px;
    }
    .com-dia-button.square{
        width: 156px;
        height: 40px;
        border-radius: 8px;
    }
    .com-dia-button.cancel{
        border: 1px solid #D2D2D2;
        color: @confirm-title-color;
        background: #fff;
    }
	.com-dia-button.cancel-noborder{
		color: @confirm-title-color;
		background: #fff;
	}
    .com-dia-button.cancel.is-disabled{
        color: #D2D2D2;
        cursor: not-allowed;
    }
    .com-dia-button.confirm{
        background: @confirm-button-bcg;
        color: @default-color;
    }
    .com-dia-button.default{
        background: none;
        color: @default-color;
        border: 1px solid #fff;
    }
    .com-dia-button.default-bcg{
        background: none;
        color: @light-dark-color;
        background: #fff;
        border: 1px solid #BEBEBE;
    }
    .com-dia-button.is-disabled{
        color: #D2D2D2;
        cursor: not-allowed;
        border: 1px solid #D2D2D2;
        background: #fff;
        > span[class^='text']{
            color: #D2D2D2;
        }
    }
</style>