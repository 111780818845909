<!-- -->
<template>
    <div class='page-taskcenter-summary flex-center mind' v-if="show">
        <div class="bcg">
            <div class="mark"></div>
            <div class="c1">
                <div class="off-btn" @click="close()"></div>
                <div class="taskcenter-title">
                    <span class="text-large-b bold text-brown-color">{{"任务中心"}}</span>
                </div>
                <div class="head">
                    <div class="item " :class="v.signValue" v-for="(v,i) in week" :key="i">
                        <div class="c3"></div>
                        <span class="text-mini bold min">{{v.day}}</span>
                    </div>
                </div>
                <span class="sign-tip text-mini bold min text-pur-color">{{"连签三天额外+10思维币&emsp;连签七天额外+20思维币"}}</span>
                <div class="sign c4" @click="signIn(signInCul)">
                    <span v-if="signInCul" class="text-normal bold text-red-color">{{"已签到"}}</span>
                    <span v-else class="text-normal bold text-red-color">{{"签到"}}</span>
                </div>
                <div class="content">
                    <div class="item c5" v-for="(v,i) in taskCon" :key="i" :class="{gray:v.disabled}">
                        <div class="l" :class="v.img"></div>
                        <div class="c6">
                            <div class="con-text">
                                <span class="text-normal bold o">{{v.taskName}}</span>
                                <span class="text-mini bold min text-pur-color t">{{v.taskDetail}}</span>
                            </div>
                            <div class="btn" v-show="v.taskStatus==0" @click="goPlay(v)">
                                <confirmPlay :disabled="v.disabled">
                                    <span class="txt bold">{{"去挑战"}}</span>
                                </confirmPlay>
                            </div>
                            <div class="btn" v-show="v.taskStatus==1" @click="goPlay(v)">
                                <confirmPlay type="primary" :disabled="v.disabled">
                                    <span class="txt bold">{{"继续挑战"}}</span>
                                </confirmPlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import com from '@/components/taskCenter.vue'

    export default {
        name: '',
        extends: com,
        props: {},
        data() {
            return {
                taskCon:[
                    {img:'c7',taskName:"",taskDetail: "",taskStatus:"0",fun:""},
                    {img:'c9',taskName:"",taskDetail: "",taskStatus:"0",fun:""},
                    {img:'c10',taskName:"",taskDetail: "",taskStatus:"0",fun:""},
                    {img:'c11',taskName:"",taskDetail: "",taskStatus:"0",fun:""},
                ],
                routerNameObj: {
                    'GAME_WIN': 'game-list',
                    'COURSE_EXERCISE': 'task',
                    'PUZZLE_EXERCISE': 'subject',
                    'VIDEO_LESSON': 'cabin',
                    'PALACE': 'palace',
                }
            };
        },
        methods: {
            goPlay(v){
                let {fun,disabled} = v
                if(disabled) return
                if(!fun) return
                this.close()
                let query = {}
                let name = this.$route.name
                query[this.queryName] = name
                this.appData.setStoreItem({'taskCenterOpenRoute': this.$route.fullPath})
                this._routeHand.goto({
                    name: fun,
                    type: 'push',
                })
            },
            async taskList(){
                let {usercode: user_code} = this
                let opt = { user_code }
                let res = await this.api.taskList(opt)
                if(res){
                    let {signInList:list,taskList,dailySignInFlag} = res
                    this.signInStatus = dailySignInFlag
                    let {week,taskCon} = this
                    for (const i in list) {
                        if (Object.hasOwnProperty.call(list, i)) {
                            if(list[i]==1) week[i].signValue="c2_0"
                            if(list[i]==0) week[i].signValue="c2_1"
                            if(list[i]==-1) week[i].signValue="c2_2"
                        }
                    }
                    for (const i in taskList) {
                        if(!taskCon[i]) continue
                        if (Object.hasOwnProperty.call(taskList, i)) {
                            let {townTask,taskStatus} = taskList[i] || {}
                            taskCon[i].taskName = townTask.taskName || ''
                            taskCon[i].taskDetail = townTask.taskDetail || ''
                            taskCon[i].taskStatus = taskStatus
                            taskCon[i].fun = this.getRouteName(townTask.taskCode)
                        }
                    }
                }
            },
            getRouteName(taskCode){
                taskCode = taskCode || ''
                let {routerNameObj} = this
                return routerNameObj[taskCode] || ''
            }
        },
        created() {
        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
    .page-taskcenter-summary.mind{
        .bcg{
            .c1{
                .content{
                    .c5{
                        .c6{
                            .con-text{
                                .t{
                                    width: 185px !important;
                                    margin-left: 8px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>