import Vue from "vue";
import VueRouter from "vue-router";
import {common} from '@/global/js/exportEnter'
const Home = () => import(/* webpackChunkName: "index" */ "../views/index/index.vue");
const token = () => import(/* webpackChunkName: "token" */ "../views/token/index.vue");

//挑战AI
import game from './game'
//闯关
import subject from './subject'
//我的作业
import task from './task'
//错题巩固
import mistakes from './mistakes'
//学习档案
import personal from './personal'
//登录 -- 测试
import login from './login'
//对弈历史
import history from './history'
//个人中心
import user from './user'
//换装秀
import avatar from './avatar'
//我的奖状
import certificate from './certificate'
//复盘
import gameReview from './gameReview'
//知识小屋
import cabin from './cabin'
//习题预览
import preview from './preview'


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
const replaceFun = VueRouter.prototype.replace
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location) {
	return replaceFun.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
	
	{
		path: "/",
		name: "Home",
		component: Home,
		backFun: 'quit_app'
	},
	{
		path: '/token/:access_token/:refresh_token',
		name: 'token-login',
		component: token,
		backFun: 'quit_app'
	},
    
	game,
	subject,
	task,
	mistakes,
	personal,
	login,
	history,
	user,
	avatar,
	certificate,
	gameReview,
	cabin,
	preview,
	{
		path: '*',
		redirect: '/',
		component: Home,
	},
];

const router = new VueRouter({
	mode: "history",
	// base: process.env.BASE_URL,
	base: '/',
	routes,
});
let routeList = {}
function routeListSave(list, par){
	if(par) par = par + '/'
	else par = ''
	list = list || routes
	for(let i in list){
		let data = list[i]
		if(!data || !data.path) continue
		let {path, name, children} = data
		path = par + handPath(path)
		let tem_data = common.deepCopy(list[i])
		delete tem_data['children']
		delete tem_data['path']
		routeList[name] = {path, ...tem_data}
		if(!children || !children.length) continue
		routeListSave(children, path)
	}
}
function handPath(path, par){
	if(!path) return path
	// path = path.replace(new RegExp(":","gm"), ":{")
	path = path.split('/')
	for(let i in path){
		let tem = path[i]
		if(tem.indexOf(':') <= -1) continue
		tem = tem.replace(new RegExp(":","gm"), "{")
		tem = tem + '}'
		path[i] = tem
	}
	return path.join('/')
}
routeListSave()
Vue.prototype._routeList = routeList
export default router;
