<!--  -->
<template>
    <div class='com-user-photo text-center' :class="[userInfo.avatarName, {'Home': isHomePage}]">
        <div class="photo-body-bx">
            <div class="user-photo-box">
                <img :src="photoCul" class="user-photo-img">
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            photo: String,
            bcgType: String
        },
        data() {
            return {
                background: {
                    'default': '#84C5FF',
                    'boy': '#F9E680',
                    'girl': '#FFBCD5'
                },
                routeInfo: this.$route,
                userInfo: {},
                userAppData: this.$store.state.app
            };
        },
        methods: {
            userInfoChange(){
                this.userInfo = this.$store.state.app
            }
        },
        created() {
            this.userInfoChange()
            this._userHand.on('userPhoto', this.userInfoChange)
        },
        components: {
        },
        computed: {
            isHomePage(){
                let {name} = this.routeInfo
                return name == 'Home'
            },
            photoCul(){
                let {photo} = this
                let app = this.userInfo
                app = app || {}
                if(!photo) photo = app.avatarHeadUrl
                if(!photo) photo = 'gaotu_tutu.png'
                if(photo.indexOf('http') <= -1) photo = `${this.PARAMS.assetsUrl_root}/user_photo/${photo}`
                return photo
            },
            photoBoxStyle(){
                let {photoCul} = this
                return {
                    'background': `url(${photoCul}) no-repeat`,
                    'background-size': '100% 100%'
                }
            },
            avatarName(){
                let {avatarName} = this.userInfo
                return avatarName
            },
            backgroundStyle(){
                let avatarName = this.avatarName
                if(!this.background[avatarName]) return {}
                return {
                    background: this.background[avatarName]
                }
            },
        },
        watch: {
            $route: {
                handler(val){
                    this.routeInfo = val
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-user-photo{
        // background: #fff;
        // border-radius: 100%;
        overflow: hidden;
        // width: @photo-size;
        // height: @photo-size;
        width: 100%;
        height: 100%;
        margin: 0px auto;
        .user-photo-img{
            width: 100%;
            height: 100%;
        }
        .user-photo-box{
            width: 100%;
            height: 100%;
        }
    }
    .photo-body-bx{
        background: #57B8D8;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    // .com-user-photo.default{
    //     background: #84C5FF;
    // }
    // .com-user-photo.boy{
    //     background: #F9E680;
    // }
    // .com-user-photo.girl{
    //     background: #FFBCD5;
    // }
    .com-user-photo.Home{
        // width: @photo-size-home;
        // height: @photo-size-home;
        // padding: 3px;
        // .background-img-max(url('@{assetsUrlV2_0}/global/photo-border.png'));
        
    }
</style>