<!--  -->
<template>
    <div class='com-nickname text-center'>
        <span class="text-small hide-point-txt">{{nicknameCul}}</span>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            nickname: [String, Number]
        },
        data() {
            return {
                maxNum: 5
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            nicknameCul(){
                if(this.nickname !== undefined) return this.nickname
                let {app} = this.$store.state
                app = app || {}
                return this.nickname || app.nickname || app.username
            },
            nicknameText(){
                let {nicknameCul} = this
                nicknameCul = nicknameCul || ''
                nicknameCul = nicknameCul.split('')
                nicknameCul.splice(5, nicknameCul.length - 5)
                return nicknameCul
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-nickname{
        // color: #fff;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        span[class^='text']{
            // display: flex;
            // height: auto;
            // line-height: normal;
            // align-items: center;
        }
    }
</style>