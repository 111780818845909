<!--  -->
<template>
    <div id="app" class="app" :style="{...appStyle, ...clearPadding}" @click="appClick">
        <!-- <div class="test-fun cursor-pointer scale-button" @click="testBoard">{{testData}}</div> -->
        <!-- <audio id="globalAudioPlay" ref="globalAudioPlay" muted></audio>
        <audio id="globalAudioReadSecond" ref="globalAudioReadSecond"></audio>
        <audio id="globalAudioReadStatus" ref="globalAudioReadStatus"></audio>
        <audio id="exercise_audio" ref="exercise_audio"></audio> -->
        <div class="global-connect-box">
            <connect ref="connect" :username.sync="username" :usercode.sync="usercode" @loaded="stompLoaded"></connect>
        </div>
        <appBcg></appBcg>
        <div id="nav" class="app-item head">
            <app-head ref="head" v-if="appLoaded"></app-head>
        </div>
        <div class="app-item content flex-center">
            <router-view
            v-if="appLoaded && mountedState"
            ref="pageNow"
            :appStyle.sync="appStyle"
            :appSizeData.sync="appSizeData"
            :htmlFontSize.sync="htmlFontSize"
            @tokenLoaded="tokenLoaded"
             />
        </div>
        <loading ref="loading" class="app-loading"></loading>
        <loading ref="clientLoading" class="client-loading"></loading>
        <confirm ref="confirm"></confirm>
        <confirm-pro ref="confirmPro"></confirm-pro>
        <setting ref="setting"></setting>
        <taskCenter ref="taskCenter" :appLoaded.sync="appLoaded"></taskCenter>
        <stoneRecord ref="stoneRecord"></stoneRecord>
        <sharePoster ref="sharePoster"></sharePoster>
        <div class="board-canvas-box hidden" ref="boardBox">
            <!-- <canvas ref="board-canvas-review" class="board-canvas-bcg review"></canvas>
            <canvas ref="board-canvas-grid" class="board-canvas-bcg grid"></canvas>
            <canvas ref="board-canvas-bcg" class="board-canvas-bcg"></canvas>
            <canvas ref="board-canvas-gam" class="board-canvas-bcg gam"></canvas> -->
        </div>
        <tasteWindow ref="tasteWindow"></tasteWindow>
    </div>
</template>

<script>
    import appHead from './components/head.vue'
    import api from '@/API/page/app'
    import loading from './components/loading.vue'
    // import appCheck from './components/check.vue'
    import appBcg from './components/appBcg.vue'
    import setting from './components/setting.vue'
    import taskCenter from '@/components/taskCenter.vue'
    import stoneRecord from '@/components/stoneRecord.vue'
    import sharePoster from '@/components/sharePoster.vue'
    import connect from '@/components/connect/connect.vue'
    import tasteWindow from './views/game/page/playerGame/components/tasteWindow.vue'
    import {handler as MathJaxCssHandler} from '@/global/css/MathJaxCssLoad'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new api(),
                appData: new this.appDataClass({key: 'app'}),
                appLoaded: false,
                appStyle: {},
                htmlFontSize: 0,
                access_token: this.$route.params.access_token,
                refresh_token: this.$route.params.refresh_token,
                debugData: {},
                pathNow: this.$route.path,
                appSizeData: {},
                userInfo: {},
                query: {},
                mountedState: false,
                testData: null,
                boardCanvasList: [
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg review', id: 'board-canvas-review', contextKey: 'gamContextReview'},
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg grid', id: 'board-canvas-grid', contextKey: 'contextGrid'},
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg', id: 'board-canvas-bcg'},
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg gam', id: 'board-canvas-gam', contextKey: 'gamContext'},
                ],
                wgoCanvasList: [],
                canvasList: [],
                canvasUseList: {},
                canvasNum: 15
            };
        },
        methods: {
            async init(){
                let res
                if(this.$refs['appCheck']) res = await this.$refs['appCheck'].action()
                if(res){
                    let name = this.$route.name
                    if(name.indexOf('Home') > -1) name = 'Home'
                    await this.goto(name)
                }
                this.appLoaded = res
            },
            async gaotuLoad(){
                if(!this.$refs['pageNow'] || !this.$refs['pageNow'].action) return
                let res = await this.$refs['pageNow'].action()
                let pathName = 'Home'
                if(!res){
                    await this._common.settimeout(1000)
                    pathName = 'login'
                }else{
                    this.goto(pathName)
                }
            },
            async checkRoute(){
                if(this._common.guestRouteCheck.call(this)) return false
                if(!this.access_token || !this.refresh_token) return true
                return false
            },
            async appInit(){
                // await this.levelListInit()
                this.appLoaded = false
                let res = await this.checkRoute()
                if(!res){
                    this.appLoaded = true
                    return
                } 
                await this.userInit()
                this.userLevelInit()
                if(!this.appData.getItem('username')){
                    this.goto('login')
                }
                this.appLoaded = true
            },
            async userInit(){
                let username = this.appData.getItem('username')
                if(!username) this.userLogoutSet()
                else{
                    await this.userTokenCheck()
                }
            },
            userLogoutSet(){
                this._userHand.userLogoutSet()
            },
            async userTokenCheck(){
                let res = await this.api.checkUser({noError: true})
                if(!res){
                    this.userLogoutSet()
                    return
                }
                await this.userTokenRefresh()
            },
            async userTokenRefresh(){
                let res = await this.api.userTokenRefresh()
                if(!res) this.userLogoutSet()
                return res
            },
            async goto(name){
                this._routeHand.goto({name, type: 'replace'})
                return this._common.nextTick.call(this)
            },
            paramsSet(){
                let loading = this.$refs['loading']
                this.PARAMS.loading = loading
                this._common.clientLoading = this.$refs.clientLoading


                // this.AudioPlay = this.$refs["globalAudioPlay"];
                // this.AudioReadSecond = this.$refs["globalAudioReadSecond"];
                // this.AudiReadStatus = this.$refs["globalAudioReadStatus"];
            },
            async globalComponentsInitCommon(){
                await this._common.nextTick.call(this)
                let ele = this.$refs['confirm']
                let elePro = this.$refs['confirmPro']
                let eleSet = this.$refs['setting']
                if(ele) this._common.confirm = ele
                if(elePro) this._common.confirmPro = elePro
                if(eleSet) this._common.userSetting = this.userSetting
                if(this.$refs.tasteWindow) this._common.tasteWindow = this.$refs.tasteWindow
            },
            async globalComponentsInitLogin(){
                await this._common.nextTick.call(this)
                let ele = this.$refs['confirm']
                let elePro = this.$refs['confirmPro']
                let eleSet = this.$refs['setting']
                let eleTask = this.$refs['taskCenter']
                let eleStone = this.$refs['stoneRecord']
                let sharePoster = this.$refs['sharePoster']
                let activateMember = this.$refs['activateMember']
                if(ele) this._common.confirm = ele
                if(elePro) this._common.confirmPro = elePro
                if(eleSet) this._common.userSetting = this.userSetting
                if(eleTask) this._common.taskCenter = this.taskCenter
                if(eleStone) this._common.stoneRecord = this.stoneRecord
                if(sharePoster) this._common.sharePoster = sharePoster
                if(activateMember) this._common.activateMember = this.activateMember
                
                this._eventBus.$emit('globalComponentsMounted')
                
            },
            windowSizeGet(){
                let width, height
                let html = document.getElementById('golaxy-gaotu-app-html')
                if(html && html.offsetWidth > 0 && html.offsetHeight > 0){
                    width = html.offsetWidth, height = html.offsetHeight
                }else{
                    width =  window.innerWidth, height = window.innerHeight
                }
                return {width, height}

            },
            setViewSize(){
                let {width, height} = this.windowSizeGet()
                let bodyClientWidth = document.body.clientWidth
                let bodyClientHeight = document.body.clientHeight
                if(bodyClientWidth != width) bodyClientWidth= width
                if(bodyClientHeight != height) bodyClientHeight = height
                let body = document.querySelector('body')
                let html = document.querySelector('html')
                if(body){
                    body.style.width = `${bodyClientWidth}px`
                    body.style.height = `${bodyClientHeight}px`
                }
                if(html){
                    html.style.width = `${bodyClientWidth}px`
                    html.style.height = `${bodyClientHeight}px`
                }
            },
            htmlFontSizeSet(){

                let width = 667, height = 375
                let fontSize = width / 100
                let px = width / height
                let html = document.getElementById('golaxy-gaotu-app-html')
                let {width: win_width, height: win_height} = this.windowSizeGet()
                let data = this._common.getCurrentSize(win_width, win_height, 'width')
                if(data.height > win_height){
                    data = this._common.getCurrentSize(win_width, win_height, 'height')
                }
                
                // if(data.width < this.PARAMS.appMinWidth || data.height < this.PARAMS.appMinHeight){
                //     data.width = this.PARAMS.appMinWidth
                //     data.height = this.PARAMS.appMinHeight
                // }
                
                
                
                fontSize = data.width / 100
                this._common.appSize = data
                fontSize = fontSize * 10
                this.appStyle = {
                    width: `${data.width}px`,
                    sizeData: {
                        ...data,
                        fontSize
                    }
                    // height: `${data.height}px`,
                }
                if(!html){
                    return
                }
                this._common.appSize = {
                    ...data,
                    fontSize
                }
                html.style.fontSize = `${fontSize}px`
                this.htmlFontSize = fontSize
                this.appSizeData = {
                    // width: data.width,
                    // height: data.height,
                    ...data,
                    fontSize
                }
                this.appData.setStoreItem({appStyle: this.appStyle})
                // this.debugDataSet()
            },
            debugDataSet(){
                if(!this.debugOn()) return
                let debugData = {}
                let computedFontSize = window.getComputedStyle(document.documentElement).fontSize
                let htmlFontSize = document.querySelector('html').style.fontSize
                if(htmlFontSize != computedFontSize){
                    let html_size = htmlFontSize.replace('px', '')
                    let com_size = computedFontSize.replace('px', '')
                    debugData.parseHtmlFontSize = html_size * html_size / com_size
                    let html = document.getElementById('golaxy-gaotu-app-html')
                    if(html){
                        html.style.fontSize = `${debugData.parseHtmlFontSize}px`
                        this.htmlFontSize = debugData.parseHtmlFontSize
                    }
                }
                this.debugData = debugData
            },
            randomNum(num, count){
                num = num || 8, count = count || 5
                let data = []
                for(let i = 1; i <= count; i++){
                    let rd = []
                    for(let j = 1; j <= num; j++){
                        rd.push(Math.floor(Math.random()*10))
                    }
                    data.push(rd.join(''))
                }
            },
            debugOn(){
                let {app: {usercode} = {}} = this.$store.state
                return usercode == '66100628' || usercode == '69837100'
            },
            quit_app(data){
                this.debug()
                if(!this._common.clientAppCheck('gaotu') && !this.clientWebCheck('gaotu')) return 
                let callback = this.appData.getItem('quit_callback')
                this.quitAppAction(callback, data)
            },
            clientWebCheck(){
                let callback = this.appData.getItem('quit_callback')
                return callback
            },
            async quitAppAction(callback, params){
                let {login} = params || {}
                if(!login){
                    let res = await this._common.confirmPro.open({
                        confirmType: 'c3',
                        message: '确定要退出吗？',
                        buttons: [
                            {title: '确定', type: 'confirm', label: 0},
                            {title: '取消', type: 'cancel', label: 1},
                        ]
                    })
                    let {label} = res || {}
                    if(label != 0) return
                }
                
                let client = this._common.getClient()
                if(callback) client = 'PC'
                let fun = `appQuit_${client}`
                
                if(!this[fun]) return
                this[fun]()
                
            },
            debug(){
                if(this._common.getClient() != 'IOS') return
                let data = {}
                try{
                    data = {
                        // Bridge: JSON.stringify(Bridge),
                        // windowBridge: JSON.stringify(window.Bridge),
                        // windowBridge_or: window.Bridge,
                        webkit: JSON.stringify(window.webkit), 
                        webkit_or: window.webkit,
                        userAgent: navigator.userAgent
                    }
                }catch(e){ data = {} }
                this.debugData = data
            },
            appQuit_PC(){
                let callback = this.appData.getItem('quit_callback')
                if(callback){
                    this.appData.removeItem('quit_callback')
                    return window.location.href = callback
                }
            },
            appQuit_Android(){
                try{
                    window.gaotuJSBridge.closeWebView('')
                }catch(e){ console.log('quit err')}
            },
            appQuit_IOS(){
                try{
                    // window.Bridge.callhandler('closeWebView', '');
                    // Bridge.callhandler('closeWebView', '');
                    // window.webkit.messageHandlers.closeWebView.postMessage(''); // last
                    this._quitApp.quitIos()
                }catch(e){ console.log('quit err')}
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('quit_app', e => {
                    this.quit_app(e)
                })
            },
            eventOff(){
                this._common.eventRemove('quit_app')
            },
            voiceInit(){
            },
            userSetting(){
                this.$refs['setting'].open()
            },
            taskCenter(){
                this.$refs['taskCenter'].open()
            },
            stoneRecord(){
                this.$refs['stoneRecord'].open()
            },
            activateMember(){
                this.$refs['activateMember'].open()
            },
            appClick(e){
                this._common.appClick(e)
            },
            voiceHand(val, oldVal){
                let {name} = val || {}, {name: oldName} = oldVal || {}
                if(name == oldName) return
                this._bcgMusic.musicOpen(this.$route.name)
            },
            userInfoChange(){
                this.userInfo = this.$store.state.app
            },
            queryFunHandl(){
                let {query} = this
                let funList = {
                    // 'taskCenter': 'taskCenterOpenByQuery'
                }
                for(let i in query){
                    let fun = funList[i]
                    if(!this[fun]) continue
                    this[fun](query[i])
                }
            },
            taskCenterOpenByQuery(val){
                if(!this.$refs['taskCenter']) return
                let username = this.appData.getItem('username')
                if(!username) return
                let {name} = this.$route
                if(val != name) return
                this.taskCenter()
                this._routeHand.clearQuery('taskCenter')
            },
            async tokenLoaded(){
                this.appLoaded = true
                await this._common.nextTick.call(this)
                await this._common.settimeout(1000)
                this.gaotuLoad()
            },
            stompLoaded(){},
            async invideTokenHand(){
                this._userHand.userLogoutSet()
                if(this._routeHand) this._routeHand.goto({name: 'login', type: 'replace'})
                await this._common.nextTick.call(this)
                if(this._common.confirm){
                    let message = this.ERRORCODE.main['invalid token']
                    this._common.confirm.open({
                        message,
                        buttons: [
                            {title: '确定', type: 'cancel'}
                        ]
                    })
                }
            },
            async testBoard(){
                await this._common.nextTick.call(this)
                let eles = document.querySelectorAll('.wgo-canvas-item')
                this.testData = 0
                if(!eles || !eles.length) return
                this.testData = `canvas: ${eles.length}`
                // window.alert(eles.length)
            },
            setDebugData(data){
                if(this._common.isArray(data)) data = data.join(',')
                if(this._common.isObject(data)) data = JSON.stringify(data)
                this.testData = data
            },
            boardCanvasInit(){
                let {boardCanvasList: list} = this
                let box = this.$refs.boardBox
                for(let i in list){
                    let {id, className} = list[i]
                    let ele = document.createElement('canvas')
                    ele.id = id
                    ele.className = className
                    let context = ele.getContext('2d')
                    list[i].context = context
                    list[i].element = ele
                }
                this.boardCanvasList = list
            },
            getBoardCanvasList(){
                let ele = this.boardCanvasList[0]
                if(!ele.element) this.boardCanvasInit()
                return this.boardCanvasList
            },
            setWgoCanvas(data, index){
                this.wgoCanvasList[index] = data
            },
            getWgoCanvas(index){
                if(index === undefined) return this.wgoCanvasList
                return this.wgoCanvasList[index]
            },
            clearWgoCanvas(){
                this.wgoCanvasList = []
            },

            canvasInit(){
                let {canvasNum: num} = this
                let list = []
                for(let i = 0; i < num; i++){
                    let element = document.createElement('canvas')
                    let context = element.getContext('2d')
                    list.push({
                        element,
                        context
                    })
                }
                this.canvasList = list
            },
            getCanvasData(key){
                let data = this.canvasUseList[key]
                if(!data) data = this.canvasList.pop()
                this.canvasUseList[key] = data
                return data
            },
            userLevelInit(){
                this.api.userLevel()
            },
            async setUserStatus(opt){
                let {type, status_change = true, source, statusDetail} = opt
                statusDetail = statusDetail === undefined ? '' : statusDetail
                let list = {
                    'engine': {
                        user_status: this.ENUMS.GAME_USER_BUSY,
                        user_status_detail: statusDetail,
                    },
                    'gameTaste': {
                        user_status: this.ENUMS.GAME_USER_BUSY,
                        user_status_detail: statusDetail,
                    },
                    'game': {
                        user_status: this.ENUMS.GAME_USER_PLAYING,
                        user_status_detail: statusDetail,
                    },
                    'gameConfig': {
                        user_status: this.ENUMS.GAME_USER_BUSY,
                        user_status_detail: statusDetail,
                    },
                    'idle': {
                        user_status: this.ENUMS.GAME_USER_IDLE,
                        user_status_detail: statusDetail,
                    }
                }
                let data = list[type]
                if(!data) return
                let {user_status, user_status_detail} = data
                if(!this.username) return
                let res = await this.api.setUserStatus(data)
                if(!res) return
                this.appData.setItem({userStatus: user_status, userStatusDetail: user_status_detail})
            },
            async levelListInit(){
                // let res = await this.api.levelList()
                // return res
            },
        },
        created() {
            this.canvasInit()
            this._common.getCanvasData = this.getCanvasData


            this._common.testBoard = this.testBoard
            this._common.setDebugData = this.setDebugData
            this._common.getBoardCanvasList = this.getBoardCanvasList
            this._common.setWgoCanvas = this.setWgoCanvas
            this._common.getWgoCanvas = this.getWgoCanvas
            this._common.clearWgoCanvas = this.clearWgoCanvas
            this._userStatus.setUserStatus = this.setUserStatus



            // window.location.href = 'http://localhost:8080/main'
            this.userInfoChange()
            this._userHand.on('app', this.userInfoChange)
            this.eventSet()
            if(this._userStatus) this._userStatus.invideTokenHand = this.invideTokenHand
        },
        components: {
            appHead,
            loading,
            appBcg,
            setting,
            // appCheck
            taskCenter,
            stoneRecord,
            sharePoster,
            connect,
            tasteWindow
        },
        computed: {
            username(){
                return this.userInfo.username
            },
            usercode(){
                return this.userInfo.usercode
            },
            clearPadding(){
                let {pathNow} = this
                let list = {
                    '/mistakes/summary': true,
                    '/user/summary': true,
                    '/avatar/summary': true,
                    '/history': true,
                    '/subject/summary': true,
                }
                let f = false
                for(let i in list){
                    if(pathNow.indexOf(i) > -1){
                        f = true
                        break
                    }
                }
                if(f) return this.clearPaddingStyle
                return {}
            },
            clearPaddingStyle(){
                return {
                    padding: '0px'
                }
            },
            queryFunWatch(){
                let {appLoaded, query} = this
                return {appLoaded, query}
            },
        },
        watch: {
            queryFunWatch: {
                handler(val, oldVal){
                    let {appLoaded} = val 
                    if(this._common.isSameObject(val, oldVal) || !appLoaded) return 
                    this.queryFunHandl()
                },
                deep: true,
                immediate: true
            },
            // query: {
            //     handler(val, oldVal){
            //         this.queryFunHandl()
            //     },
            //     deep: true,
            //     immediate: true
            // },
            $route: {
                handler(val, oldVal){
                    // this.testData = null
                    this.query = val.query || {}
                    this.voiceHand(val, oldVal)
                    let {name} = val || {}
                    let {appLoaded} = this
                    this.pathNow = val.path
                    if(appLoaded || !name) return
                    this.access_token = this.$route.params.access_token
                    this.refresh_token = this.$route.params.refresh_token
                    this.appInit()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            // this.voiceInit()
            this.globalComponentsInitCommon()
            this.htmlFontSizeSet()
            window.onresize = (e) => {
                this.htmlFontSizeSet(e)
                // this._common.emitEventBus('onresize')
                this._common.windowSizeEmit(e)
            }
            this.paramsSet()
            this.globalComponentsInitLogin()
            this.mountedState = true
            MathJaxCssHandler()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
// html, body, 
    html{
        height: 100%;
        width: 100%;
    }
    .app{
        margin: 0px auto;
        color: #fff;
        // padding: @app-padding-head @app-padding-side;
        // padding-right: 0px;
        padding: 0px;
        position: relative;
        overflow: hidden;
        height: 100vh;

        .app-loading{
            z-index: 99999;
        }

        .app-item{
            float: left;
        }
        .app-item.head{
            position: fixed;
            z-index: 301;
            top: 8px;
            left: 21px;
        } 
        .app-item.content{
            width: 100%;
            height: 100%;
            min-height: 100vh;
        } 
        .draw-icon-a{
            width: 400px;
            height: 400px;
            position: relative;
            background: #000;
            .a{
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                margin: auto;

                width: 375px;
                height: 100px;
                border-radius: 100px;
                background: #fff;
            }
            .a1{
                
            }
            .a2{
                // height: 375px;
                // width: 100px;
                // border-radius: 100px;
                // background: #000;
                transform: rotate(90deg);
            }
        }
        .debug-data{
            background: #fff;
            color: #333;
            position: fixed;
            top: 50%;
            left: 10px;
            z-index: 999;
            .clear-debug{
                text-align: right;
            }
        }
        .global-connect-box{
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            text-align: center;
        }
        .test-fun{
            position: fixed;
            top: 20px;
            right: 50%;
            transform: translateX(50%);
            z-index: 10000;
            color: #f83838;
        }
    }
</style>