import sockLink from "./sockLink";
import Stomp from "stompjs";

// export default class stompSocket extends commonApi {
export default class stompSocket {
    constructor(opt){
        opt = opt || {}
        opt.localKey = 'stomp_data'
        // super(opt)
        this.init(opt)
        this.errorKey = 'stomp'
    }
    getConnectFullUrl(){
        let {url} = this
        if(!url) return false
        return url
    }
    getHeaders(){
        let {headers} = this
        return headers || {}
    }
    init(opt){
        opt = opt || {}
        for(let i in opt){
            this[i] = opt[i]
        }
    }
    connect(opt){
        console.log('connect')
        this.init(opt)
        this.sockClient = null
        return new Promise(success => {
            this.response = success
            let url = this.getConnectFullUrl()
            if(!url){
                console.log('1')
                return success(false)
            }
            let {onclose} = this
            this.sockClass = new sockLink({url, onclose})
            this.sockClient = this.sockClass.connect()
            if(!this.sockClient){
                console.log('2')
                return success(false)
            }
            
            
            this.stompClient = Stomp.over(this.sockClient);
            if(this.debug !== undefined) this.stompClient.debug = this.debug
            else this.stompClient.debug = null
            let headers = this.getHeaders()
            this.stompClient.connect(headers, info => {
                this.connectHand(info)
            }, err => {
                this.errorHand(err)
            })
        })
        
    }
    disconnect(){
        if(this.sockClient) this.sockClient.close()
        if(this.stompClient) this.stompClient.disconnect()
        this.stompClient = null
    }
    send(path, headers, data){
        if(!this.stompClient) return false
        this.stompClient.send(path, headers, data);
    }
    sockEventSet(){
        let {sockClient} = this
        if(!sockClient) return false
        sockClient.onopen = this.sockOpen
    }
    connectHand(info){
        if(this.response) this.response(this.stompClient)
    }
    errorHand(e){
        let key = this.errorKey
        let code = 'connect error'
        console.log(e)
        if(this.onclose) this.onclose(e)
    }
}
