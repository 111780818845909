

function singleProxy(fun){
    let instance
    let handler = {
        construct(target, args){
            if(!instance){
                instance = Reflect.construct(fun, args)
            }
            return instance
        }
    }
    return new Proxy(fun, handler)
}

// const getPath = (path) => import(path);

// const singleProxyGet = async () =>{
//     let fun = await getPath('./commonFun')
//     if(!fun) return false
//     let funClass = singleProxy(fun)
//     return new funClass()
// }

export {singleProxy}