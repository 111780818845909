
import voiceHand from './voiceHand'

export default class backgroundMusic {
    constructor(){
        this._voiceHand = new voiceHand()
        this.list = {
            'Home': 'home bcg',
            'game-action': 'game bcg',
            'player-game-room': 'game bcg',
            'subject-summary': 'subject bcg',
            'subject-summary-full': 'subject bcg'
        }
    }
    musicOpen(name){
        this.closeAll()
        let key = this.list[name]
        if(!key) return
        this._voiceHand.normalPlay(key)
    }
    closeAll(){
        let {list} = this
        for(let i in list){
            this._voiceHand.normalStop(list[i])
        }
    }
}