const index = () => import(/* webpackChunkName: tsak */ "../views/task/index.vue")
// const course = () => import(/* webpackChunkName: task-course */ "../views/task/page/course.vue")
// const exercise = () => import(/* webpackChunkName: task-exercise */ "../views/task/page/exercise.vue")
// const result = () => import(/* webpackChunkName: task-result */ "../views/task/page/result.vue")


// const exercise_sub = () => import(/* webpackChunkName: task-exercise */ "../views/task/page/exercise_sub.vue")
const summary = () => import(/* webpackChunkName: task-exercise */ "../views/task/page/summary.vue")
const action = () => import(/* webpackChunkName: task-action */ "../views/task/page/action.vue")
const result = () => import(/* webpackChunkName: task-action */ "../views/task/page/result.vue")
const chapter = () => import(/* webpackChunkName: task-subject-chapter */ "../views/task/page/chapter.vue")
const section = () => import(/* webpackChunkName: task-subject-section */ "../views/task/page/section.vue")
const subAction = () => import(/* webpackChunkName: task-subject-action */ "../views/task/page/subAction.vue")
const sections = () => import(/* webpackChunkName: task-subject-sections */ "../views/task/page/sections.vue")
const subActions = () => import(/* webpackChunkName: task-subject-userExerciseList */ "../views/task/page/subActions.vue")

export default {
    path: "/task",
    name: "task",
    component: index,
    backRouteName: 'Home',
    redirect: '/task/summary/-1',
    children: [
        // {
        //     path: 'course',
        //     name: 'task-course',
        //     component: course,
        //     backRouteName: 'Home',
        // },
        // {
        //     path: 'exercise/:id/:exerciseId',
        //     name: 'task-exercise',
        //     component: exercise,
        //     backRouteName: 'task-course',
        // },
        // {
        //     path: 'exercise_sub/:id/:exerciseId',
        //     name: 'sub-exercise',
        //     component: exercise_sub,
        //     backRouteName: 'task-course',
        // },
        // {
        //     path: 'result/:id',
        //     name: 'task-result',
        //     component: result,
        //     backRouteName: 'task-course',
        // },

        {
            path: 'summary/:headVal',
            name: 'task-summary',
            component: summary,
            backRouteName: 'Home',
        },
        {
            path: 'action/:routeData',
            name: 'task-action',
            component: action,
            backFun: 'taskActionBackFun',
            // backRouteName: 'task',
        },
        {
            path: 'result/:chapterId',
            name: 'task-result',
            component: result,
            backFun: 'taskResultBackFun',
            // backRouteName: 'task',
        },
        {
            path: 'subject/chapter/:routeData',
            name: 'task-subject-chapter',
            component: chapter,
            backRouteName: 'task-summary',
            // backFun: 'taskChapterBackFun',
        },
        {
            path: 'subject/sections/:routeData',
            name: 'task-subject-sections',
            component: sections,
            backFun: 'taskSectionsBackFun',
        },
        {
            path: 'subject/section/:routeData',
            name: 'task-subject-section',
            component: section,
            backFun: 'taskSectionBackFun',
        },
        {
            path: 'subject/action/:routeData',
            name: 'task-subject-action',
            component: subAction,
            backFun: 'taskSubjectBackFun',
        },
        {
            path: 'subject/actions/:routeData',
            name: 'task-subject-actions',
            component: subActions,
            backFun: 'taskSubActionsBackFun',
        }
    ]
}
