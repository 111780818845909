
const index = () => import(/* webpackChunkName: game */ "../views/gameReview/index.vue")
const summary = () => import(/* webpackChunkName: game */ "../views/gameReview/page/summary.vue")

export default {
    path: "/game/review",
    name: "game-review",
    component: index,
    redirect: '/game/review/summary/-1',
    children: [
        {path: 'summary/:gameId', name: 'game-review-summary', component: summary, backRouteName: 'Home'},
    ]
}