import avatar from '@/global/text/avatar'

export default {
    'avatar-summary': {
        ...avatar['avatar-summary'],
        'purchase confirm sub message dress': '当前形象包含新服装，需消耗{dressPrice}思维币',
        'purchase confirm sub message avatar': '当前形象包含新形象，需消耗{avatarPrice}思维币',
        'purchase confirm sub message avatar dress': '当前形象包含新服装和新形象，需消耗{avatarPrice}（形象）+ {dressPrice}（服装）思维币',
        'purchase confirm sub message more': '当前形象包含{name}，需消耗{price}思维币',
        'none preview decorate': '未选取房间装扮',
        'purchase decorate confirm': '是否确认保存当前背景装扮',
        'purchase decorate submessage': '当前背景装扮消费{price}思维币',
        'exchange dress confirm' :'当前形象为专属伙伴装，请输入兑换码兑换',
        'purchase avatar and exchange dress confirm': '当前形象包含新形象和专属伙伴装，需要消耗{avatarPrice}思维币兑换形象和一个专属兑换码兑换服装',
        'exchange success': '兑换成功',
        'dress cdk tips': '邀请身边好友跟着脑王开动大脑，一起训练思维，即可和小伙伴一起换上好友装！可以咨询二讲老师，完成任务领取兑换码哦～',
    }
}