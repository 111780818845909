
export default {
    'getCourseLessonList': {
        url: '/town/school/course/lesson/list',
        serve: 'social'
    },
    'getCourseLessonRecord': {
        url: '/town/school/user/lesson/record/{user_code}',
        serve: 'social'
    }
}