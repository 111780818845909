export default {
    'getMiddlePalaceList': {
        url: '/town/palace/user/list',
        serve: 'social',
    },
    'getPalaceList': {
        url: '/town/palace/user/list/v2',
        serve: 'social',
    },
    'getExerciseList': {
        url: '/town/palace/exercise/list/{palace_id}',
        serve: 'social',
    },
    // 作答
    'uploadUserAnswer': {
        url: '/town/palace/exercise/user/{user_code}',
        serve: 'social',
    },
    // 答题结果
    'getPalaceUserResult': {
        url: '/town/palace/user/{user_code}',
        serve: 'social',
    },
    // 答题页详情
    'getPalaceUserInfo': {
        url: '/town/palace/user/answer/list/user/{user_code}',
        serve: 'social',
    },
    // 判断是知识岛还是知识链
    'getPalaceChainStatus': {
        url: '/town/palace/chain/status',
        serve: 'social',
    },
    // 获取视频跳转链接
    'getPalaceLive': {
        url : '/town/palace/live',
        serve: 'social',
    },
    // 跳转视频埋点
    'postLivePoint': {
        url: '/town/palace/user/live/point/{user_code}',
        serve: 'social',
    },
    // 用户题目列表
    'userPalaceExercise': {
        url: '/town/palace/user/exercise/list',
        serve: 'social',
    },
    // 实战岛查询
    'getRealCombat': {
        url: '/town/palace/user/real/combat',
        serve: 'social',
    }
}