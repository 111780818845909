

export default {
    /*socket*/
    //sockJS连接
    socketPath: 'WS_STOMP_ENDPOINT_GOLAXY',
    socketConnect: '/api/social/channel/WS_STOMP_ENDPOINT_GOLAXY',
    //游戏大厅
    gamehallChannel: "/channel/gamezone",
    gamezoneChannel: "/channel/gamezone/{usercode}",
    //邀请频道地址
    inviteChannel:"/channel/gamezone/game/invite/{usercode}",
    gameUserChannel: '/channel/wsuser/{usercode}',
    //游戏房间频道地址
    gameRoomChannel:`/channel/gameroom/`,
    //游戏频道地址
    gameChannel:`/channel/wsgame/game/`,
    //聊天地址
    chatChannelSubscribeUrl: `/channel/chatroom/`,
    chatChannelEnterUrl: `/channel/chatroom/login/`,
    chatChannelExitUrl: `/channel/chatroom/logout/`,
    chatChannelSendUrl: `/channel/chatroom/message/send/`,
    chatroomSub: '/channel/chatroom/{chatroomId}',
    chatMessageSend: '/channel/chatroom/message/send/{chatroomId}',
    //对弈订阅
    gameSub: '/channel/wsgame/{gameId}',
    
    //房间订阅
    gameroomSub: '/channel/gameroom/{gameroomId}',
    //心跳
    gamezoneHeartbeat: '/channel/wsuser/heartbeat',
}