import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class share extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'share'
        let name = 'share'
        opt = {localKey, name}
        super(opt)
    }

    async uploadImage(opt){
		opt = opt || {}
        let {file_type: certificate_type, user_code, file} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
		let options = {
			method: "post",
			...opt,
			user_code,
			uploadFile: true,
            postName: 'certificateFiles',
			file,
            // headers: {
            //     'Content-Type': 'text/plain;charset=UTF-8'
            // },
			extendParams: {
				certificate_type,
			},
		}
		return this.requestAction('uploadImage', options)
	}
}
export default singleProxy(share)
