<!--  -->
<template>
	<div class="fileItem img-upper">
		<div class="go">
			<img :src="`${PARAMS.assetsUrl}/img/wht/${color}.png`" alt="">
		</div>
		<!-- <div class="go" v-if="!checkpointItemInfo.userStatus">
			<img :src="`${PARAMS.assetsUrl}/img/wht/white.png`" alt="">
			
		</div> -->

		<!-- 很蠢的写法，回头换成动态绑定的 -->
		<!-- <div class="leftStar" v-show="showActiveStar[0].starActive">
			<img :src="`${PARAMS.assetsUrl}/img/wht/starLeftActive.png`" alt="">
		</div>
		<div class="leftStar " v-show="!showActiveStar[0].starActive">
			<img class="starRotate" :src="`${PARAMS.assetsUrl}/img/wht/starRight.png`" alt="">
		</div>
		
		<div class="upStar" v-show="showActiveStar[1].starActive">
			<img :src="`${PARAMS.assetsUrl}/img/wht/starMiddleActive.png`" alt="">
		</div>
		<div class="upStar" v-show="!showActiveStar[1].starActive">
			<img :src="`${PARAMS.assetsUrl}/img/wht/starMiddle.png`" alt="">
		</div>
		<div class="rightStar" v-show="showActiveStar[2].starActive">
			<img :src="`${PARAMS.assetsUrl}/img/wht/starRightActive.png`" alt="">
		</div>
		<div class="rightStar" v-show="!showActiveStar[2].starActive">
			<img :src="`${PARAMS.assetsUrl}/img/wht/starRight.png`" alt="">
		</div>
		<div class="text" :class="checkpointItemInfo.userStatus ? 'active':''">
			{{checkpointItemInfo.puzzleName}}
		</div> -->

		<div class="star" v-for="(v, i) in stars" :key="i" :class="[v.class, {'active': v.active}]"></div>

		<div class="text bold" :class="checkpointItemInfo.userStatus ? 'active':''">
			{{checkpointItemInfo.puzzleName}}
		</div>
	</div>
</template>

<script>
    export default {
        name: 'fileItem',
        props: {
			checkpointItemInfo: {
				type: Object,
				default(){
					return {}
				}
			}
		},
        data() {
            return {
				// 展示活动的星星
				showActiveStar:[
					{
						starActive:false,
					},
					{
						starActive:false,
					},
					{
						starActive:false,
					},
				],
			}
        },
        methods: {
			changeShowActiveStart(){
				for(let i = 0; i < this.checkpointItemInfo.userStarNum  ; i++){
					this.showActiveStar[i].starActive = true
					
				}
			},
			
        },
        created() {
			
        },
        components: {

        },
        computed: {
			defaultStar(){
				return [
					'left',
					'center',
					'right',
				]
			},
			stars(){
				let {defaultStar, userStar} = this
				let rd = []
				for(let i in defaultStar){
					let tem = {class: defaultStar[i]}
					if((Number(i) + 1) <= userStar) tem.active = true
					rd.push(tem)
				}
				return rd
			},
			userStar(){
				let {checkpointItemInfo: info = {}} = this
				return info.userStarNum || 0
			},
			color(){
				let {checkpointItemInfo: info = {}} = this
				return info.userStatus ? 'black' : 'white'
			}
        },
        watch: {
           
        },
        mounted() {
			this.changeShowActiveStart()
		},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
    .fileItem{
		width: 8px;
		height: 14px;
		position: relative;
		color: #333333;
		.go{
			width: 60px;
			height: 40px;
			position: absolute;
			top: 50%;
			left: 50%;
			
			transform: translate(-50%,-50%);
			img{
				height: 100%;
				width: 100%;
			}
		}
		.leftStar{
			width: 30px;
			height: 30px;
			position: absolute;
			bottom: 16px;
			right: 50%;
			transform: translateX(-7px);
			img{
				width: 30px;
				height: 30px;
			}
		}
		.upStar{
			width: 24px;
			height: 24px;
			position: absolute;
			bottom: 25px;
			left: 50%;
			transform: translateX(-50%);
			img{
				width: 24px;
				height: 24px;
			}
		}
		.rightStar{
			width: 30px;
			height: 30px;
			position: absolute;
			bottom: 16px;
			left: 50%;
			transform: translateX(7px);
			img{
				width: 30px;
				height: 30px;
			}
		}
		.star{
			width: 30px;
			height: 30px;
			position: absolute;
		}
		.star.left{
			background: url('@{assetsUrl}/img/wht/starRight.png');
			background-size: 100% 100%;
			bottom: 16px;
			right: 50%;
			transform: rotateY(180deg) translateX(7px);
		}
		
		.star.center{
			width: 24px;
			height: 24px;
			background: url('@{assetsUrl}/img/wht/starMiddle.png');
			background-size: 100% 100%;
			bottom: 25px;
			left: 50%;
			transform: translateX(-50%);
		}
		.star.center.active{
			background: url('@{assetsUrl}/img/wht/starMiddleActive.png');
			background-size: 100% 100%;
		}
		.star.right{
			background: url('@{assetsUrl}/img/wht/starRight.png');
			background-size: 100% 100%;
			bottom: 16px;
			left: 50%;
			transform: translateX(7px);
		}
		.star.right.active, .star.left.active{
			background: url('@{assetsUrl}/img/wht/starRightActive.png');
			background-size: 100% 100%;
		}
		.text{
			width: 60px;
			height: 20px;
			font-size: 20px;
			text-align: center;
			vertical-align: bottom;
			// font-family: PingFangSC-Semibold, PingFang SC;
			
			line-height: 20px;
			position: absolute;
			bottom: 3px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.img-upper{
		z-index: 100;
	}
	.active{
		color: #FFFFFF;
	}
	.starRotate{
		transform:rotateY(180deg);
	}
</style>