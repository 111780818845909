<template>
    <div class="global-com-connect">
        <inviteConfirmBox ref="inviteConfirmBox" class="hidden" @takeFun="takeFun"></inviteConfirmBox>
        <!-- <h1>{{connectionStatusText}}</h1> -->
    </div>
</template>
<script>
    import socketHand from '@/plugins/websocket/socketHand'
    import inviteConfirmBox from '@/components/connect/inviteConfirmBox.vue'
    import api from '@/API/page/app'

    export default {
        name: '',
        props: {
            username: String,
            usercode: [Number, String]

        },
        data() {
            return {
                appApi: new api(),
                socketHand: new socketHand(),
                moduleState: false,
                stompClient: null,
                pathNow: this.$route.path,
            };
        },
        methods: {
            takeFun(params){
                let res = this._common.takeFun.call(this, params)
                if(!res || !params || !params.callback) return
                let {callback, reject} = params
                if(res.then){
                    res.then(data => {
                        callback(data)
                    }, err => {
                        if(reject) reject(err)
                    })
                }else{
                    callback(res)
                }

            },
            sendByOwn(res){
                res = res || {}
                let {sender} = res
                return this.usercode == sender
            },
            async socketInit(){
                await this._common.nextTick.call(this)
                this.socketClose()
                await this._userStatus.userGameTasteReset()
                await this._userStatus.userLoginSend()
                await this._userStatus.userStatusQuery()
                this.stompClient = await this.socketHand.connect({quitCallback: res => {this.quit(res)}})
                if(!this.stompClient) {
                    console.log('stomp link fail')
                    return
                }
                this.stompOnopen()
                this.connectGameStateCheck()
                // this.userGameCheck()
            },
            socketClose(){
                if(!this.socketHand) return
                this.globalUnsubscribe()
                this.socketHand.disconnect()
                this.stompClient = null
            },
            globalUnsubscribe(){
                this.socketHand.inviteUnsubscribe()
            },
            async stompOnopen(){
                await this.globalSubscribe()
                this.gamezoneSubscribe()
                this.$emit('loaded')
            },
            globalSubscribe(callback){
                return new Promise(success => {
                    this.socketHand.inviteSubscribe(res => {
                        this.commonMessageHand(res)
                        if(success){
                            success(res)
                            success = null
                        }
                    })
                })
            },
            async gamezoneSubscribe(opt){
                opt = opt || {}
                return new Promise(success => {
                    this.socketHand.gamezoneSubscribe(res => {
                        if(success){
                            success(res)
                            success = null
                        }
                        this.commonMessageHand(res, opt.callback)
                    })
                })
            },
            messageParse(res){
                res = res || {}
                let {body} = res
                body = body || ''
                try{
                    body = JSON.parse(body)
                }catch(e){
                    body = null
                }
                if(!body || !this._common.isObject(body)) return false
                return body
            },
            commonMessageHand(res, callback){
                let body = this.messageParse(res)
                if(!body) return
                this.messageHand(body, callback)
            },
            messageHand(res, callback){
                res = res || {}
                let {msg} = res
                switch(msg){
                    case 'MSG_GAMEZONE_GAME_INVITE_SUBSCRIBE':
                        return this.refreshList(res)
                    case 'MSG_GAMEZONE_GAME_INVITE_SEND':
                        return this.inviteControl({type: 'receive', res})
                    case 'MSG_GAMEZONE_GAME_INVITE_CANCEL':
                        return this.inviteControl({type: 'cancel', res})
                    case 'MSG_GAMEZONE_GAME_INVITE_REJECT':
                        return this.inviteControl({type: 'reject', res})
                    case 'MSG_GAMEZONE_GAME_INVITE_ACCEPT':
                        return this.inviteAccept(res)
                    case 'MSG_GAMEZONE_GAME_INVITE_ACK':
                        return this.inviteAckConfirm(res)
                    case 'MSG_WSUSER_MULTI':
                        return this.mutilNoticeHand(res)
                    case 'MSG_WSUSER_STATUS':
                        return this.userStatusChange(res)
                    case 'MSG_GAMEZONE_GAME_TASTE_MATCHED':
                        return this.gameTasteMatched(res)

                    // case 'MSG_GAMEROOM_GAME_CONFIG_REJECT':
                    //     return this.configRejectRule(res)
                        
                }
                if(callback) callback(res)
            },
            refreshList(){},
            inviteClientIdCheck(val){
                let {inviteeUserCode, inviterUserCode, inviterClientId, inviteeClientId} = val || {}
                let {usercode} = this
                if(inviteeUserCode == usercode && inviteeClientId != this.PARAMS.web_clientId){
                    return false
                }
                if(inviterUserCode == usercode && inviterClientId != this.PARAMS.web_clientId){
                    return false
                }
                return true
            },
            async inviteControl(opt){
                // opt = opt || {}
                // let {type, res} = opt
                // res = res || {}
                // let data = res.data || {}
                // if(type == 'receive' && !this.inviteClientIdCheck(data) && this.sendByOwn(res)){
                //     return this.$refs['inviteConfirmBox'].closeByData(data)
                // }
                // if(type == 'reject' && !this.inviteClientIdCheck(data)){
                //     return this.$refs['inviteConfirmBox'].closeByData(data)
                // }
                // if((type == 'cancel' || type == 'reject') && this.sendByOwn(res)) {
                //     return
                // }
                // // this.$refs['leConfirm'].close()
                // if(type == 'receive' && !this.checkUserStatus(res)){
                //     return
                // }
                // let status = await this._userStatus.inviteStatusCheck()
                // if(status){
                //     return
                // }
                // if(res.code != 0){
                //     return
                // }
                // let sender = res.sender
                // let fun = `${type}_hand`
                // if(!this[fun]){
                //     return
                // }
                // let params = this[fun](data, sender)
                // if(!params){
                //     return
                // }
                // params.type = type
                // this.$refs['inviteConfirmBox'].open(params)
            },
            connectGameStateCheck(){
                if(this.isGamePage) return
                return this.gameroomStateCheck()
            },
            async gameroomStateCheck(opt){
                let gameRes = await this._userStatus.inviteStatusCheck(opt)
                if(!gameRes) return false
                let userInfo = await this._userStatus.wsUserInfoInit(opt)
                if(this._userStatus.gameTakeCheckByComponent){
                    let state = this._userStatus.gameTakeCheckByComponent(gameRes.id)
                    if(state == 0) return true
                    else if(!state) return
                }
                if(this._userStatus.multiedCheck(userInfo)) return this.takeRoomControl(gameRes.id)
                let par = {gameroomId: gameRes.id}
                this.gotoGameroomConfig(par)
                return true
            },
            async takeRoomControl(gameroomId){
                let res = await this.takeRoomNotice()
                if(!res) {
                    return
                }
                await this._userStatus.multiGame()
                this.gotoGameroomConfig({gameroomId})
                return true
            },
            async takeRoomNotice(){
                let message = this._errorCode.game['has game can multied']
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '是', label: 0, type: 'confirm'},
                        {title: '否', label: 1, type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            async gotoGameroomConfig(params){
                let type = 'push'
                if(this.isGamePage) type = 'replace'
                this._routeHand.goto({name: 'player-game-room',  params, type})
            },
            eventSet(){
                this.eventOff()
                this._eventBus.$on('gameLinkTakeFun', res => {
                    return this.takeFun(res)
                })
            },
            eventOff(){
                this._eventBus.$off('gameLinkTakeFun')
            },
            gameroomSubscribe(params){
                let {gameroomId, callback} = params
                this.socketHand.gameroomSubscribe(res => {
                    this.commonMessageHand(res, callback)
                }, {gameroomId})
            },
            gameroomUnsubscribe(){
                if(!this.socketHand) return
                this.socketHand.gameroomUnsubscribe()
            },
            gameSubscribe(params){
                let {gameId, callback, promiseFun} = params
                this.socketHand.gameSubscribe(res => {
                    if(promiseFun) promiseFun(res)
                    promiseFun = null
                    this.commonMessageHand(res, callback)
                }, {gameId})
                
            },
            gameUnsubscribe(){
                if(!this.socketHand) return
                this.socketHand.gameUnsubscribe()
            },
            mutilNoticeHand(res){
                let {data} = res
                let {deviceType, multiType} = data
                multiType = Number(multiType)
                switch(multiType){
                    case 10:
                        this.loginMultiHand(res)
                        break
                    case 20:
                        this.gameMultiHand(res)
                        break
                }
            },
            async loginMultiHand(res){
                let {clientId} = res || {}
                if(clientId == this.PARAMS.phone_clientId) return
                let userCode = this.usercode
                let status = await this.appApi.checkUser()
                if(!status) this._userStatus.invideTokenHand()
            },
            async gameMultiHand(res){
                let {clientId} = res || {}
                if(clientId == this.PARAMS.web_clientId){
                    return
                }
                let userCode = this.usercode
                if(this._userStatus.gameTakeAction) await this._userStatus.gameTakeAction()
                // if(!status) this.sendMultiCode({
                //     multiAction: this.PARAMS.response_multi_action,
                //     multiType: this.PARAMS.login_multi_type,
                //     userCode
                // })
            },
            userStatusChange(res){
                if(this._userStatus.userStatusChange) this._userStatus.userStatusChange(res)
            },
            checkUserStatus(res){
                // let state = this.checkUserStatusAction(res)
                // if(!state){
                //     // this.inviteCancelByState(res)
                //     return false
                // }
                // let inState = this.checkUserInviteState(res)
                // return state && inState
            },
            async gameTasteCheck(){
                if(this._common.tasteWindow && this._common.tasteWindow.active) return
                let res = await this._userStatus.userTasteStateCheck()
                if(!res) return
                let {webUserStatus, webConnectionStatus, appUserStatus, appConnectionStatus} = res
                appConnectionStatus = Number(appConnectionStatus)
                if(appConnectionStatus && appUserStatus == this.ENUMS.GAME_USER_BUSY) return
                this._common.tasteWindow.tasteCancel()

            },
            async moduleStateInit(){
                let module_name = 'GLOBAL_WEBSOCKET'
                let res = await this.appApi.moduleState({module_name, noLoad: true, noError: true})
                this.moduleState = res
            },
            gameTasteMatched(res){
                let {data, code} = res || {}
                if(code != 0 || !this.inviteClientIdCheck(data)){
                    return this.$refs['inviteConfirmBox'].closeByData(data)
                }
                this._common.tasteWindow.matched(data)
            },
        },
        created() {
            this.eventSet()
        },
        components: {
            inviteConfirmBox
        },
        computed: {
            connectionStatus(){
                return this.stompClient ? true : false
            },
            connectionStatusText(){
                if(!this.username) return
                let text = '连接中......'
                if(this.connectionStatus) text = '已连接'
                return text
            },
            isGamePage(){
                let {pathNow} = this
                return pathNow.indexOf('/game/room') > -1
            },
            initWatch(){
                let {username, moduleState} = this
                return username && moduleState
            },
        },
        watch: {
            username: {
                async handler(val, oldVal){
                    if(!val || val === oldVal) return 
                    await this.appApi.version()
                    await this.moduleStateInit()
                    this.gameTasteCheck()
                },
                deep: true,
                immediate: true
            },
            initWatch:{
                handler(val){
                    if(val && !this.stompClient) this.socketInit()
                    if(!val) this.socketClose()
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(val, oldVal){
                    this.pathNow = val.path
                    // this.inviteWindowReopen(val, oldVal)
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    
</style>