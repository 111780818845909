<template>
	<div class="personalPieChart">
		<div class="piechartBox"  :id="'piechartBoxInside' + idName"></div>
		<div class="piechartBox"  :id="'piechartBoxOutside' + idName"></div>
	</div>
	
</template>

<script>

export default {
	name: "personalPieChart",
	props:{
		percentageInfo: [String, Number],
		idName: [String, Number],
	},
	data(){
		return{
			percentage:30,
			piechartBoxInside:'',
			piechartBoxOutside:'',
			piechartBoxInside_option: {
				stillShowZeroSum: false,
				// 鼠标划过时饼状图上显示的数据
				tooltip: {
					show:false,
					trigger: 'item',
					formatter: '{a}<br/>{b}:{c} ({d}%)'
				},
				//图例
				
				//饼图中各模块的颜色
				color: ['rgba(255, 255, 255, 0.0)', '#F2F2F2'],
				// 饼图数据
				series: {
					type: 'pie',             //echarts图的类型   pie代表饼图
					radius: '96%',           //饼图中饼状部分的大小所占整个父元素的百分比
					center: ['50%', '50%'],  //整个饼图在整个父元素中的位置
					// data:''               //饼图数据
					hoverAnimation:false,	//设置不要饼图鼠标划过时变大
					data: [                  //每个模块的名字和值
						{ name: '失败', value: 100 - 30},
						{ name: '胜利', value: 30},
					],
					itemStyle: {
						
						normal: {
							borderWidth: 2, //设置border的宽度有多大
							borderColor:'#ffffff',
							label: {
							show: false,//饼图上是否出现标注文字 标注各模块代表什么  默认是true
							// position: 'inner',//控制饼图上标注文字相对于饼图的位置  默认位置在饼图外
							},
							labelLine: {
							show: false,//官网demo里外部标注上的小细线的显示隐藏    默认显示
							}
						}
					},
				}
			},
			piechartBoxOutside_option: {
				stillShowZeroSum: false,
				// 鼠标划过时饼状图上显示的数据
				tooltip: {
					show:false,
					trigger: 'item',
					formatter: '{a}<br/>{b}:{c} ({d}%)'
				},
				//图例
				
				//饼图中各模块的颜色
				color: ['#7DB5FF', 'rgba(255, 255, 255, 0.0)'],
				// 饼图数据
				series: {
					type: 'pie',             //echarts图的类型   pie代表饼图
					radius: '100.9%',           //饼图中饼状部分的大小所占整个父元素的百分比
					center: ['50%', '50%'],  //整个饼图在整个父元素中的位置
					// data:''               //饼图数据
					hoverAnimation:false,	//设置不要饼图鼠标划过时变大
					data: [                  //每个模块的名字和值
						{ name: '失败', value: 100 - 30},
						{ name: '胜利', value: 30},
					],
					itemStyle: {
						
						normal: {
							borderWidth: 2, //设置border的宽度有多大
							borderColor:'#ffffff',
							label: {
							show: false,//饼图上是否出现标注文字 标注各模块代表什么  默认是true
							// position: 'inner',//控制饼图上标注文字相对于饼图的位置  默认位置在饼图外
							},
							labelLine: {
							show: false,//官网demo里外部标注上的小细线的显示隐藏    默认显示
							}
						}
					},
				}
			},
		}
	},
	methods:{
		getPercentageInfo() {
			this.percentage = this.percentageInfo
			this.piechartBoxInside_option.series.data[0].value = this.percentage
			this.piechartBoxInside_option.series.data[1].value = 100 - this.percentage
			this.piechartBoxOutside_option.series.data[0].value = this.percentage
			this.piechartBoxOutside_option.series.data[1].value = 100 - this.percentage
		},
		init(){
			this.piechartBoxInside = this.$echarts.init(document.getElementById(`piechartBoxInside${this.idName}`))
			this.piechartBoxOutside = this.$echarts.init(document.getElementById(`piechartBoxOutside${this.idName}`))
			this.piechartBoxInside.setOption(this.piechartBoxInside_option,true)
			this.piechartBoxOutside.setOption(this.piechartBoxOutside_option,true)
		},
		sizeChange(){
			if(this.piechartBoxInside) this.piechartBoxInside.resize()
			if(this.piechartBoxOutside) this.piechartBoxOutside.resize()
		},
	},
	created() {
		this.getPercentageInfo()
	},
	computed: {

	},
	components: {

	},
	
	// 2数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据
	watch: {
		piechartBoxInside_option: {
			handler(newVal, oldVal) {
			if (this.piechartBoxInside) {
				if (newVal) {
					this.piechartBoxInside.setOption(newVal,true);
				}
				else {
					this.piechartBoxInside.setOption(oldVal,true);
				}
			} 
			else {
				this.init();
			}
		},
		deep: true //对象内部属性的监听，关键。
		},
		piechartBoxOutside_option: {
			handler(newVal, oldVal) {
			if (this.piechartBoxOutside) {
				if (newVal) {
					this.piechartBoxOutside.setOption(newVal,true);
				}
				else {
					this.piechartBoxOutside.setOption(oldVal,true);
				}
			} 
			else {
				this.init();
			}
		},
		deep: true //对象内部属性的监听，关键。
		},
		percentageInfo:{
			handler(newVal, oldVal) {
				if (newVal) {
					this.getPercentageInfo()
				}
				else{
					this.getPercentageInfo()
				}
				
			},
		},
		
	},
	
	mounted() {
		this.init()
	},
	



	};
</script>

<style  lang='less' scoped>
	/* 这个图的计算是真的离谱，之后再改 */
	.personalPieChart{
		width: 100px;
		height: 100px;
		position: relative;
		.piechartBox{
			width: 100px;
			height: 100px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
	}


</style>
