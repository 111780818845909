const index = () => import(/* webpackChunkName: user */ "../views/user/index.vue")
const summary = () => import(/* webpackChunkName: user */ "../views/user/page/summary.vue")

export default {
    path: "/user",
    name: "user",
    component: index,
    backRouteName: 'Home',
	redirect: 'user/summary',
    children:[
        {
			path:'summary',
			name:'user-summary',
			component:summary,
			backRouteName:'Home',
		},
    ]
}
