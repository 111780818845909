<!--  -->
<template>
    <div class='com-global-confirm-panel'>
        <div class="p-item title flex-center">
            <confirmTitle>
                <slot name="title"></slot>
                <span class="text-normal-ab" v-if="!$slots['title']">{{TEXT.common.notice}}</span>
            </confirmTitle>
        </div>
        <div class="p-item content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-confirm-panel{
        width: 100%;
        height: 100%;
        position: relative;
        background: #FFFEF8;
        @border-size: 7px;
        @border-shadow-size: 20%;
        .background-img-max(url('@{assetsUrlV2_0}/global/c9.png'));
        .panel-border{
            position: absolute;
            .border-shadow{
                background: #5A4294;
                position: absolute;
            }
        }
        // .top, .bottom{
        //     background-image: linear-gradient(to right, #9F83DC, #C7B0F5, #9F83DC);
        //     .border-shadow{
        //         width: 100%;
        //         height: @border-shadow-size;
        //     }
        // }
        // .left, .right{
        //     background-image: linear-gradient(to bottom, #9F83DC, #C7B0F5, #9F83DC);
        //     .border-shadow{
        //         height: 100%;
        //         width: @border-shadow-size;
        //     }
        // }
        
        .top{
            top: 0px;
            left: 0px;
            width: 100%;
            height: @border-size;
            .border-shadow{
                bottom: 0px;
            }
        }
        .right{
            height: 100%;
            width: @border-size;
            top: 0px;
            right: 0px;
            .border-shadow{
                left: 0px;
            }
        }
        .bottom{
            width: 100%;
            height: @border-size;
            bottom: 0px;
            left: 0px;
            .border-shadow{
                top: 0px;
            }
        }
        .left{
            height: 100%;
            width: @border-size;
            left: 0px;
            top: 0px;
            .border-shadow{
                right: 0px;
            }
        }
        .p-item.title{
            position: absolute;
            top: -13px;
            left: 0px;
            width: 100%;
        }
    }
</style>