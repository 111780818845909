import common from './commonFun'

export default class quitApp {
    constructor(){
        this._common = new common()
    }

    setupWebViewJavascriptBridge(callback) {
        if (window.WebViewJavascriptBridge) { return callback(WebViewJavascriptBridge); }
        if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback); }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(() => { document.documentElement.removeChild(WVJBIframe) }, 0)
    }
    getDebugEle(){
        let id = 'debugElement'
        let ele = document.querySelector(`#${id}`)
        if(!ele){
            ele = document.createElement('div')
            ele.id = id
            let body = document.getElementsByTagName('body')[0]
            console.log(body)
            body.appendChild(ele)
        }
        console.log(ele)
        return ele
    }
    clearDebug(){
        let ele = this.getDebugEle()
        ele.innerHTML = ''
    }
    insertDebugInfo(info){
        let ele = this.getDebugEle()
        let p = document.createElement('p')
        p.innerHTML = info
        ele.appendChild(p)
        console.log(ele)

    }

    quitIos(){
        // this.clearDebug()
        // this.insertDebugInfo('About to exit')
        this.setupWebViewJavascriptBridge((bridge) => {
            // this.insertDebugInfo('closeWebView')

            bridge.callHandler('closeWebView', '')
        })
    }

    showNativeLivingIos(params){
        return new Promise(res => {
            this.setupWebViewJavascriptBridge((bridge) => {
                bridge.callHandler('showNativeLiving', params, (responseData) => { 
                    responseData = responseData || {}
                    res(responseData)
                })
            })
        })
    }

    shareImageToNativeIos(params){
        return new Promise(res => {
            this.setupWebViewJavascriptBridge((bridge) => {
                bridge.callHandler('shareImageToNative', params, (responseData) => { 
                    responseData = responseData || {}
                    res(responseData)
                })
            })
        })
    }
}