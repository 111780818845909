import {singleProxy} from './singleClass';
import commonFun from '@/global/js/commonFun';
import errorHandClass from './errorHand'
import errorCode from './errorCode'
import params from './params'
import appData from './appData'
import userHand from './userHand'

const funClass = singleProxy(commonFun)
const errClass = singleProxy(errorHandClass)
let opt = {
    _common: new funClass(),
    _errorHand: new errClass(),
    singleProxy: singleProxy,
}
const common = new funClass()
const errorHand = new errClass()
export {common, errorHand, singleProxy, params, appData, userHand, errorCode}
