
export default {
	'getPersonalUser': {
		url: '/town/user/{user_code}',
		serve: 'social',
	},
	'getPersonalAI': {
		url: '/town/game/statistics/user/{user_code}',
		serve: 'social',
	},
	'getPersonalPuzzle': {
		url: '/town/puzzle/statistics/user/{user_code}',
		serve: 'social',
	},
	'schoolInfo': {
		url: '/town/school/{school_id}',
		serve: 'social'
	},
	'userInfo': {
		url: '/town/user/{user_code}',
		serve: 'social'
	},
	'statisticsInfo': {
		'url': '/town/game/statistics/user/{user_code}',
		serve: 'social'
	},
	'puzzleInfo': {
		url: '/town/puzzle/statistics/user/{user_code}',
		serve: 'social'
	},
	'gameInfo': {
		url: '/town/game/statistics/user/{user_code}',
		serve: 'social'
	}
}

