const index = () => import(/* webpackChunkName: preview */ "../views/preview/index.vue")

const puzzleAction = () => import(/* webpackChunkName: preview-puzzleAction */ "../views/preview/page/puzzleAction.vue")
const courseAction = () => import(/* webpackChunkName: preview-courseAction */ "../views/preview/page/courseAction.vue")
const subAction = () => import(/* webpackChunkName: preview-subAction */ "../views/preview/page/subAction.vue")

export default {
    path: "/exercise-preview",
    name: "preview",
    component: index,
    backRouteName: 'Home',
    redirect: '/exercise-preview/action',
    children: [
        {
            path: 'puzzle/action/:routeData',
            name: 'preview-puzzle-action',
            component: puzzleAction,
            // backFun: 'previewPuzzleBackFun',
        },
        {
            path: 'course/action/:routeData',
            name: 'preview-course-action',
            component: courseAction,
            // backFun: 'previewCourseBackFun',
        },
        {
            path: 'subject/action/:routeData',
            name: 'preview-subject-action',
            component: subAction,
            // backFun: 'previewSubjectBackFun',
            
        },
    ]
}
