<!--  -->
<template>
    <div class='com-global-confirm-title'>
        <div class="t-item shadow"></div>
        <div class="t-item bcg"></div>
        <div class="t-item text flex-center bold">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-confirm-title{
        width: 106px;
        height: 28px;
        position: relative;
        .t-item{
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .t-item.shadow{
            width: 102%;
            height: 86%;
            top: 32%;
            left: -1%;
            .background-img-max(url('@{assetsUrlV2_0}/global/c8-shadow.png'));
        }   
        .t-item.bcg{
            .background-img-max(url('@{assetsUrlV2_0}/global/c8.png'));
        }
        .t-item.text{
            color: #554B93;
            height: 14px;
            top: 9px;
            left: 0px;
            right: 0px;
            margin: 0px auto;
        }
    }
</style>