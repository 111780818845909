<!-- -->
<template>
    <div class='page-taskcenter-summary flex-center' v-if="show">
        <div class="bcg">
            <div class="mark"></div>
            <div class="c1">
                <div class="off-btn" @click="close()"></div>
                <div class="taskcenter-title">
                    <span class="text-large-b bold text-brown-color">{{"任务中心"}}</span>
                </div>
                <div class="head">
                    <div class="item " :class="v.signValue" v-for="(v,i) in week" :key="i">
                        <div class="c3"></div>
                        <span class="text-mini bold min">{{v.day}}</span>
                    </div>
                </div>
                <span class="sign-tip text-mini bold min text-pur-color">{{"连签三天额外+5棋石&emsp;连签七天额外+10棋石"}}</span>
                <div class="sign c4" @click="signIn(signInCul)">
                    <span v-if="signInCul" class="text-normal bold text-red-color">{{"已签到"}}</span>
                    <span v-else class="text-normal bold text-red-color">{{"签到"}}</span>
                </div>
                <div class="content">
                    <div class="item c5" v-for="(v,i) in taskCon" :key="i" :class="{gray:v.disabled}">
                        <div class="l" :class="v.img"></div>
                        <div class="c6">
                            <div class="con-text">
                                <span class="text-normal bold o">{{v.taskName}}</span>
                                <span class="text-mini bold min text-pur-color t">{{v.taskDetail}}</span>
                            </div>
                            <div class="btn" v-show="v.taskStatus==0" @click="goPlay(v)">
                                <confirmPlay :disabled="v.disabled">
                                    <span class="txt bold">{{"去挑战"}}</span>
                                </confirmPlay>
                            </div>
                            <div class="btn" v-show="v.taskStatus==1" @click="goPlay(v)">
                                <confirmPlay type="primary" :disabled="v.disabled">
                                    <span class="txt bold">{{"继续挑战"}}</span>
                                </confirmPlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClass from '@/API/page/taskcenter'
import userApiClass from '@/API/page/user'
    export default {
        name: '',
        props: {
            appStyle: Object,
            appLoaded: Boolean
        },
        data() {
            return {
                api: new apiClass(),
                userApi: new userApiClass(),
                appData: new this.appDataClass({key: 'taskCenter'}),
                week:[
                    {day:'周一',signValue:'c2_2'},
                    {day:'周二',signValue:'c2_2'},
                    {day:'周三',signValue:'c2_2'},
                    {day:'周四',signValue:'c2_2'},
                    {day:'周五',signValue:'c2_2'},
                    {day:'周六',signValue:'c2_2'},
                    {day:'周日',signValue:'c2_2'},
                ],
                taskCon:[
                    {img:'c7',taskName:"",taskDetail: "",taskStatus:"0",fun:"game-list"},
                    {img:'c9',taskName:"",taskDetail: "",taskStatus:"0",fun:"task"},
                    {img:'c10',taskName:"",taskDetail: "",taskStatus:"0",fun:"subject"},
                    {img:'c11',taskName:"",taskDetail: "",taskStatus:"0",fun:"cabin", disabled: false},
                ],
                show:false,
                signInStatus:0,
                queryName: 'taskCenter',
                openKey: 'taskCenterOpenRoute',
                path: this.$route.path
            };
        },
        methods: {
            pageInit(){
                this.taskList()
            },
            open(){
                this.show = true
                this.pageInit()
            },
            close(){
                this.show = false
                let {Home: {name} = {}} = this._routeList || {}
                if(this.$route.name != name) this.gotoHome()
            },
            gotoHome(){
                let name = 'Home'
                let query = this.$route.query || {}
                if(!query[this.queryName]) return
                query[this.queryName] = 'close'
                this._routeHand.goto({name, type: 'replace', query})
            },
            signIn(status){
                if(status) return
                this.taskSign()
            },
            async taskSign(){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                    // schoolId,
                    task_id:'1',//签到
                }
                let res = await this.api.taskSign(opt)
                if(res){
                    this.pageInit()
                    await this.userApi.userAccount({noLoad: true, noError: true})
                }
            },
            async taskList(){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                    // schoolId,
                }
                let res = await this.api.taskList(opt)
                if(res){
                    let {signInList:list,taskList,dailySignInFlag} = res
                    this.signInStatus = dailySignInFlag
                    let {week,taskCon} = this
                    for (const i in list) {
                        if (Object.hasOwnProperty.call(list, i)) {
                            // week[i]["value"] = list[i];
                            if(list[i]==1) week[i].signValue="c2_0"
                            if(list[i]==0) week[i].signValue="c2_1"
                            if(list[i]==-1) week[i].signValue="c2_2"
                        }
                    }
                    for (const i in taskList) {
                        if(!taskCon[i]) continue
                        if (Object.hasOwnProperty.call(taskList, i)) {
                            let {townTask,taskStatus} = taskList[i] || {}
                            taskCon[i].taskName = townTask.taskName || ''
                            taskCon[i].taskDetail = townTask.taskDetail || ''
                            taskCon[i].taskStatus = taskStatus
                        }
                    }
                }
            },
            goPlay(v){
                let {fun} = v
                if(!fun) return
                this.close()
                let query = {}
                let name = this.$route.name
                query[this.queryName] = name
                this.appData.setStoreItem({'taskCenterOpenRoute': this.$route.fullPath})
                this._routeHand.goto({
                    name: fun,
                    type: 'push',
                    // query
                })
            },
            getSignInStatus(status){
                if(status==1) return true
                if(status==0) return false
            },
            funHandl(){
                let {taskCenterOpenRoute: path} = this.$store.state
                let fullPath = this.$route.fullPath
                if(!path || path != fullPath) return
                this.open()
                this.appData.removeStoreItem({taskCenterOpenRoute: true})
            }
        },
        created() {
        },
        components: {
        },
        computed: {
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            signInCul(){
                let {signInStatus:status} = this
                let result = this.getSignInStatus(status)
                return result
            },
            pathWatch(){
                let {appLoaded, path} = this
                return {appLoaded, path}
            }
        },
        watch: {
            $route: {
                handler(val, oldVal){
                    this.path = val.path
                },
                deep: true,
                immediate: true
            },
            pathWatch: {
                handler(val, oldVal){
                    let {appLoaded} = val
                    if(this._common.isSameObject(val, oldVal) || !appLoaded) return 
                    this.funHandl()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
    .page-taskcenter-summary{
        @text-purple-color:#73769C;
        @text-pur-color:#8F798E;
        @text-yel-color:#A48974;
        @text-red-color:#8B1D39;
        @text-brown-color:#874B0C;
        .text-purple-color{
            color: @text-purple-color;
        }
        .text-pur-color{
            color:#8F798E;
        }
        .text-yel-color{
            color: @text-yel-color;
        }
        .text-red-color{
            color: @text-red-color;
        }
        .text-brown-color{
            color:#874B0C;
        }
        .min{
            transform: scale(0.9);
        }
        width: 100%;
        padding-right: @app-padding-side;
        height: 100%;
        .bcg{
            z-index: 301;
            background: rgba(0, 0, 0, 0.65);
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            .mark{
               width: 100vw;
                height: 100vh; 
            }
            .c1{
                .background-img-max(url('@{assetsUrlV2_0}/task_center/c1.png'));
                position: absolute;
                transform: translateY(-10px);
                top: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 455px;
                height: 321px;
                .off-btn{
                    .background-img-max(url('@{assetsUrlV2_0}/task_center/d5.png'));
                    position: absolute;
                    top: 58px;
                    right: 3px;
                    width: 17px;
                    height: 17px;
                    cursor: pointer;
                    z-index: 1;
                } 
                .taskcenter-title{
                    position: absolute;
                    top: 48px;
                    left: 188px;//+3
                }
                .head{
                    width: 100%;
                    padding: 0 23px;
                    position: absolute;
                    top: 81px;
                    display: flex;
                    justify-content: space-between;
                    .item{
                        // cursor: pointer;
                        width: 45px;
                        height: 17px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .c2_1{
                        .background-img-max(url('@{assetsUrlV2_0}/task_center/c2_1.png'));
                        color: #412F72;
                    }
                    .c2_2{
                        .background-img-max(url('@{assetsUrlV2_0}/task_center/c2_2.png'));
                        color: #424759;
                    }
                    .c2_0{
                        .background-img-max(url('@{assetsUrlV2_0}/task_center/c2_0.png'));
                        color: #A55D10;
                        .c3{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/c3.png'));
                            width: 16px;
                            height: 13px;
                            position: absolute;
                            top: -2px;
                            right: -2px;
                        }
                    }
                }
                .sign-tip{
                    position: absolute;
                    top: 114px;
                    right: 111px;
                }
                .sign.c4{
                    cursor: pointer;
                    .background-img-max(url('@{assetsUrlV2_0}/task_center/c4.png'));
                    width: 78px;
                    height: 22px;
                    position: absolute;
                    top: 102px;
                    right: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .content{
                    position: absolute;
                    width: 397px;
                    top: 146px;
                    left: 30px;
                    .c5{
                        .background-img-max(url('@{assetsUrlV2_0}/task_center/c5.png'));
                        width: 397px;
                        height: 39px;
                        position: relative;
                        .l{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/c7.png'));
                            width: 49px;
                            height: 35px;
                            position: absolute;
                            left: 1px;
                            z-index: 1;
                        }
                        .c7{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/c7.png'));
                        }
                        .c9{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/c9.png'));
                        }
                        .c10{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/c10.png'));
                        }
                        .c11{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/c11.png'));
                        }
                        .c6{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/c6.png'));
                            width: 366px;
                            height: 28px;
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            .con-text{
                                position: absolute;
                                width: 366px;
                                height: 28px;
                                padding-left: 25px;
                                display: flex;
                                align-items: center;
                                .o{
                                    // width: 100px;
                                    // position: absolute;
                                    color: #493D5C;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                }
                                .t{
                                    display: inline-block;
                                    width: 175px;
                                    margin-left: 18px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    line-height: 12px;
                                }
                            }
                            .btn{
                                width: 60px;
                                height: 21px;
                                position: absolute;
                                top: 4px;
                                right: 6px;
                                .txt{
                                    font-size: 13px;
                                    width: 61px;
                                    height: 22px;
                                    line-height: 22px;
                                    text-align: center;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
        /deep/.com-confirm-button{
            width: 100%;
            height: 100%;
        }
        .gray{
            filter: grayscale(1);
        }
    }
</style>