<!-- -->
<template>
    <div class='page-certificate-rule flex-center mind mind-protocol' v-if="show">
        <div class="bcg">
            <!-- <div class="mark" @click="close()"></div> -->
            <div class="d1">
                <!-- <div class="off-btn" @click="close()"></div> -->
                <div class="stone-record-title flex-center">
                    <span class="rule-head-title bold text-purple-color">{{"协议"}}</span>
                </div>
                <div class="items mini-scrollbar" 
                ref="commentContent"
                >
                    <div ref="commentBox">
                        <div class="item" v-for="(v,i) in ruleList" :key="i">
                            <div class="con" v-if="i==0||i==1">
                                <span :class="{t:i==0||i==1,'tip':i==1}" class="rule-title-size bold text-brown-color">{{v.chapter}}</span>
                                <span class="rule-title-size bold text-brown-color r">{{v.level}}</span>
                            </div>
                            <table class="content" cellspacing="0" v-else>
                                <div class="row">
                                    <span :class="{t:i==0||i==1,}" class="text-normal bold text-brown-color">{{v.chapter}}</span>
                                    <span class="text-normal bold text-brown-color r">{{v.level}}</span>
                                </div>
                            </table>
                        </div>
                        <div class="foot-button" :class="{filter: countDown}">
                            <sl-button :type="'confirm'" @click="confirmHandle">
                                <span class="text-normal-aa" v-if="countDown">{{`确认(${countDown})`}}</span>
                                <span class="text-normal-aa" v-else>{{`确认`}}</span>
                            </sl-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import com from '@/views/certificate/components/rule.vue'

    export default {
        name: '',
        extends: com,
        props: {
        },
        data() {
            return {
                ruleList:[
                    {chapter: "我是协议我是协议我是协议"},
                    // {chapter: "我是协议我是协议我是协议"},
                ],
                seconds: 10,
                timer: null,
            };
        },
        methods: {
            open(){
                this.show = true
                this.seconds = 10
                this.setProtocolTimer()
            },
            close(){
                this.show = false
            },
            confirmHandle(){
                if(this.countDown) return
                this.activateFun()
                this.close()
            },
            setProtocolTimer(){
                this.clearTimer()
                this.timer = setInterval(() => {
                    this.seconds--
                    if (this.seconds === 0) this.clearTimer()
                }, 1000)
            },
            clearTimer(){
                clearInterval(this.timer)
            },
            activateFun(){
                // 激活请求 
                this.$emit('takeFun', { fun: 'postCardActivate' })
                this.close()
            }
        },
        created() {
        },
        components: {
        },
        computed: {
            countDown() {
                return this.seconds > 0 ? `${this.seconds}` : '';
            },
        },
        watch: {
        },
        mounted() {
            document.body.appendChild(this.$el);
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.clearTimer()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>

<style lang='less' src="@/views/certificate/components/rule.less"></style>
<style lang="less">
.page-certificate-rule.mind {
    .bcg {
        .d1 {
            .background-img-max(url('@{assetsUrlMind}/img/global/c1_2.png'));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 418px;
            height: 273px;
            .stone-record-title {
                position: absolute;
                top: 2px;
                width: 80px;
                height: 30px;
                left: 0;
                right: 0;
                margin: auto;
                .rule-head-title{
                    font-size: 13px;
                }
            }
            .items {
                position: absolute;
                top: 31px;
                left: 23px;
                width: 385px;
                height: 228px;
                padding: 6px 0 20px 0;
                .foot-button{
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 4px;
                    left: 0;
                    right: 0;
                }
                .item {
                    padding: 0 16px 0 0;
                    .con {
                        width: 100%;
                        padding: 0;
                        .rule-title-size{
                            font-size: 12px;
                            line-height: 18px;
                        }
                        .tip{
                            padding: 14px 0 6px 0;
                            font-size: 11px;
                        }
                    }
                    .content{
                        width: 100%;
                        border-color: #978179;
                        padding: 0 26px;
                    }
                    .row{
                        width: 100%;
                        display: flex;
                        span{
                            width: 50%;
                            text-align: center; 
                            height: 30px;
                            line-height: 30px;
                            border-top: 2px solid #978179;
                            border-left: 2px solid #978179;
                        }
                        span:nth-of-type(even){
                            border-right: 2px solid #978179;
                        }
                    }
                }
                .item:nth-of-type(even){
                    .row{
                        background: #FFFFFF;
                    }
                }
                .item:nth-of-type(odd){
                    .row{
                        background: #FBF3EE;
                    }
                }
                .item:nth-last-child(1){
                    .row:nth-last-child(1),.row:nth-last-child(2){
                        span{
                            border-bottom: 2px solid #978179;
                        }
                    }
                }
            }
        }
    }
    // 滚动条
    .mini-scrollbar::-webkit-scrollbar {
        width: 8px;
    }
}
.mind-protocol{
    position: fixed;
    z-index: 2020;
    .filter{
        filter: grayscale(1);
    }
}
</style>