<!--  -->
<template>
    <div class='com-global-avatar-pet flex-center'>
        <div class="com-global-pet-box">
            <pet :type.sync="type" :pageName.sync="pageName" :scaleNum="scaleNum" :gif.sync="gif" :petTake.sync="petTake" :userInfoProp.sync="userInfo"></pet>
        </div>
        <div class="com-global-avatar-box" :class="{'cursor-pointer': avatarPointer}" @click="$emit('click')">
            <avatar :type.sync="type" :pageName.sync="pageName" :gif.sync="gif" :userInfoProp.sync="userInfo"></avatar>
            <div class="avatar-notice" v-if="avatarText">
                <span class="text-normal bold flex-center nowrap">{{avatarText}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import pet from '@/views/user/components/pet.vue'

    export default {
        name: '',
        props: {
            type: String,
            pageName: String,
            scaleNum: Number,
            gif: Boolean,
            avatarPointer: Boolean,
            avatarText: String,
            userInfo: Object,
            petTake: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
            pet
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-avatar-pet{
        // justify-content: flex-end;
        align-items: flex-end;
        .com-global-pet-box{
            margin-bottom: -30px;
        }
        .com-global-avatar-box{
            position: relative;
            .avatar-notice{
                position: absolute;
                width: 69px;
                height: 30px;
                padding: 5px 0px 0px;
                text-align: center;
                color: #F38932;
                left: -5px;
                bottom: 192px;
                transform: translateX(-100%);
                .background-img-max(url('@{assetsUrlV2_0}/user/c35.png'));
            }
        }
        
    }
</style>