import commonApi from '../../_EXT_API'
import { singleProxy } from '@/global/js/exportEnter'

class game extends commonApi {
    constructor(opt) {
        opt = opt || {}
        let localKey = 'activate'
        let name = 'activate'
        opt = { localKey, name }
        super(opt)
    }
    // 激活年卡
    async postCardActivate(opt) {
        opt = opt || {}
        let options = {
            method: 'post',
            ...opt,
            data: {
                ...opt,
            },
        }
        let res = await this.requestAction('postCardActivate', options)
        let {code} = res || {}
        let message = this._errorCode.activate[code]
        if(message){
            await this._common.confirmPro.open({
                message,
                confirmType: 'c3',
                buttons: [
                    {title: '确定'}
                ]
            }) 
        }
        return res
    }
}

export default singleProxy(game)