
import home from './home'
import game from './game'
import personal from './personal'
import task from './task'
import subject from './subject'
import mistakes from './mistakes'
import user from './user'
import avatar from './avatar'
import gameReview from './gameReview'
import cabin from './cabin'
import mall from './mall'

export default {
    'components': {
        t1: '确定要退出吗？',
        t2: '登录',
        t3: '学习档案',
        t4: '恭喜胜利',
        t5: '对战失败',
        t6: '吃',
        t7: '子',
        t8: '再战一局',
        t9: '退出',
        t10: '和棋',
        t11: '吃子数',
        t12: '123',
        t13: '手数',
        t14: '对战结束',
        capture_9_nr: '40手无胜负，和棋',
        capture_13_nr: '60手无胜负，和棋',
        nt_lose: '我方超时负',
        nt_win: '对方超时负',
        t15: '下一题',
        t16: '确认落子',
        t17: '确认',
        t18: '开始保持手机横屏状态吧~',
        t19: '状态值',
        'pet-food': '宠物零食',
        'pet feed confirm': '确认花费{price}棋石购买宠物零食',
        'dress purchased': '已拥有',
        'dress disabled': '很遗憾，该服装暂时不支持当前形象',
        'dress have used': '当前已穿戴',
        'background music': '背景音乐',
        'play voice': '落子音',
        'all voice': '音乐音效',
        'play confirm': '落子确认',
    },
    'common': {
        notice: '提示',
        'purchase success': '购买成功',
        'save success': '保存成功！',
        'balance not enough': '很遗憾，你的棋石暂时不够哦TT',
        'mind balance not enough': '很遗憾，你的思维币暂时不够哦TT',
        'pet status is max': '{name}吃饱啦，明天再来喂养吧～',
        'is null note': '暂无错题',
        'quit confirm exercise': '作业还未完成哦，中途退出不会保留作答记录，请再坚持一下吧！',
        'quit confirm puzzle exercise': '当前关卡还有题目没有完成TT，退出后不会保留闯关记录，不再加油坚持一下了吗？',
        'token is invalid login': '登录信息已失效，请退出重新进入'
    },
    'check': {
        t1: '开始保持手机横屏状态吧~'
    },
    'exercise': {
        title: '答题'
    },
    'pet': {
        'select pet title': '你是否愿意领养一只宠物？',
        'purchase notice': '请给可爱的宠物起个名字吧！',
        'pet name notice':'宠物名称最多不可以超过6个字',
        'purchase price notice': '重新领养宠物将花费{price}棋石',
        'name disabled': '名字最小{min}个字符，最大{max}个字符哦~',
        'purchase success': '领取成功',
        'feed success': '喂食成功！',
        'take pet new': '来领养一只宠物吧！',
        'take pet re': '你已经超过7天没有喂养了，{petName}离开了。<br />点击此处重新领养。',
        'claim pet again confirm': '重新领养宠物需花费{price}棋石，是否确认重新领养'

    },
    'certWindow': {
        'certificate level get': '恭喜你获得{level}级证书！再接再厉！',
        'certificate date get': '获得时间   {date}'
    },
    'certSingle': {
        t1: 'OF ACHIEVEMENT',
        t2: 'THIS CERTIFICATE IS PROUDLY PRESENTED TO',
        t3: '高途围棋',
        content1: '不懈努力，棋力精进，经高途围棋教研组评定，已达到',
        content2: '水平，特颁此证，以资鼓励'
    },
    'share': {
        'share client error': '该功能仅支持在高途应用上使用'
    },
    'cabin-summary': {
        'no clazz number': '您当前没有权限，请联系您的二讲老师获取'
    },
    'video': {
        'award': '恭喜成功完成学习任务，特奖励你棋石{value}个'
    },
    ...home,
    ...game,
    ...personal,
    ...task,
    ...subject,
    ...mistakes,
    ...user,
    ...avatar,
    ...gameReview,
    ...cabin,
    ...mall,
}