import Vue from 'vue'

// Vue.filter('decimalsToFractional', decimals => {
//     if (isNaN(Number(decimals))) return decimals
//     decimals = Number(decimals)
//     let intNum = parseInt(decimals)
//     if (intNum <= 0) intNum = ''
//     else {
//         decimals = decimals % intNum
//         intNum = `${intNum}`
//     }
//     let formatDecimals = decimals.toFixed(2);
//     let denominator = 100;//初始化分母
//     let numerator = formatDecimals * 100;//初始化分子
//     let bigger = 0;
//     function recursion() {
//         bigger = denominator > numerator ? denominator : numerator;
//         for (let i = bigger; i > 1; i--) {
//             if (Number.isInteger(numerator / i) && Number.isInteger(denominator / i)) {
//                 numerator = numerator / i;
//                 denominator = denominator / i;
//                 recursion();
//             }
//         }
//     }
//     recursion();
//     let tem = ''
//     if (numerator <= 0 || denominator <= 0) {
//         numerator = ''
//         denominator = ''
//         tem = ''
//     } else {
//         if (intNum > 0) intNum = `${intNum}又`
//         tem = `${numerator}/${denominator}`
//     }
//     return `${intNum}${tem}`
// })

const filterList = {
    decimalsToFractional(decimals){
        if (isNaN(Number(decimals))) return decimals
        decimals = Number(decimals)
        let intNum = parseInt(decimals)
        if (intNum <= 0) intNum = ''
        else {
            decimals = decimals % intNum
            intNum = `${intNum}`
        }
        let formatDecimals = decimals.toFixed(2);
        let denominator = 100;//初始化分母
        let numerator = formatDecimals * 100;//初始化分子
        let bigger = 0;
        function recursion() {
            bigger = denominator > numerator ? denominator : numerator;
            for (let i = bigger; i > 1; i--) {
                if (Number.isInteger(numerator / i) && Number.isInteger(denominator / i)) {
                    numerator = numerator / i;
                    denominator = denominator / i;
                    recursion();
                }
            }
        }
        recursion();
        let tem = ''
        if (numerator <= 0 || denominator <= 0) {
            numerator = ''
            denominator = ''
            tem = ''
        } else {
            if (intNum > 0) intNum = `${intNum}又`
            tem = `${numerator}/${denominator}`
        }
        return `${intNum}${tem}`
    },
    winrate(val){
        let tem = Number(val)
        if(isNaN(tem)) return val
        val = tem
        return val.toFixed(1)
    },
    fixed0(val){
        let tem = Number(val)
        if(isNaN(tem)) return val
        val = tem
        return val.toFixed(0)
    }
}

for(let i in filterList){
    Vue.filter(i, filterList[i])
}