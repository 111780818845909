export default {
	'personal-file': {
		t1: '的学习档案',
		t2: '近5局',
		t3: '近20局',
		t4: '全部',
		t5: '棋局统计',
		t6: '闯关统计',
		t7: '近7天',
		t8: '近30天',
		t9: '累计',
		t10: '胜率',
		t11: '正确率',
		t12: '全部',
	},
	'personal-summary': {
		t1: '的学习档案',
		t2: '棋局统计',
		t3: '闯关统计',
	}
}
