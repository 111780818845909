
export default {
    'getCertificateList': {
        url: '/town/school/user/certificate/{user_code}',
        serve: 'social'
    },
    'addCertificate': {
        // url: '/town/school/user/certificate/{school_id}/{user_code}',
        url: '/town/school/user/certificate/{user_code}',
        serve: 'social',
    },
    'getCertificate': {
        url: '/town/school/certificate/{user_code}',
        serve: 'social',
    },
    'uploadImage': {
        url: '/town/school/user/certificate/file/{user_code}',
        serve: 'social'
    }
}