export default {
    'Home': {
        t1: '闯关做题',
        t2: '挑战AI',
        t3: '我的作业',
        t4: '错题巩固',
        t5: '排行榜',
        t6: '消息',
        t7: '学习档案',
    },
}