
// import { Notification } from 'element-ui';

export default class eleNotice{
    constructor(){
        this.list = {}
    }
    open(opt){
        opt = opt || {}
        let {key, params} = opt
        this.objCheck(key)
        let data = Notification({
            ...params
        })
        if(key) this.objSet(key, data)
        return data
    }
    objSet(key, val){
        this.list[key] = val
    }
    objCheck(key){
        this.close(key)
    }
    close(key){
        if(!this.list[key]) return
        this.list[key].close()
    }
    closeAll(){
        Notification.closeAll()
    }
}
