<template>
    <div class="mind-activate" v-show="show">
        <!-- 兑换码弹窗 -->
        <confirmPro ref="activateConfirm" class="activate-notice">
            <div class="pet-confirm-notice">
                <div class="notice-title">
                    <span class="text-normal-aa bold">{{'请输入激活码：'}}</span>
                </div>
                <!-- <div class="notice-sub-title"> -->
                    <span class="text-mini-bb"></span>
                <!-- </div> -->
                <div class="notice-content flex-center">
                    <div class="input-body">
                        <div class="input-icon"></div>
                        <input v-model="value" class="name-input-area" />
                        <!-- <div class="error-msg" v-if="valueFlag">
                            <span class="text-mini-bb text-error-color">{{confirmErrorMsg}}</span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="pro-foot" name="foot" :class="{'n1': buttons.length <= 1}">
                <div class="foot-button" v-for="v in buttons" :key="v.label">
                    <sl-button class="scale-button" @click="clickHandle(v)" :type="v.type">
                        <span class="text-normal-aa">{{v.title}}</span>
                    </sl-button>
                </div>
            </div>
        </confirmPro>
        <protocol ref="protocol" @takeFun="postCardActivate"></protocol>
    </div>
</template>
<script>
import userApiClass from '@/API/page/user'
import API from '@/API/page/mind/activate'
import protocol from './protocol.vue'
export default {
    name: '',
    props: {},
    data() {
        return {
            userApi: new userApiClass(),
            api: new API(),
            show: false,
            value: null,
            confirmErrorMsg: '请输入激活码！',
            buttons: [
                {title: '确认', label: 0, type: 'confirm'},
            ],
        };
    },
    methods: {
        async open(){
            this.show = true;
            this.exchangeNotice()
        },
        close(){
            this.show = false;
        },
        confirm(){
            this.show = false;
        },
        takeFun(val){
            val = val || {}
            if(val.fun) this[val.fun](val.data)
        },
        async exchangeNotice(){
            let {needActivateFlag} = this
            let res = await this.$refs['activateConfirm'].open({
                confirmType: 'c3plus',
                showClose: !needActivateFlag,
            })
            if(!res) return 
            res = res || {}
            return res.label == 0
        },
        clickHandle(val){
            val = val || {}
            if(this.valueFlag) return
            if(val.label == 0){
                this.$refs['activateConfirm'].close()
                this.$refs['protocol'].open()
            }
        },
        async firstTime(){
            let res = await this.setUserAccount()
            if(!res) return
            let {needActivateFlag} = res || {}
            if(needActivateFlag) return this.open()
        },
        async postCardActivate(){
            let opt = {
                password: this.value,
                fullData: true,
                noError: true,
                user_code: this.userCode,
            }
            let res = await this.api.postCardActivate(opt)
            if(!res) return
            if(res.data == 'success')this.activateSuccess()
        },
        async setUserAccount(){
            let res = await this.userApi.userAccount({noLoad: true, noError: true})
            if(!res) return false
            return res
        },
        async activateSuccess(){
            await this._common.confirmPro.open({
                message: '激活成功',
                confirmType: 'c3',
                buttons: [
                    {title: '确定'}
                ]
            }) 
            this.$router.go(0)
        },
    },
    created() { 
    },
    components: {
        protocol,
    },
    computed: {
        valueFlag(){
            let {value = ''} = this
            if(value == null || !value.length) return true
            return false
        },
        userCode() {
            let { app: { usercode } = {} } = this.$store.state
            return usercode
        },
        needActivateFlag(){
            let {app: {needActivateFlag = false}} = this.$store.state
            return needActivateFlag
        },
    },
    watch: {},
    mounted() { 
        document.body.appendChild(this.$el);
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
    .mind-activate{
        // background: rgba(0, 0, 0, 0.5);
        // position: fixed;
        // top: 0px;
        // left: 0px;
        // width: 100vw;
        // height: 100vh;
        // z-index: 1111;
    }


    .com-confirm-pro.activate-notice{
        /deep/.van-dialog.c3plus .gotu-confirm-pro{
            padding-top: 40px;
        }
        .notice-title{
            color: #6F5E65;
            height: 13px;
        }
        .notice-price-title{
            color: #6F5E65;
            margin-top: 5px;;
        }
        .notice-sub-title{
            margin: 5px 0px;
            color: #95948F;
        }
        span[class^='text-']{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .notice-content{
            .input-body{
                position: relative;
            }
            .error-msg{
                position: absolute;
                top: 20px;
                left: 0px;
                width: 100%;
                white-space: nowrap;
            }
            .input-icon{
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 4px;
                margin: auto 0px;
                width: 9px;
                height: 12px;
                .background-img-max(url('@{assetsUrlV2_0}/avatar/c32.png'));
            }
        }
        .name-input-area{
            padding: 3px 3px 3px 17px;
            color: #6F5E65;
            width: 131px;
            height: 17px;
            background: #D6D5CF;
            border: 0px;
            border-radius: 4px;
            text-align: left;

        }
    }
</style>