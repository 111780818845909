<!--  -->
<template>
    <div class='time-se'>
        <span class="txt">{{secondVal}}秒 | {{numberVal}}次</span>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            second: Number,
            number: Number
        },
        data() {
            return {
                secondVal: 0,
                numberVal: 0
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {
            second: {
                handler(){
                    this.secondVal = this.second || 0
                },
                deep: true,
                immediate: true
            },
            number: {
                handler(){
                    this.numberVal = this.number || 0
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >

</style>