<!-- -->
<template>
    <div class='page-stone-record-summary flex-center' v-if="show">
        <div class="bcg">
            <div class="mark" @click="close()"></div>
            <div class="d1">
                <div class="off-btn" @click="close()"></div>
                <div class="stone-record-title flex-center">
                    <span class="text-normal bold text-purple-color">{{"棋石记录"}}</span>
                </div>
                <div class="items-empty" v-if="recordLength==0 && loadingFlag">
                    <span class="stone-record-size bold text-brown-color">{{"当前记录还是空的,"}}</span>
                    <span class="stone-record-size bold text-brown-color">{{"快去对弈吧~"}}</span>
                </div>
                <div class="items mini-scrollbar" 
                @scroll="scrolLoad"
                ref="commentContent"
                v-else>
                    <div ref="commentBox">
                        <div class="item" v-for="(v,i) in recordList" :key="i">
                            <div class="con">
                                <span class="stone-record-size bold text-brown-color l">{{v.date}}</span>
                                <span class="stone-record-size bold text-brown-color c">{{v.name}}</span>
                                <span class="stone-record-size bold text-brown-color r">{{v.value}}</span>
                            </div>
                            <div class="d3"></div>
                        </div>
                    </div>
                    <loading v-show="isLoading" :loading.sync="loading" :color="`#CABCAE`"></loading>
                </div>
                <div class="earn-stone scale-button" @click="taskOpen">
                    <div class="d4 flex-center">
                        <confirmPlay>
                            <span class="text-normal-ab bold">{{"去赚棋石"}}</span>  
                        </confirmPlay>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClass from '@/API/page/taskcenter'
import loading from '../views/history/components/loading.vue'
    export default {
        name: '',
        props: {
            appStyle: Object,
        },
        data() {
            return {
                api: new apiClass(),
                recordList:[],
                page:0,
                size:10,
                loading: false,
                show:false,
                recordTotal:0,
                loadingFlag: false,
            };
        },
        methods: {
            pageInit(){
                this.page = 0
                this.stoneRecord()
            },
            taskOpen(){
                this.close()
                this._common.taskCenter()
            },
            open(){
                this.show = true
                this.pageInit()
            },
            close(){
                this.show = false
            },
            async scrolLoad(){
                let {usercode: user_code,page,size,recordLength:length,recordTotal:total} = this
                if(this.loading == true) return
                if(!this.$refs['commentContent']) return
                let index = this.$refs['commentContent'].scrollTop
                let commentContent = this.$refs['commentContent'].offsetHeight
                let commentBox = this.$refs['commentBox'].offsetHeight
                if(commentBox - index + 31 > commentContent) return
                this.loading = true
                if(length==total){
                    this.loading = false
                    return
                }
                page++
                let res = await this.api.stoneRecord({user_code,page,size, noLoad: true})
                if(res){
                    let {content:list,total} = res
                    this.recordTotal = total
                    if(list.length){
                        this.page = page
                        let r = this.dataHandle(list)
                        this.recordList = this.recordList.concat(r)
                    }
                }
                this.loading = false
            },
            dataHandle(val){
                let list = val || {}
                let r = []
                for (const i of list) {
                    let {recordType,value,name} = i
                    let {year,month,day} = i.createTime.date
                    if(month<10) month = '0'+ month
                    if(day<10) day = '0' + day
                    let date = year + '-' + month + '-' + day//日期
                    if(recordType==30||recordType==20) value = '+' + value
                    if(recordType==10) value = '-' + value
                    let info = {
                        date,
                        name,
                        value,
                    }
                    r.push(info)
                }
                return r
            },
            async stoneRecord(){
                let {usercode: user_code,page,size} = this
                let opt = {
                    user_code,
                    type:0,//0:默认;10:开销;20:签到;30:任务;
                    page,
                    size,
                }
                this.loadingFlag = false
                let res = await this.api.stoneRecord(opt)
                this.loadingFlag = true
                if(res){
                    let {content:list} = res || {}
                    this.recordList = this.dataHandle(list)
                }
            },
        },
        created() {
        },
        components: {
            loading,
        },
        computed: {
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            recordLength(){
                let {recordList} = this
                return recordList.length
            },
            isLoading(){
                let {recordLength,size} = this
                let rd =false 
                if(recordLength/size>=1) rd = true
                return rd
            }
        },
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
    @text-purple-color:#554B93;
    @text-brown-color:#978179;
    .text-brown-color{
        color: @text-brown-color;
    }
    .text-purple-color{
        color: @text-purple-color;
    }
    .stone-record-size{
        font-size: 13px;
    }
    .min{
        transform: scale(0.9);
    }
    .page-stone-record-summary{
        width: 100%;
        padding-right: @app-padding-side;
        height: 100%;
        .bcg{
            z-index: 301;
            background: rgba(0, 0, 0, 0.65);
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            .mark{
                width: 100vw;
                height: 100vh;
            }
            .d1{
                .background-img-max(url('@{assetsUrlV2_0}/task_center/d1.png'));
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 285px;
                height: 323px;
                .off-btn{
                    .background-img-max(url('@{assetsUrlV2_0}/task_center/d5.png'));
                    position: absolute;
                    top: 7px;
                    right: -2px;
                    width: 17px;
                    height: 17px;
                    cursor: pointer;
                    z-index: 1;
                }
                .stone-record-title{
                    position: absolute;
                    top: 10px;
                    width: 100%;
                }
                .items-empty{
                    position: absolute;
                    top: 36px;
                    left: 25px;
                    width: 235px;
                    height: 219px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .items{
                    position: absolute;
                    top: 36px;
                    left: 25px;
                    width: 235px;
                    height: 219px;
                    .head-box{
                        padding: 0px 10px;
                        display: flex;
                        justify-content: space-between;
                    }
                    .item{
                        padding: 0px 10px;
                        .con{
                            width: 215px;
                            padding: 5px 7px;
                            display: flex;
                            justify-content: space-between;
                            .l{
                                flex: 1.5;
                                text-align: left;
                            }
                            .c{
                                flex: 1.5;
                                text-align: center;
                            }
                            .r{
                                flex: 0.5;
                                text-align: right;
                            }
                        }
                        .d3{
                            .background-img-max(url('@{assetsUrlV2_0}/task_center/d3.png'));
                            width: 215px;
                            height: 1px;
                            margin-bottom: 7px;
                        }
                    }
                    
                }
                .earn-stone{
                    // .background-img-max(url('@{assetsUrlV2_0}/task_center/d4_0.png'));
                    width: 136px;
                    height: 45px;
                    position: absolute;
                    bottom: 15px;
                    left: 75px;
                    .d4{
                        // .background-img-max(url('@{assetsUrlV2_0}/task_center/d4.png'));
                        width: 131px;
                        height: 39px;
                        position: absolute;
                        top: 3px;
                        left: 3px;
                    }
                }
            }
        }
    }
    /deep/.com-confirm-button{
        width: 100%;
        height: 100%;
    }
    // 滚动条
    .mini-scrollbar::-webkit-scrollbar{
        width: 8px;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar-x::-webkit-scrollbar{
        height: 4px;
    }
    .mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb{
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        // background: rgba(0,0,0,0.2);
        .background-img-max(url('@{assetsUrlV2_0}/task_center/d2_0.png'));
        // background: #FFFAE6;
        // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
        // background-position: center;
        
    }
    .mini-scrollbar::-webkit-scrollbar-track-piece{
        // background: red;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar::-webkit-scrollbar-thumb::before{
        content: '';
        .background-img-max(url('@{assetsUrlV2_0}/task_center/d2_0.png'));
    }
    .mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track{
        // -webkit-box-shadow: inset 0 0 5px #C7946A;
        border-radius: 10px;
        // background: rgba(178,123,85,0.1);
        // background: #B27B55;
        background-image: linear-gradient(to right, #CABCAE, #DCD1C1, #CABCAE);
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar{
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>