import {Dialog} from 'vant'


export default class confirm {
    constructor(){
        
    }
    async open(opt){
        this.close()
        opt = opt || {}
        let {message} = opt
        let res = await Dialog.confirm({
            message,
            ...opt
        }).catch(err => err)
        return res == 'confirm'
    }
    close(){
        Dialog.close()
    }
}