
export default {
    'gameMeta': {
        url: '/games/meta/{username}/{gameId}',
        serve: 'engine'
    },
    'gameSgf': {
        url: '/games/{username}/{gameId}',
        serve: 'engine'
    },
    'reviewDataCreate': {
        url: '/games/report',
        serve: 'engine'
    },
    'reviewData': {
        url: '/games/report/{username}/{gameId}',
        serve: 'engine'
    }
}