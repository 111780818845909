import errorCode from './errorCode'
import common from './commonFun'
import {Dialog} from 'vant'
import {singleProxy} from './singleClass'

class errorHand {
    constructor(){
        this.errorCode = errorCode
        this._common = new common()
    }
    errorMsgGet(opt){
        opt = opt || {}
        let {code, msg, key} = opt
        let {errorCode} = this
        if(!errorCode[key]) key = 'main'
        let data = errorCode[key][code] || errorCode[key][msg] || errorCode[key]['default']
        return data
    }
    MessageOpen(opt){
        opt = opt || {}
        let {message} = opt
        Dialog.alert({
            message,
            showConfirmButton: false,
            closeOnClickOverlay: true
        })
        return Dialog
    }
    async hand(res, key, opt){
        opt = opt || {}
        res = res || {}
        let code
        let {msg} = res
        if(!this._common.isObject(res)) code = res
        else code = res.code
        if(code == 0) return
        let err_msg = this.errorMsgGet({code, key, msg})
        if(!err_msg) return
        opt.message = err_msg
        let dialog = this.MessageOpen(opt)
        await this._common.settimeout(1500)
        if(dialog && dialog.close) dialog.close()
    }
}

export default singleProxy(errorHand)
