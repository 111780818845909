export default {
    /*http*/
    //开始对弈
    gameStart: {
        url: '/wsgame/start/{gameId}'
    },
    //大厅登录
    'gamezoneLogin': {
        url: '/gamezone/login/{usercode}'
    },
    //大厅退出
    'gamezoneLogout': {
        url: '/gamezone/logout/{usercode}'
    },
    //登出游戏房间
    'gameroomLogout': {
        url: '/gameroom/logout/{gameroomId}'
    },
    //游戏房间登录
    'gameroomLogin': {
        url: '/gameroom/login/{gameroomId}'
    },
    //获得某人的净胜盘数
    'getGoalDifference': {
        url: '/infos/level/{username}'
    },
    //关闭接受邀请的开关
    'inviteDisable': {
        url: '/gamezone/game/invite/disable'
    },
    'disableInvite': {
        url: '/gamezone/game/invite/disable'
    },
    //打开可被邀请的开关
    'inviteAble': {
        url: '/gamezone/game/invite/enable'
    },
    'enableInvite': {
        url: '/gamezone/game/invite/enable'
    },
    //查看可被邀请状态
    'getSataus': {
        url: '/users/game/options/{usercode}'
    },
    //查询当前用户是否有未完成的对局 有的话进行重连
    'reconnect': {
        url: '/playroom/reconnect'
    },
    
    //广播数子接口返回的数据
    'broadcastJudge': {
        url: '/wsgame/game/judge/broadcast/{game_id}'
    },
    //悔棋 改变服务器中当前下棋用户的id
    'backMove': {
        url: '/wsgame/backmove/{gameId}'
    },
    //创建房间（待使用）
    'createRoom': {
        url: '/gameroom/game/active/{gameroom_id}'
    },
    //动作拒绝
    'actionReject': {
        url: "/wsgame/action/reject/{game_id}"
    },
    //动作接受
    'actionAccept': {
        url: "/wsgame/action/accept/{game_id}"
    },
    //请求动作 包括数子 悔棋
    'confirmAction': {
        url: "/wsgame/action/{game_id}"
    },
    //数子接口 其他模块的接口引用过来的
    'countStone': {
        url: '/dcnn/group/judge'
    },
    //领地
    'showArea': {
        url: '/dcnn/group/area'
    },
    //游戏结束的接口
    'gameEnd': {
        url: '/wsgame/game/end/{game_id}'
    },
    //获得房间用户列表
    'getRoomUserList': {
        url: '/gameroom/user/list/{gameroom_id}'
    },
    //获取用户房间
    'getUserGameroom': {
        url: '/gameroom/user/{usercode}',
        serve: 'social'
    },
    //获取观战房间
    'userWatchRoom': {
        url: '/gameroom/user/watch/{usercode}',
        serve: 'social'
    },
    //获得游戏详细信息
    'getGameDetail': {
        url: '/wsgame/game/meta/{game_id}'
    },
    //获得游戏时间（状态）
    'getGameState': {
        url: '/wsgame/game/state/{wsGameId}'
    },
    //发送当前盘面的手顺
    'genmove': {
        url: '/wsgame/genmove/{gameId}'
    },
    //获得房间信息(创建后获取房间信息)
    'gameroomInfo': {
        url: '/gameroom/info/{gameroomId}'
    },
    //拒绝协商游戏规则 请求后广播
    'rejectGameConfig': {
        url: '/gameroom/game/config/reject/{gameroom_id}'
    },
    //接受当前游戏规则
    'acceptGameConfig': {
        url: '/gameroom/game/config/accept/{gameroomId}'
    },
    //修改当前游戏规则
    'updateConfig':{
        url: '/gameroom/game/config/update/{gameroom_id}'
    },
    'updateGameConfig':{
        url: '/gameroom/game/config/update/{gameroomId}'
    },
    //发送游戏规则
    'sendGameConfig': {
        url: '/gameroom/game/config/{gameroomId}'
    },
    
    //接受邀请(创建房间)
    'acceptInvitation': {
        url: '/gamezone/game/invite/accept'
    },
    //invite ack
    'ackInvitation': {
        url: '/gamezone/game/invite/ack',
        serve: 'social'
    },
    //邀请用户游戏
    'inviteUserGame': {
        url: '/gamezone/game/invite'
    },
    //取消邀请用户进行对局
    'inviteCancelToGame': {
        url: '/gamezone/game/invite/cancel'
    },
    //拒绝邀请 请求后广播
    'refuseInvitation': {
        url: '/gamezone/game/invite/reject'
    },
    //对弈大厅 好友列表
    'getPlayRoomFriendList': {
        url: '/gamezone/user/follow/list'
    },
    //对弈大厅列表
    'getPlayRoomUserList': {
        url: '/gamezone/user/list',
        // login: false
    },
    'getFollowingUser': {
        url: '/gamezone/user/follow/list',
        serve: 'social'
    },
    //对局列表
    'getPlayRoomList': {
        url: '/wsgame/game/list'
    },
    //房间列表
    'getRoomList': {
        url: '/gameroom/list',
        login: false
    },
    //ai列表
    'getAIList': {
        url: '/ai_player/ai_level/list'
    },
    //获得聊天室的用户列表
    'getTopicUser': {
        url: '/chatroom/user/list/{topic_id}'
    },
    //获得该topic下的历史消息
    'getTopicMessage': {
        url: '/chatroom/message/list/{topic_id}'
    },
    //创建topic
    'createTopic': {
        url: '/chatroom/{chatroom_id}'
    },
    //设置用户状态
    'setUserStatus': {
        // url: '/gamezone/user/status/{usercode}'
        url: '/wsuser/status/{usercode}'
    },
    //ws-game用户信息
    'wsUserInfo': {
        url: '/wsuser/{usercode}',
        serve: 'social'
    },
    //对局用户信息
    'gamePlayer': {
        url: '/gameroom/player/list/{gameroom_id}',
        serve: 'social'
    },
    //结束对局
    'endGame': {
        url: '/wsgame/end/{game_id}',
        serve: 'social'
    },
    //对局信息
    'gameMeta': {
        url: '/wsgame/meta/{game_id}',
        serve: 'social'
    },
    //对局信息？
    'gameInfo': {
        url: '/wsgame/{game_id}',
        serve: 'social'
    },
    //准备
    'gameReady': {
        url: '/wsgame/ready/{game_id}',
        serve: 'social'
    },
    
    // 房间准备
    'gameroomReady': {
        url: '/gameroom/reserve/ready/{gameroomId}',
        serve: 'social'
    },
    // 房间取消准备
    'gameroomUnready': {
        url: '/gameroom/reserve/unready/{gameroomId}',
        serve: 'social'
    },
    //申请数子数据
    'applyJudge': {
        url: '/wsgame/judge/data/{game_id}',
        serve:  'social'
    },
    //邀请状态
    'gameOptions': {
        url: '/gamezone/user/options/{usercode}',
        serve:  'social'
    },

    //获取对弈棋谱
    'gameSgf': {
        // url: '/games/meta/gameroom/{username}/{gameroomId}',
        url: '/games/meta/gameroom/{gameroomId}/{usercode}',
        serve:  'engine'
    },

    // 匹配对局
    'gameTaste': {
        url: '/gamezone/game/taste/match/{userCode}',
        serve:  'social'
    },
    'gameTasteHeartbeat': {
        url: '/gamezone/game/taste/heartbeat/{userCode}',
        serve:  'social'
    },
    // 取消匹配对局
    'gameTasteCancel': {
        url: '/gamezone/game/taste/cancel/{userCode}',
        serve:  'social'
    },
    'userListSearch': {
        url: '/gamezone/user/nickname/search',
        serve:  'social'
    },
    'gameStateSet': {
        url: '/wsgame/state/{wsGameId}',
        serve:  'social'
    },
    'gameTimeSync': {
        url: '/wsgame/state/sync/{wsGameId}',
        serve:  'social'
    },
    //用户信息
    'gamezoneUserInfo': {
        url: '/gamezone/user/{userCode}',
        serve: 'social'
    },
    
    //续战
    'sendPlayAgain': {
        url: '/gameroom/game/renew/{gameroomId}',
        serve: 'social'
    },
    'rejectPlayAgain': {
        url: '/gameroom/game/renew/reject/{gameroomId}',
        serve: 'social'
    },
    'acceptPlayAgain': {
        url: '/gameroom/game/renew/accept/{gameroomId}',
        serve: 'social'
    },
    'replicateGame': {
        url: '/games/{username}/replicate',
        serve: 'engine'
    },
    'closeGameroom': {
        url: '/gameroom/close/{gameroomId}',
        serve: 'social'
    },
    'gameroomInfoByCode': {
        url: '/gameroom/info/code/{gameroomCode}'
    },
    'applyArea': {
        url: '/dcnn/group/public/area',
        serve: 'engine'
    },
}