<!--  -->
<template>
    <div class='com-stone'>
        <div class="stone-shadow position-center" v-if="shadow" :class="shadow"></div>
        <div class="stone-body" :class="color"></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            color: [String, Number],
            shadow: [String, Number]
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-stone{
        width: 20px;
        height: 20px;
        margin: 0px auto;
        position: relative;
        .stone-body{
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
        .stone-body.black{
            .background-img-max(url('@{assetsUrlV2_0}/board/black.png'));
        }
        .stone-body.white{
            .background-img-max(url('@{assetsUrlV2_0}/board/white.png'));
        }
        .stone-shadow{
            position: absolute;
            width: 137.5%;
            height: 137.5%;
            z-index: 0;
        }
        .stone-shadow.light{
            .background-img-max(url('@{assetsUrlV2_0}/board/stone_shadow_light.png'));
        }
    }
</style>