

export default {
    'main': {
        'default': '获取数据失败，请稍后重试',
        '6005': '用户名或密码错误',
        '71003': '题目错误，请退出或刷新重试',
        'invalid token': '登录信息已失效，请重新登录',
        '71026': '很遗憾，该服装暂时不支持当前形象',
        '3008': '该棋谱还没有生成复盘数据'
    },
    'app': {
        'load local data error': '加载本地数据失败，请假查local storage设置',
        'check token error': '身份验证失败，可能登录信息失效，请退出重新进入',
        'unknown auth error': '未知错误，请退出重新进入'
    },
    'avatar': {
        '71026': '很遗憾，该服装暂时不支持当前形象',
        'preview time out': '当前背景预览效果仅展示{amountTime}秒'
    },
    

    
    'defaultMsg': '获取数据失败，请检查网络或稍后再试',
    'common': {
        'default': '获取数据失败，请稍后重试',
        '6005': '用户名或密码错误',
        '71003': '题目错误，请退出或刷新重试',
        'invalid token': '登录信息已失效，请重新登录',
        '71026': '很遗憾，该服装暂时不支持当前形象',
        '3008': '该棋谱还没有生成复盘数据',
        "14001": "用户不存在",
        "14002": "用户已关注",
        "14003": "关注错误",
        "14004": "查询错误",
        "14005": "展示错误",
        "14006": "消息不存在",
        "14007": "删除消息失败",
        "14008": "用户不属于群组",
        "14009": "群组不存在",
        "14010": "权限不足",
        "14011": "用户已在群组",
        "14012": "更新失败",
        '16015': '用户忙碌中',
        '16004': '当前无权限操作',
        '11008': '密码错误',
        '16017': '对局已结束',
        '6032': '文件不符合要求，请重新选择',
        'new tap open': '新标签页已打开对弈界面',
        "16001": "用户不在线",
        "16002": "用户不存在",
        "16003": "密码错误",
        "16005": "准备状态异常,请重试",
        "16006": "目标对弈房间不存在",
        "16007": "房间不存在",
        "16008": "准备异常,请重新登录房间",
        "16009": "棋手状态异常",
        "16010": "计时器异常",
        "16011": "用户未全部准备",
        "16012": "对局不存在",
        "16013": "无权限落子",
        "16014": "落子非法传参",
        "16016": "不允许悔棋",
        "16018": "棋局未落子",
        "16019": "落子异常",
        "16020": "棋盘大小非法传参",
        "16021": "让子数非法传参",
        "16022": "保留时间非法传参",
        "16023": "读秒时间非法传参",
        "16024": "读秒次数非法传参",
        "16025": "申请模块非法传参",
        "16026": "您已在房间中",
        "16027": "贴目非法传参",
        "16031": "双方已存在对局",
    },
    'game': {
        'invite title for window': '用户{nickname}向您发出对局邀请',
        'wait invite title for window': '已向{nickname}发出对局邀请，等待对方接受中',
        'wait invite title smple': '你正在邀请{nickname}对弈',
        'wait invite title for toast': '已发送对局邀请，等待对方同意',
        'invite cancel': '用户{nickname}已取消对局邀请',
        'invite reject': '{nickname}拒绝了邀请',
        'game room has closed': '房间已关闭',
        'game room has result': '对局已结束',
        'send before ask': '确定发送对局申请吗？发送后将不允许修改对局规则',
        'confirm game rule': '对局规则已更改，请确认',
        'rule not changed': '对局规则没有修改，是否直接开始对局？',
        'player has logout': '您的对局者已退出房间',
        'genmove error 1': '网络错误，请检查网络',
        'genmove error 3': '网络错误，请检查网络',

        // 'invateWaitAck': '连接中',

        'undo confirm': '是否申请悔棋？',
        'undo waiting': '申请悔棋，等待对方同意',
        'undo confirm receive': '是否同意对方的悔棋申请？',
        'undo confirm accept': '对方已同意您的悔棋申请',
        'undo confirm reject': '对方拒绝悔棋',

        'apply judge confirm': '是否申请数子？',
        'apply judge waiting': '申请数子，等待对方同意',
        'apply judge confirm receive': '是否同意对方的数子申请？',
        'apply judge confirm accept': '对方已同意您的数子申请',
        'apply judge confirm reject': '对方拒绝协商数子，可以收完单官后AI数子',
        'apply judge reject': '对方不接受AI建议结果，继续对局',
        'apply judge reject own': '继续对局',

        'apply draw confirm': '是否申请和棋？',
        'apply draw waiting': '申请和棋，等待对方同意',
        'apply draw confirm receive': '是否同意对方的和棋申请？',
        'apply draw confirm accept': '对方已同意您的和棋申请',
        'apply draw confirm reject': '对方拒绝和棋',

        'game judge confirm': '是否申请AI数子？',
        'game judge confirm compel': '是否申请强制终局？',
        'game judge continue': '尚未终局，请继续落子',
        'game judge continue game': 'AI无法判定当前局面，请收完单官或350手之后，再申请AI数子',

        'resign confirm': '要认输吗？',

        'quit config notice': '确定取消对局？',
        'user reject game rule': '对方已退出',
        'action await time out': '等待超时',

        'user is busy own': '当前忙碌，无法发送对局邀请',
        'user is busy other': '对方忙碌中，无法发送对局邀请',

        'invite busy own': '您当前忙碌中',
        'invite busy other': '对方当前忙碌中，暂时无法邀请',

        'game room cant enter for user': '该房间暂时无法进入',

        'game has multied by app': '对局已被其他设备接管',
        'has game can multied': '您有一盘对局正在进行，是否接管',

        'all user is loaded': '已全部加载完',
        'user disconnect': '对方已断开连接，{timeout}秒后将自动退出',
        'disconnect quit': '对方已断线',
        // 'reject use up': '对方已连续拒绝{num}次，暂时无法使用{name}',
        'reject use up': '已连续使用{num}次{name}，暂时无法使用',
        'probs use up': '{name}最多可使用{num}次，已用尽',

        'user color notice': '本方执{color}',
        'opponent passed': '对方停一手',
        'opponent logout': '对方已离开',
        'opponent login': '对方已重连',
        'invalid game': '不超过{moveNum}手结束，对局无效',
        'need login for watch': '登录后可旁观对局',
        'need login for match': '登录后可快速匹配对局',
        'auto end game notice': '双方第一手请在{moveNum}秒内落子',

        'has busy status on other device': '你在其他设备有一盘进行中的对局，请结束后再操作',
        'has busy function common 0': '你在其他设备有一盘进行中的对局或研究，请结束后再操作',
        'has busy function engine 0': '你在其他设备上正在进行研究，请结束后再操作',
        'has busy function game 0': '你在其他设备有一盘进行中的对局，请结束后再操作',
        'has busy function taste 0': '你在其他设备有一盘进行中的对局，请结束后再操作',

        'has busy function common 1': '你有一盘进行中的对局或研究，请结束后再操作',
        'has busy function engine 1': '你有一盘未结束的研究，请结束后再操作',
        'has busy function game 1': '你有一盘进行中的对局，请结束后再操作',
        'has busy function taste 1': '你有一盘进行中的对局，请结束后再操作',

        'double pass confirm': '连续两次停一手可能会结束对局，确定停一手吗？',
        'pass confirm': '确定要停一手吗？',
        'double pass game judge loading': '两次停一手，统计结果中...',

        'play again disabled': '双方等级差距过大，无法续战',
        'play again confirm': '是否接受 {nickname} {level} 的续战申请？',
        'game room route change': '正在{type}中，跳转将会判负，确定要离开吗？',
        'play again wait': '已发送邀请，等待对方同意中',
        'play again was rejected': '对方拒绝了您的续战邀请',
        'accept play again': '对方已同意您的续战申请',
        'reject play again': '对方拒绝了您的续战邀请',
        'cant share by game': '该对局无法分享',
        'play again by phone': '已在其他设备开始续战',
        'taste ask message': '未匹配成功，是否继续匹配？',
        'offline time notice': '已重连回对局，再断线{minute}分{second}秒将判负',
        'socket link timeout': '连接失败，请稍后重试',
        'AI resign 1': '你好厉害，我下不过你了，我要认输……',
        'AI resign 2': '我实在下不过你了，我认输还不行吗？',
        'AI resign 3': '顶不住了啊，让我认输吧！',
        'close room confirm': '确定要解散该房间吗？',
        'game room create quit owner': '退出将会解散该房间，确定要退出吗？',
        'game room user exceed limit': '已有小朋友在对局中，请你再另外创建房间呦！',
        'social game result title win': '恭喜胜利',
        'social game result title lose': '对局失败',
        'social game result title draw': '对局结束',
        'social game result title none': '对局结束',
        'none game id for report': '获取棋谱失败，请稍后重试',
        'capture game over': '对局结束，结果统计中',
        'game ready check': '连接中...',
        'is tasting': '匹配中',
        'taste remind time': '还有{time}秒匹配成功，请耐心等待',
        'taste fail': '匹配失败',
        'taste fail detail': '匹配失败，当前没有可匹配的对手，请稍后再试',
        'taste success': '匹配成功',
        'enter game countdown': '{time}秒后进入棋局',
        'taste disabled level': '你的品级还无法进入当前模块，请前往其他游戏模块多多训练哦~',
        'game ready check check status': '连接对局中，请稍等片刻',
        'taste matched error': '未查找到房间，可能房间已关闭，请重试',
        'network error': '数据获取失败，可能网络不佳',
        'taste max level confirm': '亲爱的小棋手，经围棋小镇委员会检测你已达到相应级别，此级别的练习不会增长星星，如果想增加星星到对应的难度级别匹配'
    },
    'stomp': {
        'connecting': '服务器连接中',
        'connect error': '连接服务器失败，请刷新页面重试',
        'connect error reconnect': '连接服务器失败，稍后将继续重新尝试连接',
        'connect success': '连接成功！'
    },
    'userInfo': {
        'default signature': '快去添加个性签名吧！',
        'photo file error': '请上传正确的文件，文件后缀为：{type}',
        'photo is too large': '您上传的文件过大，最大不能超过：{size}',
        'nickname limit': '昵称最长不能超过{max}个字符',
        'user logout': '已退出登录'
    },
    'board': {
        // 'delete more than one moves': '将会删除{number}手棋，确定要删除吗？'
        'delete more than one moves': '删除第{number}手及之后的所有手数？',
        'will clear by delete': '清除棋局所有手数？'
        
    },
    'analyse': {
        'next ratio popover off': '分配部分算力至实战手，并对其做出评价。',
        'next ratio popover on': '不再分配算力给实战手，也不展示实战手评价。',
        'game resource popover off': '分配部分算力，计算走势图、问题手和发挥水准。',
        'game resource popover on': '关闭全局计算，算力集中到当前盘面。',
        'global simulation popover': '全局计算量是棋局每一手计算量的总和。计算量越大数据越准确，上限{number}。',
        'clear notice': '清空当前盘面？'
    },
    'follow': {
        'confirm unfollow': '是否取消关注？',
        'confirm delete': '是否移除粉丝？'
    },
    'scrollLoad': {
        'all data has loaded': '已全部加载完'
    },
    'chat': {
        'violation title': '请选择举报理由',
        'violation success': '我们已收到您的举报，待核实后处理违规信息'
    },
    'task': {
        'daily received': '领取成功',
        'has received': '今日已领取'
    },
    'global': {
        'socket is disconnect': '连接已断开，正在重连中...'
    },
    'gameReview': {
        'try play on': '开始试下',
        'try play off': '试下结束',
        'create report confirm': 'AI复盘将会消耗复盘次数，今日剩余次数：{remindNum}',
        'create report sub message': '今日次数({amount}次)已用尽，可用{price}棋石兑换'
    },
    'mall': {
        'confirm purchased': '活动期间仅限兑换1个周边商品，是否确认购买{goodsName}物品，当前物品消耗{price}思维币',
        'confirm purchased tip': '购买成功后可主动告知二讲老师，物品将快递到你提供的地址，具体时间以二讲老师告知为准哦！',
        'purchased success': '购买成功',
        'stock double check': '周边商城实物礼品稀缺，请确定是否兑换，活动期间仅支持一个孩子一件商品，一经兑换概不退换',
        'stock purchased': '脑王商城实物礼品稀缺，请确定是否兑换，活动期间仅支持一个孩子一件商品，一经兑换概不退换',
        'stock double purchased': '每次活动期间仅限兑换一次，你已经兑换过{goodsName}了，下次活动再来选择喜欢的商品吧！',
        'stock not enough': '当前商品已经都被小朋友选走了，快去兑换其他商品吧！',
        // 'stock activity close': '兑换活动将在8月1日开启<br>快咨询二讲老师获取兑换码吧！',
        // 'stock activity expiration': '活动开放时间为8月1号10:00-9月10日24:00，时效已过期，等下次活动开始再来兑换吧！',
        'stock activity close': '活动开放时间为11月30号下午5点-12月6号下午24点，当前还无法兑换，快去攒思维币吧！',
        'stock activity expiration': '活动开放时间为11月30号下午5点-12月6号下午24点，时效已过期，等下次活动开始再来兑换吧！',
        '71033': '当前商品已经都被小朋友选走了，快去兑换其他商品吧！',
        // '71035': '兑换活动将在8月1日开启<br>快咨询二讲老师获取兑换码吧！',
        // '71036': '活动开放时间为8月1号10:00-9月10日24:00，时效已过期，等下次活动开始再来兑换吧！',
        '71035': '活动开放时间为11月30号下午5点-12月6号下午24点，当前还无法兑换，快去攒思维币吧！',
        '71036': '活动开放时间为11月30号下午5点-12月6号下午24点，时效已过期，等下次活动开始再来兑换吧！',
        '71041': '输入有误，如有疑问请联系二讲老师哦～',
        '71042': '兑换码已经被使用，如有疑问请联系二讲老师哦～',
        '71043': '活动已过期',
    },
    'activate': {
        '71039': '激活失败'
    },
    'jigsaw': {
        'restart confirm': '确定要重新开始吗？'
    }
}