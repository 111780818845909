<template>
    <div class="com-global-goal-difference flex-align-center">
        <div class="diff-item" v-for="v in list" :key="v"></div>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            value: [Number, String],
            level: [Number, String],
        },
        data() {
            return {
                userInfo: {}
            };
        },
        methods: {
            userInfoChange(){
                this.userInfo = this.$store.state.app
            },
        },
        created() {
            this._userHand.on('goalDifference', this.userInfoChange)
        },
        components: {},
        computed: {
            list(){
                let {levelCul: level, valueCul: value} = this
                let list = []
                for(let i = 0; i < value; i++){
                    list.push(value - i)
                }
                return list
            },
            levelCul(){
                let {userInfo: {level: userLevel}, level} = this
                if(level === undefined || level === null || !this._common.isNumber(level)) return userLevel
                return level
            },
            valueCul(){
                let {userInfo: {goalDifference}, value} = this
                let min = this.PARAMS.min_goal_difference
                let data = min
                if(value === undefined || value === null || !this._common.isNumber(value)) data =  goalDifference
                else data = value
                data = Math.max(data, min)
                if(isNaN(data)) data = min
                return data
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this._userHand.remove('goalDifference')
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-global-goal-difference{
        .diff-item{
            width: 12px;
            height: 11px;
            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/star.png'));
        }
    }
</style>