
// import musicSetting from '@/pages/mind/global/js/musicSetting.js'

//             musicSetting: new musicSetting(),

//             this.musicSetting.soundOpen('click','')
import voiceHand from '@/global/js/voiceHand.js'
import appDataClass from '@/global/js/appData'

export default class setting {
    constructor() {
        this._voiceHand = new voiceHand()
        this._appData = new appDataClass({ key: 'game' })
        this.userSetting = {
            'sudoku': { 'bcgMusic': 1, 'sound': 1 },
            'schulte': { 'bcgMusic': 1, 'sound': 1, },
            'arithmetic': { 'bcgMusic': 1, 'sound': 1, },
            'memory': { 'bcgMusic': 1, 'sound': 1, },
            'cubepuzzle': { 'bcgMusic': 1, 'sound': 1, },
        }
        this.list = {
            'game-sudoku-action': 'game sudoku action',
            'game-schulte-action': 'game schulte action',
            'game-arithmetic-action': 'game arithmetic action',
            'game-memory-action': 'game memory action',
            'game-cubepuzzle-action': 'game cubepuzzle action',
        }
        this.soundList = {
            'click': 'mind game click',
            'correct': 'mind game correct',
            'error': 'mind game error',
            'success': 'mind game success',
            'failed': 'mind game failed',
            'special': 'mind game special',
        }
    }
    soundOpen(name, currentGame, value) {
        let set = this.getUserSetting()
        let flag = value ? true : currentGame ? set[currentGame].sound : false
        if (!flag) return
        this.closeSoundAll()
        let { soundList: list } = this
        let key = list[name]
        if (!key) return
        this._voiceHand.normalPlay(key)
    }
    closeSoundAll() {
        let { soundList: list } = this
        for (let i in list) {
            this._voiceHand.normalStop(list[i])
        }
    }
    musicOpen(name) {
        let getUserSetting = this.getUserSetting()
        this.closeMusicAll()
        let key = this.list[name]
        if (!key) return
        this._voiceHand.normalPlay(key)
    }
    closeMusicAll() {
        let { list } = this
        for (let i in list) {
            this._voiceHand.normalStop(list[i])
        }
    }
    bcgMusicCheck(val, oldVal) {
        let { name } = val || {}, { name: oldName } = oldVal || {}
        if (name == oldName) return
        let set = this.getUserSetting()
        let musicName = ''
        if (set['sudoku'].bcgMusic && name == 'game-sudoku-action') musicName = name
        if (set['schulte'].bcgMusic && name == 'game-schulte-action') musicName = name
        if (set['arithmetic'].bcgMusic && name == 'game-arithmetic-action') musicName = name
        if (set['memory'].bcgMusic && name == 'game-memory-action') musicName = name
        if (set['cubepuzzle'].bcgMusic && name == 'game-cubepuzzle-action') musicName = name
        this.musicOpen(musicName)
    }
    getUserSetting(userSetting) {
        let data = this._appData.getItem('mind_game_setting') || {}
        let config = userSetting || this.userSetting
        for (let i in config) {
            if (data[i] === undefined) data[i] = config[i]
        }
        return data
    }
}