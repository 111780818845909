import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class user extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'user'
        let name = 'user'
        opt = {localKey, name}
        super(opt)
    }

    async petFeedGet(opt){
        opt = opt || {}
        // school_id = school_id || this.PARAMS.school_id
        opt = {
            ...opt,
            // school_id
        }
        let options = {
            method: "get",
            ...opt,
            data:{
            }
        }
        return await this.requestAction('petFeedGet', options)
    }

    async petList(opt){
        opt = opt || {}
        // school_id = school_id || this.PARAMS.school_id
        opt = {
            ...opt,
            // school_id
        }
        let options = {
            method: "get",
            ...opt,
            data:{
            }
        }
        let res = await this.requestAction('petList', options)
        if(res){
            for(let i in res){
                let {petUrlHungry, petUrlNormal} = res[i]
                try{
                    petUrlHungry = petUrlHungry.split(',')
                }catch(e) { console.log() }
                try{
                    petUrlNormal = JSON.parse(petUrlNormal)
                }catch(e) { console.log() }
                if(this._common.isObject(petUrlNormal)){
                    for(let j in petUrlNormal){
                        if(!petUrlNormal[j].indexOf || petUrlNormal[j].indexOf(',') <= -1) continue
                        try{
                            petUrlNormal[j] = petUrlNormal[j].split(',')
                        }catch(e){ console.log()}
                    }
                    res[i] = {
                        ...res[i],
                        ...petUrlNormal
                    }
                }
            }
        }
        return res
    }
    async feedPet(opt){
        opt = opt || {}
        let {user_code, foodId: food_id} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        // schoolId = schoolId || this.PARAMS.school_id
        opt = {
            ...opt,
            user_code
        }
        if(!food_id) return
        let options = {
            method: "post",
            ...opt,
            data:{
                // schoolId,
                food_id,
            }
        }
        return this.requestAction('feedPet', options)
    }
}

export default singleProxy(user)
