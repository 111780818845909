<!--  -->
<template>
    <div class='com-app-bcg'>
        <div class="bcg-img" :class="{'normal': !isToken, 'token': isToken}"></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {
                path: this.$route.path
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            isToken(){
                let {path = ''} = this
                return path.indexOf('token') > -1
            },
            background(){
                let {path} = this
                let img = `${this.PARAMS.assetsUrl}/img/app_bcg.png`
                if(path.indexOf('token') > -1) img = `${this.PARAMS.assetsUrl}/img/app_bcg_token.png`
                return img
            },
            sty(){
                let {background} = this
                background = `url(${background})`
                return {
                    background: background,
                    'background-size': '100% 100%'
                }
            }
        },
        watch: {
            $route: {
                handler(){
                    this.path = this.$route.path
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    // .com-app-bcg.normal{
    //     background: url('@{assetsUrl}/img/app_bcg.png');
    //     background-size: 100% 100%;
    // }
    // .com-app-bcg.token{
    //     background: url('@{assetsUrl}/img/app_bcg_token.png');
    //     background-size: 100% 100%;
    // }
    .com-app-bcg{
        // background: url('@{assetsUrl}/img/app_bcg.png');
        // background-size: 100% 100%;
        // opacity: 0.8;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        .bcg-img{
            width: 100%;
            height: 100%;
        }
        .bcg-img.normal{
            background: url('@{assetsUrl}/img/app_bcg.png');
            background-size: 100% 100%;
        }
        .bcg-img.token{
            background: url('@{assetsUrl}/img/app_bcg_token.png');
            background-size: 100% 100%;
        }
    }
</style>