<template>
    <div class="com-social-game-taste-window">
        <confirm-pro ref="tasteWin">
            <div class="taste-title align-center-position" slot="title" v-if="tasteSuccess">
                <span class="text-large-b bold">{{tasteStatusText}}</span>
            </div>
            <div class="taste-body" v-if="!tasteSuccess">
                <div class="taste-title"></div>
                <div class="taste-state text-center">
                    <span class="text-large-e bold">{{tasteStatusText}}</span>
                </div>
                <div class="taste-remind text-center">
                    <span class="text-normal-ab bold">{{tasteRemindText}}</span>
                </div>
                <div class="taste-ing-box taste-foot" v-if="isTasting">
                    <div class="taste-cancel-box scale-button" @click="cancel(false)">
                        <div class="body flex-center">
                            <span class="text-normal-ab bold">取消匹配</span>
                        </div>
                    </div>
                </div>
                <div class="taste-result-box taste-foot flex-center" v-if="isTasteResult">
                    <sl-button type="confirm" @click="restart">
                        <span class="text-normal-ab bold">重新匹配</span>
                    </sl-button>
                    <sl-button type="cancel" @click="cancel(false)">
                        <span class="text-normal-ab bold">取消</span>
                    </sl-button>
                </div>
            </div>
            
            <div class="taste-body taste-success" v-else>
                <div class="suc-item a">
                    <div class="a-i ia"></div>
                    <div class="a-i ib"></div>
                </div>
                <div class="suc-item b">
                    <div class="b-item left">
                        <avatar :gif="false" :userInfoProp.sync="blackUserInfo"></avatar>
                    </div>
                    <div class="b-item right">
                        <avatar :gif="false" :userInfoProp.sync="whiteUserInfo"></avatar>
                    </div>
                </div>
                <div class="suc-item c flex-center">
                    <div class="c-item countdown">
                        <span class="text-normal-aa bold">{{enterCountdownText}}</span>
                    </div>
                    <div class="c-item left name">
                        <nickname class="bold" :nickname="blackUserInfo.nickname"></nickname>
                    </div>
                    <div class="c-item label position-align-center-margin"></div>
                    <div class="c-item right name">
                        <nickname class="bold" :nickname="whiteUserInfo.nickname"></nickname>
                    </div>
                </div>
            </div>
        </confirm-pro>
    </div>
</template>
<script>
    import apiClass from '@/API/page/socialGame'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                response: null,
                maxWaitTime: this.PARAMS.taste_max_wait_time,
                // maxWaitTime: 10000,
                tasteMaxWait: this.PARAMS.taste_max_wait,
                tasteQueryTime: this.PARAMS.taste_query_time,
                successDelay: this.PARAMS.taste_success_delay,
                gameInfoCheckTime: this.PARAMS.game_info_check_time,
                remindTime: null,
                interval: null,
                gameInfo: {},
                active: false,
                tasteState: 0, // 0 -- 初始， 1 -- 正在匹配， 2 -- 匹配成功，3 -- 匹配失败
                gameTastOptions: null,
                peerUserInfo: {},
                gameMatchedInfo: {},
                enterCountdown: this.PARAMS.taste_enter_countdown,
                tasteOptions: {},
                countdownTime: null
            };
        },
        methods: {
            init(val){
                this.response = null
                this.countdownTime = null
                this.tasteOptions = {}
                this.peerUserInfo = {}
                this.gameMatchedInfo = {}
                this.gameTastOptions = null
                this.gameInfo = val || {}
                this.tasteState = 0
                this.enterCountdown = this.PARAMS.taste_enter_countdown
                this.remindTimeReset()
            },
            remindTimeReset(){
                this.remindTime = this.maxWaitTime
            },
            async open(val, extend){
                let {restart} = extend || {}
                this.init(val)
                let roomState = await this.roomCheck({init: true})
                if(roomState) return this.close(true)
                this.tasteOptions = this._common.deepCopy(val)
                let confirmState = await this.userLevelCheck(val)
                if(!confirmState) return
                let state = await this.tasteStart()
                if(!state) return false
                if(!restart) this.windowOpen()
                this.countdownTime = (new Date()).getTime()
                this.countdownStart(this.countdownTime)
                if(val.response) return true
                return new Promise(res => { this.response = res})
            },
            windowOpen(){
                this.closeOnly()
                let {confirmType} = this
                this.$refs.tasteWin.open({
                    confirmType,
                    showClose: false
                })
                this.active = true
            },
            close(res){
                this.closeOnly()
                this.active = false
                if(this.response) this.response(res)
                // this.voiceStop()
            },
            closeOnly(){
                this.$refs.tasteWin.close()
            },
            async countdownStart(time){
                if(time != this.countdownTime) return
                if(this.pageDestroy || !this.isTasting || !this.active) return
                let t = 1000
                await this._common.settimeout(t)
                if(!this.active) return
                this.remindTime -= t
                if(this.remindTime <= 0){
                    let state = await this.roomCheck({init: true})
                    if(state){
                        this.tasteState = 2
                        this.cancel(state)
                    } else this.countdownTimeout()
                    return 
                } 
                let val = this.remindTimeCul
                let gameInfoCheckTime = this.gameInfoCheckTime / 1000
                if(this.remindTime < this.maxWaitTime && val % (this.tasteMaxWait / 2) == 0) await this.maxTimeHandle() 
                if(this.remindTime < this.maxWaitTime && val % gameInfoCheckTime == 0){
                    if(!this.isTasting) return
                    let state = await this.roomCheck({init: true, noLoad: true})
                    if(state){
                        return this.cancel(true)
                    }
                }
                return this.countdownStart(time)
            },
            countdownCancel(){
                if(this.interval === null) return
                clearInterval(this.interval)
            },
            async cancel(res){
                await this.tasteCancel()
                this.countdownCancel()
                this.close(res)
            },
            async countdownTimeout(){
                this.countdownCancel()
                await this.tasteCancel()
                if(this.tasteState != 2) this.tasteState = 3
            },
            async tasteStart(){
                let state = await this._userStatus.gameTastCheck()
                if(state) return
                let res = await this.taste()
                return res

                // this.tasteState = 1
                // return true
            },
            async taste(params){
                params = params || this.params
                this.gameTastOptions = params
                let res = await this.api.gameTaste(params)
                if(res) this._userStatus.setUserStatus({type: 'gameTaste'}), this.tasteState = 1
                return res
            },
            async tasteCancel(){
                let res = await this.api.gameTasteCancel()
                if(res) this._userStatus.setUserStatus({type: 'idle'})
                return res
            },
            async maxTimeHandle(){
                let res = await this.api.gameTasteHeartbeat({noLoad: true})
                return res
            },
            async gotoGameroomConfig(params){
                this._routeHand.goto({name: 'player-game-room',  params})
            },
            async restart(){
                let val = this._common.deepCopy(this.tasteOptions)
                this.init()
                let callback = this.response
                let res = await this.open({...val, response: callback}, {restart: true})
                if(!res) this.tasteState = 3
            },

            async matched(data){
                this.gameMatchedInfo = this._common.deepCopy(data)
                await this.setPeerUserInfo(data)
                this.matchedHandler(data)
            },
            async matchedHandler(data){
                this.tasteState = 2
                // this.windowOpen()
                this._voiceHand.normalPlay('taste_success')
                this.$refs.tasteWin.setConfirmType(this.confirmType)
                await this._common.settimeout(1000)
                await this.enterCountdownStart()
                if(this.pageDestroy) return
                this._common.clientLoading.open({
                    message: '连接中'
                })
                let res = await this.roomCheck()
                this._common.clientLoading.close()
                this.close(res)
                if(!res) return

            },
            async enterCountdownStart(){
                let t = 1000, val = this.enterCountdown
                if(val == this.PARAMS.taste_enter_countdown) this._voiceHand.normalPlay('enter_delay')
                await this._common.settimeout(1000) 
                if(this.pageDestroy || val <= 0) return 
                val -= t
                this.enterCountdown = val
                return this.enterCountdownStart()
            },
            async setPeerUserInfo(data){
                let {gameroomId, inviteeUserCode, inviterUserCode} = data
                let user_code = this.$store.state.app.usercode == inviteeUserCode ? inviterUserCode : inviteeUserCode
                let res = await this.api.userAccountOther({user_code})
                if(!res) return null
                this.peerUserInfo = res
            },
            roomCheck(opt){
                opt = opt || {}
                let params = this.PARAMS
                if(!opt.noLoad) params.loading.open()
                let handler = this.roomCheckHandler
                return new Promise(res => {
                    this._eventBus.$emit('gameLinkTakeFun', {
                        fun: 'gameroomStateCheck',
                        data: opt.params,
                        callback(data){
                            if(!opt.init) handler(data)
                            res(data)
                            params.loading.close()
                        }
                    })
                })
            },
            async roomCheckHandler(data){
                if(data) return
                let message = this._errorCode.game['taste matched error']
                await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '确定'}
                    ]
                })
                this.close(false)
            },
            voiceStop(){
                this._voiceHand.normalStop('taste_success')
                this._voiceHand.normalStop('enter_delay')
            },
            tastingMusicCon(val){
                return val ? this._voiceHand.normalPlay('taste') : this._voiceHand.normalStop('taste')
            },
            async userLevelCheck(val){
                val = val || this.tasteOptions
                let {level} = this.$store.state.app || {}
                let {gift_max_level, gift_min_level} = val || {}
                if(gift_max_level === undefined || gift_min_level === undefined) return true
                // level = 2300
                if(level >= gift_min_level && level <= gift_max_level) return true
                let message = this._errorCode.game['taste max level confirm']
                let res = await this._common.confirmPro.open({
                    message,
                    // confirmType: 'c5 taste-level-confirm',
                    confirmType: 'c6 taste-level-confirm',
                    buttons: [
                        {title: '开始匹配', label: 0, type: 'confirm'},
                        {title: '返回', label: 1, type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0
            }
        },
        created() {},
        components: {},
        computed: {
            startMoveNum(){
                let {tasteOptions: {rule, handicap, boardSize}} = this
                let num = handicap || 0
                if(rule != 'capture') return num
                let moves = this._common.getCaptureInitBoard(boardSize)
                return Number(num) + Number(moves.length)
            },
            enterCountdownText(){
                let {enterCountdownVal: time} = this
                let message = this._errorCode.game['enter game countdown']
                message = this._common.urlParse(message, {time})
                return message
            },
            enterCountdownVal(){
                let {enterCountdown: val} = this
                val = parseInt(val / 1000)
                return val
            },
            blackUserCode(){
                let {gameMatchedInfo: {gameConfig = {}}} = this
                let {blackUserCode} = gameConfig
                return blackUserCode
            },
            whiteUserCode(){
                let {gameMatchedInfo: {gameConfig = {}}} = this
                let {whiteUserCode} = gameConfig
                return whiteUserCode
            },
            blackUserInfo(){
                let {blackUserCode} = this
                if(blackUserCode == this.$store.state.app.usercode) return this.$store.state.app
                return this.peerUserInfo
            },
            whiteUserInfo(){
                let {whiteUserCode} = this
                if(whiteUserCode == this.$store.state.app.usercode) return this.$store.state.app
                return this.peerUserInfo
            },
            confirmType(){
                let data = 'c8'
                if(this.tasteSuccess) data = 'c21'
                return data
            },
            isTasting(){
                return this.tasteState == 1
            },
            tasteFail(){
                return this.tasteState == 3
            },
            tasteSuccess(){
                return this.tasteState == 2
                // return true
            },
            isTasteResult(){
                return this.tasteFail || this.tasteSuccess
            },
            gameType(){
                // let {rule} = this.gameInfo
                // return rule == 'capture' ? this.ENUMS.EXERCISE_CAPTURE_TASTE_COMPETITIVE_MATCH : this.ENUMS.EXERCISE_CONTAIN_TASTE_COMPETITIVE_MATCH


                let {gameInfo: {type}} = this
                type = `${type}_taste`
                let gameInfo = this.PARAMS.peer_game_info[type] || {}
                let gameType = gameInfo.game_type
                return gameType
            },
            gamename(){
                let {gameInfo: {label}} = this
                return label || null
            },
            params(){
                let {gameType, gamename} = this
                let {boardSize: tasteBoardSize, rule: tasteRule, komi: tasteKomi, handicap: tasteHandicap = 0} = this.gameInfo
                let {startMoveNum: tasteStartMoveNum} = this
                let tasteOptionFast = 0, tasteOptionNormal = 1, tasteOptionSlow = 0, tasteOptionAi = 1

                return {gameType, gamename, tasteBoardSize, tasteRule, tasteKomi, tasteHandicap, tasteOptionFast, tasteOptionNormal, tasteOptionSlow, tasteOptionAi, tasteStartMoveNum}


            },
            remindTimeCul(){
                let {remindTime: time} = this
                time = parseInt(time / 1000)
                return time
            },
            tasteStatusText(){
                if(this.isTasting) return this._errorCode.game['is tasting']
                if(this.isTasteResult){
                    let {tasteState: result} = this
                    return this.tasteFail ? this._errorCode.game['taste fail'] : this._errorCode.game['taste success']
                }
                return ''
            },
            tasteRemindText(){
                if(!this.isTasting){
                    return this.tasteFail ? this._errorCode.game['taste fail detail'] : ''
                }
                let message = this._errorCode.game['taste remind time']
                let time = this.remindTimeCul
                return this._common.urlParse(message, {time})
            },
            tasteMusicState(){
                let {isTasting, active, pageDestroy} = this
                return isTasting && active && !pageDestroy
            },
        },
        watch: {
            tasteMusicState: {
                handler(val){
                    this.tastingMusicCon(val)
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-social-game-taste-window{
        .taste-body{
            .taste-title{
                width: 214px;
                height: 118px;
                margin: 38px auto 0px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p1.png'));
            }
            .taste-state{
                margin-top: 12px;
                color: #987AD9;
            }
            .taste-remind{
                margin-top: 13px;
                color: #978179;
                padding: 0px 20px;
            }
            .taste-foot{
                margin-top: 15px;
                width: 100%;
            }
            .taste-cancel-box{
                margin: 0px auto;
                width: 137px;
                height: 46px;
                padding: 3px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p2_shadow.png'));
                .body{
                    width: 100%;
                    height: 100%;
                    color: #9B1F3E;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p2.png'));
                }
            }
            .taste-result-box{
                margin: 15px auto 0px;
                .taste-cancel-box, .com-global-sl-button{
                    margin: 0px;
                    width: 107px;
                    height: 36px;
                    .button-shadow{
                        display: none;
                    }
                }
                .taste-cancel-box+ .taste-cancel-box{
                    margin-left: 10px;
                }
                .com-global-sl-button+ .com-global-sl-button{
                    margin-left: 10px;
                }
            }
            
        }
        .taste-title{
            top: -10px;  
            z-index: 2;              
        }
        .taste-success{
            padding: 10px 10px 10px 9px;
            width: 100%;
            height: 100%;
            .suc-item{
                position: absolute;
                top: 10px;
                left: 9px;
                width: calc(100% - 19px);
                height: calc(100% - 20px);
            }
            .a{
                z-index: 1;
                .a-i{
                    position: absolute;
                }
                .ia{
                    top: 0px;
                    left: 0px;
                    .max-size();
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p3.png'));
                }
                .ib{
                    top: 0px;
                    right: 0px;
                    width: 180px;
                    height: 180px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p4.png'));
                }
            }
            .b{
                z-index: 2;
                padding: 0px 6px;
                display: flex;
                justify-content: space-between;
                .b-item{
                    width: 112px;
                    height: 180px;
                    padding: 0px 10px;
                    overflow: hidden;
                    position: relative;
                    .com-global-user-avatar{
                        height: 130%;
                        width: auto;
                        top: 0px;
                        left: 0px;
                        .user-avatar{
                            height: 100%;
                            width: auto;
                            img{
                                height: 100%;
                                width: auto;
                            }
                        }
                    }
                }
                
            }
            .c{
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p5.png'));
                z-index: 3;
                .c-item{
                    position: absolute;
                }
                .countdown{
                    color: #F5529D;
                    text-align: center;
                    top: 25px;
                }
                .label{
                    width: 118px;
                    height: 80px;
                    top: 50px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p6.png'));
                }
                .name{
                    width: 105px;
                    height: 21px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/window/p7.png'));   
                    padding: 0px 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    bottom: 6px;
                    .com-nickname{
                        justify-content: center;
                        span{
                            font-size: 10px;
                        }
                    }
                }
                .left{
                    left: 6px;
                }
                .right{
                    right: 6px;
                }
            }
        }
        // .com-confirm-pro{
        //     .van-dialog.c21::before{
        //         content: '匹配成功';
        //         font-size: 22px;
        //         font-weight: bold;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         color: #584D92;
        //     }
        // }
        
    }
</style>