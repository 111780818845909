<!--  -->
<template>
    <div class='com-loading flex-center' v-if="value">
        <van-loading type="spinner" vertical>
            <span class="loading-text text-mini" v-if="message">{{message}}</span>
        </van-loading>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {
                value: false,
                message: null
            };
        },
        methods: {
            init(opt){
                this.message = null
                this._common.paramsInit.call(this, opt)
            },
            open(opt){
                this.init(opt)
                this.value = true
            },
            close(){
                this.value = false
            }
        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-loading{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 402;
        .loading-text{
            color: #ddd;
        }
    }
</style>