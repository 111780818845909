
export default {
    name: '',
    props: {
    },
    data() {
        return {
        };
    },
    methods: {
        confirmPlay(){
            if(!this._board) return
            this._board.confirmPlay()
        },
        playByclickEmit(opt){
            if(this.boardPlayDisabled) return
            let {x, y} = opt || {}
            delete opt.x
            delete opt.y
            this.playByclick(x, y, {...opt})
        },
        playByclick(x, y, opt){
            if(this.userPlayDisabled) return
            this._board.playByGame(x, y, opt)
            if(!this.confirmPlaySwitch) this.confirmPlay()
        },
        cancelPlay(){
            if(!this._board) return
            this._board.cancelPlay()
        }
    },
    created() {},
    components: {},
    computed: {
        confirmPlayDisabled(){
            let {gamePlayCoor} = this
            return !gamePlayCoor || !Object.keys(gamePlayCoor).length
        },
    },
    watch: {},
    mounted() {},
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}