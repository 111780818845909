export default {
    // 获取专项题
    'getSubjectExercise': {
        url: '/town/subject/exercise/{exercise_id}',
        serve: 'social',
        login: false,
    },
    // 获取作业题
    'getCourseExercise': {
        url: '/town/course/exercise/{exercise_id}',
        serve: 'social',
        login: false,
    },
    // 获取闯关题
    'getPuzzleExercise': {
        url: '/town/puzzle/exercise/{exercise_id}',
        serve: 'social',
        login: false,
    },
}
