<!--  -->
<template>
    <div class='com-global-AI'>
        <!-- <div class="AI-ext"></div> -->
        <div class="AI-body">
            <img :src="`${PARAMS.assetsUrl}/img/avatar/AI/a3.png`" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-AI{
        width: 141px;
        height: 261px;
        .AI-body{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
            }
            // .background-img-max(url('@{assetsUrl}/img/avatar/AI/a1.png'));
        }
    }
</style>