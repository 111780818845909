<!--  -->
<template>
    <div class='com-global-setting mind'>
        <confirm-pro ref="confirm">
            <div class="setting-content" slot="message">
                <div class="content-item" v-for="(v, i) in setListCul" :key="i" @click="changeSet(v)">
                    <div class="item-text flex-center">
                        <span class="text-normal-aa bold">{{v.text}}</span>
                    </div>
                    <div class="item-btn">
                        <sl-button :type="v.btnType">
                            <span class="text-normal-aa">{{v.btnText}}</span>
                        </sl-button>
                    </div>
                </div>
            </div>
        </confirm-pro>
    </div>
</template>

<script>
    import com from '@/components/setting.vue'
    export default {
        name: '',
        extends: com,
        props: {},
        data() {
            return {
                setList: [
                    {text: this.TEXT.components['background music'], state: 1, value: 'backgroundMusic'},
                    // {text: this.TEXT.components['play voice'], state: 0, value: 'playVoice'},
                    {text: this.TEXT.components['all voice'], state: 1, value: 'sound'},
                ],
            };
        },
        methods: {
            open(){
                this.$refs.confirm.open({
                    showClose: true,
                    title: '设置',
                    buttons: [],
                    confirmType: 'c15'
                })
            }
        },
        created() {
            this.pageInit()
        },
        components: {
        },
        computed: {
        },
        watch: {},
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-setting.mind{
        // .com-confirm-pro{
        //     .van-dialog{
        //         height: 223px;
        //         padding: 0px;
        //         .gotu-confirm{
        //             .pattern-a{
        //                 padding: 58px 34px 47px 33px;
        //                 width: 192px;
        //             }
        //         } 
        //         .background-img-max(url('@{assetsUrlV2_0}/global/c1_2.png'));
        //     }
        // }
        .setting-content{
            .content-item+ .content-item{
                margin-top: 31px;
            }
            .content-item{
                height: 19px;
                width: 125px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                color: @setting-text-color;
                // .background-img-max(url('@{assetsUrlV2_0}/setting/c1.png'));
                background: url('@{assetsUrlV2_0}/setting/c1.png') no-repeat;
                background-size: 100% 2px;
                background-position: left bottom;
                .item-text{
                    height: 13px;
                    margin-top: 2px;
                }
                .com-global-sl-button{
                    width: 43px;
                    height: 19px;
                }
            }
        }
    }

</style>