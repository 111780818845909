import appData from './appData'


export default class cacheRequest{
    constructor(){
        this.maxCacheTime = 60 * 60
        this._appData = new appData({key: 'request_cache'})
    }
    getCacheKey(opt){
        opt = opt || {}
        let {api, method, url, data} = opt
        data = data || {}
        try{
            data = JSON.stringify(data)
        }catch(e){
            data = ''
        }
        return `${api}-${url}-${method}-${data}`
    }
    cacheTimeCheck(time){
        let timeNow = this.getTimeNow()
        return (timeNow - time) < this.maxCacheTime
    }
    getTimeNow(){
        return parseInt(((new Date()).getTime()) / 1000)
    }
    cacheDataGet(opt){
        opt = opt || {}
        let request_cache = this._appData.getStoreItem('request_cache')
        request_cache = request_cache || {}
        let key = this.getCacheKey(opt)
        let rd = request_cache[key]
        if(!rd) return null
        let {time, res} = rd
        time = time || 0
        let f = this.cacheTimeCheck(time)
        if(!f){
            res = null
            delete request_cache[key]
            this._appData.setStoreItem({request_cache: request_cache})
        }else{
            console.log('load cache')
        }
        return res
    }
    cacheDataSet(opt){
        // api, options, data
        opt = opt || {}
        let {res} = opt
        let request_cache = this._appData.getStoreItem('request_cache')
        request_cache = request_cache || {}
        let key = this.getCacheKey(opt)
        let time = this.getTimeNow()
        request_cache[key] = {
            res,
            time
        }
        this._appData.setStoreItem({request_cache: request_cache})
    }
}