<!--  -->
<template>
    <div class='com-global-avatar-static'>
        <div class="ava-shadow"></div>
        <img :src="url">
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            src: String
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            url(){
                if(this.src) return this.src
                let data = this._userHand.avatarDressUrl() || {}
                return data.static || ''
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-avatar-static{
        position: relative;
        .ava-shadow{
            position: absolute;
            width: 83%;
            height: 5%;
            left: 6px;
            bottom: -5px;
            z-index: -1;
            .background-img-max(url('@{assetsUrlV2_0}/user/shadow_person.png'));
        }
    }
</style>