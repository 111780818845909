<!--  -->
<template>
    <div class='invite-window'>
        <div class="win-box" ref="message">
            <h1 class="win-box-head" v-if="title">
                {{$t(title)}}
            </h1>
            <div class="win-box-foot" :id="footId" v-if="!title">
                <div class="body">
                    <span class="txt">{{$t('邀请你对局')}}</span>
                    <span class="txt">（</span>
                    <span class="txt time-box">{{remainTime}}</span>
                    <span class="txt">s）</span>
                </div>
            </div>
            <div class="win-box-body" v-if="!title">
                <div class="info-box">
                    <div class="info-item">
                        <chatPhoto :photo="photoCul"></chatPhoto>
                    </div>
                    <div class="info-item b">
                        <div class="b-i">
                            <chatName :info="userInfoCul" class="u-name"></chatName>
                        </div>
                        <div class="b-i">
                            <levelCom :level="userInfoCul.level" :noneLevelShow="true"></levelCom>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- <div class="win-box-foot" :id="footId" v-if="!title">
                <div class="body">
                    <span class="txt">{{$t('邀请你对局')}}</span>
                    <span class="txt">（</span>
                    <span class="txt time-box">{{remainTime}}</span>
                    <span class="txt">s）</span>
                </div>
            </div> -->
            <div class="box-hand">
                <el-button class="le-button" :class="[v.class]" v-for="(v, i) in buttons" :key="i" data-ref="normalFunHandler" :index="i">
                    <span class="txt">{{$t(v.name)}}</span>
                </el-button>
            </div>
            <div class="close-box" data-ref="closeHandler">
                <span class="close-icon el-icon-close texts-de"></span>
            </div>
            <div class="spec-fun-box" v-if="!title">
                <el-button type="text" data-ref="rejectAllDay">
                    <span class="texts-normal">{{$t('拒绝此人今日所有邀请')}}</span>
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import noticeClass from '@/global/vue/vant-notice/api'
    
    export default {
        name: '',
        props: {
            id: String
        },
        data() {
            return {
                notice: new noticeClass(),
                userInfo: null,
                title: null,
                callback: null,
                closeCallback: null,
                buttons: [],
                remainTime: this.PARAMS.invite_timeout,
                timeInterval: null
            };
        },
        methods: {
            async open(opt){
                this.init(opt)
                let message = await this.getMessage()
                let {key} = opt
                let noticeCon = this.notice.open({
                    key,
                    
                    params: {
                        dangerouslyUseHTMLString: true,
                        duration: 0,
                        message,
                        showClose: false,
                        customClass: `invite-notice-window ${opt.noticeClass}`,
                        onClick: () =>{
                            this.funHand(opt)
                        },
                    },
                });
                if(!this.title){
                    if(opt.remainTime){
                        this.remainTime = opt.remainTime
                    }
                    this.timeoutStart()
                }
                return noticeCon
            },
            timeoutStart(){
                this.timeoutCancel()
                this.timeInterval = setInterval(() => {
                    if(this.remainTime <= 0){
                        this.timeoutCancel()
                        this.timeoutHand()
                        return
                    }
                    this.remainTime--
                    this.remainTimeRevender()
                }, 1000);
            },
            timeoutCancel(){
                if(!this.timeInterval) return
                clearInterval(this.timeInterval)
            },
            timeoutHand(){
                this.$emit('timeout', this.id)
            },
            remainTimeRevender(){
                let {footId: id} = this
                let ele = document.querySelectorAll(`#${id} .time-box`)
                if(!ele || !ele.length) return
                for(let i in ele){
                    if(!ele[i].innerText) continue
                    ele[i].innerText = this.remainTime
                }
                this.$emit('remainTimeChange', this.remainTime, this.id)
            },
            paramsInit(){
                this.userInfo = null
                this.callback = null
                this.buttons = []
                this.title = null
                this.key = null
                this.remainTime = this.PARAMS.invite_timeout
            },
            init(opt){
                this.paramsInit()
                // this._errorCode.game['invite title for window']
                this._funhand.paramsInit.call(this, opt)
            },
            async getMessage(){
                await this._funhand.nextTick.call(this)
                let ele = this.$refs['message']
                if(!ele) return null
                let message = ele.innerHTML
                return message
            },
            funHand(params){
                let e = window.event
                let list = {
                    clickBtn: 'normalFunHandler',
                    closeBtn: 'closeHandler',
                    specFun: 'rejectAllDay'
                }
                let fun = null
                for(let i in list){
                    let data = this.GLOBAL.appClick(e, list[i], 'data-ref')
                    if(!data) continue
                    fun = list[i]
                    break
                }
                if(!this[fun]) return
                this[fun]({event: e, params})
            },
            closeCheck(opt, e){
                let ele = this.GLOBAL.appClick(e, 'closeBtn', 'data-ref')
                if(!ele) return
                this.timeoutHand()
            },
            normalFunHandler(opt){
                let {event: e, params} = opt || {}
                e = e || window.event
                let ele = this.GLOBAL.appClick(e, 'normalFunHandler', 'data-ref')
                if(!ele) return
                 let index = ele.getAttribute('index')
                let {buttons, key} = params
                let val = buttons[index]
                if(!this.callback || !val) return
                let data = {key, val}
                this.callback(data)
            },
            closeHandler(opt){
                let {event: e} = opt || {}
                e = e || window.event
                let ele = this.GLOBAL.appClick(e, 'closeHandler', 'data-ref')
                if(!ele) return
                this.timeoutHand()
            },
            rejectAllDay(opt){
                let {event: e, params} = opt || {}
                e = e || window.event
                let ele = this.GLOBAL.appClick(e, 'rejectAllDay', 'data-ref')
                if(!ele) return
                // this.callback(data)
                let data = {key: params.key, val: {value: 'rejectAllDay'}}
                this.callback(data)
            }
            
        },
        created() {

        },
        components: {
        },
        computed: {
            photoCul(){
                // let {userInfoCul.photo}
                let {photo, photoFile} = this.userInfoCul
                return photoFile || photo
            },
            footId(){
                let {id = ''} = this
                return `foot_${id}`
            },
            userInfoCul(){
                let {userInfo} = this
                return userInfo || {}
            },
        },
        watch: {},
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.timeoutCancel()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >

</style>