<!--  -->
<template>
    <div class='com-confirm-pro'>
        <van-dialog 
        :class="[confirmTypeCul, {'center': !buttons.length}, defineClass]"
        :message="message" 
        ref="dialog" 
        v-model="active" 
        :show-cancel-button="showCancelButton" 
        :show-confirm-button="showConfirmButton" 
        :confirm-button-text="confirmButtonText"
        :cancel-button-text="cancelButtonText"
        :close-on-click-overlay="closeOnClickOverlay"
        :className="className"
        :z-index="zIndex"
        @confirm="confirm" 
        @cancel="cancel">
            <div class="pro-close-box scale-button" @click="handl(false, {closeBtn: true})" v-if="showClose && !$slots['close']"></div>
            <slot name="close"></slot>
            <div class="pro-title flex-center">
                <span class="text-normal bold" v-if="!$slots.title">{{title}}</span>
                <slot name="title"></slot>
            </div>
            <div class="gotu-confirm-pro">
                <div class="confirm-content-pro flex-center" v-if="!$slots.default">
                    <slot name="message"></slot>
                    <span class="pro-message text-normal-aa confirm-pro-message-color bold" v-if="!$slots.message && message">{{message}}</span>
                </div>
                <div class="confirm-content-sub flex-center" v-if="!$slots.subMessage && subMessage">
                    <span class="pro-sub-message text-mini confirm-pro-message-color bold" v-html="subMessage"></span>
                </div>
                <slot></slot>
                <div class="pro-foot" v-if="$slots.foot">
                    <slot name="foot"></slot>
                </div>
                <div class="pro-foot" v-if="buttons && buttons.length && !$slots.foot" :class="{'n1': buttons.length <= 1}">
                    <div class="foot-button" v-for="v in buttons" :key="v.label">
                        <sl-button @click="handl(v)" :type="v.type">
                            <span class="text-normal-aa">{{v.title}}</span>
                        </sl-button>
                    </div>
                </div>
            </div>    
        </van-dialog>
    </div>
</template>

<script>

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                zIndex: undefined,
                active: false,
                response: null,
                pattern: 'a',
                message: null,
                subMessage: null,
                title: null,
                showClose: false,
                buttons: [
                    // {title: '取消', type: 'cancel', label: 1},
                    // {title: '确定', type: 'confirm', label: 0},
                ],
                'showConfirmButton': false,
                'showCancelButton': false,
                'confirmButtonText': '确认',
                'cancelButtonText': '取消',
                closeOnClickOverlay: false,
                confirmType: null,
                defineClass: '',
                className: null
            };
        },
        methods: {
            paramsInit(opt){
                this.response = null
                this.pattern = 'a'
                this.message = null
                this.subMessage = null
                this.title = this.TEXT.common.notice
                this.buttons = [
                    // {title: '取消', type: 'cancel', label: 1},
                    // {title: '确定', type: 'confirm', label: 0},
                ]
                this.showConfirmButton = false
                this.showCancelButton = false
                this.confirmButtonText = '确认'
                this.cancelButtonText = '取消'
                this.defineClass = ''
                this.className = null
                this.paramsSet(opt)
                for(let i in this.buttons){
                    if(!this.buttons[i].type) this.buttons[i].type = 'confirm'
                }
                if(opt && opt.showClose === undefined) this.showClose = this.buttons.length ? false : true
            },
            paramsSet(opt){
                this._common.paramsInit.call(this, opt)
            },
            open(opt){
                this.paramsInit(opt)
                return new Promise(success => {
                    this.response = success
                    this.active = true
                })
            },
            close(){
                this.$refs['dialog'].close()
            },
            confirm(){
                if(this.response) this.response(true)
            },
            cancel(){
                if(this.response) this.response(false)
            },
            handl(val, opt){
                if(this.response) this.response(val)
                let {closeBtn} = opt || {}
                let {keepOpen} = val || {}
                if(!keepOpen || closeBtn) this.active = false
            },
            setConfirmType(type){
                this.confirmType = type
            }
        },
        created() {

        },
        components: {
        },
        computed: {
            confirmTypeCul(){
                return this.confirmType || 'c1'
            },
            patternA(){
                return this.pattern == 'a'
            },
            patternB(){
                return this.pattern == 'b'
            }
        },
        watch: {
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
    .com-confirm-pro {
        position: relative;

        .van-dialog.center {
            display: flex;
            align-items: center;
            justify-content: center;

            .gotu-confirm-pro {
                padding-top: 20px;

                .confirm-content-pro {
                    text-align: center;
                }
            }
            
        }
        .van-dialog.padding-c3{
            .gotu-confirm-pro{
                padding-left: 15px;
                padding-right: 15px;
                .pro-message{
                    height: auto;
                }
            }
        }
        
        .van-dialog__content {
            width: 100%;
            height: 100%;
        }

        .van-dialog {
            top: 50%;
            bottom: auto;
            width: auto;
            // padding: 40px 0px 22px 0px;
            overflow: visible;
            border-radius: 0px;
        }

        .gotu-confirm-pro {
            padding-top: 45px;
            padding-bottom: 8px;

            .confirm-content-sub {
                margin-top: 10px;

                .pro-sub-message {
                    // line-height: 15px;
                }
            }
        }

        .pro-title {
            width: 100%;
            height: 14px;
            top: 9px;
            left: 0px;
            position: absolute;
            color: #554B93;
        }

        .pro-foot {
            position: absolute;
            bottom: 20px;
            left: 0px;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;

            .foot-button+.foot-button {
                margin-right: 37px;
            }
        }

        .van-dialog.c1 {
            min-width: 266px;
            min-height: 100px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c1.png'));
            .gotu-confirm-pro {
                padding-top: 20px;
            }
        }

        .van-dialog.c2 {
            min-width: 232px;
            min-height: 128px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c2.png'));
        }
        
        .van-dialog.c2plus {
            min-width: 342px;
            min-height: 153px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c2.png'));
            .gotu-confirm-pro {
                padding-left: 10px;
                padding-right: 10px;
                .confirm-content-pro{
                    .pro-message{
                        line-height: 15px;
                        height: auto;
                        font-size: 11px;
                    }
                }
                .confirm-content-sub{
                    margin-top: 12px;
                    .pro-sub-message{
                        line-height: 15px;
                        height: auto;
                        font-size: 11px;
                    }
                }
            }
        }

        .van-dialog.c3 {
            width: 266px;
            height: 131px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c3.png'));
        }

        .van-dialog.c3plus {
            width: 266px;
            height: 147px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c2.png'));

            .gotu-confirm-pro {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .van-dialog.c4 {
            min-width: 231px;
            min-height: 226px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c4.png'));
        }

        .van-dialog.c5 {
            min-width: 231px;
            min-height: 175px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c5.png'));
        }

        .van-dialog.c6 {
            width: 232px;
            height: 137px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c6.png'));

        }
        .van-dialog.c6.game-review-confirm {
            .gotu-confirm-pro{
                padding-left: 10px;
                padding-right: 10px;
                .pro-message{
                    height: auto;
                }
            }
        }
        .van-dialog.c6.game-review-confirm.sub {
            .gotu-confirm-pro{
                padding-top: 30px;
            }
        }
        

        .van-dialog.c6plus {
            width: 280px;
            height: 163px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c6.png'));

            .gotu-confirm-pro {
                padding-left: 30px;
                padding-right: 30px;
                .confirm-content-pro{
                    .pro-message{
                        line-height: 15px;
                        height: auto;
                        font-size: 10px;
                    }
                }
                .confirm-content-sub{
                    margin-top: 0;
                    .pro-sub-message{
                        line-height: 15px;
                        height: auto;
                        font-size: 10px;
                        color: #e75347;
                    }
                }
            }
        }

        .van-dialog.c10 {
            width: 267px;
            height: 201px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c10.png'));
        }

        .van-dialog.c11 {
            width: 265px;
            height: 260px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c11.png'));

            .gotu-confirm-pro {
                padding-top: 48px;
                padding-bottom: 39px;
            }
        }
        .van-dialog.c12{
            width: 190px;
            height: 260px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c12.png'));
            .pro-title{
                top: 5px;
                span[class^='text']{
                    font-size: 11px;
                }
            }
            .pro-close-box{
                top: 4px;
            }
            .gotu-confirm-pro{
                padding-top: 19px;
                padding-bottom: 0px;
            }
        }
        .van-dialog.c13{
            width: 191px;
            height: 217px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c13.png'));
            .pro-title{
                top: 5px;
                span[class^='text']{
                    font-size: 10px;
                }
            }
            .pro-close-box{
                top: 5px;
                right: -2px;
                left: auto;
            }
            .gotu-confirm-pro{
                padding-top: 18px;
                padding-bottom: 0px;
                height: 100%;
            }
        }
        .van-dialog.c14{
            width: 186px;
            height: 110px;
            padding-top: 31px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c14.png'));
            .pro-title{
                top: 5px;
                span[class^='text']{
                    font-size: 10px;
                }
            }
            .gotu-confirm-pro{
                padding: 0px 27px 0px 27px;
                span.pro-message{
                    font-size: 9px;
                    font-weight: bold;
                }
            }
            .com-global-sl-button.confirm{
                color: #9A2E49;
            }
            .com-global-sl-button{
                width: 54px;
                height: 17px;
                span[class^='text']{
                    font-size: 9px;
                }
            }
            .pro-foot{
                bottom: 15px;
            }
            // .pro-close-box{
            //     top: 5px;
            //     right: -2px;
            //     left: auto;
            // }
        }
        .van-dialog.pet-re-claim{
            .gotu-confirm-pro{
                padding-left: 10px;
                padding-right: 10px;

            }
        }

        .van-dialog.balance-check {
            // transform: translateY(-50px);
            margin-top: -40px;

            // .gotu-confirm-pro{
            //     height: 100%;
            //     padding: 43px 0px 0px;
            // }
            .pro-title {
                top: 63px;
            }

            .gotu-confirm-pro {
                height: 100%;
                padding: 93px 0px 0px;

            }

            .confirm-content-pro {
                padding: 0px 27px;

                .pro-message {
                    line-height: 20px;
                    height: auto;
                }
            }
        }

        .van-dialog.c7 {
            display: block;
            min-width: 192px;
            min-height: 223px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c7.png'));

            .gotu-confirm-pro {
                padding-top: 58px;
            }
        }

        .van-dialog.c8 {
            width: 285px;
            height: 323px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c8.png'));

            .gotu-confirm-pro {
                padding-top: 0px;
                height: 100%;
            }
        }

        .van-dialog.c9 {
            display: block;
            min-width: 338px;
            min-height: 213px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c9.png'));

            .gotu-confirm-pro {
                padding-top: 40px;
            }
        }

        .van-dialog.c15 {
            display: block;
            min-width: 192px;
            min-height: 190px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c15.png'));

            .gotu-confirm-pro {
                padding-top: 65px;
            }
        }

        .van-dialog.c16{
            width: 191px;
            height: 246px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c16.png'));
            .pro-title{
                top: 5px;
                span[class^='text']{
                    font-size: 10px;
                }
            }
            .pro-close-box{
                top: 5px;
                right: -2px;
                left: auto;
            }
            // .gotu-confirm-pro{
            //     padding-top: 18px;
            //     padding-bottom: 0px;
            //     height: 100%;
            // }
        }

        .van-dialog.c17{
            width: 292px;
            height: 286px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c17.png'));
            .pro-title{
                top: 9px;
            }
            .pro-close-box{
                top: 8px;
                right: -4px;
                left: auto;
                width: 18px;
                height: 19px;
            }
            .gotu-confirm-pro{
                height: 100%;
                padding-top: 0px;
            }
        }

        .van-dialog.c18{
            width: 266px;
            height: 157px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c18.png'));
            .pro-title{
                top: 9px;
            }
            .pro-close-box{
                top: 8px;
                right: -5px;
                left: auto;
                width: 18px;
                height: 19px;
            }
            .gotu-confirm-pro{
                height: 100%;
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .van-dialog.c19{
            width: 292px;
            height: 286px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c19.png'));
            .pro-title{
                top: 9px;
            }
            .pro-close-box{
                top: 8px;
                right: -2px;
                left: auto;
                width: 18px;
                height: 19px;
            }
            .gotu-confirm-pro{
                height: 100%;
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .van-dialog.c20{
            width: 497px;
            height: 286px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c20.png'));
            .pro-title{
                top: 10px;
                left: 126px;
                width: 42px;
            }
            .pro-close-box{
                top: 8px;
                right: -2px;
                left: auto;
                width: 18px;
                height: 19px;
            }
            .gotu-confirm-pro{
                height: 100%;
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .van-dialog.c21::before{
            content: '';
            position: absolute;
            width: 173px;
            height: 45px;
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c21_head.png'));
            top: -19px;
            left: 0px;
            right: 0px;
            margin: auto;
            z-index: 4;
        }
        .van-dialog.c21{
            width: 335px;
            height: 200px;
            .pro-title{
                z-index: 5;
            }
            .gotu-confirm-pro{
                width: 100%;
                height: 100%;
                padding: 0px;
            }
            .background-img-max(url('@{assetsUrlV2_0}/confirm/c21.png'));
        }















        .confirm-title-pro {
            position: absolute;
            top: 9px;
            width: 100%;
            height: 14px;
            line-height: 14px;
            text-align: center;
            color: @confirm-pro-title-color;
        }

        .pro-close-box {
            position: absolute;
            top: 8px;
            right: -3px;
            width: 17px;
            height: 17px;
            z-index: 2;
            .background-img-max(url('@{assetsUrlV2_0}/global/c1_3.png'));
        }

        .gotu-confirm {
            .pattern-a {
                width: 300px;
                // padding: 24px 20px 20px;

            }

            .content {
                // margin-top: 10px;
            }

            .foot {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                flex-direction: row-reverse;

                .foot-button+.foot-button {
                    margin-right: 42px;
                }
            }

            .foot.n1 {
                justify-content: center;
            }

            .a-item.head {
                >span[class^="text"] {
                    line-height: 20px;
                }
            }

            .a-item.content {
                text-align: center;

                >div[class^="text"],
                >span[class^='text'] {
                    line-height: 24px;
                    height: 24px;
                }

                .sub-message-box {
                    margin-top: 13px;
                    .set-line-height(12px);
                }

                .main-message-box {
                    .set-line-height(13px);
                }
            }

            .pattern-b {
                padding: 27px 15px 22px;
                width: 303px;
                height: 335px;

                .foot {
                    margin-top: 60px;
                    display: block;

                    .com-dia-button {
                        width: 146px;
                        height: 40px;
                        line-height: 40px;
                        margin: 0px auto;
                    }

                    .foot-button+.foot-button {
                        margin-top: 12px;
                    }

                    .com-dia-button.confirm {
                        border-radius: 8px;
                    }
                }
            }
        }
    }
</style>