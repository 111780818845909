import Vue from 'vue'




const requireComponent = require.context("@/components/global", false, /\.vue$/)
let files = requireComponent.keys()
files.forEach( paths => {
    let componentName = paths.replace(/^\.\//, "").replace(/\.\w+$/, "")
    let com = requireComponent(paths)
    com = com.default || com
    Vue.component(componentName, com);
});