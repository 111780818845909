import commonApi from '../_EXT_API'

export default class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'certificate'
        let name = 'certificate'
        opt = {localKey, name}
        super(opt)
    }
    async getCertificateList(opt){
        opt = opt || {}
        let {user_code, page, size,} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
		let options = {
			method: "get",
			data: {
				page,
                size,
			},
			...opt
		}
		return this.requestAction('getCertificateList', options)
	}
    async addCertificate(opt){
        opt = opt || {}
        let {user_code} = opt
        // school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
		let options = {
			method: "post",
			// school_id,
			user_code,
			data: {
			},
		}
		return this.requestAction('addCertificate', options)
	}
    async getCertificate(opt){
        opt = opt || {}
        let {certificate_id, user_code} = opt
        // school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
		let options = {
			method: "get",
			user_code,
			data: {
				// school_id,
				certificate_id,
			},
			...opt
		}
		return this.requestAction('getCertificate', options)
	}

	async uploadImage(opt){
		opt = opt || {}
        let {file_type, user_code, files} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
		let options = {
			method: "post",
			...opt,
			user_code,
			uploadFile: true,
			files,
			extendParams: {
				file_type,
				user_code,
			},
		}
		return this.requestAction('getCertificate', options)
	}
}
