<template>
	<div class="rowLineLeft">
		<div class="inside" v-show="!isActive"></div>
		<div class="Inside-active" v-show="isActive"></div>
	</div>
</template>

<script>
export default {
	name: 'rowLineLeft',
	props: {
		isActive:{
			
		}
	},
	data() {
		return {
			
		};
	},
	methods: {

	},
	created() {

	},
	components: {
	},
	computed: {},
	watch: {},
	mounted() {},
	beforeCreate() {}, //生命周期 - 创建之前
	beforeMount() {}, //生命周期 - 挂载之前
	beforeUpdate() {}, //生命周期 - 更新之前
	updated() {}, //生命周期 - 更新之后
	beforeDestroy() {}, //生命周期 - 销毁之前
	destroyed() {}, //生命周期 - 销毁完成
	activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='less' >
	.rowLineLeft{
		height: 114px;
		width: 46px;
		position: relative;
		overflow: hidden;
		.inside{
			position: absolute;
			left: 0;
			top: 0;
			height: 114px;
			width: 105px;
			box-sizing: border-box;
			border-radius: 100% 0 0 100%;
			border: 14px solid #9EC8FF;
			border-right: none;
		}
		.Inside-active{
			position: absolute;
			left: 0;
			top: 0;
			height: 114px;
			width: 105px;
			box-sizing: border-box;
			border-radius: 100% 0 0 100%;
			border: 14px solid #FFE973;
			border-right: none;
		}
	}
	
</style>
