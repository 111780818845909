import appData from '@/global/js/appData'
import apiUrl from './apiUrl'
import {common, errorHand, params, userHand, errorCode} from '@/global/js/exportEnter'
import request from '@/global/js/request'
import cacheRequest from '@/global/js/cacheRequest'
import enums from '@/global/js/enums'

export default class commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = opt.localKey || ''
        this._appData = new appData({key: localKey})
        this._common = common
        this._errorHand = errorHand
        this._errorCode = errorCode
        this._request = new request()
        this._cacheRequest = new cacheRequest()
        this._userHand = new userHand()
        this.apiUrl = apiUrl
        this.apiList = {}
        this.maxCacheTime = 60 * 60
        this.PARAMS = params
        this.loadingTimestamp = null
        this.ENUMS = enums
        this.init(opt)
    }

    setUserStatus(opt){
        let {user_status, usercode, user_status_detail, status_change} = opt || {}
        status_change = status_change ? 1 : 0
        let device_type = 1
        usercode = usercode || this._appData.getStoreItemByCom('usercode')
        if(!usercode) return
        opt.usercode = usercode
        let options = {
            ...opt,
            method: "post",
            data: {
                user_status,
                user_status_detail,
                device_type,
                status_change,
            }
        }
        return this.requestAction('setUserStatus', options, null, 'social')
    }
    async wsUserInfoGlobal(opt){
        opt = opt || {}
        let {usercode} = opt
        usercode = usercode || this._appData.getStoreItemByCom('usercode', 'app')
        if(!usercode) return
        opt.usercode = usercode
        let options = {
            ...opt,
            method: "get",
            data: {}
        }
        let res = await this.requestAction('wsUserInfoGlobal', options, null, 'social')
        return res
    }
    async stompStatusCheck(socketHand){
        if(!socketHand) return false
        let info = await this.wsUserInfoGlobal()
        if(!info) return true
        let {webConnectionStatus} = info
        webConnectionStatus = Number(webConnectionStatus)
        let {stompClient} = socketHand
        if(stompClient && stompClient.connected && webConnectionStatus) return true

        // if(webConnectionStatus) return true
        return this.stompConnectWatch()
    }

    async stompConnectWatch(opt){
        let {time} = opt || {}
        let timeNow = (new Date()).getTime()
        if(time === undefined) time = timeNow
        let message = null
        let usercode = this._appData.getStoreItemByCom('usercode', 'app')

        let {stompClient} = this.socketHand
        if(stompClient && stompClient.connected){
            this.loadingClose()
            return true
        }

        if((timeNow - time) > this.PARAMS.webSocket_http_time){
            let message = this._errorCode.game['socket link timeout']
        }else if(!usercode){
            
            let message = this._errorCode.userInfo['user logout']
        }
        if(message){
            this.loadingClose()
            this._common.confirmPro.open({
                message,
                confirmType: 'c3',
                buttons: [
                    {title: '确定', type: 'confirm'}
                ]
            })
            return false
        }
        
        this.loadingOpen()
        await this._common.settimeout(1000)
        return this.stompConnectWatch({time})
    }

    async version(opt){
        opt = opt || {}
        if(opt.noLoad === undefined) opt.noLoad = true
        if(opt.noError === undefined) opt.noError = true
        let options = {
            ...opt,
            method: "get",
            data: {}
        }
        let res = await this.requestAction('version', options, null, 'engine')
        if(!res) return
        this.versionLocalSet(res)
    }
    versionLocalSet(res){
        let {version} = res || {}
        if(!version) return
        this._appData.setItem({version}, 'app')
    }

    async moduleState(opt){
        opt = opt || {}
        let {endpoint, version, module_name} = opt
        endpoint = endpoint || 'web'
        version = version || this._appData.getItem('version', 'app') || ''
        version = version.replace('v', '')
        // version = '3.0.1'
        if(!module_name) return false
        let options = {
            ...opt,
            endpoint,
            data: {version, module_name}
        }
        let res = await this.requestAction('moduleState', options, null, 'engine')
        res = res || {}
        let {moduleStatus} = res
        return moduleStatus == 1
    }

    async userAccount(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                // schoolId
            }
        }
        let res = await this.requestAction('userAccount', options)
        if(!res) return res
        let {avatarDressUrl, avatarUrl, dressUrl} = res
        try{ avatarDressUrl = JSON.parse(avatarDressUrl) }catch(e){ avatarDressUrl = res.avatarDressUrl }
        try{ avatarUrl = JSON.parse(avatarUrl) }catch(e){ avatarUrl = res.avatarUrl }
        try{ dressUrl = dressUrl.split(',') }catch(e){ dressUrl = res.dressUrl }
        res = {
            ...res,
            avatarDressUrl, avatarUrl, dressUrl
        }
        this._userHand.userAccountSet(res)
        return res
    }
    async userLevel(opt){
        opt = opt || {}
        let options = {
            ...opt,
            method: 'get',
            data: {
            },
        }
        let res = await this.requestAction('userLevel', options)
        if(res) this._userHand.userLevelSet(res)
        return res
    }

    async userAccountOther(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        // schoolId = schoolId || this.PARAMS.school_id
        opt = {
            ...opt,
            user_code
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                // schoolId
            }
        }
        let res = await this.requestAction('userAccount', options)
        return res
    }
    init(opt){
        opt = opt || {}
        for(let i in opt){
            this[i] = opt[i]
        }
        this.setUrlList(opt)
        this.specHandList = this.PARAMS.specHandList
    }
    setUrlList(opt){
        opt = opt || {}
        let {name} = opt
        let {apiUrl} = this
        let glo = apiUrl['_global'] || {}
        let list = this._common.deepCopy(this.apiList)
        this.apiList = {...list, ...glo}
        if(!apiUrl[name]) return
        this.apiList = {...apiUrl[name], ...glo}
    }
    usernameGet(){
        return this._appData.getStoreItemByCom('username', 'app') || null
    }
    errorCodeCheck(res){
        let {code} = res || {}
        let fun = null
        if(code !== undefined){
            if(this.specHandList[code]) fun = this.specHandList[code]
        }
        if(fun){
            if(this[fun]) this[fun](res)
            if(this._common[fun]) this._common[fun](res)
        }
        return fun ? false : true
    }
    pushDataConfig(data){
        data = data || {}
        data.school_id = this.PARAMS.school_id
        return data
    }
    async requestAction(api, options, errorKey, ser){
        errorKey = errorKey || 'common'
        options = options || {}
        let loadingTime = null
        if(!options.noLoad) loadingTime = this.loadingOpen()
        options.data = this.pushDataConfig(options.data)
        let res = await this.requestData(api, options, ser, errorKey)
        if(options.static && res) return res
        if(!options.noLoad) this.loadingClose()
        let resData = res || {}
        let noError = resData.noError || options.noError
        if((!res || res.code != 0) && !noError && this.errorCodeCheck(res)) this._errorHand.hand(res, errorKey)
        if(options.fullData) return res
        if(res && res.code == 0){
            return res.data
        }
        return false
    }
    loadingOpen(){
        if(!this.PARAMS.loading) return
        this.PARAMS.loading.open()
        let t = (new Date()).getTime()
        this.loadingTimestamp = t
        return t
    }
    loadingClose(){
        if(!this.PARAMS.loading) return
        this.PARAMS.loading.close()
        this.loadingTimestamp = null
    }
    getCacheKey(opt){
        opt = opt || {}
        let {api, method, url, data} = opt
        data = data || {}
        try{
            data = JSON.stringify(data)
        }catch(e){
            data = ''
        }
        return `${api}-${url}-${method}-${data}`
    }
    cacheTimeCheck(time, retentionTime){
        retentionTime = retentionTime === undefined ? this.maxCacheTime : retentionTime
        let timeNow = this.getTimeNow()
        return (timeNow - time) < retentionTime
    }
    getTimeNow(){
        return parseInt(((new Date()).getTime()) / 1000)
    }
    cacheDataGet(opt){
        opt = opt || {}
        let {retentionTime} = opt
        let request_cache = this._appData.getStoreItem('request_cache')
        request_cache = request_cache || {}
        let key = this.getCacheKey(opt)
        let rd = request_cache[key]
        if(!rd) return null
        let {time, res} = rd
        time = time || 0
        let f = this.cacheTimeCheck(time, retentionTime)
        if(!f){
            res = null
            delete request_cache[key]
            this._appData.setStoreItem({request_cache: request_cache})
        }else{
            console.log('load cache')
        }
        return res
    }
    cacheDataSet(opt){
        // api, options, data
        opt = opt || {}
        let {res, retentionTime} = opt
        let request_cache = this._appData.getStoreItem('request_cache')
        request_cache = request_cache || {}
        let key = this.getCacheKey(opt)
        let time = this.getTimeNow()
        request_cache[key] = {
            res,
            time,
        }
        if(retentionTime !== undefined) request_cache[key].retentionTime = retentionTime
        this._appData.setStoreItem({request_cache: request_cache})
    }

    async requestData(api, opt, ser, errorKey){
        ser = ser || 'engine'
        opt = opt || {}
        let apiData = this.apiList[api]
        let username = this.usernameGet()
        if(!apiData){
            console.log(1)
            return false
        }
        let url = apiData.url
        ser = apiData.serve || ser
        url = this.urlParse(url, opt)
        let login = apiData.login === undefined ? true : apiData.login
        if(login && !username){
            console.log(2)
            return false
        }
        let method = opt.method || 'GET'
        let data = opt.data || null
        let headers = opt.headers || null
        let option = {
            url,
            method,
            data: this._common.deepCopy(data),
            headers
        }
        for(var i in opt){
            if(option[i] !== undefined) continue
            option[i] = opt[i]
        }
        if(data === null) delete option['data']
        if(headers === null) delete option['headers']
        let res
        let {cacheData} = option
        let cacheOpt = {
            api: ser,
            url,
            data,
            method,
            retentionTime: cacheData
        }
        if(cacheData){
            res = this._cacheRequest.cacheDataGet(cacheOpt)
        }
        res = res || await this._request.send(ser, option)
        if(res && res.code == 0 && cacheData){
            cacheOpt.res = this._common.deepCopy(res)
            this._cacheRequest.cacheDataSet(cacheOpt)
        }
        return res
    }
    urlParse(url, opt){
        opt = opt || {}
        url = url || ''
        url = url.replace(new RegExp("{","gm"), "${")
        url = url.split('$')
        let rUrl = []
        for(var i in url){
            let tem = url[i] || ''
            if(tem.indexOf('{') > -1 && tem.indexOf('}') > -1){
                let funKey = tem.substring(tem.indexOf('{') + 1, tem.indexOf('}'))
                let params = ''
                if(opt[funKey]) params = opt[funKey]
                else if(this[`${funKey}Get`]) params = this[`${funKey}Get`]()
                if(params) tem = tem.replace(`{${funKey}}`, params)
            }
            rUrl.push(tem)
        }
        return rUrl.join('')
    }

    async noticeTimeout(opt){
        opt = opt || {}
        let timeout = opt.timeout || 1500
        this._.MessageOpen(opt)
        return this._common.settimeout(timeout)
    }

    async noticeByCodeConfirm(opt){
        if(!this.confirm) return
        opt = opt || {}
        let {buttons, message, code, key} = opt
        if(!message && code !== undefined){
            message = this._errorHand.errorMsgGet({code, key})
        }
        buttons = buttons || [{name: '确定', label: 0}]
        let res = await this.confirm.open({
            message: message,
            button: buttons
        })
        return res
    }

    async configLoad(resource, user_code){
        let list = {
            "game": 1,
            "puzzle": 2,
            'personal': 3,
            'avatar': 4,
            'gaotu_video': 5,
            'subject': 6,
            'video': 7,
            'subject_diffculty': 9,
            'subject_all': 4, // 专项-初中 临时4
            'subject_term_last': 5, // 专项-初中-上学期
            'subject_term_next': 6, // 专项-初中-下学期
        }
        let resource_type = list[resource]
        if(!resource_type) return false
        let optionsData = {
            resource_type,
        }
        if(user_code) optionsData.user_code = user_code
        let options = {
            cacheData: true,
            data: optionsData,
        }
        let res = await this.requestAction('config', options)
        if(res){
            let data = []
            for(let i in res){
                let {resourceDetail} = res[i]
                try{
                    resourceDetail = JSON.parse(resourceDetail)
                }catch(e){
                    resourceDetail = {}
                }
                res[i].resourceDetail = resourceDetail
                if(res[i].deleteFlag) continue
                data.push(res[i])
            }
            res = data
        }
        return res
    }
    async balanceCheck(opt){
        opt = opt || {}
        let {usercode: user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!user_code) return
        let options = {
            ...opt,
            user_code,
            data: {
                // schoolId
            }
        }
        let res = await this.requestAction('balanceCheck', options)
        if(res && this._common.confirmPro){
            let goMax = 150, mindMax = 200
            let message = `亲爱的宝贝，今天的棋石奖励（${goMax}个）已到上限，明天继续努力鸭~`
            if(this.PARAMS.school_id == '10001' || this.PARAMS.school_id == '20001') message = `亲爱的宝贝，今天的思维币奖励（${mindMax}个）已到上限，明天继续努力鸭~`
            this._common.confirmPro.open({
                message,
                className: 'balance-check',
                confirmType: 'c10 balance-check',
                buttons: [
                    {title: '确认', type: 'confirm'}
                ]
            })
        }
        return res
    }

    async allExercise(opt){
        let {type = 2} = opt || {}
        let options = {
            method: "get",
            type,
            data:{
            }
        }
        let res = await this.requestAction('allExercise', options)
        return this._common.mindExerciseHandler(res)
    }

    async updateExercise(opt){
        let {type, id, formulaContent} = opt || {}
        if(!id || !type || !formulaContent) return
        let options = {
            method: "post",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data:[
                {
                    type, id, formulaContent
                }
            ]
        }
        let res = await this.requestAction('updateExercise', options)
        return res
    }

    async levelList(opt){
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,
            data: {}
        }
        let res = await this.requestAction('levelList', options)
        return res

    }
}