export default {
    'task-summary': {
        // t1: '我的作业',
        t1: '练 功 房',
        t2: '当前无权限查看该课程作业',
        t3: '课程暂未开放，敬请期待~',
        t4: '已完成',
        t5: '节课'
    },
    'task-action': {
        t1: '查看结果'
    },
    'task-result': {
        t1: '再做一遍',
        t2: '错题巩固',
        t3: '满分全对！再接再厉哦~'
    }
}
