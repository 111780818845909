export default {
    'getGoodsList': {
        url: '/town/goods/list',
        serve: 'social',
    },
    'postPurchased': {
        url: '/town/goods/purchase/{id}',
        serve: 'social',
    },
    'purchasedRecord': {
        url: '/town/school/user/task/record/{user_code}',
        serve: 'social'
    },
    'mallOverdue': {
        url: '/town/goods/overdue',
        serve: 'social'
    },
    'mallStatus': {
        url: '/town/goods/status',
        serve: 'social'
    },
    // 检验兑换码
    'checkRedeemCode': {
        url: '/town/goods/check/card',
        serve: 'social',
    },
    // 兑换记录
    'exchangeRecord': { 
        url: '/town/goods/user/record/{user_code}',
        serve: 'social',
    },
    // 购买商品
    'postExchange': {
        url: '/town/goods/purchase/card/{id}',
        serve: 'social',
    },
    // 活跃度点击上报
    'postDashboardClick': {
        url: '/town/dashboard/user/click',
        serve: 'social',
    }
}