export default {
	'subject-directory': {
		t1: '的学习档案',
		t2: '近5局',
		t3: '近20局',
		t4: '全部',
		t5: '棋局统计',
		t6: '闯关统计',
		t7: '近7天',
		t8: '近30天',
		t9: '累计',
		t10: '胜率',
		t11: '正确率',
		t12: '全部',
	},
	'subject-list': {
		t1: '按顺序解锁关卡噢～'
	},
	'subject-summary': {
		t1: '按顺序解锁关卡噢～'
	},
	'subject-summary-full': {
		t1: '按顺序解锁关卡噢～',
		t2: '关卡暂未开放，敬请期待'
	},
	'subject-list-full': {
		t1: '按顺序解锁关卡噢～'
	},
	'subject-result': {
		t1: '继续闯关',
		t2: '重新闯关',
		t3: '返回闯关',
	},
	'subject-action': {
		t1: '查看结果',
		t2: '提示'
	}
}