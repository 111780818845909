const index = () => import(/* webpackChunkName: history */ "../views/history/index.vue")
const summary = () => import(/* webpackChunkName: history */ "../views/history/page/summary.vue")

export default {
    path: "/history",
    name: "history",
    redirect: '/history/summary/-1',
    component: index,
    children:[
        {
            path: 'summary/:exerciseId', 
            name: 'history-summary', 
            component: summary, 
            backRouteName: 'user-summary',
        },
    ]
}