

export default {
    //用户状态
    'GAME_USER_REFUSE': -1,
    'GAME_USER_CREATE': 0,
    'GAME_USER_LOGGED_IN': 10,
    'GAME_USER_IDLE': 20,
    'GAME_USER_BUSY': 30,
    'GAME_USER_PLAYING': 40,
    'GAME_USER_OFFLINE': 50,
    'GAME_USER_LOGGED_OUT': 90,

    //连接
    'CONNECTION_CLOSED': 0,
    'CONNECTION_OPEN': 1,

    //高途
    'GAOTU_STUDENT': 0,
    'GAOTU_TEACHER': 1,
    'GAOTU_ADMIN': 2,

    //关注
    'SOCIAL_NO_FOLLOW': 0,
    'SOCIAL_FOLLOWING': 1,
    'SOCIAL_FOLLOWED': 2,
    'SOCIAL_MUTUAL_FOLLOW': 3,

    'SOCIAL_NO_FOLLOW_key': 'none',
    'SOCIAL_FOLLOWING_key': 'following',
    'SOCIAL_FOLLOWED_key': 'followed',
    'SOCIAL_MUTUAL_FOLLOW_key': 'mutualFollow',

    //房间状态
    'ROOM_CREATED': 0,
    'ROOM_CONFIGURING': 10,
    'ROOM_CONFIGURED': 20,
    'ROOM_PLAYING': 30,
    'ROOM_ENDED': 40,
    'ROOM_CLOSED': 50,
    'ROOM_DELETED': 60,

    //房间内对局者状态
    'ROOM_PLAYER_CREATED': 0,
    'ROOM_PLAYER_LOGGED_IN': 10,
    'ROOM_PLAYER_CONFIGURING': 20,
    'ROOM_PLAYER_CONFIGURED': 30,
    'ROOM_PLAYER_WATCHING': 40,
    'ROOM_PLAYER_PLAYING': 50,
    'ROOM_PLAYER_PLAYED': 60,
    'ROOM_PLAYER_LOGGED_OUT': 90,

    //对局状态
    'GAME_CREATED': 0,
    'GAME_OPENING': 10,
    'GAME_MIDDLE': 20,
    'GAME_ENDING': 30,
    'GAME_ENDED': 40,


    //action type
    'ACTION_UNDO': 10,
    'ACTION_JUDGE': 20,
    'ACTION_DRAW': 30,
    'ACTION_RESULT': 40,
    'ACTION_PLAY_AGAIN': 80,

    //game type
    'NORMAL_GAME': 80,
    'RANK_GAME': 82,

    //教室
    'CLASSROOM_ADMIN': 10,
    'CLASSROOM_STUDENT': 20,

    //房间角色
    'GAME_ROLE_UNKNOWN': 0,
    'GAME_ROLE_OWNER': 5,
    'GAME_ROLE_PLAYER': 10,
    'GAME_ROLE_AUDIENCE': 20,

    //房间类型
    'INVITE_GAMEROOM': 10,
    'TASTE_GAMEROOM': 20,

    // 性别
    'GENDER_MAN': 1,
    'GENDER_WOMAN': 2,

    //报告
    'REPORT_STATUS': 2,
    'ANALYZE_SHARE': 1,

    //任务
    'DAILY_TASK': 10,
    'NORMAL_TASK': 20,
    'DAILY_LOGIN': 110,
    'DAILY_WS_GAME': 120,
    'WS_GAME_COMPETITIVE_WIN': 210,
    'TASK_RECEIVED': 10,
    'TASK_COMPOLETED': 10,
    'TASK_LIMITED': 30,

    //web设备号
    'WEB_DEVICE_TYPE': 1,

    // 课程
    'OFFLINE_COURSE': 'OC',

    // api version
    'SOCKET_API_VERSION': '2.0',

    'EXERCISE_CAPTURE_MATCH': 601,
    'EXERCISE_CONTAIN_MATCH': 602,
    'EXERCISE_CAPTURE_PEER_COMPETITIVE_MATCH': 681,
    'EXERCISE_CONTAIN_PEER_COMPETITIVE_MATCH': 682,
    'EXERCISE_CAPTURE_TASTE_COMPETITIVE_MATCH': 683,
    'EXERCISE_CONTAIN_TASTE_COMPETITIVE_MATCH': 684,

    // 房间用户状态
    'GAME_ROOM_USER_CREATED': 0,
    'GAME_ROOM_USER_LOGGED_IN': 10,
    'GAME_ROOM_USER_CONFIGURING': 20,
    'GAME_ROOM_USER_CONFIGURED': 30,
    'GAME_ROOM_USER_PLAYING': 40,
    'GAME_ROOM_USER_ENDED': 50,
    'GAME_ROOM_USER_WATCHING': 60,
    'GAME_ROOM_USER_LOGGED_OUT': 90,

    // 课程作业习题类型
    'COURSE_EXERCISE': 0,
    'COURSE_EXPANDD_EXERCISE': 10,

}
