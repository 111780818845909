<!--  -->
<template>
    <div class="global-com-cert-single" :class="`global-com-cert-single_${info.id}`">
        <div class="cert-bcg">
            <div class="item" v-for="v in bcgList" :key="v.class" :class="v.class"></div>
        </div>
        <div class="cert-static-text">
            <div class="item" v-for="v in staticTexts" :key="v.boxClass" :class="v.boxClass">
                <span class="bold text text-" :class="v.textClass">{{v.content}}</span>
            </div>
        </div>
        <div class="level-large">
            <span class="text-large-da bold">{{level}}</span>
        </div>
        <div class="level-content">
            <span class="text-small name">
                {{name}}
                <span class="text-under-line text-recover"></span>
            </span>
            <span class="text-small">{{content1}}</span>
            <span class="text-small level">
                {{level}}
                <span class="text-under-line text-recover"></span>
            </span>
            <span class="text-small">{{content2}}</span>
        </div>
        <div class="date-content">
            <span class="text-small">{{date}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            info: {
                type: Object,
                default(){ return {} }
            },
            downLoad: Boolean,
        },
        data() {
            return {
                bcgList: [
                    {class: 'b1'},
                    {class: 'b2'},
                ],
                staticTexts: [
                    {content: this.TEXT.certSingle['t1'], boxClass: 't1', textClass: 'text-small'},
                    {content: this.TEXT.certSingle['t2'], boxClass: 't2', textClass: 'text-small-half'},
                    {content: this.TEXT.certSingle['t3'], boxClass: 't3', textClass: 'text-small'},
                ],
                userInfo: this.$store.state.app,
                imgHasLoaded: false,
                CDNLinks: [
                    {url: `${this.PARAMS.assetsUrlV2_0}/certificate/c31.png`, query: '.global-com-cert-single'},
                    {url: `${this.PARAMS.assetsUrlV2_0}/certificate/c31_0.png`, query: '.global-com-cert-single .cert-bcg .b1'},
                    {url: `${this.PARAMS.assetsUrlV2_0}/certificate/c31_1.png`, query: '.global-com-cert-single .cert-bcg .b2'},
                ]
            };
        },
        methods: {
            async imgLoadInit(){
                // if(this.imgHasLoaded || !this.downLoad) return
                await this._common.nextTick.call(this)
                await this.downCDNImg()
                this.imgHasLoaded = true
                this.eleImgInit()
                return true
            },
            eleImgInit(){
                let {CDNLinks: list} = this
                for(let i in list){
                    let {query, imgLocalUrl} = list[i]
                    let ele = document.querySelector(query)
                    if(!ele || !imgLocalUrl) continue
                    if(ele != 'IMG'){
                        ele.style.background = `url('${imgLocalUrl}') no-repeat`
                        ele.style.backgroundSize = `100% 100%`
                    }else{
                        ele.src = imgLocalUrl
                    }
                    
                }
            },
            async downCDNImg(index){
                let {CDNLinks: list} = this
                index = index || 0
                if(!list[index]) return
                let {url} = list[index]
                let res = await this.getImage(url)
                if(res) this.CDNLinks[index].imgLocalUrl = res
                index++
                return this.downCDNImg(index)
            },
            getImage(url) {
                return new Promise(success => {
                    var xhr = new XMLHttpRequest();
                    xhr.open('get', url, true);
                    // 设置请求头（这一步得设置不然oss图片还是跨域）
                    xhr.setRequestHeader("Cache-Control", "no-cache");
                    xhr.responseType = 'blob';
                    xhr.onload = function () {
                        if (this.status == 200) {
                            let url = URL.createObjectURL(this.response);
                            success(url)
                        }else success(false)
                    };
                    xhr.send();
                })
                
            },
        },
        created() {
        },
        components: {
        },
        computed: {
            name(){
                let {nickname} = this.info
                nickname = nickname || this.userInfo.nickname
                return nickname
            },
            content1(){
                let text = this.TEXT.certSingle.content1
                return text
            },
            content2(){
                let text = this.TEXT.certSingle.content2
                return text
            },
            date(){
                let {date} = this.info
                return date
            },
            level(){
                let {level} = this.info
                return `${level}级`
            },
            id(){
                let {id} = this.info
                return id
            }
        },
        watch: {},
        mounted() {
            this.imgLoadInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .global-com-cert-single {
        position: relative;
        width: 333px;
        height: 253px;
        .background-img-max(url('@{assetsUrlV2_0}/certificate/c31.png'));

        .cert-bcg {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;

            .item {
                position: absolute;
            }

            .b1 {
                width: 94px;
                height: 24px;
                top: 43px;
                left: 0px;
                right: 0px;
                margin: 0px auto;
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c31_0.png'));
            }

            .b2 {
                width: 49px;
                height: 46px;
                bottom: 47px;
                right: 70px;
                .background-img-max(url('@{assetsUrlV2_0}/certificate/c31_1.png'));
            }
        }

        .cert-static-text {
            .item {
                position: absolute;
                width: 100%;
                left: 0px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            .t1 {
                height: 5px;
                color: #606060;
                top: 80px;

                .txt {
                    height: 5px;
                }
            }

            .t2 {
                height: 5px;
                color: #636363;
                top: 91px;

                .text {
                    height: 5px;
                    transform: scale(0.5) translateX(0%);
                    justify-content: center;
                }
            }

            .t3 {
                height: 6px;
                color: #3E3E3E;
                left: auto;
                bottom: 68px;
                right: 95px;
                display: flex;
                transform: scale(0.5) translate(50%);
                width: auto;

                .text {}
            }
        }

        .level-large {
            position: absolute;
            top: 110px;
            width: 100%;
            text-align: center;
            color: #404040;
        }

        .level-content {
            color: #3E3E3E;
            position: absolute;
            width: 305px;
            height: 29px;
            align-content: space-between;
            bottom: 85px;
            text-align: left;
            left: 0px;
            right: 0px;
            margin: 0px auto;
            transform: scale(0.6);
            text-align: center;

            div[class^='content'] {
                display: flex;
                align-items: center;
            }

            span.name {
                margin-right: 3px;
            }

            span.level {
                margin: 0px 3px;
            }

            span[class^='text'] {
                position: relative;
                display: inline;

                .text-under-line {
                    position: absolute;
                    left: -5%;
                    bottom: -2px;
                    transform: translateY(100%);
                    height: 1.1px;
                    width: 110%;
                    background: #3E3E3E;
                }
            }

        }

        .date-content {
            position: absolute;
            right: 87px;
            bottom: 56px;
            color: #3E3E3E;
            display: flex;
            transform: scale(0.5) translateX(50%);
        }
    }
</style>