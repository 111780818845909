export default {
    'mistakes-summary': {
        t1: '错题巩固',
        t2: '今日新增',
        t3: '共复习',
        t4: '去复习',
    },
    'mistakes-exercise': {
        t1: '当前错题数',
        t2: '下一题',
        t3: '查看答案',
        t4: '正确答案',
        t5: '我的答案',
    },
    'mistakes-result': {
        t1: '再做一遍',
        t2: '返回'
    }
}